import {
  ApolloClient,
  ApolloLink,
  InMemoryCache, createHttpLink, from
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { startFetching, stopFetching, setNetworkError, clearNetworkError } from './slices/layout';
import store from './store';

const { dispatch } = store;

const httpLink = createHttpLink({
  uri: 'https://jarvigs.herokuapp.com/v1/graphql'
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => console.log(
      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    ));
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    dispatch(stopFetching());
    dispatch(setNetworkError());
  }
});

const authLink = setContext(async () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  };
});

const middleware = new ApolloLink((operation, forward) => {
  dispatch(clearNetworkError());
  dispatch(startFetching());
  return forward(operation);
});

const afterware = new ApolloLink((operation, forward) => forward(operation).map((response) => {
  dispatch(stopFetching());
  return response;
}));

const apolloClient = new ApolloClient({
  link: from([errorLink, middleware, afterware, authLink.concat(httpLink)]),
  cache: new InMemoryCache()
});

export default apolloClient;
