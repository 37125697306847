import type { FC } from 'react';

import type { Theme } from '@material-ui/core';
import { experimentalStyled, } from '@material-ui/core/styles';
import type { SxProps } from '@material-ui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = experimentalStyled('svg')``;

const Logo: FC<LogoProps> = (props) => (

  <LogoRoot
    height="52"
    version="1.1"
    viewBox="0 0 30 30"
    width="52"
    {...props}
  >
    <title>GabionyStone</title>

    <g>
      <path
        fill="#F89C27"
        d="M28.2,17.9c0.5-0.6,0.5,11.2,0,10.6c0.5,0.6-10.7,0.5-10.2,0c-0.5,0.6-0.1-8.1,0.4-7.9 C17.9,20.3,28.7,17.3,28.2,17.9"
      />
      <path
        fill="#FFFFFF"
        d="M5.1,7.2c0.2,0.3-4,1.4-3.8,1C1.1,8.5,1.3,1.1,1.5,1.4C1.3,1.1,5.9,1,5.7,1.4C5.9,1,5.3,7.4,5.1,7.2"
      />
      <path
        fill="#F89C27"
        d="M18.5,3.7c0.6,0-12.5,3-11.9,2.7C6,6.7,6.1,1.1,6.8,1.3C6.1,1.1,19.9,1,19.2,1.2C19.9,1,19.1,3.7,18.5,3.7"
      />
      <path
        fill="#FFFFFF"
        d="M7.7,22.5c0.2-0.3-6.2,1.2-5.8,1.4c-0.4-0.2-0.4,5.1,0,4.8c-0.4,0.3,8.7,0.4,8.2,0C10.6,29,7.9,22.1,7.7,22.5"
      />
      <path
        fill="#FFFFFF"
        d="M5.2,8.2c0.2-0.3-4.1,0.8-3.9,1C1.1,9,1,14.3,1.2,14C1,14.3,6,14.3,5.7,14C6,14.3,5.4,7.9,5.2,8.2"
      />
      <path
        fill="#F89C27"
        d="M28.1,16.1c0.4,0.7,1.1-15.5,0.7-14.7c0.5-0.8-9-0.8-8.6,0c-0.5-0.8,5.2,16.1,5.1,15.3 C25.4,17.5,28.5,16.9,28.1,16.1"
      />
      <path
        fill="#F89C27"
        d="M17.6,12.1c-0.3-0.4-1.3,7.8-0.9,7.4c-0.4,0.4,7.6-1.4,7.2-1.6c0.4,0.2-1.4-6.3-1.6-5.9 C22.4,11.6,17.3,11.7,17.6,12.1"
      />
      <path
        fill="#F89C27"
        d="M16.5,28.4c0.4,0.4,0.7-8.2,0.3-7.8c0.4-0.4-8.4,1.5-8,1.7c-0.4-0.2,2.3,6.4,2.4,6 C11.1,28.8,16.9,28.9,16.5,28.4"
      />
      <path
        fill="#FFFFFF"
        d="M1.5,22.2c-0.3,0.4-0.3-7.7,0-7.3c-0.3-0.4,4.3,0.1,4.2,0.5C5.8,15.1,7,21.2,6.8,21C7,21.2,1.2,22.6,1.5,22.2"
      />
      <path
        fill="#F89C27"
        d="M18,10.6c-0.2,0.3-0.2-5.2,0-4.9c-0.2-0.2,1.7-1.1,1.7-0.8c0-0.3,2.1,6.3,1.9,6C21.8,11.2,17.8,10.8,18,10.6"
      />
      <path
        fill="#F89C27"
        d="M6.4,7.6c0,0,0.1,4.5,0.4,7.1C7.2,17.3,8.1,21,8.1,21s2.9-0.2,3.9-0.2c0.9,0,3.3-0.9,3.3-0.9s0.6-5.7,1.1-7.7 c0.6-2,1.2-6.4-0.1-6.4C14.9,5.8,11.1,6.4,6.4,7.6"
      />
    </g>

  </LogoRoot>
);
export default Logo;
