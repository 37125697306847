import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { AppThunk } from '../store';

interface LayoutState {
  isFetching: boolean;
  isNetworkError: boolean;
}

const initialState: LayoutState = {
  isFetching: false,
  isNetworkError: false,
};

const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    getFetchingStatus(state: LayoutState, action: PayloadAction<boolean>): void {
      state.isFetching = action.payload;
    },
    startFetching(state: LayoutState): void {
      state.isFetching = true;
    },
    stopFetching(state: LayoutState): void {
      state.isFetching = false;
    },
    getNetworkError(state: LayoutState, action: PayloadAction<boolean>): void {
      state.isNetworkError = action.payload;
    },
    setNetworkError(state: LayoutState): void {
      state.isNetworkError = true;
    },
    clearNetworkError(state: LayoutState): void {
      state.isNetworkError = false;
    },
  }
});

export const { reducer } = slice;

export const getFetchingStatus = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.getFetchingStatus());
};

export const startFetching = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.startFetching());
};

export const stopFetching = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.stopFetching());
};

export const getNetworkError = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.getNetworkError());
};

export const setNetworkError = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setNetworkError());
};

export const clearNetworkError = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.clearNetworkError());
};

export default slice;
