import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      Account: 'Account',
      'Add column': 'Add column',
      'Add Customer': 'Add Customer',
      'Add empty block': 'Add empty block',
      'Add product': 'Add product',
      'Add segment': 'Add segment',
      'Add Spacer': 'Add Spacer',
      'Add tag': 'Add tag',
      'Add wall': 'Add wall',
      Address: 'Address',
      Attributes: 'Attributes',
      Browse: 'Browse',
      Bottom: 'Bottom',
      'Business Name': 'Business Name',
      Cancle: 'Cancle',
      'Change Customer': 'Change Customer',
      City: 'City',
      Complete: 'Complete',
      'Contact Details': 'Contact Details',
      Count: 'Count',
      Countinue: 'Countinue',
      Cover: 'Cover',
      Create: 'Create',
      'Create Segment': 'Create Segment',
      'Create Wizard & Process': 'Create Wizard & Process',
      Created: 'Created',
      'Creating a new product': 'Creating a new product',
      Customer: 'Customer',
      Customers: 'Customers',
      'Customer Type': 'Customer Type',
      'Customer updated!': 'Customer updated!',
      Dashboard: 'Dashboard',
      Diameter: 'Diameter',
      Delete: 'Delete',
      'Delete Project': 'Delete Project',
      DIC: 'DIC',
      Duplicate: 'Duplicate',
      'Duplicate Project': 'Duplicate Project',
      'Duplicate Tags': 'Duplicate Tags',
      'Duplicate Walls & Segments': 'Duplicate Walls & Segments',
      'Edit Segment': 'Edit Segment',
      'Edit Product': 'Edit Product',
      'Enable Product': 'Enable Product',
      'Enter a keyword': 'Enter a keyword',
      'Enter Project Name and add Tags': 'Enter a Project Name (unique) and optionally add tags (type and confirm with the + button).',
      Exclude: 'Exclude',
      'Export product list': 'Export product list',
      'Export project': 'Export project',
      Eye: 'Eye',
      File: 'File',
      General: 'General',
      High: 'High',
      Height: 'Height',
      ICO: 'ICO',
      Inner: 'Inner',
      Introducing: 'Introducing',
      'Item has been deleted': 'Item has been deleted',
      'Item has been updated': 'Item has been updated',
      'Item has been added': 'Item has been added',
      Items: 'Items',
      'Max network size': 'Max network size',
      Medium: 'Medium',
      'My projects': 'My projects',
      'Must be at least 3 characters': 'Must be at least 3 characters',
      'Must be a positive number': 'Must be a positive number',
      Name: 'Name',
      Next: 'Next',
      'New Project': 'New Project',
      'New Product': 'New Product',
      'Network Error': 'Network Error',
      'Network Settings': 'Network Settings',
      'Notes & Comments': 'Notes & Comments',
      'Number of networks': 'Number of networks',
      Length: 'Length',
      Location: 'Location',
      Login: 'Login',
      'Log in': 'Log in',
      Low: 'Low',
      Orientation: 'Orientation',
      Overview: 'Overview',
      Partition: 'Partition',
      'Partition - A': 'Partitions - A',
      'Partition - B': 'Partitions - B',
      'Partition - Inner': 'Partition - Inner',
      Partitions: 'Partitions',
      'Partitions - Inner': 'Partitions - Inner',
      'Percent Off': 'Percent Off',
      Phone: 'Phone',
      'Phone number': 'Phone number',
      Platform: 'Platform',
      'Please delete all dependent data first': 'Please delete all dependent data first',
      Plating: 'Plating',
      Previous: 'Previous',
      Price: 'Price',
      'Priority in calculation': 'Priority in calculation',
      'Private Project': 'Private Project',
      'Private Project is not visibile': 'Private Project is not visible to other users.',
      Products: 'Products',
      'Product List': 'Product List',
      'Product Code': 'Product Code',
      'Product Created': 'Product Created',
      'Product Updated': 'Product Updated',
      'Product Settings': 'Product Settings',
      Profile: 'Profile',
      'Profile updated': 'Profile updated!',
      Project: 'Project',
      Projects: 'Projects',
      'Project - Details': 'Project - Details',
      'Project Name': 'Project Name',
      'Project Tags': 'Project Tags',
      'Project Type': 'Project Type',
      'Project has been created and you can access': 'The project "{{projectName}}" has been created and you can access it.',
      'Project has been deleted': 'The project has been deleted.',
      'Project Status': 'Project Status',
      'Project with this name already exists': 'A project with this name already exists',
      Required: 'Required',
      'Rows per page': 'Rows per page',
      'Save Changes': 'Save Changes',
      'Save Settings': 'Save Settings',
      'Search customers': 'Search customers',
      'Segment dimensions': 'Segment dimensions',
      Select: 'Select',
      'Select file': 'Select file',
      'Select files': 'Select files',
      Settings: 'Settings',
      'Set in bulk': 'Set in bulk',
      Side: 'Sides',
      'Side - A': 'Side - A',
      'Side - B': 'Side - B',
      Sides: 'Sides',
      Size: 'Size',
      'Something went wrong!': 'Something went wrong!',
      Spacers: 'Spacers',
      Spirals: 'Spirals',
      State: 'State',
      Status: 'Status',
      Street: 'Street',
      'Subject name': 'Subject name',
      Tags: 'Tags',
      'The action was not successful': 'The action was not successful, try reloading the page.',
      Type: 'Type',
      Undefined: 'Undefined',
      Update: 'Update',
      'Update Customer': 'Update Customer',
      'Update Product': 'Update Product',
      'Update Name': 'Update Name',
      Updated: 'Updated',
      Upload: 'Upload',
      'You are all done': 'You are all done!',
      'You are going to permanently delete the project': 'You are going to permanently delete the project with all its dependencies.',
      'You are going to permanently delete the wall': 'You are going to permanently delete the wall with all its segments.',
      'You have no projects available': 'You have no projects available.',
      'View project': 'View project',
      'Walls & Segments': 'Walls & Segments',
      Web: 'Web',
      Weight: 'Weight',
      Width: 'Width',
      'Write something': 'Write something',
      bottom: 'bottom',
      cover: 'cover',
      sideA: 'side A',
      sideB: 'side B',
      'Spiral Settings': 'Spiral Settings',
      partitionA: 'partition A',
      partitionB: 'partition B',
      partitionInner: 'partition inner'
    }
  },

  cs: {
    translation: {
      Account: 'Účet',
      'Add column': 'Přidat sloupec',
      'Add Customer': 'Přidat zákazníka',
      'Add empty block': 'Přidat volný blok',
      'Add product': 'Přidat produkt',
      'Add segment': 'Přidat segment',
      'Add Spacer': 'Přidat sponu',
      'Add tag': 'Přidat tag',
      'Add wall': 'Přidat zeď',
      Address: 'Adresa',
      Attributes: 'Atributy',
      'Business Name': 'Obchodní název',
      Browse: 'Prohlížej',
      Bottom: 'Dno',
      Cancle: 'Zrušit',
      'Change Customer': 'Změnit zákazníka',
      City: 'Město',
      Complete: 'Dokončit',
      'Contact Details': 'Kontaktní informace',
      Count: 'Počet',
      Countinue: 'Pokračujte',
      Cover: 'Víko',
      Create: 'Vytvoř',
      'Create Segment': 'Vytvoř segment',
      'Create Wizard & Process': 'Průvodce vytvořením',
      Created: 'Vytvořeno',
      'Creating a new product': 'Vytvoření nového produktu',
      Customer: 'Zákazník',
      Customers: 'Zákazníci',
      'Customer Type': 'Typ zákazníka',
      'Customer updated!': 'Zákazník aktualizován!',
      Dashboard: 'Nástěnka',
      Diameter: 'Průměr',
      DIC: 'DIČ',
      Duplicate: 'Duplikovat',
      'Duplicate Project': 'Duplikovat projekt',
      'Duplicate Tags': 'Duplikovat tagy',
      'Duplicate Walls & Segments': 'Duplikovat zdi a segmenty',
      Delete: 'Odstranit',
      'Delete Project': 'Odstranit projekt',
      'Edit Segment': 'Uprav Segment',
      'Enable Product': 'Zapnout Produkt',
      'Edit Product': 'Uprav Produkt',
      'Enter a keyword': 'Zadejte hledaný výraz',
      'Enter Project Name and add Tags': 'Zadejte název projektu (unikátní) a libovolně přidejte tagy.',
      Exclude: 'Vynechat',
      'Export product list': 'Export seznamu produktů',
      'Export project': 'Export projektu',
      Eye: 'Oko',
      File: 'Soubor',
      General: 'Všeobecné',
      High: 'Vysoká',
      Height: 'Výška',
      ICO: 'IČO',
      Inner: 'Vnitřní',
      Introducing: 'Představujemme',
      'Item has been deleted': 'Položka byla odstraněna',
      'Item has been updated': 'Položka byla aktualizována',
      'Item has been added': 'Položka byla přidána',
      Items: 'Položky',
      'Max network size': 'Maximální velikost sítě',
      'My projects': 'Mé projekty',
      Medium: 'Střední',
      'Must be at least 3 characters': 'Musí mít alespoň 3 znaky',
      'Must be a positive number': 'Musí být pozitivní číslo',
      Name: 'Jméno',
      Next: 'Další',
      'New Project': 'Nový projekt',
      'New Product': 'Nový produkt',
      'Network Error': 'Chyba sítě',
      'Network Settings': 'Nastavení sítě',
      'Notes & Comments': 'Poznámky a komentáře',
      'Number of networks': 'Počet sítí',
      Length: 'Délka',
      Location: 'Lokace',
      Login: 'Přihlášení',
      'Log in': 'Přihlásit se',
      Low: 'Nízká',
      Orientation: 'Orientace',
      Overview: 'Přehled',
      Partition: 'Příčka',
      'Partition - A': 'Příčka - A',
      'Partition - B': 'Příčka - B',
      'Partition - Inner': 'Příčka - Vnitřní',
      Partitions: 'Příčky',
      'Partitions - Inner': 'Příčky - Vnitřní',
      'Percent Off': 'Sleva %',
      Phone: 'Telefon',
      'Phone number': 'Telefon',
      Platform: 'Platforma',
      'Please delete all dependent data first': 'Odstraňte prosím prvně všechny závislá data.',
      Plating: 'Pokovování',
      Previous: 'Předchozí',
      Price: 'Cena',
      'Priority in calculation': 'Priorita při výpočtu',
      'Private Project': 'Privátní projekt',
      'Private Project is not visibile': 'Privátní projekt není viditelný dalším uživatelům.',
      Products: 'Produkty',
      'Product List': 'Seznam produktů',
      'Product Code': 'Kód Produktu',
      'Product Created': 'Produkt vytvořen',
      'Product Updated': 'Produkt Aktualizován',
      'Product Settings': 'Nastavení Produktu',
      Profile: 'Profil',
      'Profile updated': 'Profil aktualizován!',
      Project: 'Projekt',
      Projects: 'Projekty',
      'Project - Details': 'Projekt - Detaily',
      'Project Name': 'Název projektu',
      'Project Tags': 'Projekt Tagy',
      'Project Type': 'Typ projektu',
      'Project has been created and you can access': 'Projekt "{{projectName}}" byl vytvořen, a můžete k němu přistoupit.',
      'Project has been deleted': 'Projekt byl odstraněn.',
      'Project Status': 'Status projektu',
      'Project with this name already exists': 'Projekt s tímto názvem již existuje',
      Required: 'Vyžadováno',
      'Rows per page': 'Počet řádků na stránku',
      'Save Changes': 'Uložit změny',
      'Save Settings': 'Uložit nastavení',
      'Search customers': 'Vyhledat zákazníky',
      'Segment dimensions': 'Rozměry segmentu',
      Select: 'Vybrat',
      'Select file': 'Vyberte soubor',
      'Select files': 'Vyberte soubory',
      Settings: 'Nastavení',
      'Set in bulk': 'Nastavit hromadně',
      Side: 'Strana',
      'Side - A': 'Strana - A',
      'Side - B': 'Strana - B',
      Sides: 'Strany',
      Size: 'Velikost',
      'Something went wrong!': 'Něco se nepovedlo!',
      Spacers: 'Spony',
      Spirals: 'Spirály',
      'Spiral Settings': 'Nastavení spirály',
      State: 'Stát',
      Status: 'Stav',
      Street: 'Ulice',
      'Subject name': 'Název subjektu',
      Tags: 'Tagy',
      'The action was not successful': 'Akce neproběhla v pořádku, zkuste načíst znovu stránku.',
      Type: 'Typ',
      Undefined: 'Nedefinováno',
      Update: 'Uprav',
      'Update Customer': 'Aktualizovat zákazníka',
      'Update Product': 'Upravit produkt',
      'Update Name': 'Upravte název',
      Updated: 'Aktualizováno',
      Upload: 'Nahrát',
      'You are all done': 'A je to!',
      'You are going to permanently delete the project': 'Chystáte se nenávratně odstranit projekt, a to i se všemi závislostmi.',
      'You are going to permanently delete the wall': 'Chystáte se nenávratně odstranit zeď, a to i se všemi jejími segmenty.',
      'You have no projects available': 'Nemáte k dispozici žádné projekty.',
      'View project': 'Zobraz projekt',
      'Walls & Segments': 'Zdi a Segmenty ',
      Web: 'Web',
      Weight: 'Hmotnost',
      Width: 'Šířka',
      'Write something': 'Napište něco...',
      bottom: 'dno',
      cover: 'víko',
      sideA: 'strana A',
      sideB: 'strana B',
      partitionA: 'příčka A',
      partitionB: 'příčka B',
      partitionInner: 'příčka vniřní'
    },
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'cs',
    fallbackLng: 'cs',
    interpolation: {
      escapeValue: false
    }
  });
