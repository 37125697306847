import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { AppThunk } from '../store';

interface ProductsState {
  products: Array<any>
}

const initialState: ProductsState = {
  products: []
};

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts(state: ProductsState, action: PayloadAction<any[]>): void {
      state.products = action.payload;
    }
  }
});

export const { reducer } = slice;

export const getProducts = (): AppThunk => async (dispatch): Promise<void> => {
//   const data = await fetch('https://gabionystone-master.firebaseio.com/jarvigs-products.json')
//     .then((response) => response.json())
//     .then((dat) => dat);
  const data = [
    {
      isEnabled: true,
      cost: 1.71,
      price: 1.8,
      sizeX: 0,
      sizeY: 0,
      weight: 0.99,
      code: '802',
      description: 'spona C - 50 galfan - ks',
      eye: '',
      name: 'spoj. materiál',
      type: 'spacer',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1757.78,
      price: 2737,
      sizeX: 0,
      sizeY: 0,
      weight: 7.5,
      code: '801',
      description: 'spona C - 50 galfan - krabice 1600ks',
      eye: '',
      name: 'spoj. materiál',
      type: 'spacer',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 3.05,
      price: 6.56,
      sizeX: 0,
      sizeY: 0,
      weight: 0.07,
      code: 'HJ050',
      description: 'spona 50  jednostranné',
      eye: '',
      name: 'spoj. materiál',
      type: 'spacer',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 3.05,
      price: 6.56,
      sizeX: 0,
      sizeY: 0,
      weight: 0.07,
      code: 'HO050',
      description: 'spona 50',
      eye: '',
      name: 'spoj. materiál',
      type: 'spacer',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2.39,
      price: 5.18,
      sizeX: 0,
      sizeY: 0,
      weight: 0.05,
      code: 'HJ030',
      description: 'spona 30  jednostraná',
      eye: '',
      name: 'spoj. materiál',
      type: 'spacer',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2.39,
      price: 5.18,
      sizeX: 0,
      sizeY: 0,
      weight: 0.05,
      code: 'HO030',
      description: 'spona 30',
      eye: '',
      name: 'spoj. materiál',
      type: 'spacer',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 4.89,
      price: 9.89,
      sizeX: 0,
      sizeY: 0,
      weight: 0.1226,
      code: 'HJ100',
      description: 'spona 100  jednostranná',
      eye: '',
      name: 'spoj. materiál',
      type: 'spacer',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 4.89,
      price: 9.89,
      sizeX: 0,
      sizeY: 0,
      weight: 0.1226,
      code: 'HO100',
      description: 'spona 100',
      eye: '',
      name: 'spoj. materiál',
      type: 'spacer',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 4.59,
      price: 13.89,
      sizeX: 0,
      sizeY: 0,
      weight: 0.105,
      code: 'SP08017',
      description: 'spirála 80/17',
      eye: '',
      name: 'spoj. materiál',
      type: 'spiral',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 4.31,
      price: 12.65,
      sizeX: 0,
      sizeY: 0,
      weight: 0.09,
      code: 'SP07017',
      description: 'spirála 70/17',
      eye: '',
      name: 'spoj. materiál',
      type: 'spiral',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 4.03,
      price: 11.5,
      sizeX: 0,
      sizeY: 0,
      weight: 0.08,
      code: 'SP06025',
      description: 'spirála 60/25',
      eye: '',
      name: 'spoj. materiál',
      type: 'spiral',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 4.03,
      price: 11.5,
      sizeX: 0,
      sizeY: 0,
      weight: 0.08,
      code: 'SP06017',
      description: 'spirála 60/17',
      eye: '',
      name: 'spoj. materiál',
      type: 'spiral',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 3.89,
      price: 10.35,
      sizeX: 0,
      sizeY: 0,
      weight: 0.075,
      code: 'SP05517',
      description: 'spirála 55/17',
      eye: '',
      name: 'spoj. materiál',
      type: 'spiral',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 3.89,
      price: 9.43,
      sizeX: 0,
      sizeY: 0,
      weight: 0.07,
      code: 'SP05017',
      description: 'spirála 50/17',
      eye: '',
      name: 'spoj. materiál',
      type: 'spiral',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 3.45,
      price: 8.51,
      sizeX: 0,
      sizeY: 0,
      weight: 0.06,
      code: 'SP04017',
      description: 'spirála 40/17',
      eye: '',
      name: 'spoj. materiál',
      type: 'spiral',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 3.16,
      price: 7.94,
      sizeX: 0,
      sizeY: 0,
      weight: 0.038,
      code: 'SP03017',
      description: 'spirála 30/17',
      eye: '',
      name: 'spoj. materiál',
      type: 'spiral',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 10.94,
      price: 27.6,
      sizeX: 0,
      sizeY: 0,
      weight: 0.265,
      code: 'SP21025',
      description: 'spirála 210/25',
      eye: '',
      name: 'spoj. materiál',
      type: 'spiral',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 10.94,
      price: 26.45,
      sizeX: 0,
      sizeY: 0,
      weight: 0.26,
      code: 'SP21017',
      description: 'spirála 210/17',
      eye: '',
      name: 'spoj. materiál',
      type: 'spiral',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 9.2,
      price: 25.3,
      sizeX: 0,
      sizeY: 0,
      weight: 0.2,
      code: 'SP16017',
      description: 'spirála 160/17',
      eye: '',
      name: 'spoj. materiál',
      type: 'spiral',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 9.2,
      price: 23,
      sizeX: 0,
      sizeY: 0,
      weight: 0.2,
      code: 'SP15025',
      description: 'spirála 150/25',
      eye: '',
      name: 'spoj. materiál',
      type: 'spiral',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 9.2,
      price: 22.43,
      sizeX: 0,
      sizeY: 0,
      weight: 0.2,
      code: 'SP15017',
      description: 'spirála 150/17',
      eye: '',
      name: 'spoj. materiál',
      type: 'spiral',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 6.76,
      price: 16.68,
      sizeX: 0,
      sizeY: 0,
      weight: 0.15,
      code: 'SP11025',
      description: 'spirála 110/25',
      eye: '',
      name: 'spoj. materiál',
      type: 'spiral',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 6.76,
      price: 16.33,
      sizeX: 0,
      sizeY: 0,
      weight: 0.145,
      code: 'SP11017',
      description: 'spirála 110/17',
      eye: '',
      name: 'spoj. materiál',
      type: 'spiral',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 5.43,
      price: 10.86,
      sizeX: 10,
      sizeY: 10,
      weight: 0.039408138,
      code: 'A010010',
      description: '10x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 8.32,
      price: 16.63,
      sizeX: 20,
      sizeY: 10,
      weight: 0.068964242,
      code: 'A020010',
      description: '20x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 11.2,
      price: 22.4,
      sizeX: 30,
      sizeY: 10,
      weight: 0.098520346,
      code: 'A030010',
      description: '30x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 14.08,
      price: 28.17,
      sizeX: 40,
      sizeY: 10,
      weight: 0.128076449,
      code: 'A040010',
      description: '40x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 12.73,
      price: 25.45,
      sizeX: 50,
      sizeY: 10,
      weight: 0.157632553,
      code: 'A050010',
      description: '50x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 19.85,
      price: 39.7,
      sizeX: 60,
      sizeY: 10,
      weight: 0.187188657,
      code: 'A060010',
      description: '60x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 22.74,
      price: 45.47,
      sizeX: 70,
      sizeY: 10,
      weight: 0.21674476,
      code: 'A070010',
      description: '70x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 25.62,
      price: 51.24,
      sizeX: 80,
      sizeY: 10,
      weight: 0.246300864,
      code: 'A080010',
      description: '80x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 28.5,
      price: 57.01,
      sizeX: 90,
      sizeY: 10,
      weight: 0.275856968,
      code: 'A090010',
      description: '90x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 23.3,
      price: 46.61,
      sizeX: 100,
      sizeY: 10,
      weight: 0.305413071,
      code: 'A100010',
      description: '100x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 34.27,
      price: 68.54,
      sizeX: 110,
      sizeY: 10,
      weight: 0.334969175,
      code: 'A110010',
      description: '110x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 37.16,
      price: 74.31,
      sizeX: 120,
      sizeY: 10,
      weight: 0.364525279,
      code: 'A120010',
      description: '120x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 40.04,
      price: 80.08,
      sizeX: 130,
      sizeY: 10,
      weight: 0.394081383,
      code: 'A130010',
      description: '130x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 42.92,
      price: 85.85,
      sizeX: 140,
      sizeY: 10,
      weight: 0.423637486,
      code: 'A140010',
      description: '140x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 34.12,
      price: 68.24,
      sizeX: 150,
      sizeY: 10,
      weight: 0.45319359,
      code: 'A150010',
      description: '150x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 48.69,
      price: 97.38,
      sizeX: 160,
      sizeY: 10,
      weight: 0.482749694,
      code: 'A160010',
      description: '160x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 51.58,
      price: 103.15,
      sizeX: 170,
      sizeY: 10,
      weight: 0.512305797,
      code: 'A170010',
      description: '170x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 54.46,
      price: 108.92,
      sizeX: 180,
      sizeY: 10,
      weight: 0.541861901,
      code: 'A180010',
      description: '180x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 57.34,
      price: 114.69,
      sizeX: 190,
      sizeY: 10,
      weight: 0.571418005,
      code: 'A190010',
      description: '190x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 44.93,
      price: 89.87,
      sizeX: 200,
      sizeY: 10,
      weight: 0.600974108,
      code: 'A200010',
      description: '200x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 66,
      price: 131.99,
      sizeX: 220,
      sizeY: 10,
      weight: 0.660086316,
      code: 'A220010',
      description: '220x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 68.88,
      price: 137.76,
      sizeX: 230,
      sizeY: 10,
      weight: 0.689642419,
      code: 'A230010',
      description: '230x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 55.75,
      price: 111.5,
      sizeX: 250,
      sizeY: 10,
      weight: 0.748754627,
      code: 'A250010',
      description: '250x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 66.56,
      price: 133.13,
      sizeX: 300,
      sizeY: 10,
      weight: 0.896535145,
      code: 'A300010',
      description: '300x10',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 6.55,
      price: 13.1,
      sizeX: 10,
      sizeY: 10,
      weight: 0.049260173,
      code: 'B010010',
      description: '10x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 10.08,
      price: 20.16,
      sizeX: 20,
      sizeY: 10,
      weight: 0.088668311,
      code: 'B020010',
      description: '20x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 10.21,
      price: 20.41,
      sizeX: 30,
      sizeY: 10,
      weight: 0.128076449,
      code: 'B030010',
      description: '30x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 17.14,
      price: 34.27,
      sizeX: 40,
      sizeY: 10,
      weight: 0.167484588,
      code: 'B040010',
      description: '40x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 15.14,
      price: 30.28,
      sizeX: 50,
      sizeY: 10,
      weight: 0.206892726,
      code: 'B050010',
      description: '50x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 24.19,
      price: 48.38,
      sizeX: 60,
      sizeY: 10,
      weight: 0.246300864,
      code: 'B060010',
      description: '60x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 27.72,
      price: 55.44,
      sizeX: 70,
      sizeY: 10,
      weight: 0.285709002,
      code: 'B070010',
      description: '70x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 31.25,
      price: 62.5,
      sizeX: 80,
      sizeY: 10,
      weight: 0.325117141,
      code: 'B080010',
      description: '80x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 34.78,
      price: 69.55,
      sizeX: 90,
      sizeY: 10,
      weight: 0.364525279,
      code: 'B090010',
      description: '90x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 28.37,
      price: 56.74,
      sizeX: 100,
      sizeY: 10,
      weight: 0.403933417,
      code: 'B100010',
      description: '100x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 41.83,
      price: 83.66,
      sizeX: 110,
      sizeY: 10,
      weight: 0.443341555,
      code: 'B110010',
      description: '110x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 6.72,
      price: 13.44,
      sizeX: 10,
      sizeY: 10,
      weight: 0.059112207,
      code: 'C010010',
      description: '10x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 11.37,
      price: 22.74,
      sizeX: 20,
      sizeY: 10,
      weight: 0.10837238,
      code: 'C020010',
      description: '20x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 12.01,
      price: 24.02,
      sizeX: 30,
      sizeY: 10,
      weight: 0.157632553,
      code: 'C030010',
      description: '30x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 20.66,
      price: 41.33,
      sizeX: 40,
      sizeY: 10,
      weight: 0.206892726,
      code: 'C040010',
      description: '40x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 18.98,
      price: 37.97,
      sizeX: 50,
      sizeY: 10,
      weight: 0.256152899,
      code: 'C050010',
      description: '50x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 29.96,
      price: 59.92,
      sizeX: 60,
      sizeY: 10,
      weight: 0.305413071,
      code: 'C060010',
      description: '60x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 34.61,
      price: 69.22,
      sizeX: 70,
      sizeY: 10,
      weight: 0.354673244,
      code: 'C070010',
      description: '70x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 39.26,
      price: 78.51,
      sizeX: 80,
      sizeY: 10,
      weight: 0.403933417,
      code: 'C080010',
      description: '80x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 43.9,
      price: 87.81,
      sizeX: 90,
      sizeY: 10,
      weight: 0.45319359,
      code: 'C090010',
      description: '90x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 36.41,
      price: 72.83,
      sizeX: 100,
      sizeY: 10,
      weight: 0.502453763,
      code: 'C100010',
      description: '100x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 53.2,
      price: 106.4,
      sizeX: 110,
      sizeY: 10,
      weight: 0.551713936,
      code: 'C110010',
      description: '110x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 45.36,
      price: 90.72,
      sizeX: 120,
      sizeY: 10,
      weight: 0.482749694,
      code: 'B120010',
      description: '120x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 48.89,
      price: 97.78,
      sizeX: 130,
      sizeY: 10,
      weight: 0.522157832,
      code: 'B130010',
      description: '130x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 57.85,
      price: 115.7,
      sizeX: 120,
      sizeY: 10,
      weight: 0.600974108,
      code: 'C120010',
      description: '120x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 62.5,
      price: 124.99,
      sizeX: 130,
      sizeY: 10,
      weight: 0.650234281,
      code: 'C130010',
      description: '130x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 67.14,
      price: 134.29,
      sizeX: 140,
      sizeY: 10,
      weight: 0.699494454,
      code: 'C140010',
      description: '140x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 53.84,
      price: 107.69,
      sizeX: 150,
      sizeY: 10,
      weight: 0.748754627,
      code: 'C150010',
      description: '150x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 76.44,
      price: 152.88,
      sizeX: 160,
      sizeY: 10,
      weight: 0.7980148,
      code: 'C160010',
      description: '160x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 81.09,
      price: 162.18,
      sizeX: 170,
      sizeY: 10,
      weight: 0.847274972,
      code: 'C170010',
      description: '170x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 85.74,
      price: 171.47,
      sizeX: 180,
      sizeY: 10,
      weight: 0.896535145,
      code: 'C180010',
      description: '180x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 90.38,
      price: 180.77,
      sizeX: 190,
      sizeY: 10,
      weight: 0.945795318,
      code: 'C190010',
      description: '190x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 71.27,
      price: 142.55,
      sizeX: 200,
      sizeY: 10,
      weight: 0.995055491,
      code: 'C200010',
      description: '200x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 99.68,
      price: 199.36,
      sizeX: 210,
      sizeY: 10,
      weight: 1.044315664,
      code: 'C210010',
      description: '210x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 104.33,
      price: 208.66,
      sizeX: 220,
      sizeY: 10,
      weight: 1.093575836,
      code: 'C220010',
      description: '220x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 108.98,
      price: 217.95,
      sizeX: 230,
      sizeY: 10,
      weight: 1.142836009,
      code: 'C230010',
      description: '230x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 113.62,
      price: 227.25,
      sizeX: 240,
      sizeY: 10,
      weight: 1.192096182,
      code: 'C240010',
      description: '240x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 88.7,
      price: 177.41,
      sizeX: 250,
      sizeY: 10,
      weight: 1.241356355,
      code: 'C250010',
      description: '250x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 122.92,
      price: 245.84,
      sizeX: 260,
      sizeY: 10,
      weight: 1.290616528,
      code: 'C260010',
      description: '260x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 127.57,
      price: 255.14,
      sizeX: 270,
      sizeY: 10,
      weight: 1.339876701,
      code: 'C270010',
      description: '270x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 132.22,
      price: 264.43,
      sizeX: 280,
      sizeY: 10,
      weight: 1.389136873,
      code: 'C280010',
      description: '280x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 136.87,
      price: 273.73,
      sizeX: 290,
      sizeY: 10,
      weight: 1.438397046,
      code: 'C290010',
      description: '290x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 106.13,
      price: 212.27,
      sizeX: 300,
      sizeY: 10,
      weight: 1.487657219,
      code: 'C300010',
      description: '300x10',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 52.42,
      price: 104.83,
      sizeX: 140,
      sizeY: 10,
      weight: 0.56156597,
      code: 'B140010',
      description: '140x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 41.6,
      price: 83.2,
      sizeX: 150,
      sizeY: 10,
      weight: 0.600974108,
      code: 'B150010',
      description: '150x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 9.27,
      price: 18.53,
      sizeX: 10,
      sizeY: 10,
      weight: 0.068964242,
      code: 'D010010',
      description: '10x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 14.32,
      price: 28.64,
      sizeX: 20,
      sizeY: 10,
      weight: 0.128076449,
      code: 'D020010',
      description: '20x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 18.58,
      price: 37.17,
      sizeX: 30,
      sizeY: 10,
      weight: 0.187188657,
      code: 'D030010',
      description: '30x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 24.43,
      price: 48.86,
      sizeX: 40,
      sizeY: 10,
      weight: 0.246300864,
      code: 'D040010',
      description: '40x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 28.69,
      price: 57.38,
      sizeX: 50,
      sizeY: 10,
      weight: 0.305413071,
      code: 'D050010',
      description: '50x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 25.9,
      price: 51.81,
      sizeX: 60,
      sizeY: 10,
      weight: 0.364525279,
      code: 'D060010',
      description: '60x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 39.59,
      price: 79.19,
      sizeX: 70,
      sizeY: 10,
      weight: 0.423637486,
      code: 'D070010',
      description: '70x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 44.65,
      price: 89.29,
      sizeX: 80,
      sizeY: 10,
      weight: 0.482749694,
      code: 'D080010',
      description: '80x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 49.7,
      price: 99.4,
      sizeX: 90,
      sizeY: 10,
      weight: 0.541861901,
      code: 'D090010',
      description: '90x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 40.47,
      price: 80.94,
      sizeX: 100,
      sizeY: 10,
      weight: 0.600974108,
      code: 'D100010',
      description: '100x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 59.81,
      price: 119.62,
      sizeX: 110,
      sizeY: 10,
      weight: 0.660086316,
      code: 'D110010',
      description: '110x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 64.86,
      price: 129.73,
      sizeX: 120,
      sizeY: 10,
      weight: 0.719198523,
      code: 'D120010',
      description: '120x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 69.92,
      price: 139.84,
      sizeX: 130,
      sizeY: 10,
      weight: 0.77831073,
      code: 'D130010',
      description: '130x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 74.97,
      price: 149.94,
      sizeX: 140,
      sizeY: 10,
      weight: 0.837422938,
      code: 'D140010',
      description: '140x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 98.28,
      price: 196.56,
      sizeX: 270,
      sizeY: 10,
      weight: 1.073871767,
      code: 'B270010',
      description: '270x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 59.47,
      price: 118.95,
      sizeX: 160,
      sizeY: 10,
      weight: 0.640382247,
      code: 'B160010',
      description: '160x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 63,
      price: 126,
      sizeX: 170,
      sizeY: 10,
      weight: 0.679790385,
      code: 'B170010',
      description: '170x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 66.53,
      price: 133.06,
      sizeX: 180,
      sizeY: 10,
      weight: 0.719198523,
      code: 'B180010',
      description: '180x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 70.06,
      price: 140.11,
      sizeX: 190,
      sizeY: 10,
      weight: 0.758606661,
      code: 'B190010',
      description: '190x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 54.83,
      price: 109.66,
      sizeX: 200,
      sizeY: 10,
      weight: 0.7980148,
      code: 'B200010',
      description: '200x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 80.64,
      price: 161.28,
      sizeX: 220,
      sizeY: 10,
      weight: 0.876831076,
      code: 'B220010',
      description: '220x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 77.11,
      price: 154.23,
      sizeX: 210,
      sizeY: 10,
      weight: 0.837422938,
      code: 'B210010',
      description: '210x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 84.17,
      price: 168.34,
      sizeX: 230,
      sizeY: 10,
      weight: 0.916239214,
      code: 'B230010',
      description: '230x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 87.7,
      price: 175.4,
      sizeX: 240,
      sizeY: 10,
      weight: 0.955647353,
      code: 'B240010',
      description: '240x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 68.06,
      price: 136.13,
      sizeX: 250,
      sizeY: 10,
      weight: 0.995055491,
      code: 'B250010',
      description: '250x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 94.75,
      price: 189.51,
      sizeX: 260,
      sizeY: 10,
      weight: 1.034463629,
      code: 'B260010',
      description: '260x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 101.33,
      price: 202.67,
      sizeX: 280,
      sizeY: 10,
      weight: 1.113279906,
      code: 'B280010',
      description: '280x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 105.34,
      price: 210.68,
      sizeX: 290,
      sizeY: 10,
      weight: 1.152688044,
      code: 'B290010',
      description: '290x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 81.29,
      price: 162.59,
      sizeX: 300,
      sizeY: 10,
      weight: 1.192096182,
      code: 'B300010',
      description: '300x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 112.39,
      price: 224.79,
      sizeX: 310,
      sizeY: 10,
      weight: 1.23150432,
      code: 'B310010',
      description: '310x10',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 59.42,
      price: 118.85,
      sizeX: 150,
      sizeY: 10,
      weight: 0.896535145,
      code: 'D150010',
      description: '150x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 85.08,
      price: 170.16,
      sizeX: 160,
      sizeY: 10,
      weight: 0.955647353,
      code: 'D160010',
      description: '160x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 90.13,
      price: 180.27,
      sizeX: 170,
      sizeY: 10,
      weight: 1.01475956,
      code: 'D170010',
      description: '170x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 95.19,
      price: 190.38,
      sizeX: 180,
      sizeY: 10,
      weight: 1.073871767,
      code: 'D180010',
      description: '180x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 100.24,
      price: 200.49,
      sizeX: 190,
      sizeY: 10,
      weight: 1.132983975,
      code: 'D190010',
      description: '190x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 78.38,
      price: 156.76,
      sizeX: 200,
      sizeY: 10,
      weight: 1.192096182,
      code: 'D200010',
      description: '200x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 110.35,
      price: 220.7,
      sizeX: 210,
      sizeY: 10,
      weight: 1.251208389,
      code: 'D210010',
      description: '210x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 115.41,
      price: 230.81,
      sizeX: 220,
      sizeY: 10,
      weight: 1.310320597,
      code: 'D220010',
      description: '220x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 120.46,
      price: 240.92,
      sizeX: 230,
      sizeY: 10,
      weight: 1.369432804,
      code: 'D230010',
      description: '230x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 125.51,
      price: 251.03,
      sizeX: 240,
      sizeY: 10,
      weight: 1.428545012,
      code: 'D240010',
      description: '240x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 97.33,
      price: 194.66,
      sizeX: 250,
      sizeY: 10,
      weight: 1.487657219,
      code: 'D250010',
      description: '250x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 135.62,
      price: 271.25,
      sizeX: 260,
      sizeY: 10,
      weight: 1.546769426,
      code: 'D260010',
      description: '260x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 140.68,
      price: 281.35,
      sizeX: 270,
      sizeY: 10,
      weight: 1.605881634,
      code: 'D270010',
      description: '270x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 145.73,
      price: 291.46,
      sizeX: 280,
      sizeY: 10,
      weight: 1.664993841,
      code: 'D280010',
      description: '280x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 150.79,
      price: 301.57,
      sizeX: 290,
      sizeY: 10,
      weight: 1.724106049,
      code: 'D290010',
      description: '290x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 116.29,
      price: 232.57,
      sizeX: 300,
      sizeY: 10,
      weight: 1.783218256,
      code: 'D300010',
      description: '300x10',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 38.06,
      price: 76.12,
      sizeX: 100,
      sizeY: 15,
      weight: 0.556639953,
      code: 'B100015',
      description: '100x15',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 56.11,
      price: 112.23,
      sizeX: 110,
      sizeY: 15,
      weight: 0.610826143,
      code: 'B110015',
      description: '110x15',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 60.85,
      price: 121.69,
      sizeX: 120,
      sizeY: 15,
      weight: 0.665012333,
      code: 'B120015',
      description: '120x15',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 65.58,
      price: 131.15,
      sizeX: 130,
      sizeY: 15,
      weight: 0.719198523,
      code: 'B130015',
      description: '130x15',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 70.31,
      price: 140.62,
      sizeX: 140,
      sizeY: 15,
      weight: 0.773384713,
      code: 'B140015',
      description: '140x15',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 55.81,
      price: 111.61,
      sizeX: 150,
      sizeY: 15,
      weight: 0.827570903,
      code: 'B150015',
      description: '150x15',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 12.96,
      price: 25.93,
      sizeX: 20,
      sizeY: 20,
      weight: 0.118224415,
      code: 'A020020',
      description: '20x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 17.45,
      price: 34.91,
      sizeX: 30,
      sizeY: 20,
      weight: 0.167484588,
      code: 'A030020',
      description: '30x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 21.94,
      price: 43.88,
      sizeX: 40,
      sizeY: 20,
      weight: 0.21674476,
      code: 'A040020',
      description: '40x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 19.47,
      price: 38.93,
      sizeX: 50,
      sizeY: 20,
      weight: 0.266004933,
      code: 'A050020',
      description: '50x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 30.92,
      price: 61.84,
      sizeX: 60,
      sizeY: 20,
      weight: 0.315265106,
      code: 'A060020',
      description: '60x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 35.41,
      price: 70.82,
      sizeX: 70,
      sizeY: 20,
      weight: 0.364525279,
      code: 'A070020',
      description: '70x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 39.9,
      price: 79.8,
      sizeX: 80,
      sizeY: 20,
      weight: 0.413785452,
      code: 'A080020',
      description: '80x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 36.3,
      price: 72.6,
      sizeX: 100,
      sizeY: 20,
      weight: 0.512305797,
      code: 'A100020',
      description: '100x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 53.37,
      price: 106.74,
      sizeX: 110,
      sizeY: 20,
      weight: 0.56156597,
      code: 'A110020',
      description: '110x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 57.86,
      price: 115.72,
      sizeX: 120,
      sizeY: 20,
      weight: 0.610826143,
      code: 'A120020',
      description: '120x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 62.35,
      price: 124.69,
      sizeX: 130,
      sizeY: 20,
      weight: 0.660086316,
      code: 'A130020',
      description: '130x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 66.84,
      price: 133.67,
      sizeX: 140,
      sizeY: 20,
      weight: 0.709346489,
      code: 'A140020',
      description: '140x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 53.14,
      price: 106.28,
      sizeX: 150,
      sizeY: 20,
      weight: 0.758606661,
      code: 'A150020',
      description: '150x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 75.82,
      price: 151.63,
      sizeX: 160,
      sizeY: 20,
      weight: 0.807866834,
      code: 'A160020',
      description: '160x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 80.31,
      price: 160.61,
      sizeX: 170,
      sizeY: 20,
      weight: 0.857127007,
      code: 'A170020',
      description: '170x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 84.79,
      price: 169.59,
      sizeX: 180,
      sizeY: 20,
      weight: 0.90638718,
      code: 'A180020',
      description: '180x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 89.28,
      price: 178.57,
      sizeX: 190,
      sizeY: 20,
      weight: 0.955647353,
      code: 'A190020',
      description: '190x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 69.97,
      price: 139.95,
      sizeX: 200,
      sizeY: 20,
      weight: 1.004907525,
      code: 'A200020',
      description: '200x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 102.75,
      price: 205.5,
      sizeX: 220,
      sizeY: 20,
      weight: 1.103427871,
      code: 'A220020',
      description: '220x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 107.24,
      price: 214.48,
      sizeX: 230,
      sizeY: 20,
      weight: 1.152688044,
      code: 'A230020',
      description: '230x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 86.81,
      price: 173.62,
      sizeX: 250,
      sizeY: 20,
      weight: 1.251208389,
      code: 'A250020',
      description: '250x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 103.64,
      price: 207.29,
      sizeX: 300,
      sizeY: 20,
      weight: 1.497509254,
      code: 'A300020',
      description: '300x20',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 19.23,
      price: 38.45,
      sizeX: 20,
      sizeY: 20,
      weight: 0.197040691,
      code: 'C020020',
      description: '20x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 20.31,
      price: 40.63,
      sizeX: 30,
      sizeY: 20,
      weight: 0.285709002,
      code: 'C030020',
      description: '30x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 34.94,
      price: 69.89,
      sizeX: 40,
      sizeY: 20,
      weight: 0.374377313,
      code: 'C040020',
      description: '40x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 32.1,
      price: 64.21,
      sizeX: 50,
      sizeY: 20,
      weight: 0.463045624,
      code: 'C050020',
      description: '50x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 50.66,
      price: 101.32,
      sizeX: 60,
      sizeY: 20,
      weight: 0.551713936,
      code: 'C060020',
      description: '60x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 58.52,
      price: 117.04,
      sizeX: 70,
      sizeY: 20,
      weight: 0.640382247,
      code: 'C070020',
      description: '70x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 66.38,
      price: 132.76,
      sizeX: 80,
      sizeY: 20,
      weight: 0.729050558,
      code: 'C080020',
      description: '80x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 74.24,
      price: 148.48,
      sizeX: 90,
      sizeY: 20,
      weight: 0.817718869,
      code: 'C090020',
      description: '90x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 61.57,
      price: 123.15,
      sizeX: 100,
      sizeY: 20,
      weight: 0.90638718,
      code: 'C100020',
      description: '100x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 89.96,
      price: 179.91,
      sizeX: 110,
      sizeY: 20,
      weight: 0.995055491,
      code: 'C110020',
      description: '110x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 97.82,
      price: 195.63,
      sizeX: 120,
      sizeY: 20,
      weight: 1.083723802,
      code: 'C120020',
      description: '120x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 105.67,
      price: 211.35,
      sizeX: 130,
      sizeY: 20,
      weight: 1.172392113,
      code: 'C130020',
      description: '130x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 113.53,
      price: 227.07,
      sizeX: 140,
      sizeY: 20,
      weight: 1.261060424,
      code: 'C140020',
      description: '140x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 91.04,
      price: 182.09,
      sizeX: 150,
      sizeY: 20,
      weight: 1.349728735,
      code: 'C150020',
      description: '150x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 58.52,
      price: 117.04,
      sizeX: 90,
      sizeY: 20,
      weight: 0.640382247,
      code: 'B090020',
      description: '90x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 129.25,
      price: 258.5,
      sizeX: 160,
      sizeY: 20,
      weight: 1.438397046,
      code: 'C160020',
      description: '160x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 137.11,
      price: 274.22,
      sizeX: 170,
      sizeY: 20,
      weight: 1.527065357,
      code: 'C170020',
      description: '170x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 144.97,
      price: 289.94,
      sizeX: 180,
      sizeY: 20,
      weight: 1.615733668,
      code: 'C180020',
      description: '180x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 152.83,
      price: 305.66,
      sizeX: 190,
      sizeY: 20,
      weight: 1.704401979,
      code: 'C190020',
      description: '190x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 120.52,
      price: 241.03,
      sizeX: 200,
      sizeY: 20,
      weight: 1.79307029,
      code: 'C200020',
      description: '200x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 168.55,
      price: 337.09,
      sizeX: 210,
      sizeY: 20,
      weight: 1.881738602,
      code: 'C210020',
      description: '210x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 176.41,
      price: 352.81,
      sizeX: 220,
      sizeY: 20,
      weight: 1.970406913,
      code: 'C220020',
      description: '220x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 184.26,
      price: 368.53,
      sizeX: 230,
      sizeY: 20,
      weight: 2.059075224,
      code: 'C230020',
      description: '230x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 192.12,
      price: 384.25,
      sizeX: 240,
      sizeY: 20,
      weight: 2.147743535,
      code: 'C240020',
      description: '240x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 149.99,
      price: 299.97,
      sizeX: 250,
      sizeY: 20,
      weight: 2.236411846,
      code: 'C250020',
      description: '250x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 207.84,
      price: 415.68,
      sizeX: 260,
      sizeY: 20,
      weight: 2.325080157,
      code: 'C260020',
      description: '260x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 215.7,
      price: 431.4,
      sizeX: 270,
      sizeY: 20,
      weight: 2.413748468,
      code: 'C270020',
      description: '270x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 223.56,
      price: 447.12,
      sizeX: 280,
      sizeY: 20,
      weight: 2.502416779,
      code: 'C280020',
      description: '280x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 231.42,
      price: 462.84,
      sizeX: 290,
      sizeY: 20,
      weight: 2.59108509,
      code: 'C290020',
      description: '290x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 179.46,
      price: 358.91,
      sizeX: 300,
      sizeY: 20,
      weight: 2.679753401,
      code: 'C300020',
      description: '300x20',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 52.59,
      price: 105.17,
      sizeX: 80,
      sizeY: 20,
      weight: 0.571418005,
      code: 'B080020',
      description: '80x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 17.18,
      price: 34.36,
      sizeX: 30,
      sizeY: 20,
      weight: 0.226596795,
      code: 'B030020',
      description: '30x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 46.65,
      price: 93.3,
      sizeX: 70,
      sizeY: 20,
      weight: 0.502453763,
      code: 'B070020',
      description: '70x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 28.84,
      price: 57.68,
      sizeX: 40,
      sizeY: 20,
      weight: 0.295561037,
      code: 'B040020',
      description: '40x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 11.03,
      price: 22.06,
      sizeX: 10,
      sizeY: 20,
      weight: 0.088668311,
      code: 'B010020',
      description: '10x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 16.97,
      price: 33.94,
      sizeX: 20,
      sizeY: 20,
      weight: 0.157632553,
      code: 'B020020',
      description: '20x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 40.71,
      price: 81.43,
      sizeX: 60,
      sizeY: 20,
      weight: 0.433489521,
      code: 'B060020',
      description: '60x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 47.75,
      price: 95.5,
      sizeX: 100,
      sizeY: 20,
      weight: 0.709346489,
      code: 'B100020',
      description: '100x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 70.39,
      price: 140.79,
      sizeX: 110,
      sizeY: 20,
      weight: 0.77831073,
      code: 'B110020',
      description: '110x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 76.33,
      price: 152.66,
      sizeX: 120,
      sizeY: 20,
      weight: 0.847274972,
      code: 'B120020',
      description: '120x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 82.27,
      price: 164.53,
      sizeX: 130,
      sizeY: 20,
      weight: 0.916239214,
      code: 'B130020',
      description: '130x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 88.2,
      price: 176.41,
      sizeX: 140,
      sizeY: 20,
      weight: 0.985203456,
      code: 'B140020',
      description: '140x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 70.01,
      price: 140.02,
      sizeX: 150,
      sizeY: 20,
      weight: 1.054167698,
      code: 'B150020',
      description: '150x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 106.01,
      price: 212.02,
      sizeX: 170,
      sizeY: 20,
      weight: 1.192096182,
      code: 'B170020',
      description: '170x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 111.95,
      price: 223.9,
      sizeX: 180,
      sizeY: 20,
      weight: 1.261060424,
      code: 'B180020',
      description: '180x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 92.27,
      price: 184.54,
      sizeX: 200,
      sizeY: 20,
      weight: 1.398988908,
      code: 'B200020',
      description: '200x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 135.69,
      price: 271.39,
      sizeX: 220,
      sizeY: 20,
      weight: 1.536917392,
      code: 'B220020',
      description: '220x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 129.76,
      price: 259.51,
      sizeX: 210,
      sizeY: 20,
      weight: 1.46795315,
      code: 'B210020',
      description: '210x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 141.63,
      price: 283.26,
      sizeX: 230,
      sizeY: 20,
      weight: 1.605881634,
      code: 'B230020',
      description: '230x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 147.57,
      price: 295.13,
      sizeX: 240,
      sizeY: 20,
      weight: 1.674845876,
      code: 'B240020',
      description: '240x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 114.53,
      price: 229.06,
      sizeX: 250,
      sizeY: 20,
      weight: 1.743810118,
      code: 'B250020',
      description: '250x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 159.44,
      price: 318.88,
      sizeX: 260,
      sizeY: 20,
      weight: 1.81277436,
      code: 'B260020',
      description: '260x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 165.37,
      price: 330.75,
      sizeX: 270,
      sizeY: 20,
      weight: 1.881738602,
      code: 'B270020',
      description: '270x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 171.31,
      price: 342.62,
      sizeX: 280,
      sizeY: 20,
      weight: 1.950702843,
      code: 'B280020',
      description: '280x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 188.33,
      price: 376.65,
      sizeX: 310,
      sizeY: 20,
      weight: 2.157595569,
      code: 'B310020',
      description: '310x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 177.25,
      price: 354.49,
      sizeX: 290,
      sizeY: 20,
      weight: 2.019667085,
      code: 'B290020',
      description: '290x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 136.79,
      price: 273.58,
      sizeX: 300,
      sizeY: 20,
      weight: 2.088631327,
      code: 'B300020',
      description: '300x20',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 16.62,
      price: 33.24,
      sizeX: 10,
      sizeY: 20,
      weight: 0.128076449,
      code: 'D010020',
      description: '10x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 34.76,
      price: 69.51,
      sizeX: 30,
      sizeY: 20,
      weight: 0.34482121,
      code: 'D030020',
      description: '30x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 43.82,
      price: 87.65,
      sizeX: 40,
      sizeY: 20,
      weight: 0.45319359,
      code: 'D040020',
      description: '40x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 38.6,
      price: 77.2,
      sizeX: 50,
      sizeY: 20,
      weight: 0.56156597,
      code: 'D050020',
      description: '50x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 61.96,
      price: 123.92,
      sizeX: 60,
      sizeY: 20,
      weight: 0.66993835,
      code: 'D060020',
      description: '60x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 71.03,
      price: 142.06,
      sizeX: 70,
      sizeY: 20,
      weight: 0.77831073,
      code: 'D070020',
      description: '70x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 80.1,
      price: 160.19,
      sizeX: 80,
      sizeY: 20,
      weight: 0.886683111,
      code: 'D080020',
      description: '80x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 89.16,
      price: 178.33,
      sizeX: 90,
      sizeY: 20,
      weight: 0.995055491,
      code: 'D090020',
      description: '90x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 72.6,
      price: 145.21,
      sizeX: 100,
      sizeY: 20,
      weight: 1.103427871,
      code: 'D100020',
      description: '100x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 140.61,
      price: 281.22,
      sizeX: 200,
      sizeY: 20,
      weight: 2.187151673,
      code: 'D200020',
      description: '200x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 25.69,
      price: 51.38,
      sizeX: 20,
      sizeY: 20,
      weight: 0.23644883,
      code: 'D020020',
      description: '20x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 116.37,
      price: 232.73,
      sizeX: 120,
      sizeY: 20,
      weight: 1.320172631,
      code: 'D120020',
      description: '120x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 134.5,
      price: 269.01,
      sizeX: 140,
      sizeY: 20,
      weight: 1.536917392,
      code: 'D140020',
      description: '140x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 106.61,
      price: 213.21,
      sizeX: 150,
      sizeY: 20,
      weight: 1.645289772,
      code: 'D150020',
      description: '150x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 152.64,
      price: 305.28,
      sizeX: 160,
      sizeY: 20,
      weight: 1.753662152,
      code: 'D160020',
      description: '160x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 161.71,
      price: 323.41,
      sizeX: 170,
      sizeY: 20,
      weight: 1.862034532,
      code: 'D170020',
      description: '170x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 170.77,
      price: 341.55,
      sizeX: 180,
      sizeY: 20,
      weight: 1.970406913,
      code: 'D180020',
      description: '180x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 107.3,
      price: 214.6,
      sizeX: 110,
      sizeY: 20,
      weight: 1.211800251,
      code: 'D110020',
      description: '110x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 125.44,
      price: 250.87,
      sizeX: 130,
      sizeY: 20,
      weight: 1.428545012,
      code: 'D130020',
      description: '130x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 179.84,
      price: 359.68,
      sizeX: 190,
      sizeY: 20,
      weight: 2.078779293,
      code: 'D190020',
      description: '190x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 197.98,
      price: 395.96,
      sizeX: 210,
      sizeY: 20,
      weight: 2.295524053,
      code: 'D210020',
      description: '210x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 207.05,
      price: 414.09,
      sizeX: 220,
      sizeY: 20,
      weight: 2.403896433,
      code: 'D220020',
      description: '220x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 216.11,
      price: 432.23,
      sizeX: 230,
      sizeY: 20,
      weight: 2.512268814,
      code: 'D230020',
      description: '230x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 225.18,
      price: 450.36,
      sizeX: 240,
      sizeY: 20,
      weight: 2.620641194,
      code: 'D240020',
      description: '240x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 174.62,
      price: 349.23,
      sizeX: 250,
      sizeY: 20,
      weight: 2.729013574,
      code: 'D250020',
      description: '250x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 243.32,
      price: 486.63,
      sizeX: 260,
      sizeY: 20,
      weight: 2.837385954,
      code: 'D260020',
      description: '260x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 252.38,
      price: 504.77,
      sizeX: 270,
      sizeY: 20,
      weight: 2.945758334,
      code: 'D270020',
      description: '270x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 261.45,
      price: 522.9,
      sizeX: 280,
      sizeY: 20,
      weight: 3.054130715,
      code: 'D280020',
      description: '280x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 270.52,
      price: 541.04,
      sizeX: 290,
      sizeY: 20,
      weight: 3.162503095,
      code: 'D290020',
      description: '290x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 208.62,
      price: 417.24,
      sizeX: 300,
      sizeY: 20,
      weight: 3.270875475,
      code: 'D30020',
      description: '300x20',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 30.66,
      price: 61.32,
      sizeX: 50,
      sizeY: 25,
      weight: 0.443341555,
      code: 'B050025',
      description: '50x25',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 20.66,
      price: 41.33,
      sizeX: 30,
      sizeY: 25,
      weight: 0.275856968,
      code: 'B030025',
      description: '30x25',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 57.44,
      price: 114.87,
      sizeX: 100,
      sizeY: 25,
      weight: 0.862053024,
      code: 'B100025',
      description: '100x25',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 84.67,
      price: 169.35,
      sizeX: 110,
      sizeY: 25,
      weight: 0.945795318,
      code: 'B110025',
      description: '110x25',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 91.81,
      price: 183.63,
      sizeX: 120,
      sizeY: 25,
      weight: 1.029537612,
      code: 'B120025',
      description: '120x25',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 98.96,
      price: 197.91,
      sizeX: 130,
      sizeY: 25,
      weight: 1.113279906,
      code: 'B130025',
      description: '130x25',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 106.1,
      price: 212.19,
      sizeX: 140,
      sizeY: 25,
      weight: 1.197022199,
      code: 'B140025',
      description: '140x25',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 84.21,
      price: 168.43,
      sizeX: 150,
      sizeY: 25,
      weight: 1.280764493,
      code: 'B150025',
      description: '150x25',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 137.77,
      price: 275.53,
      sizeX: 250,
      sizeY: 25,
      weight: 2.118187431,
      code: 'B250025',
      description: '250x25',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 164.54,
      price: 329.08,
      sizeX: 300,
      sizeY: 25,
      weight: 2.5368989,
      code: 'B300025',
      description: '300x25',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 38.66,
      price: 77.32,
      sizeX: 50,
      sizeY: 25,
      weight: 0.566491987,
      code: 'C050025',
      description: '50x25',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 74.15,
      price: 148.31,
      sizeX: 100,
      sizeY: 25,
      weight: 1.108353888,
      code: 'C100025',
      description: '100x25',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 17.78,
      price: 35.56,
      sizeX: 30,
      sizeY: 30,
      weight: 0.23644883,
      code: 'A030030',
      description: '30x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 22.35,
      price: 44.7,
      sizeX: 40,
      sizeY: 30,
      weight: 0.305413071,
      code: 'A040030',
      description: '40x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 22.92,
      price: 45.84,
      sizeX: 50,
      sizeY: 30,
      weight: 0.374377313,
      code: 'A050030',
      description: '50x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 31.49,
      price: 62.99,
      sizeX: 60,
      sizeY: 30,
      weight: 0.443341555,
      code: 'A060030',
      description: '60x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 36.06,
      price: 72.13,
      sizeX: 70,
      sizeY: 30,
      weight: 0.512305797,
      code: 'A070030',
      description: '70x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 40.64,
      price: 81.27,
      sizeX: 80,
      sizeY: 30,
      weight: 0.581270039,
      code: 'A080030',
      description: '80x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 59.64,
      price: 119.28,
      sizeX: 90,
      sizeY: 30,
      weight: 0.650234281,
      code: 'A090030',
      description: '90x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 42.73,
      price: 85.46,
      sizeX: 100,
      sizeY: 30,
      weight: 0.719198523,
      code: 'A100030',
      description: '100x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 53.87,
      price: 107.75,
      sizeX: 110,
      sizeY: 30,
      weight: 0.788162765,
      code: 'A110030',
      description: '110x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 58.44,
      price: 116.89,
      sizeX: 120,
      sizeY: 30,
      weight: 0.857127007,
      code: 'A120030',
      description: '120x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 63.02,
      price: 126.03,
      sizeX: 130,
      sizeY: 30,
      weight: 0.926091249,
      code: 'A130030',
      description: '130x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 67.59,
      price: 135.17,
      sizeX: 140,
      sizeY: 30,
      weight: 0.995055491,
      code: 'A140030',
      description: '140x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 62.54,
      price: 125.07,
      sizeX: 150,
      sizeY: 30,
      weight: 1.064019733,
      code: 'A150030',
      description: '150x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 76.73,
      price: 153.46,
      sizeX: 160,
      sizeY: 30,
      weight: 1.132983975,
      code: 'A160030',
      description: '160x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 81.3,
      price: 162.6,
      sizeX: 170,
      sizeY: 30,
      weight: 1.201948217,
      code: 'A170030',
      description: '170x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 85.87,
      price: 171.74,
      sizeX: 180,
      sizeY: 30,
      weight: 1.270912459,
      code: 'A180030',
      description: '180x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 90.44,
      price: 180.88,
      sizeX: 190,
      sizeY: 30,
      weight: 1.339876701,
      code: 'A190030',
      description: '190x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 82.35,
      price: 164.69,
      sizeX: 200,
      sizeY: 30,
      weight: 1.408840943,
      code: 'A200030',
      description: '200x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 100.06,
      price: 200.12,
      sizeX: 210,
      sizeY: 30,
      weight: 1.477805184,
      code: 'A210030',
      description: '210x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 104.16,
      price: 208.31,
      sizeX: 220,
      sizeY: 30,
      weight: 1.546769426,
      code: 'A220030',
      description: '220x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 108.73,
      price: 217.45,
      sizeX: 230,
      sizeY: 30,
      weight: 1.615733668,
      code: 'A230030',
      description: '230x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 113.3,
      price: 226.6,
      sizeX: 240,
      sizeY: 30,
      weight: 1.68469791,
      code: 'A240030',
      description: '240x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 102.15,
      price: 204.31,
      sizeX: 250,
      sizeY: 30,
      weight: 1.753662152,
      code: 'A250030',
      description: '250x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 122.44,
      price: 244.88,
      sizeX: 260,
      sizeY: 30,
      weight: 1.822626394,
      code: 'A260030',
      description: '260x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 127.01,
      price: 254.02,
      sizeX: 270,
      sizeY: 30,
      weight: 1.891590636,
      code: 'A270030',
      description: '270x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 131.58,
      price: 263.17,
      sizeX: 280,
      sizeY: 30,
      weight: 1.960554878,
      code: 'A280030',
      description: '280x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 181.54,
      price: 363.08,
      sizeX: 290,
      sizeY: 30,
      weight: 2.02951912,
      code: 'A290030',
      description: '290x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 121.96,
      price: 243.92,
      sizeX: 300,
      sizeY: 30,
      weight: 2.098483362,
      code: 'A300030',
      description: '300x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 145.3,
      price: 290.59,
      sizeX: 310,
      sizeY: 30,
      weight: 2.167447604,
      code: 'A310030',
      description: '310x30',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 11.63,
      price: 23.27,
      sizeX: 10,
      sizeY: 30,
      weight: 0.128076449,
      code: 'B010030',
      description: '10x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 17.89,
      price: 35.78,
      sizeX: 20,
      sizeY: 30,
      weight: 0.226596795,
      code: 'B020030',
      description: '20x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 82.27,
      price: 164.53,
      sizeX: 90,
      sizeY: 30,
      weight: 0.916239214,
      code: 'B090030',
      description: '90x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 55.44,
      price: 110.88,
      sizeX: 80,
      sizeY: 30,
      weight: 0.817718869,
      code: 'B080030',
      description: '80x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 49.18,
      price: 98.37,
      sizeX: 70,
      sizeY: 30,
      weight: 0.719198523,
      code: 'B070030',
      description: '70x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 30.41,
      price: 60.82,
      sizeX: 40,
      sizeY: 30,
      weight: 0.423637486,
      code: 'B040030',
      description: '40x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 42.92,
      price: 85.85,
      sizeX: 60,
      sizeY: 30,
      weight: 0.620678177,
      code: 'B060030',
      description: '60x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 31.06,
      price: 62.11,
      sizeX: 50,
      sizeY: 30,
      weight: 0.522157832,
      code: 'B050030',
      description: '50x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 20.93,
      price: 41.86,
      sizeX: 30,
      sizeY: 30,
      weight: 0.325117141,
      code: 'B030030',
      description: '30x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 58.18,
      price: 116.35,
      sizeX: 100,
      sizeY: 30,
      weight: 1.01475956,
      code: 'B100030',
      description: '100x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 74.22,
      price: 148.43,
      sizeX: 110,
      sizeY: 30,
      weight: 1.113279906,
      code: 'B110030',
      description: '110x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 80.47,
      price: 160.95,
      sizeX: 120,
      sizeY: 30,
      weight: 1.211800251,
      code: 'B120030',
      description: '120x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 86.73,
      price: 173.47,
      sizeX: 130,
      sizeY: 30,
      weight: 1.310320597,
      code: 'B130030',
      description: '130x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 92.99,
      price: 185.98,
      sizeX: 140,
      sizeY: 30,
      weight: 1.408840943,
      code: 'B140030',
      description: '140x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 85.29,
      price: 170.59,
      sizeX: 150,
      sizeY: 30,
      weight: 1.507361288,
      code: 'B150030',
      description: '150x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 105.51,
      price: 211.02,
      sizeX: 160,
      sizeY: 30,
      weight: 1.605881634,
      code: 'B160030',
      description: '160x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 111.77,
      price: 223.53,
      sizeX: 170,
      sizeY: 30,
      weight: 1.704401979,
      code: 'B170030',
      description: '170x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 118.02,
      price: 236.05,
      sizeX: 180,
      sizeY: 30,
      weight: 1.802922325,
      code: 'B180030',
      description: '180x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 165.71,
      price: 331.42,
      sizeX: 190,
      sizeY: 30,
      weight: 1.901442671,
      code: 'B190030',
      description: '190x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 112.41,
      price: 224.83,
      sizeX: 200,
      sizeY: 30,
      weight: 1.999963016,
      code: 'B200030',
      description: '200x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 143.06,
      price: 286.12,
      sizeX: 220,
      sizeY: 30,
      weight: 2.197003708,
      code: 'B220030',
      description: '220x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 136.8,
      price: 273.6,
      sizeX: 210,
      sizeY: 30,
      weight: 2.098483362,
      code: 'B210030',
      description: '210x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 149.32,
      price: 298.63,
      sizeX: 230,
      sizeY: 30,
      weight: 2.295524053,
      code: 'B230030',
      description: '230x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 155.57,
      price: 311.15,
      sizeX: 240,
      sizeY: 30,
      weight: 2.394044399,
      code: 'B240030',
      description: '240x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 174.35,
      price: 348.7,
      sizeX: 270,
      sizeY: 30,
      weight: 2.689605436,
      code: 'B270030',
      description: '270x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 168.09,
      price: 336.18,
      sizeX: 260,
      sizeY: 30,
      weight: 2.59108509,
      code: 'B260030',
      description: '260x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 139.53,
      price: 279.07,
      sizeX: 250,
      sizeY: 30,
      weight: 2.492564744,
      code: 'B250030',
      description: '250x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 172.8,
      price: 345.6,
      sizeX: 310,
      sizeY: 30,
      weight: 3.083686818,
      code: 'B310030',
      description: '310x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 180.61,
      price: 361.21,
      sizeX: 280,
      sizeY: 30,
      weight: 2.788125781,
      code: 'B280030',
      description: '280x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 249.15,
      price: 498.31,
      sizeX: 290,
      sizeY: 30,
      weight: 2.886646127,
      code: 'B290030',
      description: '290x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 166.65,
      price: 333.3,
      sizeX: 300,
      sizeY: 30,
      weight: 2.985166473,
      code: 'B300030',
      description: '300x30',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 28.62,
      price: 57.23,
      sizeX: 30,
      sizeY: 30,
      weight: 0.413785452,
      code: 'C030030',
      description: '30x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 36.92,
      price: 73.84,
      sizeX: 40,
      sizeY: 30,
      weight: 0.541861901,
      code: 'C040030',
      description: '40x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 39.19,
      price: 78.38,
      sizeX: 50,
      sizeY: 30,
      weight: 0.66993835,
      code: 'C050030',
      description: '50x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 53.52,
      price: 107.05,
      sizeX: 60,
      sizeY: 30,
      weight: 0.7980148,
      code: 'C060030',
      description: '60x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 61.83,
      price: 123.65,
      sizeX: 70,
      sizeY: 30,
      weight: 0.926091249,
      code: 'C070030',
      description: '70x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 70.13,
      price: 140.26,
      sizeX: 80,
      sizeY: 30,
      weight: 1.054167698,
      code: 'C080030',
      description: '80x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 78.43,
      price: 156.86,
      sizeX: 90,
      sizeY: 30,
      weight: 1.182244148,
      code: 'C090030',
      description: '90x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 75.17,
      price: 150.34,
      sizeX: 100,
      sizeY: 30,
      weight: 1.310320597,
      code: 'C100030',
      description: '100x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 95.04,
      price: 190.07,
      sizeX: 110,
      sizeY: 30,
      weight: 1.438397046,
      code: 'C110030',
      description: '110x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 103.34,
      price: 206.68,
      sizeX: 120,
      sizeY: 30,
      weight: 1.566473496,
      code: 'C120030',
      description: '120x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 111.64,
      price: 223.28,
      sizeX: 130,
      sizeY: 30,
      weight: 1.694549945,
      code: 'C130030',
      description: '130x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 119.94,
      price: 239.88,
      sizeX: 140,
      sizeY: 30,
      weight: 1.822626394,
      code: 'C140030',
      description: '140x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 111.15,
      price: 222.29,
      sizeX: 150,
      sizeY: 30,
      weight: 1.950702843,
      code: 'C150030',
      description: '150x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 136.55,
      price: 273.09,
      sizeX: 160,
      sizeY: 30,
      weight: 2.078779293,
      code: 'C160030',
      description: '160x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 144.85,
      price: 289.7,
      sizeX: 170,
      sizeY: 30,
      weight: 2.206855742,
      code: 'C170030',
      description: '170x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 153.15,
      price: 306.3,
      sizeX: 180,
      sizeY: 30,
      weight: 2.334932191,
      code: 'C180030',
      description: '180x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 161.45,
      price: 322.91,
      sizeX: 190,
      sizeY: 30,
      weight: 2.463008641,
      code: 'C190030',
      description: '190x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 147.12,
      price: 294.24,
      sizeX: 200,
      sizeY: 30,
      weight: 2.59108509,
      code: 'C200030',
      description: '200x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 178.06,
      price: 356.12,
      sizeX: 210,
      sizeY: 30,
      weight: 2.719161539,
      code: 'C210030',
      description: '210x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 186.36,
      price: 372.72,
      sizeX: 220,
      sizeY: 30,
      weight: 2.847237989,
      code: 'C220030',
      description: '220x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 194.66,
      price: 389.33,
      sizeX: 230,
      sizeY: 30,
      weight: 2.975314438,
      code: 'C230030',
      description: '230x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 202.97,
      price: 405.93,
      sizeX: 240,
      sizeY: 30,
      weight: 3.103390887,
      code: 'C240030',
      description: '240x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 183.1,
      price: 366.2,
      sizeX: 250,
      sizeY: 30,
      weight: 3.231467337,
      code: 'C250030',
      description: '250x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 219.57,
      price: 439.14,
      sizeX: 260,
      sizeY: 30,
      weight: 3.359543786,
      code: 'C260030',
      description: '260x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 227.87,
      price: 455.75,
      sizeX: 270,
      sizeY: 30,
      weight: 3.487620235,
      code: 'C270030',
      description: '270x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 236.18,
      price: 472.35,
      sizeX: 280,
      sizeY: 30,
      weight: 3.615696685,
      code: 'C280030',
      description: '280x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 244.48,
      price: 488.95,
      sizeX: 290,
      sizeY: 30,
      weight: 3.743773134,
      code: 'C290030',
      description: '290x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 219.08,
      price: 438.15,
      sizeX: 300,
      sizeY: 30,
      weight: 3.871849583,
      code: 'C300030',
      description: '300x30',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 17.98,
      price: 35.96,
      sizeX: 10,
      sizeY: 30,
      weight: 0.187188657,
      code: 'D010030',
      description: '10x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 27.79,
      price: 55.59,
      sizeX: 20,
      sizeY: 30,
      weight: 0.34482121,
      code: 'D020030',
      description: '20x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 31.25,
      price: 62.5,
      sizeX: 30,
      sizeY: 30,
      weight: 0.502453763,
      code: 'D030030',
      description: '30x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 48.26,
      price: 96.51,
      sizeX: 50,
      sizeY: 30,
      weight: 0.817718869,
      code: 'D050030',
      description: '50x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 90.77,
      price: 181.54,
      sizeX: 100,
      sizeY: 30,
      weight: 1.605881634,
      code: 'D100030',
      description: '100x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 177.14,
      price: 354.28,
      sizeX: 200,
      sizeY: 30,
      weight: 3.182207164,
      code: 'D200030',
      description: '200x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 116.09,
      price: 232.18,
      sizeX: 110,
      sizeY: 30,
      weight: 1.763514187,
      code: 'D110030',
      description: '110x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 135.71,
      price: 271.43,
      sizeX: 130,
      sizeY: 30,
      weight: 2.078779293,
      code: 'D130030',
      description: '130x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 194.58,
      price: 389.16,
      sizeX: 190,
      sizeY: 30,
      weight: 3.024574611,
      code: 'D190030',
      description: '190x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 47.41,
      price: 94.83,
      sizeX: 40,
      sizeY: 30,
      weight: 0.660086316,
      code: 'D040030',
      description: '40x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 65.49,
      price: 130.98,
      sizeX: 60,
      sizeY: 30,
      weight: 0.975351422,
      code: 'D060030',
      description: '60x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 75.3,
      price: 150.6,
      sizeX: 70,
      sizeY: 30,
      weight: 1.132983975,
      code: 'D070030',
      description: '70x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 86.66,
      price: 173.32,
      sizeX: 80,
      sizeY: 30,
      weight: 1.290616528,
      code: 'D080030',
      description: '80x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 96.47,
      price: 192.94,
      sizeX: 90,
      sizeY: 30,
      weight: 1.448249081,
      code: 'D090030',
      description: '90x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 125.9,
      price: 251.81,
      sizeX: 120,
      sizeY: 30,
      weight: 1.92114674,
      code: 'D120030',
      description: '120x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 145.53,
      price: 291.05,
      sizeX: 140,
      sizeY: 30,
      weight: 2.236411846,
      code: 'D140030',
      description: '140x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 133.28,
      price: 266.57,
      sizeX: 150,
      sizeY: 30,
      weight: 2.394044399,
      code: 'D150030',
      description: '150x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 165.15,
      price: 330.29,
      sizeX: 160,
      sizeY: 30,
      weight: 2.551676952,
      code: 'D160030',
      description: '160x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 174.96,
      price: 349.92,
      sizeX: 170,
      sizeY: 30,
      weight: 2.709309505,
      code: 'D170030',
      description: '170x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 184.77,
      price: 369.54,
      sizeX: 180,
      sizeY: 30,
      weight: 2.866942058,
      code: 'D180030',
      description: '180x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 214.2,
      price: 428.41,
      sizeX: 210,
      sizeY: 30,
      weight: 3.339839717,
      code: 'D210030',
      description: '210x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 194.15,
      price: 388.29,
      sizeX: 220,
      sizeY: 30,
      weight: 3.49747227,
      code: 'D220030',
      description: '220x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 233.82,
      price: 467.65,
      sizeX: 230,
      sizeY: 30,
      weight: 3.655104823,
      code: 'D230030',
      description: '230x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 243.64,
      price: 487.27,
      sizeX: 240,
      sizeY: 30,
      weight: 3.812737376,
      code: 'D240030',
      description: '240x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 218.31,
      price: 436.63,
      sizeX: 250,
      sizeY: 30,
      weight: 3.970369929,
      code: 'D250030',
      description: '250x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 263.26,
      price: 526.52,
      sizeX: 260,
      sizeY: 30,
      weight: 4.128002482,
      code: 'D260030',
      description: '260x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 236.66,
      price: 473.32,
      sizeX: 270,
      sizeY: 30,
      weight: 4.285635035,
      code: 'D270030',
      description: '270x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 282.88,
      price: 565.76,
      sizeX: 280,
      sizeY: 30,
      weight: 4.443267588,
      code: 'D280030',
      description: '280x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 390.25,
      price: 780.51,
      sizeX: 290,
      sizeY: 30,
      weight: 4.600900141,
      code: 'D290030',
      description: '290x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 260.83,
      price: 521.66,
      sizeX: 300,
      sizeY: 30,
      weight: 4.758532694,
      code: 'D300030',
      description: '300x30',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 41.01,
      price: 82.01,
      sizeX: 50,
      sizeY: 35,
      weight: 0.600974108,
      code: 'B050035',
      description: '50x35',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 27.64,
      price: 55.27,
      sizeX: 30,
      sizeY: 35,
      weight: 0.374377313,
      code: 'B030035',
      description: '30x35',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 76.81,
      price: 153.63,
      sizeX: 100,
      sizeY: 35,
      weight: 1.167466096,
      code: 'B100035',
      description: '100x35',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 113.24,
      price: 226.47,
      sizeX: 110,
      sizeY: 35,
      weight: 1.280764493,
      code: 'B110035',
      description: '110x35',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 122.78,
      price: 245.57,
      sizeX: 120,
      sizeY: 35,
      weight: 1.394062891,
      code: 'B120035',
      description: '120x35',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 132.33,
      price: 264.67,
      sizeX: 130,
      sizeY: 35,
      weight: 1.507361288,
      code: 'B130035',
      description: '130x35',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 141.88,
      price: 283.76,
      sizeX: 140,
      sizeY: 35,
      weight: 1.620659686,
      code: 'B140035',
      description: '140x35',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 112.62,
      price: 225.24,
      sizeX: 150,
      sizeY: 35,
      weight: 1.733958083,
      code: 'B150035',
      description: '150x35',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 184.23,
      price: 368.47,
      sizeX: 250,
      sizeY: 35,
      weight: 2.866942058,
      code: 'B250035',
      description: '250x35',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 99.31,
      price: 198.63,
      sizeX: 100,
      sizeY: 35,
      weight: 1.512287305,
      code: 'C100035',
      description: '100x35',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 138.75,
      price: 277.51,
      sizeX: 105,
      sizeY: 35,
      weight: 1.586177565,
      code: 'C105035',
      description: '105x35',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 27.65,
      price: 55.3,
      sizeX: 40,
      sizeY: 40,
      weight: 0.394081383,
      code: 'A040040',
      description: '40x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 28.97,
      price: 57.94,
      sizeX: 50,
      sizeY: 40,
      weight: 0.482749694,
      code: 'A050040',
      description: '50x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 39.2,
      price: 78.4,
      sizeX: 60,
      sizeY: 40,
      weight: 0.571418005,
      code: 'A060040',
      description: '60x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 44.98,
      price: 89.95,
      sizeX: 70,
      sizeY: 40,
      weight: 0.660086316,
      code: 'A070040',
      description: '70x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 50.16,
      price: 100.31,
      sizeX: 80,
      sizeY: 40,
      weight: 0.748754627,
      code: 'A080040',
      description: '80x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 55.93,
      price: 111.86,
      sizeX: 90,
      sizeY: 40,
      weight: 0.837422938,
      code: 'A090040',
      description: '90x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 53.99,
      price: 107.99,
      sizeX: 100,
      sizeY: 40,
      weight: 0.926091249,
      code: 'A100040',
      description: '100x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 68.08,
      price: 136.15,
      sizeX: 110,
      sizeY: 40,
      weight: 1.01475956,
      code: 'A110040',
      description: '110x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 73.85,
      price: 147.7,
      sizeX: 120,
      sizeY: 40,
      weight: 1.103427871,
      code: 'A120040',
      description: '120x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 106.17,
      price: 212.34,
      sizeX: 130,
      sizeY: 40,
      weight: 1.192096182,
      code: 'A130040',
      description: '130x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 85.4,
      price: 170.81,
      sizeX: 140,
      sizeY: 40,
      weight: 1.280764493,
      code: 'A140040',
      description: '140x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 79.02,
      price: 158.04,
      sizeX: 150,
      sizeY: 40,
      weight: 1.369432804,
      code: 'A150040',
      description: '150x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 96.95,
      price: 193.91,
      sizeX: 160,
      sizeY: 40,
      weight: 1.458101115,
      code: 'A160040',
      description: '160x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 102.73,
      price: 205.46,
      sizeX: 170,
      sizeY: 40,
      weight: 1.546769426,
      code: 'A170040',
      description: '170x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 108.5,
      price: 217.01,
      sizeX: 180,
      sizeY: 40,
      weight: 1.635437737,
      code: 'A180040',
      description: '180x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 152.37,
      price: 304.74,
      sizeX: 190,
      sizeY: 40,
      weight: 1.724106049,
      code: 'A190040',
      description: '190x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 104.05,
      price: 208.09,
      sizeX: 200,
      sizeY: 40,
      weight: 1.81277436,
      code: 'A200040',
      description: '200x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 125.83,
      price: 251.66,
      sizeX: 210,
      sizeY: 40,
      weight: 1.901442671,
      code: 'A210040',
      description: '210x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 131.6,
      price: 263.21,
      sizeX: 220,
      sizeY: 40,
      weight: 1.990110982,
      code: 'A220040',
      description: '220x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 137.38,
      price: 274.76,
      sizeX: 230,
      sizeY: 40,
      weight: 2.078779293,
      code: 'A230040',
      description: '230x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 143.15,
      price: 286.31,
      sizeX: 240,
      sizeY: 40,
      weight: 2.167447604,
      code: 'A240040',
      description: '240x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 129.07,
      price: 258.15,
      sizeX: 250,
      sizeY: 40,
      weight: 2.256115915,
      code: 'A250040',
      description: '250x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 154.71,
      price: 309.41,
      sizeX: 260,
      sizeY: 40,
      weight: 2.344784226,
      code: 'A260040',
      description: '260x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 160.48,
      price: 320.96,
      sizeX: 270,
      sizeY: 40,
      weight: 2.433452537,
      code: 'A270040',
      description: '270x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 166.26,
      price: 332.51,
      sizeX: 280,
      sizeY: 40,
      weight: 2.522120848,
      code: 'A280040',
      description: '280x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 229.37,
      price: 458.75,
      sizeX: 290,
      sizeY: 40,
      weight: 2.610789159,
      code: 'A290040',
      description: '290x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 154.1,
      price: 308.2,
      sizeX: 300,
      sizeY: 40,
      weight: 2.69945747,
      code: 'A300040',
      description: '300x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 183.58,
      price: 367.16,
      sizeX: 310,
      sizeY: 40,
      weight: 2.788125781,
      code: 'A310040',
      description: '310x40',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 23.06,
      price: 46.12,
      sizeX: 20,
      sizeY: 40,
      weight: 0.295561037,
      code: 'B020040',
      description: '20x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 106.01,
      price: 212.02,
      sizeX: 90,
      sizeY: 40,
      weight: 1.192096182,
      code: 'B090040',
      description: '90x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 26.97,
      price: 53.94,
      sizeX: 30,
      sizeY: 40,
      weight: 0.423637486,
      code: 'B030040',
      description: '30x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 71.44,
      price: 142.89,
      sizeX: 80,
      sizeY: 40,
      weight: 1.064019733,
      code: 'B080040',
      description: '80x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 63.38,
      price: 126.76,
      sizeX: 70,
      sizeY: 40,
      weight: 0.935943283,
      code: 'B070040',
      description: '70x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 55.32,
      price: 110.63,
      sizeX: 60,
      sizeY: 40,
      weight: 0.807866834,
      code: 'B060040',
      description: '60x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 40.02,
      price: 80.05,
      sizeX: 50,
      sizeY: 40,
      weight: 0.679790385,
      code: 'B050040',
      description: '50x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 39.19,
      price: 78.37,
      sizeX: 40,
      sizeY: 40,
      weight: 0.551713936,
      code: 'B040040',
      description: '40x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 74.97,
      price: 149.94,
      sizeX: 100,
      sizeY: 40,
      weight: 1.320172631,
      code: 'B100040',
      description: '100x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 95.64,
      price: 191.27,
      sizeX: 110,
      sizeY: 40,
      weight: 1.448249081,
      code: 'B110040',
      description: '110x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 111.77,
      price: 223.53,
      sizeX: 130,
      sizeY: 40,
      weight: 1.704401979,
      code: 'B130040',
      description: '130x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 119.83,
      price: 239.66,
      sizeX: 140,
      sizeY: 40,
      weight: 1.832478429,
      code: 'B140040',
      description: '140x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 135.96,
      price: 271.92,
      sizeX: 160,
      sizeY: 40,
      weight: 2.088631327,
      code: 'B160040',
      description: '160x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 144.02,
      price: 288.05,
      sizeX: 170,
      sizeY: 40,
      weight: 2.216707777,
      code: 'B170040',
      description: '170x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 109.91,
      price: 219.83,
      sizeX: 150,
      sizeY: 40,
      weight: 1.960554878,
      code: 'B150040',
      description: '150x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 152.09,
      price: 304.18,
      sizeX: 180,
      sizeY: 40,
      weight: 2.344784226,
      code: 'B180040',
      description: '180x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 144.86,
      price: 289.72,
      sizeX: 200,
      sizeY: 40,
      weight: 2.600937125,
      code: 'B200040',
      description: '200x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 213.54,
      price: 427.07,
      sizeX: 190,
      sizeY: 40,
      weight: 2.472860675,
      code: 'B190040',
      description: '190x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 176.28,
      price: 352.56,
      sizeX: 210,
      sizeY: 40,
      weight: 2.729013574,
      code: 'B210040',
      description: '210x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 184.35,
      price: 368.69,
      sizeX: 220,
      sizeY: 40,
      weight: 2.857090023,
      code: 'B220040',
      description: '220x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 192.41,
      price: 384.82,
      sizeX: 230,
      sizeY: 40,
      weight: 2.985166473,
      code: 'B230040',
      description: '230x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 200.47,
      price: 400.95,
      sizeX: 240,
      sizeY: 40,
      weight: 3.113242922,
      code: 'B240040',
      description: '240x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 179.81,
      price: 359.61,
      sizeX: 250,
      sizeY: 40,
      weight: 3.241319371,
      code: 'B250040',
      description: '250x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 216.6,
      price: 433.21,
      sizeX: 260,
      sizeY: 40,
      weight: 3.369395821,
      code: 'B260040',
      description: '260x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 224.67,
      price: 449.34,
      sizeX: 270,
      sizeY: 40,
      weight: 3.49747227,
      code: 'B270040',
      description: '270x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 232.73,
      price: 465.46,
      sizeX: 280,
      sizeY: 40,
      weight: 3.625548719,
      code: 'B280040',
      description: '280x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 240.8,
      price: 481.59,
      sizeX: 290,
      sizeY: 40,
      weight: 3.753625168,
      code: 'B290040',
      description: '290x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 255.85,
      price: 511.71,
      sizeX: 310,
      sizeY: 40,
      weight: 4.009778067,
      code: 'B310040',
      description: '310x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 214.75,
      price: 429.5,
      sizeX: 300,
      sizeY: 40,
      weight: 3.881701618,
      code: 'B300040',
      description: '300x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 47.63,
      price: 95.26,
      sizeX: 40,
      sizeY: 40,
      weight: 0.709346489,
      code: 'C040040',
      description: '40x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 50.56,
      price: 101.12,
      sizeX: 50,
      sizeY: 40,
      weight: 0.876831076,
      code: 'C050040',
      description: '50x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 69.05,
      price: 138.1,
      sizeX: 60,
      sizeY: 40,
      weight: 1.044315664,
      code: 'C060040',
      description: '60x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 79.76,
      price: 159.52,
      sizeX: 70,
      sizeY: 40,
      weight: 1.211800251,
      code: 'C070040',
      description: '70x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 90.47,
      price: 180.94,
      sizeX: 80,
      sizeY: 40,
      weight: 1.379284839,
      code: 'C080040',
      description: '80x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 101.18,
      price: 202.36,
      sizeX: 90,
      sizeY: 40,
      weight: 1.546769426,
      code: 'C090040',
      description: '90x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 96.97,
      price: 193.95,
      sizeX: 100,
      sizeY: 40,
      weight: 1.714254014,
      code: 'C100040',
      description: '100x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 122.6,
      price: 245.21,
      sizeX: 110,
      sizeY: 40,
      weight: 1.881738602,
      code: 'C110040',
      description: '110x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 133.31,
      price: 266.63,
      sizeX: 120,
      sizeY: 40,
      weight: 2.049223189,
      code: 'C120040',
      description: '120x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 144.02,
      price: 288.05,
      sizeX: 130,
      sizeY: 40,
      weight: 2.216707777,
      code: 'C130040',
      description: '130x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 154.73,
      price: 309.47,
      sizeX: 140,
      sizeY: 40,
      weight: 2.384192364,
      code: 'C140040',
      description: '140x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 143.39,
      price: 286.77,
      sizeX: 150,
      sizeY: 40,
      weight: 2.551676952,
      code: 'C150040',
      description: '150x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 176.16,
      price: 352.31,
      sizeX: 160,
      sizeY: 40,
      weight: 2.719161539,
      code: 'C160040',
      description: '160x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 186.87,
      price: 373.73,
      sizeX: 170,
      sizeY: 40,
      weight: 2.886646127,
      code: 'C170040',
      description: '170x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 197.58,
      price: 395.15,
      sizeX: 180,
      sizeY: 40,
      weight: 3.054130715,
      code: 'C180040',
      description: '180x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 208.29,
      price: 416.57,
      sizeX: 190,
      sizeY: 40,
      weight: 3.221615302,
      code: 'C190040',
      description: '190x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 189.8,
      price: 379.6,
      sizeX: 200,
      sizeY: 40,
      weight: 3.38909989,
      code: 'C200040',
      description: '200x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 229.71,
      price: 459.42,
      sizeX: 210,
      sizeY: 40,
      weight: 3.556584477,
      code: 'C210040',
      description: '210x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 240.42,
      price: 480.84,
      sizeX: 220,
      sizeY: 40,
      weight: 3.724069065,
      code: 'C220040',
      description: '220x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 251.13,
      price: 502.26,
      sizeX: 230,
      sizeY: 40,
      weight: 3.891553652,
      code: 'C230040',
      description: '230x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 261.84,
      price: 523.68,
      sizeX: 240,
      sizeY: 40,
      weight: 4.05903824,
      code: 'C240040',
      description: '240x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 236.21,
      price: 472.42,
      sizeX: 250,
      sizeY: 40,
      weight: 4.226522828,
      code: 'C250040',
      description: '250x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 283.26,
      price: 566.52,
      sizeX: 260,
      sizeY: 40,
      weight: 4.394007415,
      code: 'C260040',
      description: '260x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 293.97,
      price: 587.94,
      sizeX: 270,
      sizeY: 40,
      weight: 4.561492003,
      code: 'C270040',
      description: '270x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 304.68,
      price: 609.36,
      sizeX: 280,
      sizeY: 40,
      weight: 4.72897659,
      code: 'C280040',
      description: '280x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 315.39,
      price: 630.78,
      sizeX: 290,
      sizeY: 40,
      weight: 4.896461178,
      code: 'C290040',
      description: '290x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 282.62,
      price: 565.24,
      sizeX: 300,
      sizeY: 40,
      weight: 5.063945765,
      code: 'C300040',
      description: '300x40',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 23.5,
      price: 46.99,
      sizeX: 10,
      sizeY: 40,
      weight: 0.246300864,
      code: 'D010040',
      description: '10x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 36.32,
      price: 72.64,
      sizeX: 20,
      sizeY: 40,
      weight: 0.45319359,
      code: 'D020040',
      description: '20x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 63.06,
      price: 126.12,
      sizeX: 50,
      sizeY: 40,
      weight: 1.073871767,
      code: 'D050040',
      description: '50x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 118.62,
      price: 237.23,
      sizeX: 100,
      sizeY: 40,
      weight: 2.108335396,
      code: 'D100040',
      description: '100x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 229.73,
      price: 459.47,
      sizeX: 200,
      sizeY: 40,
      weight: 4.177262655,
      code: 'D200040',
      description: '200x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 49.14,
      price: 98.28,
      sizeX: 30,
      sizeY: 40,
      weight: 0.660086316,
      code: 'D030040',
      description: '30x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 87.6,
      price: 175.21,
      sizeX: 60,
      sizeY: 40,
      weight: 1.280764493,
      code: 'D060040',
      description: '60x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 100.42,
      price: 200.85,
      sizeX: 70,
      sizeY: 40,
      weight: 1.487657219,
      code: 'D070040',
      description: '70x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 113.25,
      price: 226.49,
      sizeX: 80,
      sizeY: 40,
      weight: 1.694549945,
      code: 'D080040',
      description: '80x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 126.07,
      price: 252.13,
      sizeX: 90,
      sizeY: 40,
      weight: 1.901442671,
      code: 'D090040',
      description: '90x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 61.96,
      price: 123.92,
      sizeX: 40,
      sizeY: 40,
      weight: 0.866979042,
      code: 'D040040',
      description: '40x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 164.53,
      price: 329.06,
      sizeX: 120,
      sizeY: 40,
      weight: 2.522120848,
      code: 'D120040',
      description: '120x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 190.17,
      price: 380.35,
      sizeX: 140,
      sizeY: 40,
      weight: 2.9359063,
      code: 'D140040',
      description: '140x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 175.93,
      price: 351.86,
      sizeX: 150,
      sizeY: 40,
      weight: 3.142799026,
      code: 'D150040',
      description: '150x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 215.82,
      price: 431.63,
      sizeX: 160,
      sizeY: 40,
      weight: 3.349691751,
      code: 'D160040',
      description: '160x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 228.64,
      price: 457.27,
      sizeX: 170,
      sizeY: 40,
      weight: 3.556584477,
      code: 'D170040',
      description: '170x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 241.46,
      price: 482.92,
      sizeX: 180,
      sizeY: 40,
      weight: 3.763477203,
      code: 'D180040',
      description: '180x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 151.71,
      price: 303.42,
      sizeX: 110,
      sizeY: 40,
      weight: 2.315228122,
      code: 'D110040',
      description: '110x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 177.35,
      price: 354.7,
      sizeX: 130,
      sizeY: 40,
      weight: 2.729013574,
      code: 'D130040',
      description: '130x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 339.04,
      price: 678.08,
      sizeX: 190,
      sizeY: 40,
      weight: 3.970369929,
      code: 'D190040',
      description: '190x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 279.92,
      price: 559.84,
      sizeX: 210,
      sizeY: 40,
      weight: 4.384155381,
      code: 'D210040',
      description: '210x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 292.74,
      price: 585.49,
      sizeX: 220,
      sizeY: 40,
      weight: 4.591048106,
      code: 'D220040',
      description: '220x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 305.56,
      price: 611.13,
      sizeX: 230,
      sizeY: 40,
      weight: 4.797940832,
      code: 'D230040',
      description: '230x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 318.39,
      price: 636.77,
      sizeX: 240,
      sizeY: 40,
      weight: 5.004833558,
      code: 'D240040',
      description: '240x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 285.29,
      price: 570.59,
      sizeX: 250,
      sizeY: 40,
      weight: 5.211726284,
      code: 'D250040',
      description: '250x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 344.03,
      price: 688.06,
      sizeX: 260,
      sizeY: 40,
      weight: 5.41861901,
      code: 'D260040',
      description: '260x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 356.85,
      price: 713.7,
      sizeX: 270,
      sizeY: 40,
      weight: 5.625511735,
      code: 'D270040',
      description: '270x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 369.67,
      price: 739.34,
      sizeX: 280,
      sizeY: 40,
      weight: 5.832404461,
      code: 'D280040',
      description: '280x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 382.49,
      price: 764.98,
      sizeX: 290,
      sizeY: 40,
      weight: 6.039297187,
      code: 'D290040',
      description: '290x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 340.85,
      price: 681.7,
      sizeX: 300,
      sizeY: 40,
      weight: 6.246189913,
      code: 'D300040',
      description: '300x40',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 34.19,
      price: 68.37,
      sizeX: 20,
      sizeY: 45,
      weight: 0.330043158,
      code: 'B020045',
      description: '20x45',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 34.61,
      price: 69.22,
      sizeX: 30,
      sizeY: 45,
      weight: 0.472897659,
      code: 'B030045',
      description: '30x45',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 96.19,
      price: 192.38,
      sizeX: 100,
      sizeY: 45,
      weight: 1.472879167,
      code: 'B100045',
      description: '100x45',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 153.75,
      price: 307.51,
      sizeX: 120,
      sizeY: 45,
      weight: 1.758588169,
      code: 'B120045',
      description: '120x45',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 58.1,
      price: 116.2,
      sizeX: 40,
      sizeY: 45,
      weight: 0.61575216,
      code: 'B040045',
      description: '40x45',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 177.67,
      price: 355.33,
      sizeX: 140,
      sizeY: 45,
      weight: 2.044297172,
      code: 'B014045',
      description: '140x45',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 185.87,
      price: 371.73,
      sizeX: 200,
      sizeY: 45,
      weight: 2.901424179,
      code: 'B200045',
      description: '200x45',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 230.7,
      price: 461.41,
      sizeX: 250,
      sizeY: 45,
      weight: 3.615696685,
      code: 'B250045',
      description: '250x45',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 303.19,
      price: 606.38,
      sizeX: 250,
      sizeY: 45,
      weight: 4.724050573,
      code: 'C250045',
      description: '250x45',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 499.57,
      price: 999.14,
      sizeX: 310,
      sizeY: 45,
      weight: 5.847182513,
      code: 'C310045',
      description: '310x45',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 35.02,
      price: 70.04,
      sizeX: 50,
      sizeY: 50,
      weight: 0.591122074,
      code: 'A050050',
      description: '50x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 47.38,
      price: 94.77,
      sizeX: 60,
      sizeY: 50,
      weight: 0.699494454,
      code: 'A060050',
      description: '60x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 54.36,
      price: 108.73,
      sizeX: 70,
      sizeY: 50,
      weight: 0.807866834,
      code: 'A070050',
      description: '70x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 61.34,
      price: 122.69,
      sizeX: 80,
      sizeY: 50,
      weight: 0.916239214,
      code: 'A080050',
      description: '80x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 91.1,
      price: 182.19,
      sizeX: 90,
      sizeY: 50,
      weight: 1.024611595,
      code: 'A090050',
      description: '90x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 65.26,
      price: 130.52,
      sizeX: 100,
      sizeY: 50,
      weight: 1.132983975,
      code: 'A100050',
      description: '100x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 81.57,
      price: 163.13,
      sizeX: 110,
      sizeY: 50,
      weight: 1.241356355,
      code: 'A110050',
      description: '110x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 89.26,
      price: 178.52,
      sizeX: 120,
      sizeY: 50,
      weight: 1.349728735,
      code: 'A120050',
      description: '120x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 96.24,
      price: 192.48,
      sizeX: 130,
      sizeY: 50,
      weight: 1.458101115,
      code: 'A130050',
      description: '130x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 103.22,
      price: 206.44,
      sizeX: 140,
      sizeY: 50,
      weight: 1.566473496,
      code: 'A140050',
      description: '140x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 95.5,
      price: 191.01,
      sizeX: 150,
      sizeY: 50,
      weight: 1.674845876,
      code: 'A150050',
      description: '150x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 117.18,
      price: 234.35,
      sizeX: 160,
      sizeY: 50,
      weight: 1.783218256,
      code: 'A160050',
      description: '160x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 123.44,
      price: 246.89,
      sizeX: 170,
      sizeY: 50,
      weight: 1.891590636,
      code: 'A170050',
      description: '170x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 131.14,
      price: 262.27,
      sizeX: 180,
      sizeY: 50,
      weight: 1.999963016,
      code: 'A180050',
      description: '180x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 184.15,
      price: 368.31,
      sizeX: 190,
      sizeY: 50,
      weight: 2.108335396,
      code: 'A190050',
      description: '190x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 125.13,
      price: 250.26,
      sizeX: 200,
      sizeY: 50,
      weight: 2.216707777,
      code: 'A200050',
      description: '200x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 151.36,
      price: 302.72,
      sizeX: 210,
      sizeY: 50,
      weight: 2.325080157,
      code: 'A210050',
      description: '210x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 159.05,
      price: 318.11,
      sizeX: 220,
      sizeY: 50,
      weight: 2.433452537,
      code: 'A220050',
      description: '220x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 166.03,
      price: 332.06,
      sizeX: 230,
      sizeY: 50,
      weight: 2.541824917,
      code: 'A230050',
      description: '230x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 173.01,
      price: 346.02,
      sizeX: 240,
      sizeY: 50,
      weight: 2.650197297,
      code: 'A240050',
      description: '240x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 155.99,
      price: 311.98,
      sizeX: 250,
      sizeY: 50,
      weight: 2.758569678,
      code: 'A250050',
      description: '250x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 186.26,
      price: 372.51,
      sizeX: 260,
      sizeY: 50,
      weight: 2.866942058,
      code: 'A260050',
      description: '260x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 193.95,
      price: 387.9,
      sizeX: 270,
      sizeY: 50,
      weight: 2.975314438,
      code: 'A270050',
      description: '270x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 200.22,
      price: 400.43,
      sizeX: 280,
      sizeY: 50,
      weight: 3.083686818,
      code: 'A280050',
      description: '280x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 276.26,
      price: 552.52,
      sizeX: 290,
      sizeY: 50,
      weight: 3.192059198,
      code: 'A290050',
      description: '290x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 185.62,
      price: 371.23,
      sizeX: 300,
      sizeY: 50,
      weight: 3.300431579,
      code: 'A300050',
      description: '300x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 221.15,
      price: 442.31,
      sizeX: 310,
      sizeY: 50,
      weight: 3.408803959,
      code: 'A310050',
      description: '310x50',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 17.04,
      price: 34.09,
      sizeX: 10,
      sizeY: 50,
      weight: 0.206892726,
      code: 'B010050',
      description: '10x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 128.01,
      price: 256.02,
      sizeX: 90,
      sizeY: 50,
      weight: 1.46795315,
      code: 'B090050',
      description: '90x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 26.91,
      price: 53.83,
      sizeX: 20,
      sizeY: 50,
      weight: 0.364525279,
      code: 'B020050',
      description: '20x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 86.14,
      price: 172.28,
      sizeX: 80,
      sizeY: 50,
      weight: 1.310320597,
      code: 'B080050',
      description: '80x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 31.88,
      price: 63.76,
      sizeX: 30,
      sizeY: 50,
      weight: 0.522157832,
      code: 'B030050',
      description: '30x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 76.27,
      price: 152.54,
      sizeX: 70,
      sizeY: 50,
      weight: 1.152688044,
      code: 'B070050',
      description: '70x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 66.4,
      price: 132.79,
      sizeX: 60,
      sizeY: 50,
      weight: 0.995055491,
      code: 'B060050',
      description: '60x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 46.66,
      price: 93.31,
      sizeX: 40,
      sizeY: 50,
      weight: 0.679790385,
      code: 'B040050',
      description: '40x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 48.99,
      price: 97.98,
      sizeX: 50,
      sizeY: 50,
      weight: 0.837422938,
      code: 'B050050',
      description: '50x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 91.76,
      price: 183.52,
      sizeX: 100,
      sizeY: 50,
      weight: 1.625585703,
      code: 'B100050',
      description: '100x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 115.75,
      price: 231.5,
      sizeX: 110,
      sizeY: 50,
      weight: 1.783218256,
      code: 'B110050',
      description: '110x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 125.62,
      price: 251.24,
      sizeX: 120,
      sizeY: 50,
      weight: 1.940850809,
      code: 'B120050',
      description: '120x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 135.49,
      price: 270.98,
      sizeX: 130,
      sizeY: 50,
      weight: 2.098483362,
      code: 'B130050',
      description: '130x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 165.1,
      price: 330.2,
      sizeX: 160,
      sizeY: 50,
      weight: 2.571381021,
      code: 'B160050',
      description: '160x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 145.36,
      price: 290.72,
      sizeX: 140,
      sizeY: 50,
      weight: 2.256115915,
      code: 'B140050',
      description: '140x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 174.97,
      price: 349.95,
      sizeX: 170,
      sizeY: 50,
      weight: 2.729013574,
      code: 'B170050',
      description: '170x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 134.53,
      price: 269.07,
      sizeX: 150,
      sizeY: 50,
      weight: 2.413748468,
      code: 'B150050',
      description: '150x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 184.84,
      price: 369.69,
      sizeX: 180,
      sizeY: 50,
      weight: 2.886646127,
      code: 'B180050',
      description: '180x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 259.62,
      price: 519.24,
      sizeX: 190,
      sizeY: 50,
      weight: 3.04427868,
      code: 'B190050',
      description: '190x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 177.31,
      price: 354.61,
      sizeX: 200,
      sizeY: 50,
      weight: 3.201911233,
      code: 'B200050',
      description: '200x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 214.45,
      price: 428.91,
      sizeX: 210,
      sizeY: 50,
      weight: 3.359543786,
      code: 'B210050',
      description: '210x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 224.33,
      price: 448.65,
      sizeX: 220,
      sizeY: 50,
      weight: 3.517176339,
      code: 'B220050',
      description: '220x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 234.2,
      price: 468.39,
      sizeX: 230,
      sizeY: 50,
      weight: 3.674808892,
      code: 'B230050',
      description: '230x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 244.07,
      price: 488.13,
      sizeX: 240,
      sizeY: 50,
      weight: 3.832441445,
      code: 'B240050',
      description: '240x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 263.81,
      price: 527.62,
      sizeX: 260,
      sizeY: 50,
      weight: 4.147706551,
      code: 'B260050',
      description: '260x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 273.68,
      price: 547.36,
      sizeX: 270,
      sizeY: 50,
      weight: 4.305339104,
      code: 'B270050',
      description: '270x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 220.08,
      price: 440.16,
      sizeX: 250,
      sizeY: 50,
      weight: 3.990073998,
      code: 'B250050',
      description: '250x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 283.55,
      price: 567.1,
      sizeX: 280,
      sizeY: 50,
      weight: 4.462971657,
      code: 'B280050',
      description: '280x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 293.42,
      price: 586.84,
      sizeX: 290,
      sizeY: 50,
      weight: 4.62060421,
      code: 'B290050',
      description: '290x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 262.85,
      price: 525.7,
      sizeX: 300,
      sizeY: 50,
      weight: 4.778236763,
      code: 'B300050',
      description: '300x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 313.16,
      price: 626.32,
      sizeX: 310,
      sizeY: 50,
      weight: 4.935869316,
      code: 'B310050',
      description: '310x50',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 61.93,
      price: 123.86,
      sizeX: 50,
      sizeY: 50,
      weight: 1.083723802,
      code: 'C050050',
      description: '50x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 73.3,
      price: 146.6,
      sizeX: 60,
      sizeY: 50,
      weight: 1.290616528,
      code: 'C060050',
      description: '60x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 97.7,
      price: 195.39,
      sizeX: 70,
      sizeY: 50,
      weight: 1.497509254,
      code: 'C070050',
      description: '70x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 110.81,
      price: 221.63,
      sizeX: 80,
      sizeY: 50,
      weight: 1.704401979,
      code: 'C080050',
      description: '80x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 123.93,
      price: 247.87,
      sizeX: 90,
      sizeY: 50,
      weight: 1.911294705,
      code: 'C090050',
      description: '90x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 118.78,
      price: 237.56,
      sizeX: 100,
      sizeY: 50,
      weight: 2.118187431,
      code: 'C100050',
      description: '100x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 150.17,
      price: 300.34,
      sizeX: 110,
      sizeY: 50,
      weight: 2.325080157,
      code: 'C110050',
      description: '110x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 163.29,
      price: 326.58,
      sizeX: 120,
      sizeY: 50,
      weight: 2.531972883,
      code: 'C120050',
      description: '120x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 176.41,
      price: 352.82,
      sizeX: 130,
      sizeY: 50,
      weight: 2.738865608,
      code: 'C130050',
      description: '130x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 189.53,
      price: 379.05,
      sizeX: 140,
      sizeY: 50,
      weight: 2.945758334,
      code: 'C140050',
      description: '140x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 175.63,
      price: 351.25,
      sizeX: 150,
      sizeY: 50,
      weight: 3.15265106,
      code: 'C150050',
      description: '150x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 215.76,
      price: 431.53,
      sizeX: 160,
      sizeY: 50,
      weight: 3.359543786,
      code: 'C160050',
      description: '160x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 228.88,
      price: 457.76,
      sizeX: 170,
      sizeY: 50,
      weight: 3.566436512,
      code: 'C170050',
      description: '170x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 242,
      price: 484,
      sizeX: 180,
      sizeY: 50,
      weight: 3.773329238,
      code: 'C180050',
      description: '180x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 255.12,
      price: 510.24,
      sizeX: 190,
      sizeY: 50,
      weight: 3.980221963,
      code: 'C190050',
      description: '190x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 232.47,
      price: 464.95,
      sizeX: 200,
      sizeY: 50,
      weight: 4.187114689,
      code: 'C200050',
      description: '200x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 281.36,
      price: 562.71,
      sizeX: 210,
      sizeY: 50,
      weight: 4.394007415,
      code: 'C210050',
      description: '210x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 294.48,
      price: 588.95,
      sizeX: 220,
      sizeY: 50,
      weight: 4.600900141,
      code: 'C220050',
      description: '220x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 307.59,
      price: 615.19,
      sizeX: 230,
      sizeY: 50,
      weight: 4.807792867,
      code: 'C230050',
      description: '230x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 320.71,
      price: 641.43,
      sizeX: 240,
      sizeY: 50,
      weight: 5.014685593,
      code: 'C240050',
      description: '240x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 289.32,
      price: 578.64,
      sizeX: 250,
      sizeY: 50,
      weight: 5.221578318,
      code: 'C250050',
      description: '250x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 346.95,
      price: 693.9,
      sizeX: 260,
      sizeY: 50,
      weight: 5.428471044,
      code: 'C260050',
      description: '260x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 360.07,
      price: 720.14,
      sizeX: 270,
      sizeY: 50,
      weight: 5.63536377,
      code: 'C270050',
      description: '270x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 373.19,
      price: 746.37,
      sizeX: 280,
      sizeY: 50,
      weight: 5.842256496,
      code: 'C280050',
      description: '280x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 386.31,
      price: 772.61,
      sizeX: 290,
      sizeY: 50,
      weight: 6.049149222,
      code: 'C290050',
      description: '290x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 346.17,
      price: 692.34,
      sizeX: 300,
      sizeY: 50,
      weight: 6.256041947,
      code: 'C300050',
      description: '300x50',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 26.51,
      price: 53.03,
      sizeX: 10,
      sizeY: 50,
      weight: 0.305413071,
      code: 'D010050',
      description: '10x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 42.35,
      price: 84.69,
      sizeX: 20,
      sizeY: 50,
      weight: 0.56156597,
      code: 'D020050',
      description: '20x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 50.42,
      price: 100.84,
      sizeX: 30,
      sizeY: 50,
      weight: 0.817718869,
      code: 'D030050',
      description: '30x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 64.14,
      price: 128.28,
      sizeX: 40,
      sizeY: 50,
      weight: 1.073871767,
      code: 'D040050',
      description: '40x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 284.75,
      price: 569.51,
      sizeX: 200,
      sizeY: 50,
      weight: 5.172318146,
      code: 'D200050',
      description: '200x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 78.17,
      price: 156.34,
      sizeX: 50,
      sizeY: 50,
      weight: 1.330024666,
      code: 'D050050',
      description: '50x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 147.03,
      price: 294.06,
      sizeX: 100,
      sizeY: 50,
      weight: 2.610789159,
      code: 'D100050',
      description: '100x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 184.83,
      price: 369.66,
      sizeX: 110,
      sizeY: 50,
      weight: 2.866942058,
      code: 'D110050',
      description: '110x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 216.49,
      price: 432.98,
      sizeX: 130,
      sizeY: 50,
      weight: 3.379247855,
      code: 'D130050',
      description: '130x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 311.48,
      price: 622.96,
      sizeX: 190,
      sizeY: 50,
      weight: 4.916165247,
      code: 'D190050',
      description: '190x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 105.67,
      price: 211.34,
      sizeX: 60,
      sizeY: 50,
      weight: 1.586177565,
      code: 'D060050',
      description: '60x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 121.5,
      price: 243.01,
      sizeX: 70,
      sizeY: 50,
      weight: 1.842330463,
      code: 'D070050',
      description: '70x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 137.33,
      price: 274.67,
      sizeX: 80,
      sizeY: 50,
      weight: 2.098483362,
      code: 'D080050',
      description: '80x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 204.22,
      price: 408.44,
      sizeX: 90,
      sizeY: 50,
      weight: 2.354636261,
      code: 'D090050',
      description: '90x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 200.66,
      price: 401.32,
      sizeX: 120,
      sizeY: 50,
      weight: 3.123094956,
      code: 'D120050',
      description: '120x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 232.32,
      price: 464.65,
      sizeX: 140,
      sizeY: 50,
      weight: 3.635400754,
      code: 'D140050',
      description: '140x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 215.89,
      price: 431.78,
      sizeX: 150,
      sizeY: 50,
      weight: 3.891553652,
      code: 'D150050',
      description: '150x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 263.99,
      price: 527.97,
      sizeX: 160,
      sizeY: 50,
      weight: 4.147706551,
      code: 'D160050',
      description: '160x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 279.82,
      price: 559.63,
      sizeX: 170,
      sizeY: 50,
      weight: 4.40385945,
      code: 'D170050',
      description: '170x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 295.65,
      price: 591.3,
      sizeX: 180,
      sizeY: 50,
      weight: 4.660012348,
      code: 'D180050',
      description: '180x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 343.14,
      price: 686.29,
      sizeX: 210,
      sizeY: 50,
      weight: 5.428471044,
      code: 'D210050',
      description: '210x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 358.97,
      price: 717.95,
      sizeX: 220,
      sizeY: 50,
      weight: 5.684623943,
      code: 'D220050',
      description: '220x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 374.81,
      price: 749.61,
      sizeX: 230,
      sizeY: 50,
      weight: 5.940776841,
      code: 'D230050',
      description: '230x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 390.64,
      price: 781.27,
      sizeX: 240,
      sizeY: 50,
      weight: 6.19692974,
      code: 'D240050',
      description: '240x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 353.61,
      price: 707.23,
      sizeX: 250,
      sizeY: 50,
      weight: 6.453082639,
      code: 'D250050',
      description: '250x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 422.3,
      price: 844.6,
      sizeX: 260,
      sizeY: 50,
      weight: 6.709235537,
      code: 'D260050',
      description: '260x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 438.13,
      price: 876.26,
      sizeX: 270,
      sizeY: 50,
      weight: 6.965388436,
      code: 'D270050',
      description: '270x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 453.96,
      price: 907.93,
      sizeX: 280,
      sizeY: 50,
      weight: 7.221541335,
      code: 'D280050',
      description: '280x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 469.79,
      price: 939.59,
      sizeX: 290,
      sizeY: 50,
      weight: 7.477694233,
      code: 'D290050',
      description: '290x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 422.47,
      price: 844.95,
      sizeX: 300,
      sizeY: 50,
      weight: 7.733847132,
      code: 'D300050',
      description: '300x50',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 69.8,
      price: 139.61,
      sizeX: 40,
      sizeY: 55,
      weight: 0.74382861,
      code: 'B040055',
      description: '40x55',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 115.57,
      price: 231.14,
      sizeX: 100,
      sizeY: 55,
      weight: 1.778292239,
      code: 'B100055',
      description: '100x55',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 199.09,
      price: 398.18,
      sizeX: 130,
      sizeY: 55,
      weight: 2.295524053,
      code: 'B130055',
      description: '130x55',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 223.3,
      price: 446.61,
      sizeX: 200,
      sizeY: 55,
      weight: 3.502398287,
      code: 'B200055',
      description: '200x55',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 457.65,
      price: 915.31,
      sizeX: 310,
      sizeY: 55,
      weight: 5.39891494,
      code: 'B310055',
      description: '310x55',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 209.06,
      price: 418.11,
      sizeX: 105,
      sizeY: 55,
      weight: 2.433452537,
      code: 'C105055',
      description: '105x55',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 409.58,
      price: 819.15,
      sizeX: 210,
      sizeY: 55,
      weight: 4.812718884,
      code: 'C210055',
      description: '210x55',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 419.12,
      price: 838.25,
      sizeX: 215,
      sizeY: 55,
      weight: 4.926017281,
      code: 'C215055',
      description: '215x55',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 436.09,
      price: 872.17,
      sizeX: 300,
      sizeY: 55,
      weight: 6.852090039,
      code: 'C300055',
      description: '300x55',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 591,
      price: 1181.99,
      sizeX: 305,
      sizeY: 55,
      weight: 6.965388436,
      code: 'C305055',
      description: '305x55',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 600.54,
      price: 1201.09,
      sizeX: 310,
      sizeY: 55,
      weight: 7.078686833,
      code: 'C310055',
      description: '310x55',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 62.92,
      price: 125.84,
      sizeX: 70,
      sizeY: 60,
      weight: 0.955647353,
      code: 'A070060',
      description: '70x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 71.1,
      price: 142.2,
      sizeX: 80,
      sizeY: 60,
      weight: 1.083723802,
      code: 'A080060',
      description: '80x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 105.71,
      price: 211.43,
      sizeX: 90,
      sizeY: 60,
      weight: 1.211800251,
      code: 'A090060',
      description: '90x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 75.81,
      price: 151.61,
      sizeX: 100,
      sizeY: 60,
      weight: 1.339876701,
      code: 'A100060',
      description: '100x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 95.65,
      price: 191.3,
      sizeX: 110,
      sizeY: 60,
      weight: 1.46795315,
      code: 'A110060',
      description: '110x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 103.84,
      price: 207.67,
      sizeX: 120,
      sizeY: 60,
      weight: 1.596029599,
      code: 'A120060',
      description: '120x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 112.85,
      price: 225.7,
      sizeX: 130,
      sizeY: 60,
      weight: 1.724106049,
      code: 'A130060',
      description: '130x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 121.03,
      price: 242.07,
      sizeX: 140,
      sizeY: 60,
      weight: 1.852182498,
      code: 'A140060',
      description: '140x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 129.22,
      price: 258.44,
      sizeX: 150,
      sizeY: 60,
      weight: 1.980258947,
      code: 'A150060',
      description: '150x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 137.4,
      price: 274.8,
      sizeX: 160,
      sizeY: 60,
      weight: 2.108335396,
      code: 'A160060',
      description: '160x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 145.58,
      price: 291.17,
      sizeX: 170,
      sizeY: 60,
      weight: 2.236411846,
      code: 'A170060',
      description: '170x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 153.77,
      price: 307.54,
      sizeX: 180,
      sizeY: 60,
      weight: 2.364488295,
      code: 'A180060',
      description: '180x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 215.94,
      price: 431.87,
      sizeX: 190,
      sizeY: 60,
      weight: 2.492564744,
      code: 'A190060',
      description: '190x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 147.45,
      price: 294.9,
      sizeX: 200,
      sizeY: 60,
      weight: 2.620641194,
      code: 'A200060',
      description: '200x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 178.32,
      price: 356.64,
      sizeX: 210,
      sizeY: 60,
      weight: 2.748717643,
      code: 'A210060',
      description: '210x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 186.5,
      price: 373,
      sizeX: 220,
      sizeY: 60,
      weight: 2.876794092,
      code: 'A220060',
      description: '220x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 194.69,
      price: 389.37,
      sizeX: 230,
      sizeY: 60,
      weight: 3.004870542,
      code: 'A230060',
      description: '230x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 202.87,
      price: 405.74,
      sizeX: 240,
      sizeY: 60,
      weight: 3.132946991,
      code: 'A240060',
      description: '240x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 182.91,
      price: 365.82,
      sizeX: 250,
      sizeY: 60,
      weight: 3.26102344,
      code: 'A250060',
      description: '250x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 219.24,
      price: 438.47,
      sizeX: 260,
      sizeY: 60,
      weight: 3.38909989,
      code: 'A260060',
      description: '260x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 227.42,
      price: 454.84,
      sizeX: 270,
      sizeY: 60,
      weight: 3.517176339,
      code: 'A270060',
      description: '270x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 235.6,
      price: 471.2,
      sizeX: 280,
      sizeY: 60,
      weight: 3.645252788,
      code: 'A280060',
      description: '280x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 325.05,
      price: 650.09,
      sizeX: 290,
      sizeY: 60,
      weight: 3.773329238,
      code: 'A290060',
      description: '290x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 218.37,
      price: 436.75,
      sizeX: 300,
      sizeY: 60,
      weight: 3.901405687,
      code: 'A300060',
      description: '300x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 260.15,
      price: 520.3,
      sizeX: 310,
      sizeY: 60,
      weight: 4.029482136,
      code: 'A310060',
      description: '310x60',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 153.5,
      price: 307,
      sizeX: 90,
      sizeY: 60,
      weight: 1.743810118,
      code: 'B090060',
      description: '90x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 33.39,
      price: 66.78,
      sizeX: 20,
      sizeY: 60,
      weight: 0.433489521,
      code: 'B020060',
      description: '20x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 45.07,
      price: 90.13,
      sizeX: 30,
      sizeY: 60,
      weight: 0.620678177,
      code: 'B030060',
      description: '30x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 103.45,
      price: 206.9,
      sizeX: 80,
      sizeY: 60,
      weight: 1.556621461,
      code: 'B080060',
      description: '80x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 56.74,
      price: 113.49,
      sizeX: 40,
      sizeY: 60,
      weight: 0.807866834,
      code: 'B040060',
      description: '40x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 91.77,
      price: 183.55,
      sizeX: 70,
      sizeY: 60,
      weight: 1.369432804,
      code: 'B070060',
      description: '70x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 57.96,
      price: 115.91,
      sizeX: 50,
      sizeY: 60,
      weight: 0.995055491,
      code: 'B050060',
      description: '50x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 80.1,
      price: 160.19,
      sizeX: 60,
      sizeY: 60,
      weight: 1.182244148,
      code: 'B060060',
      description: '60x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 108.56,
      price: 217.11,
      sizeX: 100,
      sizeY: 60,
      weight: 1.930998774,
      code: 'B100060',
      description: '100x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 138.48,
      price: 276.96,
      sizeX: 110,
      sizeY: 60,
      weight: 2.118187431,
      code: 'B110060',
      description: '110x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 150.16,
      price: 300.31,
      sizeX: 120,
      sizeY: 60,
      weight: 2.305376088,
      code: 'B120060',
      description: '120x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 196.86,
      price: 393.72,
      sizeX: 160,
      sizeY: 60,
      weight: 3.054130715,
      code: 'B160060',
      description: '160x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 161.83,
      price: 323.67,
      sizeX: 130,
      sizeY: 60,
      weight: 2.492564744,
      code: 'B130060',
      description: '130x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 159.15,
      price: 318.31,
      sizeX: 150,
      sizeY: 60,
      weight: 2.866942058,
      code: 'B150060',
      description: '150x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 208.54,
      price: 417.08,
      sizeX: 170,
      sizeY: 60,
      weight: 3.241319371,
      code: 'B170060',
      description: '170x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 173.51,
      price: 347.02,
      sizeX: 140,
      sizeY: 60,
      weight: 2.679753401,
      code: 'B140060',
      description: '140x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 220.22,
      price: 440.43,
      sizeX: 180,
      sizeY: 60,
      weight: 3.428508028,
      code: 'B180060',
      description: '180x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 209.75,
      price: 419.51,
      sizeX: 200,
      sizeY: 60,
      weight: 3.802885341,
      code: 'B200060',
      description: '200x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 309.19,
      price: 618.38,
      sizeX: 190,
      sizeY: 60,
      weight: 3.615696685,
      code: 'B190060',
      description: '190x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 266.92,
      price: 533.84,
      sizeX: 220,
      sizeY: 60,
      weight: 4.177262655,
      code: 'B220060',
      description: '220x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 255.25,
      price: 510.49,
      sizeX: 210,
      sizeY: 60,
      weight: 3.990073998,
      code: 'B210060',
      description: '210x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 278.6,
      price: 557.2,
      sizeX: 230,
      sizeY: 60,
      weight: 4.364451311,
      code: 'B230060',
      description: '230x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 290.28,
      price: 580.55,
      sizeX: 240,
      sizeY: 60,
      weight: 4.551639968,
      code: 'B240060',
      description: '240x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 313.63,
      price: 627.26,
      sizeX: 260,
      sizeY: 60,
      weight: 4.926017281,
      code: 'B260060',
      description: '260x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 325.31,
      price: 650.61,
      sizeX: 270,
      sizeY: 60,
      weight: 5.113205938,
      code: 'B270060',
      description: '270x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 260.35,
      price: 520.7,
      sizeX: 250,
      sizeY: 60,
      weight: 4.738828625,
      code: 'B250060',
      description: '250x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 336.98,
      price: 673.96,
      sizeX: 280,
      sizeY: 60,
      weight: 5.300394595,
      code: 'B280060',
      description: '280x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 348.66,
      price: 697.32,
      sizeX: 290,
      sizeY: 60,
      weight: 5.487583252,
      code: 'B290060',
      description: '290x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 310.95,
      price: 621.9,
      sizeX: 300,
      sizeY: 60,
      weight: 5.674771908,
      code: 'B300060',
      description: '300x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 372.01,
      price: 744.02,
      sizeX: 310,
      sizeY: 60,
      weight: 5.861960565,
      code: 'B310060',
      description: '310x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 100.1,
      price: 200.21,
      sizeX: 60,
      sizeY: 60,
      weight: 1.536917392,
      code: 'C060060',
      description: '60x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 115.63,
      price: 231.26,
      sizeX: 70,
      sizeY: 60,
      weight: 1.783218256,
      code: 'C070060',
      description: '70x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 131.16,
      price: 262.32,
      sizeX: 80,
      sizeY: 60,
      weight: 2.02951912,
      code: 'C080060',
      description: '80x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 146.68,
      price: 293.37,
      sizeX: 90,
      sizeY: 60,
      weight: 2.275819984,
      code: 'C090060',
      description: '90x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 140.58,
      price: 281.17,
      sizeX: 100,
      sizeY: 60,
      weight: 2.522120848,
      code: 'C100060',
      description: '100x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 177.74,
      price: 355.48,
      sizeX: 110,
      sizeY: 60,
      weight: 2.768421712,
      code: 'C110060',
      description: '110x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 193.26,
      price: 386.53,
      sizeX: 120,
      sizeY: 60,
      weight: 3.014722576,
      code: 'C120060',
      description: '120x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 208.79,
      price: 417.58,
      sizeX: 130,
      sizeY: 60,
      weight: 3.26102344,
      code: 'C130060',
      description: '130x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 224.32,
      price: 448.64,
      sizeX: 140,
      sizeY: 60,
      weight: 3.507324304,
      code: 'C140060',
      description: '140x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 239.85,
      price: 479.69,
      sizeX: 150,
      sizeY: 60,
      weight: 3.753625168,
      code: 'C150060',
      description: '150x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 255.37,
      price: 510.74,
      sizeX: 160,
      sizeY: 60,
      weight: 3.999926033,
      code: 'C160060',
      description: '160x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 270.9,
      price: 541.8,
      sizeX: 170,
      sizeY: 60,
      weight: 4.246226897,
      code: 'C170060',
      description: '170x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 286.43,
      price: 572.85,
      sizeX: 180,
      sizeY: 60,
      weight: 4.492527761,
      code: 'C180060',
      description: '180x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 301.95,
      price: 603.9,
      sizeX: 190,
      sizeY: 60,
      weight: 4.738828625,
      code: 'C190060',
      description: '190x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 275.15,
      price: 550.3,
      sizeX: 200,
      sizeY: 60,
      weight: 4.985129489,
      code: 'C200060',
      description: '200x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 333.01,
      price: 666.01,
      sizeX: 210,
      sizeY: 60,
      weight: 5.231430353,
      code: 'C210060',
      description: '210x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 348.53,
      price: 697.07,
      sizeX: 220,
      sizeY: 60,
      weight: 5.477731217,
      code: 'C220060',
      description: '220x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 364.06,
      price: 728.12,
      sizeX: 230,
      sizeY: 60,
      weight: 5.724032081,
      code: 'C230060',
      description: '230x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 379.59,
      price: 759.17,
      sizeX: 240,
      sizeY: 60,
      weight: 5.970332945,
      code: 'C240060',
      description: '240x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 342.43,
      price: 684.86,
      sizeX: 250,
      sizeY: 60,
      weight: 6.216633809,
      code: 'C250060',
      description: '250x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 410.64,
      price: 821.28,
      sizeX: 260,
      sizeY: 60,
      weight: 6.462934673,
      code: 'C260060',
      description: '260x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 426.17,
      price: 852.33,
      sizeX: 270,
      sizeY: 60,
      weight: 6.709235537,
      code: 'C270060',
      description: '270x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 441.69,
      price: 883.39,
      sizeX: 280,
      sizeY: 60,
      weight: 6.955536401,
      code: 'C280060',
      description: '280x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 457.22,
      price: 914.44,
      sizeX: 290,
      sizeY: 60,
      weight: 7.201837266,
      code: 'C290060',
      description: '290x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 409.71,
      price: 819.43,
      sizeX: 300,
      sizeY: 60,
      weight: 7.44813813,
      code: 'C300060',
      description: '300x60',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 34.53,
      price: 69.06,
      sizeX: 10,
      sizeY: 60,
      weight: 0.364525279,
      code: 'D010060',
      description: '10x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 53.37,
      price: 106.74,
      sizeX: 20,
      sizeY: 60,
      weight: 0.66993835,
      code: 'D020060',
      description: '20x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 72.21,
      price: 144.42,
      sizeX: 30,
      sizeY: 60,
      weight: 0.975351422,
      code: 'D030060',
      description: '30x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 174.31,
      price: 348.62,
      sizeX: 100,
      sizeY: 60,
      weight: 3.113242922,
      code: 'D100060',
      description: '100x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 337.61,
      price: 675.21,
      sizeX: 200,
      sizeY: 60,
      weight: 6.167373636,
      code: 'D200060',
      description: '200x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 91.05,
      price: 182.11,
      sizeX: 40,
      sizeY: 60,
      weight: 1.280764493,
      code: 'D040060',
      description: '40x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 92.66,
      price: 185.33,
      sizeX: 50,
      sizeY: 60,
      weight: 1.586177565,
      code: 'D050060',
      description: '50x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 128.74,
      price: 257.47,
      sizeX: 60,
      sizeY: 60,
      weight: 1.891590636,
      code: 'D060060',
      description: '60x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 147.58,
      price: 295.16,
      sizeX: 70,
      sizeY: 60,
      weight: 2.197003708,
      code: 'D070060',
      description: '70x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 166.42,
      price: 332.84,
      sizeX: 80,
      sizeY: 60,
      weight: 2.502416779,
      code: 'D080060',
      description: '80x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 185.26,
      price: 370.52,
      sizeX: 90,
      sizeY: 60,
      weight: 2.80782985,
      code: 'D090060',
      description: '90x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 222.94,
      price: 445.89,
      sizeX: 110,
      sizeY: 60,
      weight: 3.418655993,
      code: 'D110060',
      description: '110x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 241.79,
      price: 483.57,
      sizeX: 120,
      sizeY: 60,
      weight: 3.724069065,
      code: 'D120060',
      description: '120x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 260.63,
      price: 521.26,
      sizeX: 130,
      sizeY: 60,
      weight: 4.029482136,
      code: 'D130060',
      description: '130x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 279.47,
      price: 558.94,
      sizeX: 140,
      sizeY: 60,
      weight: 4.334895208,
      code: 'D140060',
      description: '140x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 255.96,
      price: 511.92,
      sizeX: 150,
      sizeY: 60,
      weight: 4.640308279,
      code: 'D150060',
      description: '150x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 317.15,
      price: 634.31,
      sizeX: 160,
      sizeY: 60,
      weight: 4.945721351,
      code: 'D160060',
      description: '160x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 335.99,
      price: 671.99,
      sizeX: 170,
      sizeY: 60,
      weight: 5.251134422,
      code: 'D170060',
      description: '170x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 354.84,
      price: 709.67,
      sizeX: 180,
      sizeY: 60,
      weight: 5.556547494,
      code: 'D180060',
      description: '180x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 373.68,
      price: 747.36,
      sizeX: 190,
      sizeY: 60,
      weight: 5.861960565,
      code: 'D190060',
      description: '190x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 411.36,
      price: 822.72,
      sizeX: 210,
      sizeY: 60,
      weight: 6.472786708,
      code: 'D210060',
      description: '210x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 430.2,
      price: 860.4,
      sizeX: 220,
      sizeY: 60,
      weight: 6.778199779,
      code: 'D220060',
      description: '220x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 449.04,
      price: 898.09,
      sizeX: 230,
      sizeY: 60,
      weight: 7.083612851,
      code: 'D230060',
      description: '230x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 467.89,
      price: 935.77,
      sizeX: 240,
      sizeY: 60,
      weight: 7.389025922,
      code: 'D240060',
      description: '240x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 419.25,
      price: 838.51,
      sizeX: 250,
      sizeY: 60,
      weight: 7.694438994,
      code: 'D250060',
      description: '250x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 505.57,
      price: 1011.14,
      sizeX: 260,
      sizeY: 60,
      weight: 7.999852065,
      code: 'D260060',
      description: '260x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 524.41,
      price: 1048.82,
      sizeX: 270,
      sizeY: 60,
      weight: 8.305265137,
      code: 'D270060',
      description: '270x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 543.25,
      price: 1086.5,
      sizeX: 280,
      sizeY: 60,
      weight: 8.610678208,
      code: 'D280060',
      description: '280x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 562.09,
      price: 1124.19,
      sizeX: 290,
      sizeY: 60,
      weight: 8.916091279,
      code: 'D290060',
      description: '290x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 500.9,
      price: 1001.8,
      sizeX: 300,
      sizeY: 60,
      weight: 9.221504351,
      code: 'D300060',
      description: '300x60',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 31.19,
      price: 62.38,
      sizeX: 10,
      sizeY: 65,
      weight: 0.266004933,
      code: 'B010065',
      description: '10x65',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 47.96,
      price: 95.93,
      sizeX: 20,
      sizeY: 65,
      weight: 0.467971642,
      code: 'B020065',
      description: '20x65',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 134.94,
      price: 269.89,
      sizeX: 100,
      sizeY: 65,
      weight: 2.08370531,
      code: 'B100065',
      description: '100x65',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 64.74,
      price: 129.47,
      sizeX: 30,
      sizeY: 65,
      weight: 0.66993835,
      code: 'B030065',
      description: '30x65',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 232.47,
      price: 464.93,
      sizeX: 130,
      sizeY: 65,
      weight: 2.689605436,
      code: 'B130065',
      description: '130x65',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 72.05,
      price: 144.09,
      sizeX: 50,
      sizeY: 65,
      weight: 1.073871767,
      code: 'B050065',
      description: '50x65',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 260.74,
      price: 521.48,
      sizeX: 200,
      sizeY: 65,
      weight: 4.103372395,
      code: 'B200065',
      description: '200x65',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 73.14,
      price: 146.28,
      sizeX: 70,
      sizeY: 70,
      weight: 1.103427871,
      code: 'A070070',
      description: '70x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 82.53,
      price: 165.05,
      sizeX: 80,
      sizeY: 70,
      weight: 1.251208389,
      code: 'A080070',
      description: '80x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 122.55,
      price: 245.1,
      sizeX: 90,
      sizeY: 70,
      weight: 1.398988908,
      code: 'A090070',
      description: '90x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 87.79,
      price: 175.59,
      sizeX: 100,
      sizeY: 70,
      weight: 1.546769426,
      code: 'A100070',
      description: '100x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 110.69,
      price: 221.38,
      sizeX: 110,
      sizeY: 70,
      weight: 1.694549945,
      code: 'A110070',
      description: '110x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 120.08,
      price: 240.15,
      sizeX: 120,
      sizeY: 70,
      weight: 1.842330463,
      code: 'A120070',
      description: '120x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 129.46,
      price: 258.93,
      sizeX: 130,
      sizeY: 70,
      weight: 1.990110982,
      code: 'A130070',
      description: '130x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 138.85,
      price: 277.7,
      sizeX: 140,
      sizeY: 70,
      weight: 2.1378915,
      code: 'A140070',
      description: '140x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 128.47,
      price: 256.95,
      sizeX: 150,
      sizeY: 70,
      weight: 2.285672019,
      code: 'A150070',
      description: '150x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 157.63,
      price: 315.25,
      sizeX: 160,
      sizeY: 70,
      weight: 2.433452537,
      code: 'A160070',
      description: '160x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 167.01,
      price: 334.03,
      sizeX: 170,
      sizeY: 70,
      weight: 2.581233055,
      code: 'A170070',
      description: '170x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 176.4,
      price: 352.8,
      sizeX: 180,
      sizeY: 70,
      weight: 2.729013574,
      code: 'A180070',
      description: '180x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 247.72,
      price: 495.43,
      sizeX: 190,
      sizeY: 70,
      weight: 2.876794092,
      code: 'A190070',
      description: '190x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 169.15,
      price: 338.3,
      sizeX: 200,
      sizeY: 70,
      weight: 3.024574611,
      code: 'A200070',
      description: '200x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 204.56,
      price: 409.13,
      sizeX: 210,
      sizeY: 70,
      weight: 3.172355129,
      code: 'A210070',
      description: '210x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 213.95,
      price: 427.9,
      sizeX: 220,
      sizeY: 70,
      weight: 3.320135648,
      code: 'A220070',
      description: '220x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 223.34,
      price: 446.68,
      sizeX: 230,
      sizeY: 70,
      weight: 3.467916166,
      code: 'A230070',
      description: '230x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 232.73,
      price: 465.45,
      sizeX: 240,
      sizeY: 70,
      weight: 3.615696685,
      code: 'A240070',
      description: '240x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 209.83,
      price: 419.66,
      sizeX: 250,
      sizeY: 70,
      weight: 3.763477203,
      code: 'A250070',
      description: '250x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 251.5,
      price: 503,
      sizeX: 260,
      sizeY: 70,
      weight: 3.911257721,
      code: 'A260070',
      description: '260x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 260.89,
      price: 521.77,
      sizeX: 270,
      sizeY: 70,
      weight: 4.05903824,
      code: 'A270070',
      description: '270x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 270.27,
      price: 540.55,
      sizeX: 280,
      sizeY: 70,
      weight: 4.206818758,
      code: 'A280070',
      description: '280x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 250.51,
      price: 501.02,
      sizeX: 300,
      sizeY: 70,
      weight: 4.502379795,
      code: 'A300070',
      description: '300x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 298.44,
      price: 596.87,
      sizeX: 310,
      sizeY: 70,
      weight: 4.650160314,
      code: 'A310070',
      description: '310x70',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 132.93,
      price: 265.87,
      sizeX: 90,
      sizeY: 70,
      weight: 2.019667085,
      code: 'B090070',
      description: '90x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 25.07,
      price: 50.15,
      sizeX: 10,
      sizeY: 70,
      weight: 0.285709002,
      code: 'B010070',
      description: '10x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 38.56,
      price: 77.11,
      sizeX: 20,
      sizeY: 70,
      weight: 0.502453763,
      code: 'B020070',
      description: '20x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 119.45,
      price: 238.9,
      sizeX: 80,
      sizeY: 70,
      weight: 1.802922325,
      code: 'B080070',
      description: '80x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 45.1,
      price: 90.2,
      sizeX: 30,
      sizeY: 70,
      weight: 0.719198523,
      code: 'B030070',
      description: '30x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 105.97,
      price: 211.94,
      sizeX: 70,
      sizeY: 70,
      weight: 1.586177565,
      code: 'B070070',
      description: '70x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 65.52,
      price: 131.04,
      sizeX: 40,
      sizeY: 70,
      weight: 0.935943283,
      code: 'B040070',
      description: '40x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 66.92,
      price: 133.85,
      sizeX: 50,
      sizeY: 70,
      weight: 1.152688044,
      code: 'B050070',
      description: '50x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 92.49,
      price: 184.97,
      sizeX: 60,
      sizeY: 70,
      weight: 1.369432804,
      code: 'B060070',
      description: '60x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 125.35,
      price: 250.7,
      sizeX: 100,
      sizeY: 70,
      weight: 2.236411846,
      code: 'B100070',
      description: '100x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 159.9,
      price: 319.8,
      sizeX: 110,
      sizeY: 70,
      weight: 2.453156606,
      code: 'B110070',
      description: '110x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 173.38,
      price: 346.77,
      sizeX: 120,
      sizeY: 70,
      weight: 2.669901367,
      code: 'B120070',
      description: '120x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 183.77,
      price: 367.55,
      sizeX: 150,
      sizeY: 70,
      weight: 3.320135648,
      code: 'B150070',
      description: '150x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 227.31,
      price: 454.63,
      sizeX: 160,
      sizeY: 70,
      weight: 3.536880408,
      code: 'B160070',
      description: '160x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 186.87,
      price: 373.73,
      sizeX: 130,
      sizeY: 70,
      weight: 2.886646127,
      code: 'B130070',
      description: '130x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 240.8,
      price: 481.59,
      sizeX: 170,
      sizeY: 70,
      weight: 3.753625168,
      code: 'B170070',
      description: '170x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 200.35,
      price: 400.7,
      sizeX: 140,
      sizeY: 70,
      weight: 3.103390887,
      code: 'B140070',
      description: '140x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 254.28,
      price: 508.56,
      sizeX: 180,
      sizeY: 70,
      weight: 3.970369929,
      code: 'B180070',
      description: '180x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 267.76,
      price: 535.52,
      sizeX: 190,
      sizeY: 70,
      weight: 4.187114689,
      code: 'B190070',
      description: '190x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 242.2,
      price: 484.4,
      sizeX: 200,
      sizeY: 70,
      weight: 4.40385945,
      code: 'B200070',
      description: '200x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 294.73,
      price: 589.46,
      sizeX: 210,
      sizeY: 70,
      weight: 4.62060421,
      code: 'B210070',
      description: '210x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 308.21,
      price: 616.42,
      sizeX: 220,
      sizeY: 70,
      weight: 4.83734897,
      code: 'B220070',
      description: '220x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 321.69,
      price: 643.39,
      sizeX: 230,
      sizeY: 70,
      weight: 5.054093731,
      code: 'B230070',
      description: '230x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 335.18,
      price: 670.35,
      sizeX: 240,
      sizeY: 70,
      weight: 5.270838491,
      code: 'B240070',
      description: '240x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 362.14,
      price: 724.28,
      sizeX: 260,
      sizeY: 70,
      weight: 5.704328012,
      code: 'B260070',
      description: '260x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 375.62,
      price: 751.25,
      sizeX: 270,
      sizeY: 70,
      weight: 5.921072772,
      code: 'B270070',
      description: '270x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 389.11,
      price: 778.21,
      sizeX: 280,
      sizeY: 70,
      weight: 6.137817533,
      code: 'B280070',
      description: '280x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 300.62,
      price: 601.25,
      sizeX: 250,
      sizeY: 70,
      weight: 5.487583252,
      code: 'B250070',
      description: '250x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 359.05,
      price: 718.1,
      sizeX: 300,
      sizeY: 70,
      weight: 6.571307053,
      code: 'B300070',
      description: '300x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 536.79,
      price: 1073.57,
      sizeX: 290,
      sizeY: 70,
      weight: 6.354562293,
      code: 'B290070',
      description: '290x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 429.56,
      price: 859.11,
      sizeX: 310,
      sizeY: 70,
      weight: 6.788051814,
      code: 'B310070',
      description: '310x70',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 133.57,
      price: 267.13,
      sizeX: 70,
      sizeY: 70,
      weight: 2.068927258,
      code: 'C070070',
      description: '70x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 151.5,
      price: 303,
      sizeX: 80,
      sizeY: 70,
      weight: 2.354636261,
      code: 'C080070',
      description: '80x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 169.44,
      price: 338.87,
      sizeX: 90,
      sizeY: 70,
      weight: 2.640345263,
      code: 'C090070',
      description: '90x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 162.39,
      price: 324.78,
      sizeX: 100,
      sizeY: 70,
      weight: 2.926054265,
      code: 'C100070',
      description: '100x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 205.31,
      price: 410.61,
      sizeX: 110,
      sizeY: 70,
      weight: 3.211763268,
      code: 'C110070',
      description: '110x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 223.24,
      price: 446.48,
      sizeX: 120,
      sizeY: 70,
      weight: 3.49747227,
      code: 'C120070',
      description: '120x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 241.18,
      price: 482.35,
      sizeX: 130,
      sizeY: 70,
      weight: 3.783181272,
      code: 'C130070',
      description: '130x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 259.11,
      price: 518.22,
      sizeX: 140,
      sizeY: 70,
      weight: 4.068890274,
      code: 'C140070',
      description: '140x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 240.11,
      price: 480.21,
      sizeX: 150,
      sizeY: 70,
      weight: 4.354599277,
      code: 'C150070',
      description: '150x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 294.98,
      price: 589.96,
      sizeX: 160,
      sizeY: 70,
      weight: 4.640308279,
      code: 'C160070',
      description: '160x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 312.92,
      price: 625.83,
      sizeX: 170,
      sizeY: 70,
      weight: 4.926017281,
      code: 'C170070',
      description: '170x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 330.85,
      price: 661.7,
      sizeX: 180,
      sizeY: 70,
      weight: 5.211726284,
      code: 'C180070',
      description: '180x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 348.78,
      price: 697.57,
      sizeX: 190,
      sizeY: 70,
      weight: 5.497435286,
      code: 'C190070',
      description: '190x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 317.82,
      price: 635.65,
      sizeX: 200,
      sizeY: 70,
      weight: 5.783144288,
      code: 'C200070',
      description: '200x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 384.65,
      price: 769.31,
      sizeX: 210,
      sizeY: 70,
      weight: 6.068853291,
      code: 'C210070',
      description: '210x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 402.59,
      price: 805.18,
      sizeX: 220,
      sizeY: 70,
      weight: 6.354562293,
      code: 'C220070',
      description: '220x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 420.52,
      price: 841.05,
      sizeX: 230,
      sizeY: 70,
      weight: 6.640271295,
      code: 'C230070',
      description: '230x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 438.46,
      price: 876.92,
      sizeX: 240,
      sizeY: 70,
      weight: 6.925980298,
      code: 'C240070',
      description: '240x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 395.54,
      price: 791.08,
      sizeX: 250,
      sizeY: 70,
      weight: 7.2116893,
      code: 'C250070',
      description: '250x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 474.33,
      price: 948.66,
      sizeX: 260,
      sizeY: 70,
      weight: 7.497398302,
      code: 'C260070',
      description: '260x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 492.26,
      price: 984.53,
      sizeX: 270,
      sizeY: 70,
      weight: 7.783107305,
      code: 'C270070',
      description: '270x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 510.2,
      price: 1020.4,
      sizeX: 280,
      sizeY: 70,
      weight: 8.068816307,
      code: 'C280070',
      description: '280x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 528.13,
      price: 1056.27,
      sizeX: 290,
      sizeY: 70,
      weight: 8.354525309,
      code: 'C290070',
      description: '290x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 473.26,
      price: 946.52,
      sizeX: 300,
      sizeY: 70,
      weight: 8.640234312,
      code: 'C300070',
      description: '300x70',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 40.04,
      price: 80.09,
      sizeX: 10,
      sizeY: 70,
      weight: 0.423637486,
      code: 'D010070',
      description: '10x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 61.9,
      price: 123.79,
      sizeX: 20,
      sizeY: 70,
      weight: 0.77831073,
      code: 'D020070',
      description: '20x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 72.58,
      price: 145.16,
      sizeX: 30,
      sizeY: 70,
      weight: 1.132983975,
      code: 'D030070',
      description: '30x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 391.54,
      price: 783.08,
      sizeX: 200,
      sizeY: 70,
      weight: 7.162429127,
      code: 'D200070',
      description: '200x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 105.6,
      price: 211.2,
      sizeX: 40,
      sizeY: 70,
      weight: 1.487657219,
      code: 'D040070',
      description: '40x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 107.47,
      price: 214.94,
      sizeX: 50,
      sizeY: 70,
      weight: 1.842330463,
      code: 'D050070',
      description: '50x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 202.16,
      price: 404.32,
      sizeX: 100,
      sizeY: 70,
      weight: 3.615696685,
      code: 'D100070',
      description: '100x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 149.3,
      price: 298.61,
      sizeX: 60,
      sizeY: 70,
      weight: 2.197003708,
      code: 'D060070',
      description: '60x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 171.16,
      price: 342.31,
      sizeX: 70,
      sizeY: 70,
      weight: 2.551676952,
      code: 'D070070',
      description: '70x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 193.01,
      price: 386.01,
      sizeX: 80,
      sizeY: 70,
      weight: 2.906350196,
      code: 'D080070',
      description: '80x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 214.86,
      price: 429.72,
      sizeX: 90,
      sizeY: 70,
      weight: 3.26102344,
      code: 'D090070',
      description: '90x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 258.56,
      price: 517.12,
      sizeX: 110,
      sizeY: 70,
      weight: 3.970369929,
      code: 'D110070',
      description: '110x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 280.41,
      price: 560.83,
      sizeX: 120,
      sizeY: 70,
      weight: 4.325043173,
      code: 'D120070',
      description: '120x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 302.27,
      price: 604.53,
      sizeX: 130,
      sizeY: 70,
      weight: 4.679716417,
      code: 'D130070',
      description: '130x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 324.12,
      price: 648.24,
      sizeX: 140,
      sizeY: 70,
      weight: 5.034389662,
      code: 'D140070',
      description: '140x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 296.85,
      price: 593.7,
      sizeX: 150,
      sizeY: 70,
      weight: 5.389062906,
      code: 'D150070',
      description: '150x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 367.82,
      price: 735.64,
      sizeX: 160,
      sizeY: 70,
      weight: 5.74373615,
      code: 'D160070',
      description: '160x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 389.67,
      price: 779.35,
      sizeX: 170,
      sizeY: 70,
      weight: 6.098409394,
      code: 'D170070',
      description: '170x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 411.52,
      price: 823.05,
      sizeX: 180,
      sizeY: 70,
      weight: 6.453082639,
      code: 'D180070',
      description: '180x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 433.38,
      price: 866.75,
      sizeX: 190,
      sizeY: 70,
      weight: 6.807755883,
      code: 'D190070',
      description: '190x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 477.08,
      price: 954.16,
      sizeX: 210,
      sizeY: 70,
      weight: 7.517102372,
      code: 'D210070',
      description: '210x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 498.93,
      price: 997.86,
      sizeX: 220,
      sizeY: 70,
      weight: 7.871775616,
      code: 'D220070',
      description: '220x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 520.78,
      price: 1041.57,
      sizeX: 230,
      sizeY: 70,
      weight: 8.22644886,
      code: 'D230070',
      description: '230x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 542.64,
      price: 1085.27,
      sizeX: 240,
      sizeY: 70,
      weight: 8.581122104,
      code: 'D240070',
      description: '240x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 486.23,
      price: 972.46,
      sizeX: 250,
      sizeY: 70,
      weight: 8.935795349,
      code: 'D250070',
      description: '250x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 586.34,
      price: 1172.68,
      sizeX: 260,
      sizeY: 70,
      weight: 9.290468593,
      code: 'D260070',
      description: '260x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 608.19,
      price: 1216.38,
      sizeX: 270,
      sizeY: 70,
      weight: 9.645141837,
      code: 'D270070',
      description: '270x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 630.04,
      price: 1260.09,
      sizeX: 280,
      sizeY: 70,
      weight: 9.999815081,
      code: 'D280070',
      description: '280x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 651.89,
      price: 1303.79,
      sizeX: 290,
      sizeY: 70,
      weight: 10.35448833,
      code: 'D290070',
      description: '290x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 580.92,
      price: 1161.85,
      sizeX: 300,
      sizeY: 70,
      weight: 10.70916157,
      code: 'D300070',
      description: '300x70',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 74.03,
      price: 148.06,
      sizeX: 30,
      sizeY: 75,
      weight: 0.768458696,
      code: 'B030075',
      description: '30x75',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 93.21,
      price: 186.43,
      sizeX: 40,
      sizeY: 75,
      weight: 0.999981508,
      code: 'B040075',
      description: '40x75',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 82.39,
      price: 164.78,
      sizeX: 50,
      sizeY: 75,
      weight: 1.23150432,
      code: 'B050075',
      description: '50x75',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 154.32,
      price: 308.64,
      sizeX: 100,
      sizeY: 75,
      weight: 2.389118382,
      code: 'B100075',
      description: '100x75',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 246.66,
      price: 493.32,
      sizeX: 120,
      sizeY: 75,
      weight: 2.852164006,
      code: 'B120075',
      description: '120x75',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 226.25,
      price: 452.5,
      sizeX: 150,
      sizeY: 75,
      weight: 3.546732443,
      code: 'B150075',
      description: '150x75',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 323.39,
      price: 646.77,
      sizeX: 160,
      sizeY: 75,
      weight: 3.778255255,
      code: 'B160075',
      description: '160x75',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 342.57,
      price: 685.13,
      sizeX: 170,
      sizeY: 75,
      weight: 4.009778067,
      code: 'B170075',
      description: '170x75',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 380.93,
      price: 761.86,
      sizeX: 190,
      sizeY: 75,
      weight: 4.472823692,
      code: 'B190075',
      description: '190x75',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 298.18,
      price: 596.36,
      sizeX: 200,
      sizeY: 75,
      weight: 4.704346504,
      code: 'B200075',
      description: '200x75',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 419.29,
      price: 838.58,
      sizeX: 210,
      sizeY: 75,
      weight: 4.935869316,
      code: 'B210075',
      description: '210x75',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 442.04,
      price: 884.08,
      sizeX: 300,
      sizeY: 75,
      weight: 7.019574626,
      code: 'B300075',
      description: '300x75',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 93.12,
      price: 186.23,
      sizeX: 80,
      sizeY: 80,
      weight: 1.418692977,
      code: 'A080080',
      description: '80x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 138.28,
      price: 276.56,
      sizeX: 90,
      sizeY: 80,
      weight: 1.586177565,
      code: 'A090080',
      description: '90x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 114.3,
      price: 228.6,
      sizeX: 100,
      sizeY: 80,
      weight: 1.753662152,
      code: 'A100080',
      description: '100x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 124.89,
      price: 249.78,
      sizeX: 110,
      sizeY: 80,
      weight: 1.92114674,
      code: 'A110080',
      description: '110x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 135.48,
      price: 270.97,
      sizeX: 120,
      sizeY: 80,
      weight: 2.088631327,
      code: 'A120080',
      description: '120x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 147.15,
      price: 294.29,
      sizeX: 130,
      sizeY: 80,
      weight: 2.256115915,
      code: 'A130080',
      description: '130x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 156.67,
      price: 313.33,
      sizeX: 140,
      sizeY: 80,
      weight: 2.423600502,
      code: 'A140080',
      description: '140x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 167.26,
      price: 334.52,
      sizeX: 150,
      sizeY: 80,
      weight: 2.59108509,
      code: 'A150080',
      description: '150x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 177.85,
      price: 355.7,
      sizeX: 160,
      sizeY: 80,
      weight: 2.758569678,
      code: 'A160080',
      description: '160x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 188.44,
      price: 376.88,
      sizeX: 170,
      sizeY: 80,
      weight: 2.926054265,
      code: 'A170080',
      description: '170x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 199.03,
      price: 398.07,
      sizeX: 180,
      sizeY: 80,
      weight: 3.093538853,
      code: 'A180080',
      description: '180x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 279.5,
      price: 559,
      sizeX: 190,
      sizeY: 80,
      weight: 3.26102344,
      code: 'A190080',
      description: '190x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 220.22,
      price: 440.43,
      sizeX: 200,
      sizeY: 80,
      weight: 3.428508028,
      code: 'A200080',
      description: '200x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 230.81,
      price: 461.61,
      sizeX: 210,
      sizeY: 80,
      weight: 3.595992615,
      code: 'A210080',
      description: '210x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 241.4,
      price: 482.8,
      sizeX: 220,
      sizeY: 80,
      weight: 3.763477203,
      code: 'A220080',
      description: '220x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 251.99,
      price: 503.98,
      sizeX: 230,
      sizeY: 80,
      weight: 3.930961791,
      code: 'A230080',
      description: '230x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 262.58,
      price: 525.16,
      sizeX: 240,
      sizeY: 80,
      weight: 4.098446378,
      code: 'A240080',
      description: '240x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 273.17,
      price: 546.35,
      sizeX: 250,
      sizeY: 80,
      weight: 4.265930966,
      code: 'A250080',
      description: '250x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 283.76,
      price: 567.53,
      sizeX: 260,
      sizeY: 80,
      weight: 4.433415553,
      code: 'A260080',
      description: '260x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 294.36,
      price: 588.71,
      sizeX: 270,
      sizeY: 80,
      weight: 4.600900141,
      code: 'A270080',
      description: '270x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 304.95,
      price: 609.9,
      sizeX: 280,
      sizeY: 80,
      weight: 4.768384728,
      code: 'A280080',
      description: '280x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 326.13,
      price: 652.26,
      sizeX: 300,
      sizeY: 80,
      weight: 5.103353904,
      code: 'A300080',
      description: '300x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 336.72,
      price: 673.45,
      sizeX: 310,
      sizeY: 80,
      weight: 5.270838491,
      code: 'A310080',
      description: '310x80',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 200.99,
      price: 401.98,
      sizeX: 90,
      sizeY: 80,
      weight: 2.295524053,
      code: 'B090080',
      description: '90x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 37.91,
      price: 75.82,
      sizeX: 10,
      sizeY: 80,
      weight: 0.325117141,
      code: 'B010080',
      description: '10x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 58.3,
      price: 116.59,
      sizeX: 20,
      sizeY: 80,
      weight: 0.571418005,
      code: 'B020080',
      description: '20x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 180.61,
      price: 361.21,
      sizeX: 80,
      sizeY: 80,
      weight: 2.049223189,
      code: 'B080080',
      description: '80x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 59.01,
      price: 118.02,
      sizeX: 30,
      sizeY: 80,
      weight: 0.817718869,
      code: 'B030080',
      description: '30x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 99.07,
      price: 198.13,
      sizeX: 40,
      sizeY: 80,
      weight: 1.064019733,
      code: 'B040080',
      description: '40x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 87.57,
      price: 175.13,
      sizeX: 50,
      sizeY: 80,
      weight: 1.310320597,
      code: 'B050080',
      description: '50x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 139.84,
      price: 279.67,
      sizeX: 60,
      sizeY: 80,
      weight: 1.556621461,
      code: 'B060080',
      description: '60x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 160.22,
      price: 320.44,
      sizeX: 70,
      sizeY: 80,
      weight: 1.802922325,
      code: 'B070080',
      description: '70x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 164.01,
      price: 328.02,
      sizeX: 100,
      sizeY: 80,
      weight: 2.541824917,
      code: 'B100080',
      description: '100x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 241.76,
      price: 483.52,
      sizeX: 110,
      sizeY: 80,
      weight: 2.788125781,
      code: 'B110080',
      description: '110x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 262.15,
      price: 524.29,
      sizeX: 120,
      sizeY: 80,
      weight: 3.034426645,
      code: 'B120080',
      description: '120x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 343.69,
      price: 687.37,
      sizeX: 160,
      sizeY: 80,
      weight: 4.019630102,
      code: 'B160080',
      description: '160x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 240.45,
      price: 480.91,
      sizeX: 150,
      sizeY: 80,
      weight: 3.773329238,
      code: 'B150080',
      description: '150x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 384.46,
      price: 768.92,
      sizeX: 180,
      sizeY: 80,
      weight: 4.51223183,
      code: 'B180080',
      description: '180x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 364.07,
      price: 728.15,
      sizeX: 170,
      sizeY: 80,
      weight: 4.265930966,
      code: 'B170080',
      description: '170x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 282.53,
      price: 565.06,
      sizeX: 130,
      sizeY: 80,
      weight: 3.280727509,
      code: 'B130080',
      description: '130x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 302.92,
      price: 605.83,
      sizeX: 140,
      sizeY: 80,
      weight: 3.527028374,
      code: 'B140080',
      description: '140x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 404.84,
      price: 809.69,
      sizeX: 190,
      sizeY: 80,
      weight: 4.758532694,
      code: 'B190080',
      description: '190x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 316.9,
      price: 633.8,
      sizeX: 200,
      sizeY: 80,
      weight: 5.004833558,
      code: 'B200080',
      description: '200x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 466,
      price: 932,
      sizeX: 220,
      sizeY: 80,
      weight: 5.497435286,
      code: 'B220080',
      description: '220x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 445.61,
      price: 891.23,
      sizeX: 210,
      sizeY: 80,
      weight: 5.251134422,
      code: 'B210080',
      description: '210x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 486.38,
      price: 972.77,
      sizeX: 230,
      sizeY: 80,
      weight: 5.74373615,
      code: 'B230080',
      description: '230x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 506.77,
      price: 1013.54,
      sizeX: 240,
      sizeY: 80,
      weight: 5.990037014,
      code: 'B240080',
      description: '240x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 567.92,
      price: 1135.85,
      sizeX: 270,
      sizeY: 80,
      weight: 6.728939606,
      code: 'B270080',
      description: '270x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 393.34,
      price: 786.69,
      sizeX: 250,
      sizeY: 80,
      weight: 6.236337878,
      code: 'B250080',
      description: '250x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 547.54,
      price: 1095.08,
      sizeX: 260,
      sizeY: 80,
      weight: 6.482638742,
      code: 'B260080',
      description: '260x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 588.31,
      price: 1176.62,
      sizeX: 280,
      sizeY: 80,
      weight: 6.975240471,
      code: 'B280080',
      description: '280x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 608.69,
      price: 1217.39,
      sizeX: 290,
      sizeY: 80,
      weight: 7.221541335,
      code: 'B290080',
      description: '290x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 469.79,
      price: 939.57,
      sizeX: 300,
      sizeY: 80,
      weight: 7.467842199,
      code: 'B300080',
      description: '300x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 649.46,
      price: 1298.93,
      sizeX: 310,
      sizeY: 80,
      weight: 7.714143063,
      code: 'B310080',
      description: '310x80',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 171.84,
      price: 343.69,
      sizeX: 80,
      sizeY: 80,
      weight: 2.679753401,
      code: 'C080080',
      description: '80x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 192.19,
      price: 384.37,
      sizeX: 90,
      sizeY: 80,
      weight: 3.004870542,
      code: 'C090080',
      description: '90x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 212.53,
      price: 425.06,
      sizeX: 100,
      sizeY: 80,
      weight: 3.329987682,
      code: 'C100080',
      description: '100x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 232.87,
      price: 465.75,
      sizeX: 110,
      sizeY: 80,
      weight: 3.655104823,
      code: 'C110080',
      description: '110x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 253.22,
      price: 506.43,
      sizeX: 120,
      sizeY: 80,
      weight: 3.980221963,
      code: 'C120080',
      description: '120x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 273.56,
      price: 547.12,
      sizeX: 130,
      sizeY: 80,
      weight: 4.305339104,
      code: 'C130080',
      description: '130x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 293.9,
      price: 587.8,
      sizeX: 140,
      sizeY: 80,
      weight: 4.630456245,
      code: 'C140080',
      description: '140x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 314.25,
      price: 628.49,
      sizeX: 150,
      sizeY: 80,
      weight: 4.955573385,
      code: 'C150080',
      description: '150x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 334.59,
      price: 669.18,
      sizeX: 160,
      sizeY: 80,
      weight: 5.280690526,
      code: 'C160080',
      description: '160x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 354.93,
      price: 709.86,
      sizeX: 170,
      sizeY: 80,
      weight: 5.605807666,
      code: 'C170080',
      description: '170x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 375.27,
      price: 750.55,
      sizeX: 180,
      sizeY: 80,
      weight: 5.930924807,
      code: 'C180080',
      description: '180x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 395.62,
      price: 791.24,
      sizeX: 190,
      sizeY: 80,
      weight: 6.256041947,
      code: 'C190080',
      description: '190x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 415.96,
      price: 831.92,
      sizeX: 200,
      sizeY: 80,
      weight: 6.581159088,
      code: 'C200080',
      description: '200x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 436.3,
      price: 872.61,
      sizeX: 210,
      sizeY: 80,
      weight: 6.906276229,
      code: 'C210080',
      description: '210x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 456.65,
      price: 913.29,
      sizeX: 220,
      sizeY: 80,
      weight: 7.231393369,
      code: 'C220080',
      description: '220x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 476.99,
      price: 953.98,
      sizeX: 230,
      sizeY: 80,
      weight: 7.55651051,
      code: 'C230080',
      description: '230x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 497.33,
      price: 994.67,
      sizeX: 240,
      sizeY: 80,
      weight: 7.88162765,
      code: 'C240080',
      description: '240x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 517.68,
      price: 1035.35,
      sizeX: 250,
      sizeY: 80,
      weight: 8.206744791,
      code: 'C250080',
      description: '250x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 538.02,
      price: 1076.04,
      sizeX: 260,
      sizeY: 80,
      weight: 8.531861932,
      code: 'C260080',
      description: '260x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 558.36,
      price: 1116.72,
      sizeX: 270,
      sizeY: 80,
      weight: 8.856979072,
      code: 'C270080',
      description: '270x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 578.71,
      price: 1157.41,
      sizeX: 280,
      sizeY: 80,
      weight: 9.182096213,
      code: 'C280080',
      description: '280x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 599.05,
      price: 1198.1,
      sizeX: 290,
      sizeY: 80,
      weight: 9.507213353,
      code: 'C290080',
      description: '290x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 619.39,
      price: 1238.78,
      sizeX: 300,
      sizeY: 80,
      weight: 9.832330494,
      code: 'C300080',
      description: '300x80',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 60.75,
      price: 121.49,
      sizeX: 10,
      sizeY: 80,
      weight: 0.482749694,
      code: 'D010080',
      description: '10x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 93.9,
      price: 187.79,
      sizeX: 20,
      sizeY: 80,
      weight: 0.886683111,
      code: 'D020080',
      description: '20x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 127.05,
      price: 254.09,
      sizeX: 30,
      sizeY: 80,
      weight: 1.290616528,
      code: 'D030080',
      description: '30x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 160.19,
      price: 320.39,
      sizeX: 40,
      sizeY: 80,
      weight: 1.694549945,
      code: 'D040080',
      description: '40x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 141.08,
      price: 282.16,
      sizeX: 50,
      sizeY: 80,
      weight: 2.098483362,
      code: 'D050080',
      description: '50x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 514.01,
      price: 1028.02,
      sizeX: 200,
      sizeY: 80,
      weight: 8.157484618,
      code: 'D200080',
      description: '200x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 226.49,
      price: 452.99,
      sizeX: 60,
      sizeY: 80,
      weight: 2.502416779,
      code: 'D060080',
      description: '60x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 265.39,
      price: 530.78,
      sizeX: 100,
      sizeY: 80,
      weight: 4.118150447,
      code: 'D100080',
      description: '100x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 259.64,
      price: 519.28,
      sizeX: 70,
      sizeY: 80,
      weight: 2.906350196,
      code: 'D070080',
      description: '70x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 292.79,
      price: 585.58,
      sizeX: 80,
      sizeY: 80,
      weight: 3.310283613,
      code: 'D080080',
      description: '80x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 325.94,
      price: 651.88,
      sizeX: 90,
      sizeY: 80,
      weight: 3.71421703,
      code: 'D090080',
      description: '90x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 392.24,
      price: 784.48,
      sizeX: 110,
      sizeY: 80,
      weight: 4.522083864,
      code: 'D110080',
      description: '110x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 425.39,
      price: 850.78,
      sizeX: 120,
      sizeY: 80,
      weight: 4.926017281,
      code: 'D120080',
      description: '120x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 458.54,
      price: 917.08,
      sizeX: 130,
      sizeY: 80,
      weight: 5.329950699,
      code: 'D130080',
      description: '130x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 491.69,
      price: 983.38,
      sizeX: 140,
      sizeY: 80,
      weight: 5.733884116,
      code: 'D140080',
      description: '140x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 389.7,
      price: 779.4,
      sizeX: 150,
      sizeY: 80,
      weight: 6.137817533,
      code: 'D150080',
      description: '150x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 557.99,
      price: 1115.97,
      sizeX: 160,
      sizeY: 80,
      weight: 6.54175095,
      code: 'D160080',
      description: '160x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 591.14,
      price: 1182.27,
      sizeX: 170,
      sizeY: 80,
      weight: 6.945684367,
      code: 'D170080',
      description: '170x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 624.28,
      price: 1248.57,
      sizeX: 180,
      sizeY: 80,
      weight: 7.349617784,
      code: 'D180080',
      description: '180x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 657.43,
      price: 1314.87,
      sizeX: 190,
      sizeY: 80,
      weight: 7.753551201,
      code: 'D190080',
      description: '190x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 723.73,
      price: 1447.47,
      sizeX: 210,
      sizeY: 80,
      weight: 8.561418035,
      code: 'D210080',
      description: '210x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 756.88,
      price: 1513.76,
      sizeX: 220,
      sizeY: 80,
      weight: 8.965351452,
      code: 'D220080',
      description: '220x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 790.03,
      price: 1580.06,
      sizeX: 230,
      sizeY: 80,
      weight: 9.369284869,
      code: 'D230080',
      description: '230x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 823.18,
      price: 1646.36,
      sizeX: 240,
      sizeY: 80,
      weight: 9.773218286,
      code: 'D240080',
      description: '240x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 638.32,
      price: 1276.64,
      sizeX: 250,
      sizeY: 80,
      weight: 10.1771517,
      code: 'D250080',
      description: '250x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 889.48,
      price: 1778.96,
      sizeX: 260,
      sizeY: 80,
      weight: 10.58108512,
      code: 'D260080',
      description: '260x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 922.63,
      price: 1845.26,
      sizeX: 270,
      sizeY: 80,
      weight: 10.98501854,
      code: 'D270080',
      description: '270x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 955.78,
      price: 1911.56,
      sizeX: 280,
      sizeY: 80,
      weight: 11.38895195,
      code: 'D280080',
      description: '280x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 988.93,
      price: 1977.85,
      sizeX: 290,
      sizeY: 80,
      weight: 11.79288537,
      code: 'D290080',
      description: '290x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 762.63,
      price: 1525.26,
      sizeX: 300,
      sizeY: 80,
      weight: 12.19681879,
      code: 'D300080',
      description: '300x80',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 231.6,
      price: 463.2,
      sizeX: 100,
      sizeY: 85,
      weight: 2.694531453,
      code: 'B100085',
      description: '100x85',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 277.63,
      price: 555.26,
      sizeX: 120,
      sizeY: 85,
      weight: 3.216689285,
      code: 'B120085',
      description: '120x85',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 254.66,
      price: 509.32,
      sizeX: 150,
      sizeY: 85,
      weight: 3.999926033,
      code: 'B150085',
      description: '150x85',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 493.52,
      price: 987.05,
      sizeX: 220,
      sizeY: 85,
      weight: 5.827478444,
      code: 'B220085',
      description: '220x85',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 601.47,
      price: 1202.94,
      sizeX: 270,
      sizeY: 85,
      weight: 7.132873024,
      code: 'B270085',
      description: '270x85',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 154.01,
      price: 308.01,
      sizeX: 90,
      sizeY: 90,
      weight: 1.773366221,
      code: 'A090090',
      description: '90x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 127.3,
      price: 254.6,
      sizeX: 100,
      sizeY: 90,
      weight: 1.960554878,
      code: 'A100090',
      description: '100x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 185.46,
      price: 370.92,
      sizeX: 110,
      sizeY: 90,
      weight: 2.147743535,
      code: 'A110090',
      description: '110x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 201.19,
      price: 402.38,
      sizeX: 120,
      sizeY: 90,
      weight: 2.334932191,
      code: 'A120090',
      description: '120x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 216.92,
      price: 433.83,
      sizeX: 130,
      sizeY: 90,
      weight: 2.522120848,
      code: 'A130090',
      description: '130x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 234.23,
      price: 468.46,
      sizeX: 140,
      sizeY: 90,
      weight: 2.709309505,
      code: 'A140090',
      description: '140x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 187.47,
      price: 374.94,
      sizeX: 150,
      sizeY: 90,
      weight: 2.896498162,
      code: 'A150090',
      description: '150x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 265.68,
      price: 531.37,
      sizeX: 160,
      sizeY: 90,
      weight: 3.083686818,
      code: 'A160090',
      description: '160x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 279.83,
      price: 559.65,
      sizeX: 170,
      sizeY: 90,
      weight: 3.270875475,
      code: 'A170090',
      description: '170x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 297.14,
      price: 594.28,
      sizeX: 180,
      sizeY: 90,
      weight: 3.458064132,
      code: 'A180090',
      description: '180x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 312.87,
      price: 625.73,
      sizeX: 190,
      sizeY: 90,
      weight: 3.645252788,
      code: 'A190090',
      description: '190x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 246.45,
      price: 492.89,
      sizeX: 200,
      sizeY: 90,
      weight: 3.832441445,
      code: 'A200090',
      description: '200x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 344.32,
      price: 688.64,
      sizeX: 210,
      sizeY: 90,
      weight: 4.019630102,
      code: 'A210090',
      description: '210x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 360.05,
      price: 720.1,
      sizeX: 220,
      sizeY: 90,
      weight: 4.206818758,
      code: 'A220090',
      description: '220x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 375.78,
      price: 751.55,
      sizeX: 230,
      sizeY: 90,
      weight: 4.394007415,
      code: 'A230090',
      description: '230x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 391.5,
      price: 783.01,
      sizeX: 240,
      sizeY: 90,
      weight: 4.581196072,
      code: 'A240090',
      description: '240x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 305.42,
      price: 610.85,
      sizeX: 250,
      sizeY: 90,
      weight: 4.768384728,
      code: 'A250090',
      description: '250x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 422.96,
      price: 845.92,
      sizeX: 260,
      sizeY: 90,
      weight: 4.955573385,
      code: 'A260090',
      description: '260x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 438.69,
      price: 877.37,
      sizeX: 270,
      sizeY: 90,
      weight: 5.142762042,
      code: 'A270090',
      description: '270x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 454.41,
      price: 908.83,
      sizeX: 280,
      sizeY: 90,
      weight: 5.329950699,
      code: 'A280090',
      description: '280x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 470.14,
      price: 940.28,
      sizeX: 290,
      sizeY: 90,
      weight: 5.517139355,
      code: 'A290090',
      description: '290x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 364.4,
      price: 728.8,
      sizeX: 300,
      sizeY: 90,
      weight: 5.704328012,
      code: 'A300090',
      description: '300x90',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 224.74,
      price: 449.47,
      sizeX: 90,
      sizeY: 90,
      weight: 2.571381021,
      code: 'B090090',
      description: '90x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 42.39,
      price: 84.78,
      sizeX: 10,
      sizeY: 90,
      weight: 0.364525279,
      code: 'B010090',
      description: '10x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 65.18,
      price: 130.37,
      sizeX: 20,
      sizeY: 90,
      weight: 0.640382247,
      code: 'B020090',
      description: '20x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 65.98,
      price: 131.96,
      sizeX: 30,
      sizeY: 90,
      weight: 0.916239214,
      code: 'B030090',
      description: '30x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 110.77,
      price: 221.54,
      sizeX: 40,
      sizeY: 90,
      weight: 1.192096182,
      code: 'B040090',
      description: '40x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 97.91,
      price: 195.82,
      sizeX: 50,
      sizeY: 90,
      weight: 1.46795315,
      code: 'B050090',
      description: '50x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 156.36,
      price: 312.71,
      sizeX: 60,
      sizeY: 90,
      weight: 1.743810118,
      code: 'B060090',
      description: '60x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 179.15,
      price: 358.3,
      sizeX: 70,
      sizeY: 90,
      weight: 2.019667085,
      code: 'B070090',
      description: '70x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 201.94,
      price: 403.89,
      sizeX: 80,
      sizeY: 90,
      weight: 2.295524053,
      code: 'B080090',
      description: '80x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 270.32,
      price: 540.65,
      sizeX: 110,
      sizeY: 90,
      weight: 3.123094956,
      code: 'B110090',
      description: '110x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 384.29,
      price: 768.58,
      sizeX: 160,
      sizeY: 90,
      weight: 4.502379795,
      code: 'B160090',
      description: '160x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 407.08,
      price: 814.17,
      sizeX: 170,
      sizeY: 90,
      weight: 4.778236763,
      code: 'B170090',
      description: '170x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 293.12,
      price: 586.23,
      sizeX: 120,
      sizeY: 90,
      weight: 3.398951924,
      code: 'B120090',
      description: '120x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 429.88,
      price: 859.75,
      sizeX: 180,
      sizeY: 90,
      weight: 5.054093731,
      code: 'B180090',
      description: '180x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 268.86,
      price: 537.72,
      sizeX: 150,
      sizeY: 90,
      weight: 4.226522828,
      code: 'B150090',
      description: '150x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 183.39,
      price: 366.77,
      sizeX: 100,
      sizeY: 90,
      weight: 2.847237989,
      code: 'B100090',
      description: '100x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 315.91,
      price: 631.82,
      sizeX: 130,
      sizeY: 90,
      weight: 3.674808892,
      code: 'B130090',
      description: '130x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 354.34,
      price: 708.67,
      sizeX: 200,
      sizeY: 90,
      weight: 5.605807666,
      code: 'B200090',
      description: '200x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 338.7,
      price: 677.41,
      sizeX: 140,
      sizeY: 90,
      weight: 3.95066586,
      code: 'B140090',
      description: '140x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 452.67,
      price: 905.34,
      sizeX: 190,
      sizeY: 90,
      weight: 5.329950699,
      code: 'B190090',
      description: '190x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 543.84,
      price: 1087.69,
      sizeX: 230,
      sizeY: 90,
      weight: 6.43337857,
      code: 'B230090',
      description: '230x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 498.26,
      price: 996.51,
      sizeX: 210,
      sizeY: 90,
      weight: 5.881664634,
      code: 'B210090',
      description: '210x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 521.05,
      price: 1042.1,
      sizeX: 220,
      sizeY: 90,
      weight: 6.157521602,
      code: 'B220090',
      description: '220x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 566.64,
      price: 1133.27,
      sizeX: 240,
      sizeY: 90,
      weight: 6.709235537,
      code: 'B240090',
      description: '240x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 439.81,
      price: 879.62,
      sizeX: 250,
      sizeY: 90,
      weight: 6.985092505,
      code: 'B250090',
      description: '250x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 612.22,
      price: 1224.44,
      sizeX: 260,
      sizeY: 90,
      weight: 7.260949473,
      code: 'B260090',
      description: '260x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 635.02,
      price: 1270.03,
      sizeX: 270,
      sizeY: 90,
      weight: 7.536806441,
      code: 'B270090',
      description: '270x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 657.81,
      price: 1315.62,
      sizeX: 280,
      sizeY: 90,
      weight: 7.812663408,
      code: 'B280090',
      description: '280x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 680.6,
      price: 1361.2,
      sizeX: 290,
      sizeY: 90,
      weight: 8.088520376,
      code: 'B290090',
      description: '290x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 525.29,
      price: 1050.57,
      sizeX: 300,
      sizeY: 90,
      weight: 8.364377344,
      code: 'B300090',
      description: '300x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 726.19,
      price: 1452.38,
      sizeX: 310,
      sizeY: 90,
      weight: 8.640234312,
      code: 'B310090',
      description: '310x90',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 286.58,
      price: 573.17,
      sizeX: 90,
      sizeY: 90,
      weight: 3.369395821,
      code: 'C090090',
      description: '90x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 237.69,
      price: 475.38,
      sizeX: 100,
      sizeY: 90,
      weight: 3.733921099,
      code: 'C100090',
      description: '100x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 347.25,
      price: 694.51,
      sizeX: 110,
      sizeY: 90,
      weight: 4.098446378,
      code: 'C110090',
      description: '110x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 377.59,
      price: 755.18,
      sizeX: 120,
      sizeY: 90,
      weight: 4.462971657,
      code: 'C120090',
      description: '120x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 407.92,
      price: 815.85,
      sizeX: 130,
      sizeY: 90,
      weight: 4.827496936,
      code: 'C130090',
      description: '130x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 438.26,
      price: 876.52,
      sizeX: 140,
      sizeY: 90,
      weight: 5.192022215,
      code: 'C140090',
      description: '140x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 351.45,
      price: 702.89,
      sizeX: 150,
      sizeY: 90,
      weight: 5.556547494,
      code: 'C150090',
      description: '150x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 498.93,
      price: 997.86,
      sizeX: 160,
      sizeY: 90,
      weight: 5.921072772,
      code: 'C160090',
      description: '160x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 529.26,
      price: 1058.53,
      sizeX: 170,
      sizeY: 90,
      weight: 6.285598051,
      code: 'C170090',
      description: '170x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 559.6,
      price: 1119.2,
      sizeX: 180,
      sizeY: 90,
      weight: 6.65012333,
      code: 'C180090',
      description: '180x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 589.93,
      price: 1179.87,
      sizeX: 190,
      sizeY: 90,
      weight: 7.014648609,
      code: 'C190090',
      description: '190x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 465.2,
      price: 930.4,
      sizeX: 200,
      sizeY: 90,
      weight: 7.379173888,
      code: 'C200090',
      description: '200x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 650.6,
      price: 1301.21,
      sizeX: 210,
      sizeY: 90,
      weight: 7.743699166,
      code: 'C210090',
      description: '210x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 680.94,
      price: 1361.88,
      sizeX: 220,
      sizeY: 90,
      weight: 8.108224445,
      code: 'C220090',
      description: '220x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 711.27,
      price: 1422.55,
      sizeX: 230,
      sizeY: 90,
      weight: 8.472749724,
      code: 'C230090',
      description: '230x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 741.61,
      price: 1483.22,
      sizeX: 240,
      sizeY: 90,
      weight: 8.837275003,
      code: 'C240090',
      description: '240x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 578.96,
      price: 1157.92,
      sizeX: 250,
      sizeY: 90,
      weight: 9.201800282,
      code: 'C250090',
      description: '250x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 802.28,
      price: 1604.56,
      sizeX: 260,
      sizeY: 90,
      weight: 9.566325561,
      code: 'C260090',
      description: '260x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 832.61,
      price: 1665.23,
      sizeX: 270,
      sizeY: 90,
      weight: 9.930850839,
      code: 'C270090',
      description: '270x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 862.95,
      price: 1725.9,
      sizeX: 280,
      sizeY: 90,
      weight: 10.29537612,
      code: 'C280090',
      description: '280x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 893.28,
      price: 1786.57,
      sizeX: 290,
      sizeY: 90,
      weight: 10.6599014,
      code: 'C290090',
      description: '290x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 692.71,
      price: 1385.43,
      sizeX: 300,
      sizeY: 90,
      weight: 11.02442668,
      code: 'C300090',
      description: '300x90',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 68.1,
      price: 136.2,
      sizeX: 10,
      sizeY: 90,
      weight: 0.541861901,
      code: 'D010090',
      description: '10x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 105.26,
      price: 210.53,
      sizeX: 20,
      sizeY: 90,
      weight: 0.995055491,
      code: 'D020090',
      description: '20x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 142.43,
      price: 284.85,
      sizeX: 30,
      sizeY: 90,
      weight: 1.448249081,
      code: 'D030090',
      description: '30x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 179.59,
      price: 359.18,
      sizeX: 40,
      sizeY: 90,
      weight: 1.901442671,
      code: 'D040090',
      description: '40x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 158.16,
      price: 316.33,
      sizeX: 50,
      sizeY: 90,
      weight: 2.354636261,
      code: 'D050090',
      description: '50x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 253.92,
      price: 507.83,
      sizeX: 60,
      sizeY: 90,
      weight: 2.80782985,
      code: 'D060090',
      description: '60x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 576.25,
      price: 1152.49,
      sizeX: 200,
      sizeY: 90,
      weight: 9.152540109,
      code: 'D200090',
      description: '200x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 291.08,
      price: 582.16,
      sizeX: 70,
      sizeY: 90,
      weight: 3.26102344,
      code: 'D070090',
      description: '70x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 297.52,
      price: 595.05,
      sizeX: 100,
      sizeY: 90,
      weight: 4.62060421,
      code: 'D100090',
      description: '100x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 328.24,
      price: 656.48,
      sizeX: 80,
      sizeY: 90,
      weight: 3.71421703,
      code: 'D080090',
      description: '80x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 365.4,
      price: 730.81,
      sizeX: 90,
      sizeY: 90,
      weight: 4.16741062,
      code: 'D090090',
      description: '90x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 439.73,
      price: 879.46,
      sizeX: 110,
      sizeY: 90,
      weight: 5.0737978,
      code: 'D110090',
      description: '110x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 476.89,
      price: 953.78,
      sizeX: 120,
      sizeY: 90,
      weight: 5.52699139,
      code: 'D120090',
      description: '120x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 514.06,
      price: 1028.11,
      sizeX: 130,
      sizeY: 90,
      weight: 5.98018498,
      code: 'D130100',
      description: '130x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 551.22,
      price: 1102.44,
      sizeX: 140,
      sizeY: 90,
      weight: 6.43337857,
      code: 'D140090',
      description: '140x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 436.88,
      price: 873.77,
      sizeX: 150,
      sizeY: 90,
      weight: 6.886572159,
      code: 'D150090',
      description: '150x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 625.54,
      price: 1251.09,
      sizeX: 160,
      sizeY: 90,
      weight: 7.339765749,
      code: 'D160090',
      description: '160x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 662.71,
      price: 1325.41,
      sizeX: 170,
      sizeY: 90,
      weight: 7.792959339,
      code: 'D170090',
      description: '170x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 699.87,
      price: 1399.74,
      sizeX: 180,
      sizeY: 90,
      weight: 8.246152929,
      code: 'D180090',
      description: '180x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 737.03,
      price: 1474.07,
      sizeX: 190,
      sizeY: 90,
      weight: 8.699346519,
      code: 'D190090',
      description: '190x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 811.36,
      price: 1622.72,
      sizeX: 210,
      sizeY: 90,
      weight: 9.605733699,
      code: 'D210090',
      description: '210x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 848.52,
      price: 1697.04,
      sizeX: 220,
      sizeY: 90,
      weight: 10.05892729,
      code: 'D220090',
      description: '220x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 885.68,
      price: 1771.37,
      sizeX: 230,
      sizeY: 90,
      weight: 10.51212088,
      code: 'D230090',
      description: '230x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 922.85,
      price: 1845.69,
      sizeX: 240,
      sizeY: 90,
      weight: 10.96531447,
      code: 'D240090',
      description: '240x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 715.61,
      price: 1431.21,
      sizeX: 250,
      sizeY: 90,
      weight: 11.41850806,
      code: 'D250090',
      description: '250x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 997.17,
      price: 1994.35,
      sizeX: 260,
      sizeY: 90,
      weight: 11.87170165,
      code: 'D260090',
      description: '260x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1034.34,
      price: 2068.67,
      sizeX: 270,
      sizeY: 90,
      weight: 12.32489524,
      code: 'D270090',
      description: '270x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1071.5,
      price: 2143,
      sizeX: 280,
      sizeY: 90,
      weight: 12.77808883,
      code: 'D280090',
      description: '280x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1108.66,
      price: 2217.32,
      sizeX: 290,
      sizeY: 90,
      weight: 13.23128242,
      code: 'D290090',
      description: '290x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 854.97,
      price: 1709.93,
      sizeX: 300,
      sizeY: 90,
      weight: 13.68447601,
      code: 'D300090',
      description: '300x90',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 68.63,
      price: 137.25,
      sizeX: 20,
      sizeY: 95,
      weight: 0.674864368,
      code: 'B020095',
      description: '20x95',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 116.62,
      price: 233.24,
      sizeX: 40,
      sizeY: 95,
      weight: 1.256134407,
      code: 'B040095',
      description: '40x95',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 164.62,
      price: 329.23,
      sizeX: 60,
      sizeY: 95,
      weight: 1.837404446,
      code: 'B060095',
      description: '60x95',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 188.61,
      price: 377.23,
      sizeX: 70,
      sizeY: 95,
      weight: 2.128039466,
      code: 'B070095',
      description: '70x95',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 283.07,
      price: 566.13,
      sizeX: 150,
      sizeY: 95,
      weight: 4.453119622,
      code: 'B150095',
      description: '150x95',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 308.6,
      price: 617.2,
      sizeX: 120,
      sizeY: 95,
      weight: 3.581214564,
      code: 'B120095',
      description: '120x95',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 373.06,
      price: 746.11,
      sizeX: 200,
      sizeY: 95,
      weight: 5.90629472,
      code: 'B200095',
      description: '200x95',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 193.08,
      price: 386.15,
      sizeX: 100,
      sizeY: 95,
      weight: 2.999944524,
      code: 'B100095',
      description: '100x95',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 572.57,
      price: 1145.14,
      sizeX: 230,
      sizeY: 95,
      weight: 6.778199779,
      code: 'B230095',
      description: '230x95',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 553.04,
      price: 1106.07,
      sizeX: 300,
      sizeY: 95,
      weight: 8.812644917,
      code: 'B300095',
      description: '300x95',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 607.36,
      price: 1214.72,
      sizeX: 200,
      sizeY: 95,
      weight: 9.650067854,
      code: 'D200095',
      description: '200x95',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 313.59,
      price: 627.18,
      sizeX: 100,
      sizeY: 95,
      weight: 4.871831091,
      code: 'D100095',
      description: '100x95',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 122.73,
      price: 245.45,
      sizeX: 100,
      sizeY: 100,
      weight: 2.167447604,
      code: 'A100100',
      description: '100x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 154.61,
      price: 309.22,
      sizeX: 110,
      sizeY: 100,
      weight: 2.37434033,
      code: 'A110100',
      description: '110x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 167.61,
      price: 335.22,
      sizeX: 120,
      sizeY: 100,
      weight: 2.581233055,
      code: 'A120100',
      description: '120x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 180.61,
      price: 361.21,
      sizeX: 130,
      sizeY: 100,
      weight: 2.788125781,
      code: 'A130100',
      description: '130x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 193.61,
      price: 387.21,
      sizeX: 140,
      sizeY: 100,
      weight: 2.995018507,
      code: 'A140100',
      description: '140x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 179.06,
      price: 358.12,
      sizeX: 150,
      sizeY: 100,
      weight: 3.201911233,
      code: 'A150100',
      description: '150x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 219.61,
      price: 439.21,
      sizeX: 160,
      sizeY: 100,
      weight: 3.408803959,
      code: 'A160100',
      description: '160x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 229.99,
      price: 459.98,
      sizeX: 170,
      sizeY: 100,
      weight: 3.615696685,
      code: 'A170100',
      description: '170x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 244.3,
      price: 488.59,
      sizeX: 180,
      sizeY: 100,
      weight: 3.82258941,
      code: 'A180100',
      description: '180x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 255.99,
      price: 511.98,
      sizeX: 190,
      sizeY: 100,
      weight: 4.029482136,
      code: 'A190100',
      description: '190x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 233.12,
      price: 466.25,
      sizeX: 200,
      sizeY: 100,
      weight: 4.236374862,
      code: 'A200100',
      description: '200x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 281.99,
      price: 563.98,
      sizeX: 210,
      sizeY: 100,
      weight: 4.443267588,
      code: 'A210100',
      description: '210x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 393.32,
      price: 786.63,
      sizeX: 220,
      sizeY: 100,
      weight: 4.650160314,
      code: 'A220100',
      description: '220x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 410.65,
      price: 821.3,
      sizeX: 230,
      sizeY: 100,
      weight: 4.85705304,
      code: 'A230100',
      description: '230x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 427.98,
      price: 855.96,
      sizeX: 240,
      sizeY: 100,
      weight: 5.063945765,
      code: 'A240100',
      description: '240x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 289.45,
      price: 578.91,
      sizeX: 250,
      sizeY: 100,
      weight: 5.270838491,
      code: 'A250100',
      description: '250x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 462.65,
      price: 925.3,
      sizeX: 260,
      sizeY: 100,
      weight: 5.477731217,
      code: 'A260100',
      description: '260x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 359.99,
      price: 719.97,
      sizeX: 270,
      sizeY: 100,
      weight: 5.684623943,
      code: 'A270100',
      description: '270x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 497.31,
      price: 994.63,
      sizeX: 280,
      sizeY: 100,
      weight: 5.891516669,
      code: 'A280100',
      description: '280x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 514.65,
      price: 1029.29,
      sizeX: 290,
      sizeY: 100,
      weight: 6.098409394,
      code: 'A290100',
      description: '290x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 265.99,
      price: 531.98,
      sizeX: 300,
      sizeY: 100,
      weight: 6.30530212,
      code: 'A300100',
      description: '300x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 274.66,
      price: 549.31,
      sizeX: 310,
      sizeY: 100,
      weight: 6.512194846,
      code: 'A310100',
      description: '310x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 283.32,
      price: 566.65,
      sizeX: 320,
      sizeY: 100,
      weight: 6.719087572,
      code: 'A320100',
      description: '320x100',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 51.56,
      price: 103.11,
      sizeX: 20,
      sizeY: 100,
      weight: 0.709346489,
      code: 'B020100',
      description: '20x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 61.06,
      price: 122.12,
      sizeX: 30,
      sizeY: 100,
      weight: 1.01475956,
      code: 'B030100',
      description: '30x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 89.36,
      price: 178.71,
      sizeX: 40,
      sizeY: 100,
      weight: 1.320172631,
      code: 'B040100',
      description: '40x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 93.82,
      price: 187.65,
      sizeX: 50,
      sizeY: 100,
      weight: 1.625585703,
      code: 'B050100',
      description: '50x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 127.16,
      price: 254.32,
      sizeX: 60,
      sizeY: 100,
      weight: 1.930998774,
      code: 'B060100',
      description: '60x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 146.06,
      price: 292.12,
      sizeX: 70,
      sizeY: 100,
      weight: 2.236411846,
      code: 'B070100',
      description: '70x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 164.96,
      price: 329.92,
      sizeX: 80,
      sizeY: 100,
      weight: 2.541824917,
      code: 'B080100',
      description: '80x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 183.86,
      price: 367.73,
      sizeX: 90,
      sizeY: 100,
      weight: 2.847237989,
      code: 'B090100',
      description: '90x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 221.66,
      price: 443.33,
      sizeX: 110,
      sizeY: 100,
      weight: 3.458064132,
      code: 'B110100',
      description: '110x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 316.17,
      price: 632.34,
      sizeX: 160,
      sizeY: 100,
      weight: 4.985129489,
      code: 'B160100',
      description: '160x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 353.97,
      price: 707.95,
      sizeX: 180,
      sizeY: 100,
      weight: 5.595955632,
      code: 'B180100',
      description: '180x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 257.63,
      price: 515.27,
      sizeX: 150,
      sizeY: 100,
      weight: 4.679716417,
      code: 'B150100',
      description: '150x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 335.07,
      price: 670.14,
      sizeX: 170,
      sizeY: 100,
      weight: 5.29054256,
      code: 'B170100',
      description: '170x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 259.47,
      price: 518.93,
      sizeX: 130,
      sizeY: 100,
      weight: 4.068890274,
      code: 'B130100',
      description: '130x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 372.87,
      price: 745.75,
      sizeX: 190,
      sizeY: 100,
      weight: 5.901368703,
      code: 'B190100',
      description: '190x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 339.54,
      price: 679.08,
      sizeX: 200,
      sizeY: 100,
      weight: 6.206781775,
      code: 'B200100',
      description: '200x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 240.57,
      price: 481.13,
      sizeX: 120,
      sizeY: 100,
      weight: 3.763477203,
      code: 'B120100',
      description: '120x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 278.37,
      price: 556.74,
      sizeX: 140,
      sizeY: 100,
      weight: 4.374303346,
      code: 'B140100',
      description: '140x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 410.68,
      price: 821.35,
      sizeX: 210,
      sizeY: 100,
      weight: 6.512194846,
      code: 'B210100',
      description: '210x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 429.58,
      price: 859.15,
      sizeX: 220,
      sizeY: 100,
      weight: 6.817607918,
      code: 'B220100',
      description: '220x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 448.48,
      price: 896.96,
      sizeX: 230,
      sizeY: 100,
      weight: 7.123020989,
      code: 'B230100',
      description: '230x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 467.38,
      price: 934.76,
      sizeX: 240,
      sizeY: 100,
      weight: 7.42843406,
      code: 'B240100',
      description: '240x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 135.18,
      price: 270.35,
      sizeX: 100,
      sizeY: 100,
      weight: 3.15265106,
      code: 'B100100',
      description: '100x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 421.44,
      price: 842.89,
      sizeX: 250,
      sizeY: 100,
      weight: 7.733847132,
      code: 'B250100',
      description: '250x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 399.79,
      price: 799.58,
      sizeX: 310,
      sizeY: 100,
      weight: 9.566325561,
      code: 'B310100',
      description: '310x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 505.18,
      price: 1010.36,
      sizeX: 260,
      sizeY: 100,
      weight: 8.039260203,
      code: 'B260100',
      description: '260x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 412.39,
      price: 824.78,
      sizeX: 320,
      sizeY: 100,
      weight: 9.871738632,
      code: 'B320100',
      description: '320x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 32.65,
      price: 65.31,
      sizeX: 10,
      sizeY: 100,
      weight: 0.403933417,
      code: 'B010100',
      description: '10x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 524.08,
      price: 1048.16,
      sizeX: 270,
      sizeY: 100,
      weight: 8.344673275,
      code: 'B270100',
      description: '270x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 542.98,
      price: 1085.97,
      sizeX: 280,
      sizeY: 100,
      weight: 8.650086346,
      code: 'B280100',
      description: '280x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 561.88,
      price: 1123.77,
      sizeX: 290,
      sizeY: 100,
      weight: 8.955499418,
      code: 'B290100',
      description: '290x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 503.35,
      price: 1006.7,
      sizeX: 300,
      sizeY: 100,
      weight: 9.260912489,
      code: 'B300100',
      description: '300x100',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 227.8,
      price: 455.6,
      sizeX: 100,
      sizeY: 100,
      weight: 4.137854516,
      code: 'C100100',
      description: '100x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 288.01,
      price: 576.02,
      sizeX: 110,
      sizeY: 100,
      weight: 4.541787934,
      code: 'C110100',
      description: '110x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 313.17,
      price: 626.33,
      sizeX: 120,
      sizeY: 100,
      weight: 4.945721351,
      code: 'C120100',
      description: '120x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 338.33,
      price: 676.65,
      sizeX: 130,
      sizeY: 100,
      weight: 5.349654768,
      code: 'C130100',
      description: '130x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 363.49,
      price: 726.97,
      sizeX: 140,
      sizeY: 100,
      weight: 5.753588185,
      code: 'C140100',
      description: '140x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 336.83,
      price: 673.65,
      sizeX: 150,
      sizeY: 100,
      weight: 6.157521602,
      code: 'C150100',
      description: '150x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 413.8,
      price: 827.61,
      sizeX: 160,
      sizeY: 100,
      weight: 6.561455019,
      code: 'C160100',
      description: '160x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 438.96,
      price: 877.93,
      sizeX: 170,
      sizeY: 100,
      weight: 6.965388436,
      code: 'C170100',
      description: '170x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 464.12,
      price: 928.25,
      sizeX: 180,
      sizeY: 100,
      weight: 7.369321853,
      code: 'C180100',
      description: '180x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 489.28,
      price: 978.57,
      sizeX: 190,
      sizeY: 100,
      weight: 7.77325527,
      code: 'C190100',
      description: '190x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 445.85,
      price: 891.7,
      sizeX: 200,
      sizeY: 100,
      weight: 8.177188687,
      code: 'C200100',
      description: '200x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 539.6,
      price: 1079.2,
      sizeX: 210,
      sizeY: 100,
      weight: 8.581122104,
      code: 'C210100',
      description: '210x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 564.76,
      price: 1129.52,
      sizeX: 220,
      sizeY: 100,
      weight: 8.985055521,
      code: 'C220100',
      description: '220x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 589.92,
      price: 1179.84,
      sizeX: 230,
      sizeY: 100,
      weight: 9.388988938,
      code: 'C230100',
      description: '230x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 615.08,
      price: 1230.16,
      sizeX: 240,
      sizeY: 100,
      weight: 9.792922356,
      code: 'C240100',
      description: '240x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 554.87,
      price: 1109.75,
      sizeX: 250,
      sizeY: 100,
      weight: 10.19685577,
      code: 'C250100',
      description: '250x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 665.4,
      price: 1330.8,
      sizeX: 260,
      sizeY: 100,
      weight: 10.60078919,
      code: 'C260100',
      description: '260x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 690.56,
      price: 1381.12,
      sizeX: 270,
      sizeY: 100,
      weight: 11.00472261,
      code: 'C270100',
      description: '270x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 715.72,
      price: 1431.44,
      sizeX: 280,
      sizeY: 100,
      weight: 11.40865602,
      code: 'C280100',
      description: '280x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 740.88,
      price: 1481.75,
      sizeX: 290,
      sizeY: 100,
      weight: 11.81258944,
      code: 'C290100',
      description: '290x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 663.9,
      price: 1327.8,
      sizeX: 300,
      sizeY: 100,
      weight: 12.21652286,
      code: 'C300100',
      description: '300x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 527.46,
      price: 1054.93,
      sizeX: 310,
      sizeY: 100,
      weight: 12.62045628,
      code: 'C310100',
      description: '310x100',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 51.71,
      price: 103.43,
      sizeX: 10,
      sizeY: 100,
      weight: 0.600974108,
      code: 'D010100',
      description: '10x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 82.6,
      price: 165.19,
      sizeX: 20,
      sizeY: 100,
      weight: 1.103427871,
      code: 'D020100',
      description: '20x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 98.55,
      price: 197.11,
      sizeX: 30,
      sizeY: 100,
      weight: 1.605881634,
      code: 'D030100',
      description: '30x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 125.11,
      price: 250.23,
      sizeX: 40,
      sizeY: 100,
      weight: 2.108335396,
      code: 'D040100',
      description: '40x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 152.19,
      price: 304.37,
      sizeX: 50,
      sizeY: 100,
      weight: 2.610789159,
      code: 'D050100',
      description: '50x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 178.64,
      price: 357.28,
      sizeX: 60,
      sizeY: 100,
      weight: 3.113242922,
      code: 'D060100',
      description: '60x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 205.41,
      price: 410.81,
      sizeX: 70,
      sizeY: 100,
      weight: 3.615696685,
      code: 'D070100',
      description: '70x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 267.89,
      price: 535.78,
      sizeX: 80,
      sizeY: 100,
      weight: 4.118150447,
      code: 'D080100',
      description: '80x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 286.27,
      price: 572.54,
      sizeX: 100,
      sizeY: 100,
      weight: 5.123057973,
      code: 'D100100',
      description: '100x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 554.43,
      price: 1108.86,
      sizeX: 200,
      sizeY: 100,
      weight: 10.1475956,
      code: 'D200100',
      description: '200x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 298.77,
      price: 597.55,
      sizeX: 90,
      sizeY: 100,
      weight: 4.62060421,
      code: 'D090100',
      description: '90x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 360.54,
      price: 721.07,
      sizeX: 110,
      sizeY: 100,
      weight: 5.625511735,
      code: 'D110100',
      description: '110x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 607.6,
      price: 1215.19,
      sizeX: 190,
      sizeY: 100,
      weight: 9.645141837,
      code: 'D190100',
      description: '190x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 391.42,
      price: 782.84,
      sizeX: 120,
      sizeY: 100,
      weight: 6.127965498,
      code: 'D120100',
      description: '120x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 422.3,
      price: 844.6,
      sizeX: 130,
      sizeY: 100,
      weight: 6.630419261,
      code: 'D130090',
      description: '130x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 453.18,
      price: 906.37,
      sizeX: 140,
      sizeY: 100,
      weight: 7.132873024,
      code: 'D140100',
      description: '140x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 420.35,
      price: 840.7,
      sizeX: 150,
      sizeY: 100,
      weight: 7.635326786,
      code: 'D150100',
      description: '150x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 514.95,
      price: 1029.9,
      sizeX: 160,
      sizeY: 100,
      weight: 8.137780549,
      code: 'D160100',
      description: '160x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 545.83,
      price: 1091.66,
      sizeX: 170,
      sizeY: 100,
      weight: 8.640234312,
      code: 'D170100',
      description: '170x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 576.71,
      price: 1153.43,
      sizeX: 180,
      sizeY: 100,
      weight: 9.142688074,
      code: 'D180100',
      description: '180x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 669.36,
      price: 1338.72,
      sizeX: 210,
      sizeY: 100,
      weight: 10.65004936,
      code: 'D210100',
      description: '210x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 700.24,
      price: 1400.49,
      sizeX: 220,
      sizeY: 100,
      weight: 11.15250313,
      code: 'D220100',
      description: '220x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 731.13,
      price: 1462.25,
      sizeX: 230,
      sizeY: 100,
      weight: 11.65495689,
      code: 'D230100',
      description: '230x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 762.01,
      price: 1524.02,
      sizeX: 240,
      sizeY: 100,
      weight: 12.15741065,
      code: 'D240100',
      description: '240x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 688.51,
      price: 1377.02,
      sizeX: 250,
      sizeY: 100,
      weight: 12.65986441,
      code: 'D250100',
      description: '250x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 823.77,
      price: 1647.55,
      sizeX: 260,
      sizeY: 100,
      weight: 13.16231818,
      code: 'D260100',
      description: '260x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 854.65,
      price: 1709.31,
      sizeX: 270,
      sizeY: 100,
      weight: 13.66477194,
      code: 'D270100',
      description: '270x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 885.54,
      price: 1771.07,
      sizeX: 280,
      sizeY: 100,
      weight: 14.1672257,
      code: 'D280100',
      description: '280x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 916.42,
      price: 1832.84,
      sizeX: 290,
      sizeY: 100,
      weight: 14.66967946,
      code: 'D290100',
      description: '290x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 822.59,
      price: 1645.19,
      sizeX: 300,
      sizeY: 100,
      weight: 15.17213323,
      code: 'D300100',
      description: '300x100',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 566.83,
      price: 1133.66,
      sizeX: 200,
      sizeY: 102,
      weight: 10.39635947,
      code: 'D200102',
      description: '200x102.5',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 34.22,
      price: 68.43,
      sizeX: 10,
      sizeY: 105,
      weight: 0.423637486,
      code: 'B010105',
      description: '10x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 54.02,
      price: 108.04,
      sizeX: 20,
      sizeY: 105,
      weight: 0.74382861,
      code: 'B020105',
      description: '20x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 73.82,
      price: 147.65,
      sizeX: 30,
      sizeY: 105,
      weight: 1.064019733,
      code: 'B030105',
      description: '30x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 93.63,
      price: 187.25,
      sizeX: 40,
      sizeY: 105,
      weight: 1.384210856,
      code: 'B040105',
      description: '40x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 113.43,
      price: 226.86,
      sizeX: 50,
      sizeY: 105,
      weight: 1.704401979,
      code: 'B050105',
      description: '50x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 153.04,
      price: 306.08,
      sizeX: 70,
      sizeY: 105,
      weight: 2.344784226,
      code: 'B070105',
      description: '70x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 172.84,
      price: 345.69,
      sizeX: 80,
      sizeY: 105,
      weight: 2.664975349,
      code: 'B080105',
      description: '80x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 192.65,
      price: 385.3,
      sizeX: 90,
      sizeY: 105,
      weight: 2.985166473,
      code: 'B090105',
      description: '90x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 212.45,
      price: 424.9,
      sizeX: 100,
      sizeY: 105,
      weight: 3.305357596,
      code: 'B100105',
      description: '100x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 331.28,
      price: 662.55,
      sizeX: 160,
      sizeY: 105,
      weight: 5.226504336,
      code: 'B160105',
      description: '160x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 311.47,
      price: 622.95,
      sizeX: 150,
      sizeY: 105,
      weight: 4.906313212,
      code: 'B150105',
      description: '150x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 232.26,
      price: 464.51,
      sizeX: 110,
      sizeY: 105,
      weight: 3.625548719,
      code: 'B110105',
      description: '110x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 370.89,
      price: 741.77,
      sizeX: 180,
      sizeY: 105,
      weight: 5.866886582,
      code: 'B180105',
      description: '180x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 252.06,
      price: 504.12,
      sizeX: 120,
      sizeY: 105,
      weight: 3.945739842,
      code: 'B120105',
      description: '120x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 291.67,
      price: 583.34,
      sizeX: 140,
      sizeY: 105,
      weight: 4.586122089,
      code: 'B140105',
      description: '140x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 351.08,
      price: 702.16,
      sizeX: 170,
      sizeY: 105,
      weight: 5.546695459,
      code: 'B170105',
      description: '170x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 271.86,
      price: 543.73,
      sizeX: 130,
      sizeY: 105,
      weight: 4.265930966,
      code: 'B130105',
      description: '130x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 410.49,
      price: 820.99,
      sizeX: 200,
      sizeY: 105,
      weight: 6.507268829,
      code: 'B200105',
      description: '200x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 648.14,
      price: 1296.29,
      sizeX: 320,
      sizeY: 105,
      weight: 10.34956231,
      code: 'B320105',
      description: '320x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 520.92,
      price: 1041.84,
      sizeX: 190,
      sizeY: 105,
      weight: 6.187077706,
      code: 'B190105',
      description: '190x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 573.73,
      price: 1147.46,
      sizeX: 210,
      sizeY: 105,
      weight: 6.827459952,
      code: 'B210105',
      description: '210x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 600.14,
      price: 1200.27,
      sizeX: 220,
      sizeY: 105,
      weight: 7.147651075,
      code: 'B220105',
      description: '220x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 626.54,
      price: 1253.08,
      sizeX: 230,
      sizeY: 105,
      weight: 7.467842199,
      code: 'B230105',
      description: '230x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 652.95,
      price: 1305.89,
      sizeX: 240,
      sizeY: 105,
      weight: 7.788033322,
      code: 'B240105',
      description: '240x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 509.51,
      price: 1019.03,
      sizeX: 250,
      sizeY: 105,
      weight: 8.108224445,
      code: 'B250105',
      description: '250x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 418.89,
      price: 837.79,
      sizeX: 310,
      sizeY: 105,
      weight: 10.02937119,
      code: 'B310105',
      description: '310x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 705.76,
      price: 1411.52,
      sizeX: 260,
      sizeY: 105,
      weight: 8.428415569,
      code: 'B260105',
      description: '260x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 732.16,
      price: 1464.33,
      sizeX: 270,
      sizeY: 105,
      weight: 8.748606692,
      code: 'B270105',
      description: '270x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 758.57,
      price: 1517.14,
      sizeX: 280,
      sizeY: 105,
      weight: 9.068797815,
      code: 'B280105',
      description: '280x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 784.97,
      price: 1569.95,
      sizeX: 290,
      sizeY: 105,
      weight: 9.388988938,
      code: 'B290105',
      description: '290x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 608.54,
      price: 1217.07,
      sizeX: 300,
      sizeY: 105,
      weight: 9.709180062,
      code: 'B300105',
      description: '300x105',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 535.13,
      price: 1070.26,
      sizeX: 300,
      sizeY: 105,
      weight: 12.81257095,
      code: 'C300105',
      description: '300x105',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 552.71,
      price: 1105.41,
      sizeX: 310,
      sizeY: 105,
      weight: 13.23620844,
      code: 'C310105',
      description: '310x105',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 166.08,
      price: 332.15,
      sizeX: 110,
      sizeY: 110,
      weight: 2.600937125,
      code: 'A110110',
      description: '110x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 180.28,
      price: 360.56,
      sizeX: 120,
      sizeY: 110,
      weight: 2.82753392,
      code: 'A120110',
      description: '120x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 194.48,
      price: 388.97,
      sizeX: 130,
      sizeY: 110,
      weight: 3.054130715,
      code: 'A130110',
      description: '130x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 208.69,
      price: 417.37,
      sizeX: 140,
      sizeY: 110,
      weight: 3.280727509,
      code: 'A140110',
      description: '140x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 222.89,
      price: 445.78,
      sizeX: 150,
      sizeY: 110,
      weight: 3.507324304,
      code: 'A150110',
      description: '150x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 237.09,
      price: 474.19,
      sizeX: 160,
      sizeY: 110,
      weight: 3.733921099,
      code: 'A160110',
      description: '160x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 251.3,
      price: 502.6,
      sizeX: 170,
      sizeY: 110,
      weight: 3.960517894,
      code: 'A170110',
      description: '170x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 266.93,
      price: 533.86,
      sizeX: 180,
      sizeY: 110,
      weight: 4.187114689,
      code: 'A180110',
      description: '180x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 281.13,
      price: 562.27,
      sizeX: 190,
      sizeY: 110,
      weight: 4.413711484,
      code: 'A190110',
      description: '190x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 295.34,
      price: 590.67,
      sizeX: 200,
      sizeY: 110,
      weight: 4.640308279,
      code: 'A200110',
      description: '200x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 309.54,
      price: 619.08,
      sizeX: 210,
      sizeY: 110,
      weight: 4.866905074,
      code: 'A210110',
      description: '210x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 323.74,
      price: 647.49,
      sizeX: 220,
      sizeY: 110,
      weight: 5.093501869,
      code: 'A220110',
      description: '220x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 337.95,
      price: 675.9,
      sizeX: 230,
      sizeY: 110,
      weight: 5.320098664,
      code: 'A230110',
      description: '230x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 352.15,
      price: 704.3,
      sizeX: 240,
      sizeY: 110,
      weight: 5.546695459,
      code: 'A240110',
      description: '240x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 366.36,
      price: 732.71,
      sizeX: 250,
      sizeY: 110,
      weight: 5.773292254,
      code: 'A250110',
      description: '250x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 380.56,
      price: 761.12,
      sizeX: 260,
      sizeY: 110,
      weight: 5.999889049,
      code: 'A260110',
      description: '260x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 394.76,
      price: 789.53,
      sizeX: 270,
      sizeY: 110,
      weight: 6.226485844,
      code: 'A270110',
      description: '270x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 408.97,
      price: 817.93,
      sizeX: 280,
      sizeY: 110,
      weight: 6.453082639,
      code: 'A280110',
      description: '280x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 423.17,
      price: 846.34,
      sizeX: 290,
      sizeY: 110,
      weight: 6.679679434,
      code: 'A290110',
      description: '290x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 377.82,
      price: 755.64,
      sizeX: 300,
      sizeY: 110,
      weight: 6.906276229,
      code: 'A300110',
      description: '300x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 300.1,
      price: 600.2,
      sizeX: 310,
      sizeY: 110,
      weight: 7.132873024,
      code: 'A310110',
      description: '310x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 309.57,
      price: 619.14,
      sizeX: 320,
      sizeY: 110,
      weight: 7.359469819,
      code: 'A320110',
      description: '320x110',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 47.7,
      price: 95.4,
      sizeX: 10,
      sizeY: 110,
      weight: 0.443341555,
      code: 'B010110',
      description: '10x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 78.96,
      price: 157.92,
      sizeX: 20,
      sizeY: 110,
      weight: 0.77831073,
      code: 'B020110',
      description: '20x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 79.93,
      price: 159.85,
      sizeX: 30,
      sizeY: 110,
      weight: 1.113279906,
      code: 'B030110',
      description: '30x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 134.18,
      price: 268.36,
      sizeX: 40,
      sizeY: 110,
      weight: 1.448249081,
      code: 'B040110',
      description: '40x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 121.34,
      price: 242.68,
      sizeX: 50,
      sizeY: 110,
      weight: 1.783218256,
      code: 'B050110',
      description: '50x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 189.4,
      price: 378.8,
      sizeX: 60,
      sizeY: 110,
      weight: 2.118187431,
      code: 'B060110',
      description: '60x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 217.01,
      price: 434.01,
      sizeX: 70,
      sizeY: 110,
      weight: 2.453156606,
      code: 'B070110',
      description: '70x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 244.62,
      price: 489.23,
      sizeX: 80,
      sizeY: 110,
      weight: 2.788125781,
      code: 'B080110',
      description: '80x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 272.23,
      price: 544.45,
      sizeX: 90,
      sizeY: 110,
      weight: 3.123094956,
      code: 'B090110',
      description: '90x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 222.14,
      price: 444.28,
      sizeX: 100,
      sizeY: 110,
      weight: 3.458064132,
      code: 'B100110',
      description: '100x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 465.49,
      price: 930.99,
      sizeX: 160,
      sizeY: 110,
      weight: 5.467879182,
      code: 'B160110',
      description: '160x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 325.68,
      price: 651.35,
      sizeX: 150,
      sizeY: 110,
      weight: 5.132910007,
      code: 'B150110',
      description: '150x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 327.45,
      price: 654.89,
      sizeX: 110,
      sizeY: 110,
      weight: 3.793033307,
      code: 'B110110',
      description: '110x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 355.06,
      price: 710.11,
      sizeX: 120,
      sizeY: 110,
      weight: 4.128002482,
      code: 'B120110',
      description: '120x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 429.21,
      price: 858.43,
      sizeX: 200,
      sizeY: 110,
      weight: 6.807755883,
      code: 'B200110',
      description: '200x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 382.66,
      price: 765.33,
      sizeX: 130,
      sizeY: 110,
      weight: 4.462971657,
      code: 'B130110',
      description: '130x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 410.27,
      price: 820.55,
      sizeX: 140,
      sizeY: 110,
      weight: 4.797940832,
      code: 'B140110',
      description: '140x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 493.1,
      price: 986.21,
      sizeX: 170,
      sizeY: 110,
      weight: 5.802848358,
      code: 'B170110',
      description: '170x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 520.71,
      price: 1041.43,
      sizeX: 180,
      sizeY: 110,
      weight: 6.137817533,
      code: 'B180110',
      description: '180x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 548.32,
      price: 1096.64,
      sizeX: 190,
      sizeY: 110,
      weight: 6.472786708,
      code: 'B190110',
      description: '190x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 603.54,
      price: 1207.08,
      sizeX: 210,
      sizeY: 110,
      weight: 7.142725058,
      code: 'B210110',
      description: '210x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 631.15,
      price: 1262.3,
      sizeX: 220,
      sizeY: 110,
      weight: 7.477694233,
      code: 'B220110',
      description: '220x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 658.76,
      price: 1317.52,
      sizeX: 230,
      sizeY: 110,
      weight: 7.812663408,
      code: 'B230110',
      description: '230x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 686.37,
      price: 1372.74,
      sizeX: 240,
      sizeY: 110,
      weight: 8.147632584,
      code: 'B240110',
      description: '240x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 532.75,
      price: 1065.5,
      sizeX: 250,
      sizeY: 110,
      weight: 8.482601759,
      code: 'B250110',
      description: '250x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 741.59,
      price: 1483.18,
      sizeX: 260,
      sizeY: 110,
      weight: 8.817570934,
      code: 'B260110',
      description: '260x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 769.2,
      price: 1538.4,
      sizeX: 270,
      sizeY: 110,
      weight: 9.152540109,
      code: 'B270110',
      description: '270x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 796.81,
      price: 1593.62,
      sizeX: 280,
      sizeY: 110,
      weight: 9.487509284,
      code: 'B280110',
      description: '280x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 824.42,
      price: 1648.84,
      sizeX: 290,
      sizeY: 110,
      weight: 9.822478459,
      code: 'B290110',
      description: '290x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 551.45,
      price: 1102.89,
      sizeX: 300,
      sizeY: 110,
      weight: 10.15744763,
      code: 'B300110',
      description: '300x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 437.99,
      price: 875.99,
      sizeX: 310,
      sizeY: 110,
      weight: 10.49241681,
      code: 'B310110',
      description: '310x110',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 315.58,
      price: 631.15,
      sizeX: 110,
      sizeY: 110,
      weight: 4.985129489,
      code: 'C110110',
      description: '110x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 343.14,
      price: 686.29,
      sizeX: 120,
      sizeY: 110,
      weight: 5.428471044,
      code: 'C120110',
      description: '120x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 370.71,
      price: 741.42,
      sizeX: 130,
      sizeY: 110,
      weight: 5.8718126,
      code: 'C130110',
      description: '130x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 398.28,
      price: 796.56,
      sizeX: 140,
      sizeY: 110,
      weight: 6.315154155,
      code: 'C140110',
      description: '140x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 425.85,
      price: 851.69,
      sizeX: 150,
      sizeY: 110,
      weight: 6.75849571,
      code: 'C150110',
      description: '150x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 453.41,
      price: 906.83,
      sizeX: 160,
      sizeY: 110,
      weight: 7.201837266,
      code: 'C160110',
      description: '160x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 480.98,
      price: 961.96,
      sizeX: 170,
      sizeY: 110,
      weight: 7.645178821,
      code: 'C170110',
      description: '170x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 508.55,
      price: 1017.1,
      sizeX: 180,
      sizeY: 110,
      weight: 8.088520376,
      code: 'C180110',
      description: '180x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 536.12,
      price: 1072.23,
      sizeX: 190,
      sizeY: 110,
      weight: 8.531861932,
      code: 'C190110',
      description: '190x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 563.68,
      price: 1127.37,
      sizeX: 200,
      sizeY: 110,
      weight: 8.975203487,
      code: 'C200110',
      description: '200x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 591.25,
      price: 1182.5,
      sizeX: 210,
      sizeY: 110,
      weight: 9.418545042,
      code: 'C210110',
      description: '210x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 618.82,
      price: 1237.64,
      sizeX: 220,
      sizeY: 110,
      weight: 9.861886598,
      code: 'C220110',
      description: '220x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 646.39,
      price: 1292.77,
      sizeX: 230,
      sizeY: 110,
      weight: 10.30522815,
      code: 'C230110',
      description: '230x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 673.95,
      price: 1347.91,
      sizeX: 240,
      sizeY: 110,
      weight: 10.74856971,
      code: 'C240110',
      description: '240x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 701.52,
      price: 1403.04,
      sizeX: 250,
      sizeY: 110,
      weight: 11.19191126,
      code: 'C250110',
      description: '250x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 729.09,
      price: 1458.18,
      sizeX: 260,
      sizeY: 110,
      weight: 11.63525282,
      code: 'C260110',
      description: '260x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 756.66,
      price: 1513.31,
      sizeX: 270,
      sizeY: 110,
      weight: 12.07859437,
      code: 'C270110',
      description: '270x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 784.22,
      price: 1568.45,
      sizeX: 280,
      sizeY: 110,
      weight: 12.52193593,
      code: 'C280110',
      description: '280x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 811.79,
      price: 1623.58,
      sizeX: 290,
      sizeY: 110,
      weight: 12.96527748,
      code: 'C290110',
      description: '290x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 727.44,
      price: 1454.89,
      sizeX: 300,
      sizeY: 110,
      weight: 13.40861904,
      code: 'C300110',
      description: '300x110',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 62.11,
      price: 124.21,
      sizeX: 10,
      sizeY: 110,
      weight: 0.660086316,
      code: 'D010110',
      description: '10x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 96,
      price: 192,
      sizeX: 20,
      sizeY: 110,
      weight: 1.211800251,
      code: 'D020110',
      description: '20x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 112.57,
      price: 225.15,
      sizeX: 30,
      sizeY: 110,
      weight: 1.763514187,
      code: 'D030110',
      description: '30x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 163.78,
      price: 327.57,
      sizeX: 40,
      sizeY: 110,
      weight: 2.315228122,
      code: 'D040110',
      description: '40x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 166.68,
      price: 333.36,
      sizeX: 50,
      sizeY: 110,
      weight: 2.866942058,
      code: 'D050110',
      description: '50x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 231.57,
      price: 463.14,
      sizeX: 60,
      sizeY: 110,
      weight: 3.418655993,
      code: 'D060110',
      description: '60x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 265.46,
      price: 530.92,
      sizeX: 70,
      sizeY: 110,
      weight: 3.970369929,
      code: 'D070110',
      description: '70x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 299.35,
      price: 598.71,
      sizeX: 80,
      sizeY: 110,
      weight: 4.522083864,
      code: 'D080110',
      description: '80x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 607.28,
      price: 1214.57,
      sizeX: 200,
      sizeY: 110,
      weight: 11.14265109,
      code: 'D200110',
      description: '200x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 333.25,
      price: 666.49,
      sizeX: 90,
      sizeY: 110,
      weight: 5.0737978,
      code: 'D090110',
      description: '90x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 313.55,
      price: 627.1,
      sizeX: 100,
      sizeY: 110,
      weight: 5.625511735,
      code: 'D100110',
      description: '100x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 401.03,
      price: 802.06,
      sizeX: 110,
      sizeY: 110,
      weight: 6.177225671,
      code: 'D110110',
      description: '110x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 434.92,
      price: 869.85,
      sizeX: 120,
      sizeY: 110,
      weight: 6.728939606,
      code: 'D120110',
      description: '120x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 468.82,
      price: 937.63,
      sizeX: 130,
      sizeY: 110,
      weight: 7.280653542,
      code: 'D130110',
      description: '130x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 502.71,
      price: 1005.42,
      sizeX: 140,
      sizeY: 110,
      weight: 7.832367478,
      code: 'D140110',
      description: '140x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 460.42,
      price: 920.83,
      sizeX: 150,
      sizeY: 110,
      weight: 8.384081413,
      code: 'D150110',
      description: '150x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 570.49,
      price: 1140.99,
      sizeX: 160,
      sizeY: 110,
      weight: 8.935795349,
      code: 'D160110',
      description: '160x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 604.39,
      price: 1208.77,
      sizeX: 170,
      sizeY: 110,
      weight: 9.487509284,
      code: 'D170110',
      description: '170x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 638.28,
      price: 1276.56,
      sizeX: 180,
      sizeY: 110,
      weight: 10.03922322,
      code: 'D180110',
      description: '180x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 672.17,
      price: 1344.35,
      sizeX: 190,
      sizeY: 110,
      weight: 10.59093716,
      code: 'D190110',
      description: '190x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 739.96,
      price: 1479.92,
      sizeX: 210,
      sizeY: 110,
      weight: 11.69436503,
      code: 'D210110',
      description: '210x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 773.85,
      price: 1547.7,
      sizeX: 220,
      sizeY: 110,
      weight: 12.24607896,
      code: 'D220110',
      description: '220x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 807.74,
      price: 1615.49,
      sizeX: 230,
      sizeY: 110,
      weight: 12.7977929,
      code: 'D230110',
      description: '230x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 841.64,
      price: 1683.27,
      sizeX: 240,
      sizeY: 110,
      weight: 13.34950683,
      code: 'D240110',
      description: '240x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 754.15,
      price: 1508.3,
      sizeX: 250,
      sizeY: 110,
      weight: 13.90122077,
      code: 'D250110',
      description: '250x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 909.42,
      price: 1818.84,
      sizeX: 260,
      sizeY: 110,
      weight: 14.4529347,
      code: 'D260110',
      description: '260x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 943.31,
      price: 1886.63,
      sizeX: 270,
      sizeY: 110,
      weight: 15.00464864,
      code: 'D270110',
      description: '270x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 977.21,
      price: 1954.41,
      sizeX: 280,
      sizeY: 110,
      weight: 15.55636257,
      code: 'D280110',
      description: '280x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1011.1,
      price: 2022.2,
      sizeX: 290,
      sizeY: 110,
      weight: 16.10807651,
      code: 'D290110',
      description: '290x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 902.62,
      price: 1805.23,
      sizeX: 300,
      sizeY: 110,
      weight: 16.65979045,
      code: 'D300110',
      description: '300x110',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 341.73,
      price: 683.45,
      sizeX: 110,
      sizeY: 115,
      weight: 3.960517894,
      code: 'B110115',
      description: '110x115',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 198.66,
      price: 397.32,
      sizeX: 120,
      sizeY: 120,
      weight: 3.073834784,
      code: 'A120120',
      description: '120x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 214.07,
      price: 428.14,
      sizeX: 130,
      sizeY: 120,
      weight: 3.320135648,
      code: 'A130120',
      description: '130x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 229.48,
      price: 458.95,
      sizeX: 140,
      sizeY: 120,
      weight: 3.566436512,
      code: 'A140120',
      description: '140x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 244.88,
      price: 489.77,
      sizeX: 150,
      sizeY: 120,
      weight: 3.812737376,
      code: 'A150120',
      description: '150x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 260.29,
      price: 520.59,
      sizeX: 160,
      sizeY: 120,
      weight: 4.05903824,
      code: 'A160120',
      description: '160x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 275.7,
      price: 551.4,
      sizeX: 170,
      sizeY: 120,
      weight: 4.305339104,
      code: 'A170120',
      description: '170x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 291.11,
      price: 582.22,
      sizeX: 180,
      sizeY: 120,
      weight: 4.551639968,
      code: 'A180120',
      description: '180x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 408.69,
      price: 817.38,
      sizeX: 190,
      sizeY: 120,
      weight: 4.797940832,
      code: 'A190120',
      description: '190x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 429.23,
      price: 858.46,
      sizeX: 200,
      sizeY: 120,
      weight: 5.044241696,
      code: 'A200120',
      description: '200x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 449.78,
      price: 899.55,
      sizeX: 210,
      sizeY: 120,
      weight: 5.29054256,
      code: 'A210120',
      description: '210x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 470.32,
      price: 940.64,
      sizeX: 220,
      sizeY: 120,
      weight: 5.536843424,
      code: 'A220120',
      description: '220x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 490.86,
      price: 981.73,
      sizeX: 230,
      sizeY: 120,
      weight: 5.783144288,
      code: 'A230120',
      description: '230x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 511.41,
      price: 1022.81,
      sizeX: 240,
      sizeY: 120,
      weight: 6.029445153,
      code: 'A240120',
      description: '240x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 398.96,
      price: 797.93,
      sizeX: 250,
      sizeY: 120,
      weight: 6.275746017,
      code: 'A250120',
      description: '250x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 552.49,
      price: 1104.99,
      sizeX: 260,
      sizeY: 120,
      weight: 6.522046881,
      code: 'A260120',
      description: '260x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 573.04,
      price: 1146.08,
      sizeX: 270,
      sizeY: 120,
      weight: 6.768347745,
      code: 'A270120',
      description: '270x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 593.58,
      price: 1187.16,
      sizeX: 280,
      sizeY: 120,
      weight: 7.014648609,
      code: 'A280120',
      description: '280x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 460.59,
      price: 921.19,
      sizeX: 290,
      sizeY: 120,
      weight: 7.260949473,
      code: 'A290120',
      description: '290x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 474.46,
      price: 948.91,
      sizeX: 300,
      sizeY: 120,
      weight: 7.507250337,
      code: 'A300120',
      description: '300x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 491.41,
      price: 982.82,
      sizeX: 310,
      sizeY: 120,
      weight: 7.753551201,
      code: 'A310120',
      description: '310x120',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 55.83,
      price: 111.66,
      sizeX: 10,
      sizeY: 120,
      weight: 0.482749694,
      code: 'B010120',
      description: '10x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 85.85,
      price: 171.69,
      sizeX: 20,
      sizeY: 120,
      weight: 0.847274972,
      code: 'B020120',
      description: '20x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 86.9,
      price: 173.8,
      sizeX: 30,
      sizeY: 120,
      weight: 1.211800251,
      code: 'B030120',
      description: '30x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 145.88,
      price: 291.77,
      sizeX: 40,
      sizeY: 120,
      weight: 1.57632553,
      code: 'B040120',
      description: '40x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 128.95,
      price: 257.9,
      sizeX: 50,
      sizeY: 120,
      weight: 1.940850809,
      code: 'B050120',
      description: '50x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 205.92,
      price: 411.84,
      sizeX: 60,
      sizeY: 120,
      weight: 2.305376088,
      code: 'B060120',
      description: '60x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 235.94,
      price: 471.87,
      sizeX: 70,
      sizeY: 120,
      weight: 2.669901367,
      code: 'B070120',
      description: '70x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 265.95,
      price: 531.91,
      sizeX: 80,
      sizeY: 120,
      weight: 3.034426645,
      code: 'B080120',
      description: '80x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 295.97,
      price: 591.94,
      sizeX: 90,
      sizeY: 120,
      weight: 3.398951924,
      code: 'B090120',
      description: '90x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 244.52,
      price: 489.05,
      sizeX: 100,
      sizeY: 120,
      weight: 3.763477203,
      code: 'B100120',
      description: '100x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 356.01,
      price: 712.01,
      sizeX: 110,
      sizeY: 120,
      weight: 4.128002482,
      code: 'B110120',
      description: '110x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 386.02,
      price: 772.05,
      sizeX: 120,
      sizeY: 120,
      weight: 4.492527761,
      code: 'B120120',
      description: '120x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 354.08,
      price: 708.17,
      sizeX: 150,
      sizeY: 120,
      weight: 5.586103597,
      code: 'B150120',
      description: '150x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 416.04,
      price: 832.08,
      sizeX: 130,
      sizeY: 120,
      weight: 4.85705304,
      code: 'B130120',
      description: '130x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 506.1,
      price: 1012.19,
      sizeX: 160,
      sizeY: 120,
      weight: 5.950628876,
      code: 'B160120',
      description: '160x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 446.06,
      price: 892.12,
      sizeX: 140,
      sizeY: 120,
      weight: 5.221578318,
      code: 'B140120',
      description: '140x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 466.65,
      price: 933.3,
      sizeX: 200,
      sizeY: 120,
      weight: 7.408729991,
      code: 'B200120',
      description: '200x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 536.11,
      price: 1072.23,
      sizeX: 170,
      sizeY: 120,
      weight: 6.315154155,
      code: 'B170120',
      description: '170x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 566.13,
      price: 1132.26,
      sizeX: 180,
      sizeY: 120,
      weight: 6.679679434,
      code: 'B180120',
      description: '180x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 596.15,
      price: 1192.3,
      sizeX: 190,
      sizeY: 120,
      weight: 7.044204713,
      code: 'B190120',
      description: '190x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 656.18,
      price: 1312.37,
      sizeX: 210,
      sizeY: 120,
      weight: 7.77325527,
      code: 'B210120',
      description: '210x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 686.2,
      price: 1372.4,
      sizeX: 220,
      sizeY: 120,
      weight: 8.137780549,
      code: 'B220120',
      description: '220x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 716.22,
      price: 1432.44,
      sizeX: 230,
      sizeY: 120,
      weight: 8.502305828,
      code: 'B230120',
      description: '230x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 746.24,
      price: 1492.48,
      sizeX: 240,
      sizeY: 120,
      weight: 8.866831107,
      code: 'B240120',
      description: '240x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 579.22,
      price: 1158.43,
      sizeX: 250,
      sizeY: 120,
      weight: 9.231356385,
      code: 'B250120',
      description: '250x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 806.27,
      price: 1612.55,
      sizeX: 260,
      sizeY: 120,
      weight: 9.595881664,
      code: 'B260120',
      description: '260x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 836.29,
      price: 1672.58,
      sizeX: 270,
      sizeY: 120,
      weight: 9.960406943,
      code: 'B270120',
      description: '270x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 866.31,
      price: 1732.62,
      sizeX: 280,
      sizeY: 120,
      weight: 10.32493222,
      code: 'B280120',
      description: '280x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 896.33,
      price: 1792.65,
      sizeX: 290,
      sizeY: 120,
      weight: 10.6894575,
      code: 'B290120',
      description: '290x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 691.78,
      price: 1383.57,
      sizeX: 300,
      sizeY: 120,
      weight: 11.05398278,
      code: 'B300120',
      description: '300x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 956.36,
      price: 1912.72,
      sizeX: 310,
      sizeY: 120,
      weight: 11.41850806,
      code: 'B310120',
      description: '310x120',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 497.49,
      price: 994.98,
      sizeX: 120,
      sizeY: 120,
      weight: 5.911220738,
      code: 'C120120',
      description: '120x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 537.46,
      price: 1074.92,
      sizeX: 130,
      sizeY: 120,
      weight: 6.393970431,
      code: 'C130120',
      description: '130x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 577.43,
      price: 1154.85,
      sizeX: 140,
      sizeY: 120,
      weight: 6.876720125,
      code: 'C140120',
      description: '140x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 463.05,
      price: 926.09,
      sizeX: 150,
      sizeY: 120,
      weight: 7.359469819,
      code: 'C150120',
      description: '150x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 81.83,
      price: 163.66,
      sizeX: 16,
      sizeY: 120,
      weight: 7.842219512,
      code: 'C160120',
      description: '160x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 697.33,
      price: 1394.66,
      sizeX: 170,
      sizeY: 120,
      weight: 8.324969206,
      code: 'C170120',
      description: '170x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 737.3,
      price: 1474.59,
      sizeX: 180,
      sizeY: 120,
      weight: 8.807718899,
      code: 'C180120',
      description: '180x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 777.26,
      price: 1554.53,
      sizeX: 190,
      sizeY: 120,
      weight: 9.290468593,
      code: 'C190120',
      description: '190x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 612.92,
      price: 1225.85,
      sizeX: 200,
      sizeY: 120,
      weight: 9.773218286,
      code: 'C200120',
      description: '200x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 857.2,
      price: 1714.4,
      sizeX: 210,
      sizeY: 120,
      weight: 10.25596798,
      code: 'C210120',
      description: '210x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 897.17,
      price: 1794.34,
      sizeX: 220,
      sizeY: 120,
      weight: 10.73871767,
      code: 'C220120',
      description: '220x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 937.14,
      price: 1874.27,
      sizeX: 230,
      sizeY: 120,
      weight: 11.22146737,
      code: 'C230120',
      description: '230x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 977.1,
      price: 1954.21,
      sizeX: 240,
      sizeY: 120,
      weight: 11.70421706,
      code: 'C240120',
      description: '240x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 762.8,
      price: 1525.61,
      sizeX: 250,
      sizeY: 120,
      weight: 12.18696675,
      code: 'C250120',
      description: '250x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1057.04,
      price: 2114.08,
      sizeX: 260,
      sizeY: 120,
      weight: 12.66971645,
      code: 'C260120',
      description: '260x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1097.01,
      price: 2194.01,
      sizeX: 270,
      sizeY: 120,
      weight: 13.15246614,
      code: 'C270120',
      description: '270x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1136.97,
      price: 2273.95,
      sizeX: 280,
      sizeY: 120,
      weight: 13.63521584,
      code: 'C280120',
      description: '280x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1176.94,
      price: 2353.88,
      sizeX: 290,
      sizeY: 120,
      weight: 14.11796553,
      code: 'C290120',
      description: '290x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 912.68,
      price: 1825.36,
      sizeX: 300,
      sizeY: 120,
      weight: 14.60071522,
      code: 'C300120',
      description: '300x120',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 90.16,
      price: 180.33,
      sizeX: 10,
      sizeY: 120,
      weight: 0.719198523,
      code: 'D010120',
      description: '10x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 139.37,
      price: 278.73,
      sizeX: 20,
      sizeY: 120,
      weight: 1.320172631,
      code: 'D020120',
      description: '20x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 141.43,
      price: 282.86,
      sizeX: 30,
      sizeY: 120,
      weight: 1.92114674,
      code: 'D030120',
      description: '30x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 237.77,
      price: 475.55,
      sizeX: 40,
      sizeY: 120,
      weight: 2.522120848,
      code: 'D040120',
      description: '40x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 209.4,
      price: 418.81,
      sizeX: 50,
      sizeY: 120,
      weight: 3.123094956,
      code: 'D050120',
      description: '50x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 336.18,
      price: 672.36,
      sizeX: 60,
      sizeY: 120,
      weight: 3.724069065,
      code: 'D060120',
      description: '60x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 385.39,
      price: 770.77,
      sizeX: 70,
      sizeY: 120,
      weight: 4.325043173,
      code: 'D070120',
      description: '70x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 434.59,
      price: 869.18,
      sizeX: 80,
      sizeY: 120,
      weight: 4.926017281,
      code: 'D080120',
      description: '80x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 393.92,
      price: 787.84,
      sizeX: 100,
      sizeY: 120,
      weight: 6.127965498,
      code: 'D100120',
      description: '100x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 483.79,
      price: 967.58,
      sizeX: 90,
      sizeY: 120,
      weight: 5.52699139,
      code: 'D090120',
      description: '90x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 582.2,
      price: 1164.4,
      sizeX: 110,
      sizeY: 120,
      weight: 6.728939606,
      code: 'D110120',
      description: '110x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 762.95,
      price: 1525.89,
      sizeX: 200,
      sizeY: 120,
      weight: 12.13770658,
      code: 'D200120',
      description: '200x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 631.4,
      price: 1262.81,
      sizeX: 120,
      sizeY: 120,
      weight: 7.329913715,
      code: 'D120120',
      description: '120x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 680.61,
      price: 1361.21,
      sizeX: 130,
      sizeY: 120,
      weight: 7.930887823,
      code: 'D130120',
      description: '130x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 729.81,
      price: 1459.62,
      sizeX: 140,
      sizeY: 120,
      weight: 8.531861932,
      code: 'D140120',
      description: '140x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 578.43,
      price: 1156.86,
      sizeX: 150,
      sizeY: 120,
      weight: 9.13283604,
      code: 'D150120',
      description: '150x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 828.22,
      price: 1656.44,
      sizeX: 160,
      sizeY: 120,
      weight: 9.733810148,
      code: 'D160120',
      description: '160x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 877.42,
      price: 1754.84,
      sizeX: 170,
      sizeY: 120,
      weight: 10.33478426,
      code: 'D170120',
      description: '170x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 926.63,
      price: 1853.25,
      sizeX: 180,
      sizeY: 120,
      weight: 10.93575836,
      code: 'D180120',
      description: '180x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 975.83,
      price: 1951.66,
      sizeX: 190,
      sizeY: 120,
      weight: 11.53673247,
      code: 'D190120',
      description: '190x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1074.24,
      price: 2148.47,
      sizeX: 210,
      sizeY: 120,
      weight: 12.73868069,
      code: 'D210120',
      description: '210x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1123.44,
      price: 2246.88,
      sizeX: 220,
      sizeY: 120,
      weight: 13.3396548,
      code: 'D220120',
      description: '220x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1172.64,
      price: 2345.29,
      sizeX: 230,
      sizeY: 120,
      weight: 13.94062891,
      code: 'D230120',
      description: '230x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1221.85,
      price: 2443.69,
      sizeX: 240,
      sizeY: 120,
      weight: 14.54160301,
      code: 'D240120',
      description: '240x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 947.46,
      price: 1894.92,
      sizeX: 250,
      sizeY: 120,
      weight: 15.14257712,
      code: 'D250120',
      description: '250x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1320.25,
      price: 2640.51,
      sizeX: 260,
      sizeY: 120,
      weight: 15.74355123,
      code: 'D260120',
      description: '260x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1369.46,
      price: 2738.92,
      sizeX: 270,
      sizeY: 120,
      weight: 16.34452534,
      code: 'D270120',
      description: '270x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1418.66,
      price: 2837.32,
      sizeX: 280,
      sizeY: 120,
      weight: 16.94549945,
      code: 'D280120',
      description: '280x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1467.87,
      price: 2935.73,
      sizeX: 290,
      sizeY: 120,
      weight: 17.54647356,
      code: 'D290120',
      description: '290x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1131.97,
      price: 2263.94,
      sizeX: 300,
      sizeY: 120,
      weight: 18.14744766,
      code: 'D300120',
      description: '300x120',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 307.73,
      price: 615.47,
      sizeX: 130,
      sizeY: 130,
      weight: 3.586140581,
      code: 'A130130',
      description: '130x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 329.88,
      price: 659.77,
      sizeX: 140,
      sizeY: 130,
      weight: 3.852145514,
      code: 'A140130',
      description: '140x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 352.03,
      price: 704.06,
      sizeX: 150,
      sizeY: 130,
      weight: 4.118150447,
      code: 'A150130',
      description: '150x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 374.18,
      price: 748.36,
      sizeX: 160,
      sizeY: 130,
      weight: 4.384155381,
      code: 'A160130',
      description: '160x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 396.33,
      price: 792.66,
      sizeX: 170,
      sizeY: 130,
      weight: 4.650160314,
      code: 'A170130',
      description: '170x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 418.48,
      price: 836.96,
      sizeX: 180,
      sizeY: 130,
      weight: 4.916165247,
      code: 'A180130',
      description: '180x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 440.63,
      price: 881.26,
      sizeX: 190,
      sizeY: 130,
      weight: 5.18217018,
      code: 'A190130',
      description: '190x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 462.78,
      price: 925.56,
      sizeX: 200,
      sizeY: 130,
      weight: 5.448175113,
      code: 'A200130',
      description: '200x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 484.93,
      price: 969.85,
      sizeX: 210,
      sizeY: 130,
      weight: 5.714180047,
      code: 'A210130',
      description: '210x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 507.08,
      price: 1014.15,
      sizeX: 220,
      sizeY: 130,
      weight: 5.98018498,
      code: 'A220130',
      description: '220x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 529.23,
      price: 1058.45,
      sizeX: 230,
      sizeY: 130,
      weight: 6.246189913,
      code: 'A230130',
      description: '230x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 551.37,
      price: 1102.75,
      sizeX: 240,
      sizeY: 130,
      weight: 6.512194846,
      code: 'A240130',
      description: '240x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 573.52,
      price: 1147.05,
      sizeX: 250,
      sizeY: 130,
      weight: 6.778199779,
      code: 'A250130',
      description: '250x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 595.67,
      price: 1191.35,
      sizeX: 260,
      sizeY: 130,
      weight: 7.044204713,
      code: 'A260130',
      description: '260x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 617.82,
      price: 1235.64,
      sizeX: 270,
      sizeY: 130,
      weight: 7.310209646,
      code: 'A270130',
      description: '270x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 639.97,
      price: 1279.94,
      sizeX: 280,
      sizeY: 130,
      weight: 7.576214579,
      code: 'A280130',
      description: '280x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 662.12,
      price: 1324.24,
      sizeX: 290,
      sizeY: 130,
      weight: 7.842219512,
      code: 'A290130',
      description: '290x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 684.27,
      price: 1368.54,
      sizeX: 300,
      sizeY: 130,
      weight: 8.108224445,
      code: 'A300130',
      description: '300x130',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 60.31,
      price: 120.62,
      sizeX: 10,
      sizeY: 130,
      weight: 0.522157832,
      code: 'B010130',
      description: '10x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 92.74,
      price: 185.47,
      sizeX: 20,
      sizeY: 130,
      weight: 0.916239214,
      code: 'B020130',
      description: '20x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 93.87,
      price: 187.74,
      sizeX: 30,
      sizeY: 130,
      weight: 1.310320597,
      code: 'B030130',
      description: '30x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 157.59,
      price: 315.17,
      sizeX: 40,
      sizeY: 130,
      weight: 1.704401979,
      code: 'B040130',
      description: '40x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 142.51,
      price: 285.02,
      sizeX: 50,
      sizeY: 130,
      weight: 2.098483362,
      code: 'B050130',
      description: '50x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 222.44,
      price: 444.88,
      sizeX: 60,
      sizeY: 130,
      weight: 2.492564744,
      code: 'B060130',
      description: '60x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 254.86,
      price: 509.73,
      sizeX: 70,
      sizeY: 130,
      weight: 2.886646127,
      code: 'B070130',
      description: '70x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 287.29,
      price: 574.58,
      sizeX: 80,
      sizeY: 130,
      weight: 3.280727509,
      code: 'B080130',
      description: '80x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 319.72,
      price: 639.43,
      sizeX: 90,
      sizeY: 130,
      weight: 3.674808892,
      code: 'B090130',
      description: '90x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 260.89,
      price: 521.79,
      sizeX: 100,
      sizeY: 130,
      weight: 4.068890274,
      code: 'B100130',
      description: '100x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 384.57,
      price: 769.14,
      sizeX: 110,
      sizeY: 130,
      weight: 4.462971657,
      code: 'B110130',
      description: '110x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 416.99,
      price: 833.99,
      sizeX: 120,
      sizeY: 130,
      weight: 4.85705304,
      code: 'B120130',
      description: '120x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 449.42,
      price: 898.84,
      sizeX: 130,
      sizeY: 130,
      weight: 5.251134422,
      code: 'B130130',
      description: '130x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 382.49,
      price: 764.98,
      sizeX: 150,
      sizeY: 130,
      weight: 6.039297187,
      code: 'B150130',
      description: '150x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 546.7,
      price: 1093.4,
      sizeX: 160,
      sizeY: 130,
      weight: 6.43337857,
      code: 'B160130',
      description: '160x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 481.85,
      price: 963.69,
      sizeX: 140,
      sizeY: 130,
      weight: 5.645215805,
      code: 'B140130',
      description: '140x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 504.09,
      price: 1008.18,
      sizeX: 200,
      sizeY: 130,
      weight: 8.0097041,
      code: 'B20130',
      description: '200x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 611.55,
      price: 1223.1,
      sizeX: 180,
      sizeY: 130,
      weight: 7.221541335,
      code: 'B180130',
      description: '180x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 579.12,
      price: 1158.25,
      sizeX: 170,
      sizeY: 130,
      weight: 6.827459952,
      code: 'B170130',
      description: '170x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 643.98,
      price: 1287.95,
      sizeX: 190,
      sizeY: 130,
      weight: 7.615622717,
      code: 'B190130',
      description: '190x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 708.83,
      price: 1417.65,
      sizeX: 210,
      sizeY: 130,
      weight: 8.403785482,
      code: 'B210130',
      description: '210x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 741.25,
      price: 1482.51,
      sizeX: 220,
      sizeY: 130,
      weight: 8.797866865,
      code: 'B220130',
      description: '220x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 773.68,
      price: 1547.36,
      sizeX: 230,
      sizeY: 130,
      weight: 9.191948247,
      code: 'B230130',
      description: '230x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 806.1,
      price: 1612.21,
      sizeX: 240,
      sizeY: 130,
      weight: 9.58602963,
      code: 'B240130',
      description: '240x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 625.69,
      price: 1251.37,
      sizeX: 250,
      sizeY: 130,
      weight: 9.980111012,
      code: 'B250130',
      description: '250x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 870.96,
      price: 1741.91,
      sizeX: 260,
      sizeY: 130,
      weight: 10.37419239,
      code: 'B260130',
      description: '260x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 903.38,
      price: 1806.77,
      sizeX: 270,
      sizeY: 130,
      weight: 10.76827378,
      code: 'B270130',
      description: '270x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 935.81,
      price: 1871.62,
      sizeX: 280,
      sizeY: 130,
      weight: 11.16235516,
      code: 'B280130',
      description: '280x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 968.23,
      price: 1936.47,
      sizeX: 290,
      sizeY: 130,
      weight: 11.55643654,
      code: 'B290130',
      description: '290x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 747.28,
      price: 1494.57,
      sizeX: 300,
      sizeY: 130,
      weight: 11.95051792,
      code: 'B300130',
      description: '300x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1033.09,
      price: 2066.17,
      sizeX: 310,
      sizeY: 130,
      weight: 12.34459931,
      code: 'B310130',
      description: '310x130',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 580.64,
      price: 1161.28,
      sizeX: 130,
      sizeY: 130,
      weight: 6.916128263,
      code: 'C130130',
      description: '130x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 623.82,
      price: 1247.63,
      sizeX: 140,
      sizeY: 130,
      weight: 7.438286095,
      code: 'C140130',
      description: '140x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 500.25,
      price: 1000.49,
      sizeX: 150,
      sizeY: 130,
      weight: 7.960443927,
      code: 'C150130',
      description: '150x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 710.17,
      price: 1420.35,
      sizeX: 160,
      sizeY: 130,
      weight: 8.482601759,
      code: 'C160130',
      description: '160x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 753.35,
      price: 1506.7,
      sizeX: 170,
      sizeY: 130,
      weight: 9.004759591,
      code: 'C170130',
      description: '170x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 796.53,
      price: 1593.06,
      sizeX: 180,
      sizeY: 130,
      weight: 9.526917422,
      code: 'C180130',
      description: '180x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 839.71,
      price: 1679.42,
      sizeX: 190,
      sizeY: 130,
      weight: 10.04907525,
      code: 'C190130',
      description: '190x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 662.17,
      price: 1324.33,
      sizeX: 200,
      sizeY: 130,
      weight: 10.57123309,
      code: 'C200130',
      description: '200x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 926.07,
      price: 1852.13,
      sizeX: 210,
      sizeY: 130,
      weight: 11.09339092,
      code: 'C210130',
      description: '210x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 969.24,
      price: 1938.49,
      sizeX: 220,
      sizeY: 130,
      weight: 11.61554875,
      code: 'C220130',
      description: '220x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1012.42,
      price: 2024.84,
      sizeX: 230,
      sizeY: 130,
      weight: 12.13770658,
      code: 'C230130',
      description: '230x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1055.6,
      price: 2111.2,
      sizeX: 240,
      sizeY: 130,
      weight: 12.65986441,
      code: 'C240130',
      description: '240x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 824.08,
      price: 1648.17,
      sizeX: 250,
      sizeY: 130,
      weight: 13.18202225,
      code: 'C250130',
      description: '250x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1141.96,
      price: 2283.92,
      sizeX: 260,
      sizeY: 130,
      weight: 13.70418008,
      code: 'C260130',
      description: '260x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1185.14,
      price: 2370.27,
      sizeX: 270,
      sizeY: 130,
      weight: 14.22633791,
      code: 'C270130',
      description: '270x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1228.31,
      price: 2456.63,
      sizeX: 280,
      sizeY: 130,
      weight: 14.74849574,
      code: 'C280130',
      description: '280x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1271.49,
      price: 2542.99,
      sizeX: 290,
      sizeY: 130,
      weight: 15.27065357,
      code: 'C290130',
      description: '290x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 986,
      price: 1972.01,
      sizeX: 300,
      sizeY: 130,
      weight: 15.7928114,
      code: 'C300130',
      description: '300x130',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 97.52,
      price: 195.03,
      sizeX: 10,
      sizeY: 130,
      weight: 0.77831073,
      code: 'D010130',
      description: '10x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 150.73,
      price: 301.47,
      sizeX: 20,
      sizeY: 130,
      weight: 1.428545012,
      code: 'D020130',
      description: '20x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 152.96,
      price: 305.93,
      sizeX: 30,
      sizeY: 130,
      weight: 2.078779293,
      code: 'D030130',
      description: '30x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 257.17,
      price: 514.34,
      sizeX: 40,
      sizeY: 130,
      weight: 2.729013574,
      code: 'D040130',
      description: '40x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 226.48,
      price: 452.97,
      sizeX: 50,
      sizeY: 130,
      weight: 3.379247855,
      code: 'D050130',
      description: '50x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 363.6,
      price: 727.21,
      sizeX: 60,
      sizeY: 130,
      weight: 4.029482136,
      code: 'D060130',
      description: '60x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 416.82,
      price: 833.64,
      sizeX: 70,
      sizeY: 130,
      weight: 4.679716417,
      code: 'D070130',
      description: '70x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 470.04,
      price: 940.08,
      sizeX: 80,
      sizeY: 130,
      weight: 5.329950699,
      code: 'D080130',
      description: '80x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 426.05,
      price: 852.1,
      sizeX: 100,
      sizeY: 130,
      weight: 6.630419261,
      code: 'D100130',
      description: '100x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 523.26,
      price: 1046.51,
      sizeX: 90,
      sizeY: 130,
      weight: 5.98018498,
      code: 'D090130',
      description: '90x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 629.69,
      price: 1259.38,
      sizeX: 110,
      sizeY: 130,
      weight: 7.280653542,
      code: 'D110130',
      description: '110x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 682.91,
      price: 1365.81,
      sizeX: 120,
      sizeY: 130,
      weight: 7.930887823,
      code: 'D120130',
      description: '120x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 825.18,
      price: 1650.36,
      sizeX: 200,
      sizeY: 130,
      weight: 13.13276207,
      code: 'D200130',
      description: '200x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 736.12,
      price: 1472.25,
      sizeX: 130,
      sizeY: 130,
      weight: 8.581122104,
      code: 'D130130',
      description: '130x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 789.34,
      price: 1578.68,
      sizeX: 140,
      sizeY: 130,
      weight: 9.231356385,
      code: 'D140130',
      description: '140x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 625.61,
      price: 1251.23,
      sizeX: 150,
      sizeY: 130,
      weight: 9.881590667,
      code: 'D150130',
      description: '150x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 895.78,
      price: 1791.55,
      sizeX: 160,
      sizeY: 130,
      weight: 10.53182495,
      code: 'D160130',
      description: '160x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 948.99,
      price: 1897.99,
      sizeX: 170,
      sizeY: 130,
      weight: 11.18205923,
      code: 'D170130',
      description: '170x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1002.21,
      price: 2004.42,
      sizeX: 180,
      sizeY: 130,
      weight: 11.83229351,
      code: 'D180130',
      description: '180x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1055.43,
      price: 2110.85,
      sizeX: 190,
      sizeY: 130,
      weight: 12.48252779,
      code: 'D190130',
      description: '190x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1161.86,
      price: 2323.72,
      sizeX: 210,
      sizeY: 130,
      weight: 13.78299635,
      code: 'D210130',
      description: '210x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1215.08,
      price: 2430.16,
      sizeX: 220,
      sizeY: 130,
      weight: 14.43323063,
      code: 'D220130',
      description: '220x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1268.3,
      price: 2536.59,
      sizeX: 230,
      sizeY: 130,
      weight: 15.08346492,
      code: 'D230130',
      description: '230x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1321.51,
      price: 2643.03,
      sizeX: 240,
      sizeY: 130,
      weight: 15.7336992,
      code: 'D240130',
      description: '240x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1024.74,
      price: 2049.49,
      sizeX: 250,
      sizeY: 130,
      weight: 16.38393348,
      code: 'D250130',
      description: '250x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1427.95,
      price: 2855.9,
      sizeX: 260,
      sizeY: 130,
      weight: 17.03416776,
      code: 'D260130',
      description: '260x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1481.17,
      price: 2962.33,
      sizeX: 270,
      sizeY: 130,
      weight: 17.68440204,
      code: 'D270130',
      description: '270x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1534.38,
      price: 3068.76,
      sizeX: 280,
      sizeY: 130,
      weight: 18.33463632,
      code: 'D280130',
      description: '280x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1587.6,
      price: 3175.2,
      sizeX: 290,
      sizeY: 130,
      weight: 18.9848706,
      code: 'D290130',
      description: '290x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1224.31,
      price: 2448.62,
      sizeX: 300,
      sizeY: 130,
      weight: 19.63510488,
      code: 'D300130',
      description: '300x130',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 353.8,
      price: 707.59,
      sizeX: 140,
      sizeY: 140,
      weight: 4.137854516,
      code: 'A140140',
      description: '140x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 377.55,
      price: 755.1,
      sizeX: 150,
      sizeY: 140,
      weight: 4.423563519,
      code: 'A150140',
      description: '150x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 401.31,
      price: 802.61,
      sizeX: 160,
      sizeY: 140,
      weight: 4.709272521,
      code: 'A160140',
      description: '160x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 425.06,
      price: 850.12,
      sizeX: 170,
      sizeY: 140,
      weight: 4.994981523,
      code: 'A170140',
      description: '170x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 448.81,
      price: 897.63,
      sizeX: 180,
      sizeY: 140,
      weight: 5.280690526,
      code: 'A180140',
      description: '180x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 472.57,
      price: 945.14,
      sizeX: 190,
      sizeY: 140,
      weight: 5.566399528,
      code: 'A190140',
      description: '190x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 496.32,
      price: 992.65,
      sizeX: 200,
      sizeY: 140,
      weight: 5.85210853,
      code: 'A200140',
      description: '200x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 520.08,
      price: 1040.16,
      sizeX: 210,
      sizeY: 140,
      weight: 6.137817533,
      code: 'A210140',
      description: '210x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 543.83,
      price: 1087.67,
      sizeX: 220,
      sizeY: 140,
      weight: 6.423526535,
      code: 'A220140',
      description: '220x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 567.59,
      price: 1135.18,
      sizeX: 230,
      sizeY: 140,
      weight: 6.709235537,
      code: 'A230140',
      description: '230x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 591.34,
      price: 1182.68,
      sizeX: 240,
      sizeY: 140,
      weight: 6.99494454,
      code: 'A240140',
      description: '240x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 461.32,
      price: 922.65,
      sizeX: 250,
      sizeY: 140,
      weight: 7.280653542,
      code: 'A250140',
      description: '250x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 638.85,
      price: 1277.7,
      sizeX: 260,
      sizeY: 140,
      weight: 7.566362544,
      code: 'A260140',
      description: '260x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 662.61,
      price: 1325.21,
      sizeX: 270,
      sizeY: 140,
      weight: 7.852071547,
      code: 'A270140',
      description: '270x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 686.36,
      price: 1372.72,
      sizeX: 280,
      sizeY: 140,
      weight: 8.137780549,
      code: 'A280140',
      description: '280x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 710.12,
      price: 1420.23,
      sizeX: 290,
      sizeY: 140,
      weight: 8.423489551,
      code: 'A290140',
      description: '290x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 733.87,
      price: 1467.74,
      sizeX: 300,
      sizeY: 140,
      weight: 8.709198554,
      code: 'A300140',
      description: '300x140',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 64.79,
      price: 129.58,
      sizeX: 10,
      sizeY: 140,
      weight: 0.56156597,
      code: 'B010140',
      description: '10x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 99.62,
      price: 199.25,
      sizeX: 20,
      sizeY: 140,
      weight: 0.985203456,
      code: 'B020140',
      description: '20x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 100.84,
      price: 201.69,
      sizeX: 30,
      sizeY: 140,
      weight: 1.408840943,
      code: 'B030140',
      description: '30x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 169.29,
      price: 338.58,
      sizeX: 40,
      sizeY: 140,
      weight: 1.832478429,
      code: 'B040140',
      description: '40x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 149.64,
      price: 299.29,
      sizeX: 50,
      sizeY: 140,
      weight: 2.256115915,
      code: 'B050140',
      description: '50x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 238.96,
      price: 477.92,
      sizeX: 60,
      sizeY: 140,
      weight: 2.679753401,
      code: 'B060140',
      description: '60x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 273.79,
      price: 547.59,
      sizeX: 70,
      sizeY: 140,
      weight: 3.103390887,
      code: 'B070140',
      description: '70x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 308.63,
      price: 617.25,
      sizeX: 80,
      sizeY: 140,
      weight: 3.527028374,
      code: 'B080140',
      description: '80x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 343.46,
      price: 686.92,
      sizeX: 90,
      sizeY: 140,
      weight: 3.95066586,
      code: 'B090160',
      description: '90x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 280.27,
      price: 560.54,
      sizeX: 100,
      sizeY: 140,
      weight: 4.374303346,
      code: 'B100140',
      description: '100x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 413.13,
      price: 826.26,
      sizeX: 110,
      sizeY: 140,
      weight: 4.797940832,
      code: 'B110140',
      description: '110x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 447.96,
      price: 895.93,
      sizeX: 120,
      sizeY: 140,
      weight: 5.221578318,
      code: 'B120140',
      description: '120x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 410.9,
      price: 821.8,
      sizeX: 150,
      sizeY: 140,
      weight: 6.492490777,
      code: 'B150140',
      description: '150x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 482.8,
      price: 965.6,
      sizeX: 130,
      sizeY: 140,
      weight: 5.645215805,
      code: 'B130140',
      description: '130x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 517.63,
      price: 1035.26,
      sizeX: 140,
      sizeY: 140,
      weight: 6.068853291,
      code: 'B140140',
      description: '140x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 587.3,
      price: 1174.6,
      sizeX: 160,
      sizeY: 140,
      weight: 6.916128263,
      code: 'B160140',
      description: '160x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 541.53,
      price: 1083.05,
      sizeX: 200,
      sizeY: 140,
      weight: 8.610678208,
      code: 'B200140',
      description: '200x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 656.97,
      price: 1313.94,
      sizeX: 180,
      sizeY: 140,
      weight: 7.763403236,
      code: 'B180140',
      description: '180x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 622.13,
      price: 1244.27,
      sizeX: 170,
      sizeY: 140,
      weight: 7.339765749,
      code: 'B170140',
      description: '170x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 691.8,
      price: 1383.6,
      sizeX: 190,
      sizeY: 140,
      weight: 8.187040722,
      code: 'B190140',
      description: '190x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 761.47,
      price: 1522.94,
      sizeX: 210,
      sizeY: 140,
      weight: 9.034315694,
      code: 'B210140',
      description: '210x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 796.3,
      price: 1592.61,
      sizeX: 220,
      sizeY: 140,
      weight: 9.45795318,
      code: 'B220140',
      description: '220x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 831.14,
      price: 1662.28,
      sizeX: 230,
      sizeY: 140,
      weight: 9.881590667,
      code: 'B230140',
      description: '230x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 865.97,
      price: 1731.94,
      sizeX: 240,
      sizeY: 140,
      weight: 10.30522815,
      code: 'B240140',
      description: '240x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 672.15,
      price: 1344.31,
      sizeX: 250,
      sizeY: 140,
      weight: 10.72886564,
      code: 'B250140',
      description: '250x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 935.64,
      price: 1871.28,
      sizeX: 260,
      sizeY: 140,
      weight: 11.15250313,
      code: 'B260140',
      description: '260x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 970.47,
      price: 1940.95,
      sizeX: 270,
      sizeY: 140,
      weight: 11.57614061,
      code: 'B270140',
      description: '270x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1005.31,
      price: 2010.62,
      sizeX: 280,
      sizeY: 140,
      weight: 11.9997781,
      code: 'B280140',
      description: '280x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1040.14,
      price: 2080.28,
      sizeX: 290,
      sizeY: 140,
      weight: 12.42341558,
      code: 'B290140',
      description: '290x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 802.78,
      price: 1605.56,
      sizeX: 300,
      sizeY: 140,
      weight: 12.84705307,
      code: 'B300140',
      description: '300x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1109.81,
      price: 2219.62,
      sizeX: 310,
      sizeY: 140,
      weight: 13.27069056,
      code: 'B310140',
      description: '310x140',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 670.21,
      price: 1340.41,
      sizeX: 140,
      sizeY: 140,
      weight: 7.999852065,
      code: 'C140140',
      description: '140x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 537.45,
      price: 1074.89,
      sizeX: 150,
      sizeY: 140,
      weight: 8.561418035,
      code: 'C150140',
      description: '150x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 762.98,
      price: 1525.97,
      sizeX: 160,
      sizeY: 140,
      weight: 9.122984005,
      code: 'C160140',
      description: '160x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 809.37,
      price: 1618.75,
      sizeX: 170,
      sizeY: 140,
      weight: 9.684549975,
      code: 'C170140',
      description: '170x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 855.76,
      price: 1711.53,
      sizeX: 180,
      sizeY: 140,
      weight: 10.24611595,
      code: 'C180140',
      description: '180x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 902.15,
      price: 1804.3,
      sizeX: 190,
      sizeY: 140,
      weight: 10.80768192,
      code: 'C190140',
      description: '190x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 711.41,
      price: 1422.81,
      sizeX: 200,
      sizeY: 140,
      weight: 11.36924789,
      code: 'C200140',
      description: '200x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 994.93,
      price: 1989.86,
      sizeX: 210,
      sizeY: 140,
      weight: 11.93081386,
      code: 'C210140',
      description: '210x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1041.32,
      price: 2082.64,
      sizeX: 220,
      sizeY: 140,
      weight: 12.49237983,
      code: 'C220140',
      description: '220x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1087.71,
      price: 2175.42,
      sizeX: 230,
      sizeY: 140,
      weight: 13.0539458,
      code: 'C230140',
      description: '230x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1134.1,
      price: 2268.2,
      sizeX: 240,
      sizeY: 140,
      weight: 13.61551177,
      code: 'C240140',
      description: '240x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 885.37,
      price: 1770.73,
      sizeX: 250,
      sizeY: 140,
      weight: 14.17707774,
      code: 'C250140',
      description: '250x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1226.88,
      price: 2453.75,
      sizeX: 260,
      sizeY: 140,
      weight: 14.73864371,
      code: 'C260140',
      description: '260x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1273.27,
      price: 2546.53,
      sizeX: 270,
      sizeY: 140,
      weight: 15.30020968,
      code: 'C270140',
      description: '270x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1319.66,
      price: 2639.31,
      sizeX: 280,
      sizeY: 140,
      weight: 15.86177565,
      code: 'C280140',
      description: '280x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1366.05,
      price: 2732.09,
      sizeX: 290,
      sizeY: 140,
      weight: 16.42334162,
      code: 'C290140',
      description: '290x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1059.33,
      price: 2118.65,
      sizeX: 300,
      sizeY: 140,
      weight: 16.98490759,
      code: 'C300140',
      description: '300x140',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 104.87,
      price: 209.74,
      sizeX: 10,
      sizeY: 140,
      weight: 0.837422938,
      code: 'D010140',
      description: '10x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 162.1,
      price: 324.2,
      sizeX: 20,
      sizeY: 140,
      weight: 1.536917392,
      code: 'D020140',
      description: '20x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 164.5,
      price: 329,
      sizeX: 30,
      sizeY: 140,
      weight: 2.236411846,
      code: 'D030140',
      description: '30x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 276.56,
      price: 553.13,
      sizeX: 40,
      sizeY: 140,
      weight: 2.9359063,
      code: 'D040140',
      description: '40x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 243.57,
      price: 487.13,
      sizeX: 50,
      sizeY: 140,
      weight: 3.635400754,
      code: 'D050140',
      description: '50x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 391.03,
      price: 782.05,
      sizeX: 60,
      sizeY: 140,
      weight: 4.334895208,
      code: 'D060140',
      description: '60x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 448.26,
      price: 896.51,
      sizeX: 70,
      sizeY: 140,
      weight: 5.034389662,
      code: 'D070140',
      description: '70x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 505.49,
      price: 1010.97,
      sizeX: 80,
      sizeY: 140,
      weight: 5.733884116,
      code: 'D080140',
      description: '80x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 562.72,
      price: 1125.44,
      sizeX: 90,
      sizeY: 140,
      weight: 6.43337857,
      code: 'D090140',
      description: '90x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 458.18,
      price: 916.36,
      sizeX: 100,
      sizeY: 140,
      weight: 7.132873024,
      code: 'D100140',
      description: '100x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 677.18,
      price: 1354.36,
      sizeX: 110,
      sizeY: 140,
      weight: 7.832367478,
      code: 'D110140',
      description: '110x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 734.41,
      price: 1468.82,
      sizeX: 120,
      sizeY: 140,
      weight: 8.531861932,
      code: 'D120140',
      description: '120x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 791.64,
      price: 1583.28,
      sizeX: 130,
      sizeY: 140,
      weight: 9.231356385,
      code: 'D130140',
      description: '130x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 887.41,
      price: 1774.82,
      sizeX: 200,
      sizeY: 140,
      weight: 14.12781756,
      code: 'D200140',
      description: '200x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 848.87,
      price: 1697.74,
      sizeX: 140,
      sizeY: 140,
      weight: 9.930850839,
      code: 'D140140',
      description: '140x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 679.58,
      price: 1359.15,
      sizeX: 150,
      sizeY: 140,
      weight: 10.63034529,
      code: 'D150140',
      description: '150x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 963.33,
      price: 1926.67,
      sizeX: 160,
      sizeY: 140,
      weight: 11.32983975,
      code: 'D160140',
      description: '160x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1020.56,
      price: 2041.13,
      sizeX: 170,
      sizeY: 140,
      weight: 12.0293342,
      code: 'D170140',
      description: '170x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1077.8,
      price: 2155.59,
      sizeX: 180,
      sizeY: 140,
      weight: 12.72882866,
      code: 'D180140',
      description: '180x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1135.03,
      price: 2270.05,
      sizeX: 190,
      sizeY: 140,
      weight: 13.42832311,
      code: 'D190140',
      description: '190x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1249.49,
      price: 2498.98,
      sizeX: 210,
      sizeY: 140,
      weight: 14.82731202,
      code: 'D210140',
      description: '210x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1306.72,
      price: 2613.44,
      sizeX: 220,
      sizeY: 140,
      weight: 15.52680647,
      code: 'D220140',
      description: '220x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1363.95,
      price: 2727.9,
      sizeX: 230,
      sizeY: 140,
      weight: 16.22630093,
      code: 'D230140',
      description: '230x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1421.18,
      price: 2842.36,
      sizeX: 240,
      sizeY: 140,
      weight: 16.92579538,
      code: 'D240140',
      description: '240x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1102.03,
      price: 2204.05,
      sizeX: 250,
      sizeY: 140,
      weight: 17.62528983,
      code: 'D250140',
      description: '250x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1535.64,
      price: 3071.28,
      sizeX: 260,
      sizeY: 140,
      weight: 18.32478429,
      code: 'D260140',
      description: '260x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1592.87,
      price: 3185.75,
      sizeX: 270,
      sizeY: 140,
      weight: 19.02427874,
      code: 'D270140',
      description: '270x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1650.1,
      price: 3300.21,
      sizeX: 280,
      sizeY: 140,
      weight: 19.7237732,
      code: 'D280140',
      description: '280x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1707.33,
      price: 3414.67,
      sizeX: 290,
      sizeY: 140,
      weight: 20.42326765,
      code: 'D290140',
      description: '290x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1316.64,
      price: 2633.29,
      sizeX: 300,
      sizeY: 140,
      weight: 21.1227621,
      code: 'D300140',
      description: '300x140',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 302.3,
      price: 604.6,
      sizeX: 150,
      sizeY: 150,
      weight: 4.72897659,
      code: 'A150150',
      description: '150x150',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 428.43,
      price: 856.86,
      sizeX: 160,
      sizeY: 150,
      weight: 5.034389662,
      code: 'A160150',
      description: '160x150',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 453.79,
      price: 907.58,
      sizeX: 170,
      sizeY: 150,
      weight: 5.339802733,
      code: 'A170150',
      description: '170x150',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 479.15,
      price: 958.3,
      sizeX: 180,
      sizeY: 150,
      weight: 5.645215805,
      code: 'A180150',
      description: '180x150',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 504.51,
      price: 1009.02,
      sizeX: 190,
      sizeY: 150,
      weight: 5.950628876,
      code: 'A190150',
      description: '190x150',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 397.4,
      price: 794.8,
      sizeX: 200,
      sizeY: 150,
      weight: 6.256041947,
      code: 'A200150',
      description: '200x150',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 555.23,
      price: 1110.46,
      sizeX: 210,
      sizeY: 150,
      weight: 6.561455019,
      code: 'A210150',
      description: '210x150',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 580.59,
      price: 1161.18,
      sizeX: 220,
      sizeY: 150,
      weight: 6.86686809,
      code: 'A220150',
      description: '220x150',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 605.95,
      price: 1211.9,
      sizeX: 230,
      sizeY: 150,
      weight: 7.172281162,
      code: 'A230150',
      description: '230x150',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 631.31,
      price: 1262.62,
      sizeX: 240,
      sizeY: 150,
      weight: 7.477694233,
      code: 'A240150',
      description: '240x150',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 492.5,
      price: 985,
      sizeX: 250,
      sizeY: 150,
      weight: 7.783107305,
      code: 'A250150',
      description: '250x150',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 682.03,
      price: 1364.06,
      sizeX: 260,
      sizeY: 150,
      weight: 8.088520376,
      code: 'A260150',
      description: '260x150',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 707.39,
      price: 1414.78,
      sizeX: 270,
      sizeY: 150,
      weight: 8.393933448,
      code: 'A270150',
      description: '270x150',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 732.75,
      price: 1465.5,
      sizeX: 280,
      sizeY: 150,
      weight: 8.699346519,
      code: 'A280150',
      description: '280x150',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 758.11,
      price: 1516.22,
      sizeX: 290,
      sizeY: 150,
      weight: 9.004759591,
      code: 'A290150',
      description: '290x150',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 587.6,
      price: 1175.21,
      sizeX: 300,
      sizeY: 150,
      weight: 9.310172662,
      code: 'A300150',
      description: '300x150',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 51.95,
      price: 103.9,
      sizeX: 10,
      sizeY: 150,
      weight: 0.600974108,
      code: 'B010150',
      description: '10x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 106.51,
      price: 213.02,
      sizeX: 20,
      sizeY: 150,
      weight: 1.054167698,
      code: 'B020150',
      description: '20x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 93.44,
      price: 186.88,
      sizeX: 30,
      sizeY: 150,
      weight: 1.507361288,
      code: 'B030150',
      description: '30x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 135.75,
      price: 271.49,
      sizeX: 40,
      sizeY: 150,
      weight: 1.960554878,
      code: 'B040150',
      description: '40x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 138.66,
      price: 277.32,
      sizeX: 50,
      sizeY: 150,
      weight: 2.413748468,
      code: 'B050150',
      description: '50x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 191.61,
      price: 383.22,
      sizeX: 60,
      sizeY: 150,
      weight: 2.866942058,
      code: 'B060150',
      description: '60x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 219.54,
      price: 439.08,
      sizeX: 70,
      sizeY: 150,
      weight: 3.320135648,
      code: 'B070150',
      description: '70x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 247.47,
      price: 494.95,
      sizeX: 80,
      sizeY: 150,
      weight: 3.773329238,
      code: 'B080150',
      description: '80x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 275.4,
      price: 550.81,
      sizeX: 90,
      sizeY: 150,
      weight: 4.226522828,
      code: 'B090150',
      description: '90x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 259.7,
      price: 519.39,
      sizeX: 100,
      sizeY: 150,
      weight: 4.679716417,
      code: 'B100150',
      description: '100x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 331.27,
      price: 662.54,
      sizeX: 110,
      sizeY: 150,
      weight: 5.132910007,
      code: 'B110150',
      description: '110x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 359.2,
      price: 718.4,
      sizeX: 120,
      sizeY: 150,
      weight: 5.586103597,
      code: 'B120150',
      description: '120x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 387.13,
      price: 774.26,
      sizeX: 130,
      sizeY: 150,
      weight: 6.039297187,
      code: 'B130150',
      description: '130x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 415.06,
      price: 830.13,
      sizeX: 140,
      sizeY: 150,
      weight: 6.492490777,
      code: 'B140150',
      description: '140x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 380.73,
      price: 761.47,
      sizeX: 150,
      sizeY: 150,
      weight: 6.945684367,
      code: 'B150150',
      description: '150x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 501.77,
      price: 1003.54,
      sizeX: 200,
      sizeY: 150,
      weight: 9.211652316,
      code: 'B200150',
      description: '200x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 470.93,
      price: 941.85,
      sizeX: 160,
      sizeY: 150,
      weight: 7.398877957,
      code: 'B160150',
      description: '160x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 526.79,
      price: 1053.58,
      sizeX: 180,
      sizeY: 150,
      weight: 8.305265137,
      code: 'B180150',
      description: '180x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 498.86,
      price: 997.72,
      sizeX: 170,
      sizeY: 150,
      weight: 7.852071547,
      code: 'B170150',
      description: '170x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 554.72,
      price: 1109.44,
      sizeX: 190,
      sizeY: 150,
      weight: 8.758458726,
      code: 'B190150',
      description: '190x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 610.58,
      price: 1221.17,
      sizeX: 210,
      sizeY: 150,
      weight: 9.664845906,
      code: 'B210150',
      description: '210x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 638.52,
      price: 1277.03,
      sizeX: 220,
      sizeY: 150,
      weight: 10.1180395,
      code: 'B220150',
      description: '220x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 666.45,
      price: 1332.9,
      sizeX: 230,
      sizeY: 150,
      weight: 10.57123309,
      code: 'B230150',
      description: '230x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 694.38,
      price: 1388.76,
      sizeX: 240,
      sizeY: 150,
      weight: 11.02442668,
      code: 'B240150',
      description: '240x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 622.81,
      price: 1245.61,
      sizeX: 250,
      sizeY: 150,
      weight: 11.47762027,
      code: 'B250150',
      description: '250x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 750.24,
      price: 1500.49,
      sizeX: 260,
      sizeY: 150,
      weight: 11.93081386,
      code: 'B260150',
      description: '260x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 778.17,
      price: 1556.35,
      sizeX: 270,
      sizeY: 150,
      weight: 12.38400745,
      code: 'B270150',
      description: '270x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 806.11,
      price: 1612.21,
      sizeX: 280,
      sizeY: 150,
      weight: 12.83720104,
      code: 'B280150',
      description: '280x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 834.04,
      price: 1668.08,
      sizeX: 290,
      sizeY: 150,
      weight: 13.29039463,
      code: 'B290150',
      description: '290x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 743.84,
      price: 1487.69,
      sizeX: 300,
      sizeY: 150,
      weight: 13.74358822,
      code: 'B300150',
      description: '300x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 889.9,
      price: 1779.8,
      sizeX: 310,
      sizeY: 150,
      weight: 14.19678181,
      code: 'B310150',
      description: '310x150',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 574.65,
      price: 1149.29,
      sizeX: 150,
      sizeY: 150,
      weight: 9.162392144,
      code: 'C150150',
      description: '150x150',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 611.85,
      price: 1223.69,
      sizeX: 160,
      sizeY: 150,
      weight: 9.763366252,
      code: 'C160150',
      description: '160x150',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 649.05,
      price: 1298.09,
      sizeX: 170,
      sizeY: 150,
      weight: 10.36434036,
      code: 'C170150',
      description: '170x150',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 686.25,
      price: 1372.49,
      sizeX: 180,
      sizeY: 150,
      weight: 10.96531447,
      code: 'C180150',
      description: '180x150',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 723.45,
      price: 1446.89,
      sizeX: 190,
      sizeY: 150,
      weight: 11.56628858,
      code: 'C190150',
      description: '190x150',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 659.23,
      price: 1318.45,
      sizeX: 200,
      sizeY: 150,
      weight: 12.16726269,
      code: 'C200150',
      description: '200x150',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 797.85,
      price: 1595.69,
      sizeX: 210,
      sizeY: 150,
      weight: 12.76823679,
      code: 'C210150',
      description: '210x150',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 835.05,
      price: 1670.09,
      sizeX: 220,
      sizeY: 150,
      weight: 13.3692109,
      code: 'C220150',
      description: '220x150',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 872.25,
      price: 1744.49,
      sizeX: 230,
      sizeY: 150,
      weight: 13.97018501,
      code: 'C230150',
      description: '230x150',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 909.45,
      price: 1818.9,
      sizeX: 240,
      sizeY: 150,
      weight: 14.57115912,
      code: 'C240150',
      description: '240x150',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 820.43,
      price: 1640.86,
      sizeX: 250,
      sizeY: 150,
      weight: 15.17213323,
      code: 'C250150',
      description: '250x150',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 983.85,
      price: 1967.7,
      sizeX: 260,
      sizeY: 150,
      weight: 15.77310734,
      code: 'C260150',
      description: '260x150',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1021.05,
      price: 2042.1,
      sizeX: 270,
      sizeY: 150,
      weight: 16.37408144,
      code: 'C270150',
      description: '270x150',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1058.25,
      price: 2116.5,
      sizeX: 280,
      sizeY: 150,
      weight: 16.97505555,
      code: 'C280150',
      description: '280x150',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1095.45,
      price: 2190.9,
      sizeX: 290,
      sizeY: 150,
      weight: 17.57602966,
      code: 'C290150',
      description: '290x150',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 981.63,
      price: 1963.26,
      sizeX: 300,
      sizeY: 150,
      weight: 18.17700377,
      code: 'C300150',
      description: '300x150',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 76.91,
      price: 153.83,
      sizeX: 10,
      sizeY: 150,
      weight: 0.896535145,
      code: 'D010150',
      description: '10x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 122.85,
      price: 245.69,
      sizeX: 20,
      sizeY: 150,
      weight: 1.645289772,
      code: 'D020150',
      description: '20x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 146.48,
      price: 292.96,
      sizeX: 30,
      sizeY: 150,
      weight: 2.394044399,
      code: 'D030150',
      description: '30x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 214.71,
      price: 429.42,
      sizeX: 40,
      sizeY: 150,
      weight: 3.142799026,
      code: 'D040150',
      description: '40x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 226.2,
      price: 452.4,
      sizeX: 50,
      sizeY: 150,
      weight: 3.891553652,
      code: 'D050150',
      description: '50x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 306.58,
      price: 613.16,
      sizeX: 60,
      sizeY: 150,
      weight: 4.640308279,
      code: 'D060150',
      description: '60x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 352.51,
      price: 705.02,
      sizeX: 70,
      sizeY: 150,
      weight: 5.389062906,
      code: 'D070150',
      description: '70x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 398.45,
      price: 796.89,
      sizeX: 80,
      sizeY: 150,
      weight: 6.137817533,
      code: 'D080150',
      description: '80x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 444.38,
      price: 888.76,
      sizeX: 90,
      sizeY: 150,
      weight: 6.886572159,
      code: 'D090150',
      description: '90x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 425.5,
      price: 851.01,
      sizeX: 100,
      sizeY: 150,
      weight: 7.635326786,
      code: 'D100150',
      description: '100x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 536.25,
      price: 1072.49,
      sizeX: 110,
      sizeY: 150,
      weight: 8.384081413,
      code: 'D110150',
      description: '110x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 582.18,
      price: 1164.36,
      sizeX: 120,
      sizeY: 150,
      weight: 9.13283604,
      code: 'D120150',
      description: '120x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 628.11,
      price: 1256.22,
      sizeX: 130,
      sizeY: 150,
      weight: 9.881590667,
      code: 'D130150',
      description: '130x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 823.03,
      price: 1646.05,
      sizeX: 200,
      sizeY: 150,
      weight: 15.12287305,
      code: 'D200150',
      description: '200x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 674.05,
      price: 1348.09,
      sizeX: 140,
      sizeY: 150,
      weight: 10.63034529,
      code: 'D140150',
      description: '140x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 623.98,
      price: 1247.96,
      sizeX: 150,
      sizeY: 150,
      weight: 11.37909992,
      code: 'D150150',
      description: '150x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 765.91,
      price: 1531.82,
      sizeX: 160,
      sizeY: 150,
      weight: 12.12785455,
      code: 'D160150',
      description: '160x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 811.85,
      price: 1623.69,
      sizeX: 170,
      sizeY: 150,
      weight: 12.87660917,
      code: 'D170150',
      description: '170x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 857.89,
      price: 1715.78,
      sizeX: 180,
      sizeY: 150,
      weight: 13.6253638,
      code: 'D180150',
      description: '180x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 903.71,
      price: 1807.42,
      sizeX: 190,
      sizeY: 150,
      weight: 14.37411843,
      code: 'D190150',
      description: '190x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 995.58,
      price: 1991.16,
      sizeX: 210,
      sizeY: 150,
      weight: 15.87162768,
      code: 'D210150',
      description: '210x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1041.51,
      price: 2083.02,
      sizeX: 220,
      sizeY: 150,
      weight: 16.62038231,
      code: 'D220150',
      description: '220x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1087.45,
      price: 2174.89,
      sizeX: 230,
      sizeY: 150,
      weight: 17.36913693,
      code: 'D230150',
      description: '230x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1133.38,
      price: 2266.76,
      sizeX: 240,
      sizeY: 150,
      weight: 18.11789156,
      code: 'D240150',
      description: '240x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1023.41,
      price: 2046.82,
      sizeX: 250,
      sizeY: 150,
      weight: 18.86664619,
      code: 'D250150',
      description: '250x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1225.24,
      price: 2450.49,
      sizeX: 260,
      sizeY: 150,
      weight: 19.61540081,
      code: 'D260150',
      description: '260x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1271.18,
      price: 2542.36,
      sizeX: 270,
      sizeY: 150,
      weight: 20.36415544,
      code: 'D270150',
      description: '270x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1317.11,
      price: 2634.22,
      sizeX: 280,
      sizeY: 150,
      weight: 21.11291007,
      code: 'D280150',
      description: '280x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1363.04,
      price: 2726.09,
      sizeX: 290,
      sizeY: 150,
      weight: 21.8616647,
      code: 'D290150',
      description: '290x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1222.71,
      price: 2445.42,
      sizeX: 300,
      sizeY: 150,
      weight: 22.61041932,
      code: 'D300150',
      description: '300x150',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 452.86,
      price: 905.71,
      sizeX: 160,
      sizeY: 160,
      weight: 5.359506802,
      code: 'A160160',
      description: '160x160',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 479.82,
      price: 959.64,
      sizeX: 170,
      sizeY: 160,
      weight: 5.684623943,
      code: 'A170160',
      description: '170x160',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 506.79,
      price: 1013.58,
      sizeX: 180,
      sizeY: 160,
      weight: 6.009741083,
      code: 'A180160',
      description: '180x160',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 533.75,
      price: 1067.51,
      sizeX: 190,
      sizeY: 160,
      weight: 6.334858224,
      code: 'A190160',
      description: '190x160',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 563.42,
      price: 1126.83,
      sizeX: 200,
      sizeY: 160,
      weight: 6.659975365,
      code: 'A200160',
      description: '200x160',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 590.38,
      price: 1180.76,
      sizeX: 210,
      sizeY: 160,
      weight: 6.985092505,
      code: 'A210160',
      description: '210x160',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 617.35,
      price: 1234.69,
      sizeX: 220,
      sizeY: 160,
      weight: 7.310209646,
      code: 'A220160',
      description: '220x160',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 644.31,
      price: 1288.62,
      sizeX: 230,
      sizeY: 160,
      weight: 7.635326786,
      code: 'A230160',
      description: '230x160',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 671.28,
      price: 1342.55,
      sizeX: 240,
      sizeY: 160,
      weight: 7.960443927,
      code: 'A240160',
      description: '240x160',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 523.68,
      price: 1047.36,
      sizeX: 250,
      sizeY: 160,
      weight: 8.285561067,
      code: 'A250160',
      description: '250x160',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 725.21,
      price: 1450.42,
      sizeX: 260,
      sizeY: 160,
      weight: 8.610678208,
      code: 'A260160',
      description: '260x160',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 752.17,
      price: 1504.35,
      sizeX: 270,
      sizeY: 160,
      weight: 8.935795349,
      code: 'A270160',
      description: '270x160',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 779.14,
      price: 1558.28,
      sizeX: 280,
      sizeY: 160,
      weight: 9.260912489,
      code: 'A280160',
      description: '280x160',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 806.1,
      price: 1612.21,
      sizeX: 290,
      sizeY: 160,
      weight: 9.58602963,
      code: 'A290160',
      description: '290x160',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 624.8,
      price: 1249.61,
      sizeX: 300,
      sizeY: 160,
      weight: 9.91114677,
      code: 'A300160',
      description: '300x160',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 73.75,
      price: 147.5,
      sizeX: 10,
      sizeY: 160,
      weight: 0.640382247,
      code: 'B010160',
      description: '10x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 113.4,
      price: 226.8,
      sizeX: 20,
      sizeY: 160,
      weight: 1.12313194,
      code: 'B020160',
      description: '20x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 114.79,
      price: 229.57,
      sizeX: 30,
      sizeY: 160,
      weight: 1.605881634,
      code: 'B030160',
      description: '30x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 192.7,
      price: 385.4,
      sizeX: 40,
      sizeY: 160,
      weight: 2.088631327,
      code: 'B040160',
      description: '40x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 170.34,
      price: 340.67,
      sizeX: 50,
      sizeY: 160,
      weight: 2.571381021,
      code: 'B050160',
      description: '50x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 272,
      price: 544,
      sizeX: 60,
      sizeY: 160,
      weight: 3.054130715,
      code: 'B060160',
      description: '60x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 311.65,
      price: 623.3,
      sizeX: 70,
      sizeY: 160,
      weight: 3.536880408,
      code: 'B070160',
      description: '70x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 351.3,
      price: 702.6,
      sizeX: 80,
      sizeY: 160,
      weight: 4.019630102,
      code: 'B080160',
      description: '80x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 319.03,
      price: 638.05,
      sizeX: 100,
      sizeY: 160,
      weight: 4.985129489,
      code: 'B100160',
      description: '100x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 470.25,
      price: 940.5,
      sizeX: 110,
      sizeY: 160,
      weight: 5.467879182,
      code: 'B110160',
      description: '110x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 509.9,
      price: 1019.8,
      sizeX: 120,
      sizeY: 160,
      weight: 5.950628876,
      code: 'B120160',
      description: '120x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 549.55,
      price: 1099.11,
      sizeX: 130,
      sizeY: 160,
      weight: 6.43337857,
      code: 'B130160',
      description: '130x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 616.4,
      price: 1232.81,
      sizeX: 200,
      sizeY: 160,
      weight: 9.812626425,
      code: 'B200160',
      description: '200x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 589.2,
      price: 1178.41,
      sizeX: 140,
      sizeY: 160,
      weight: 6.916128263,
      code: 'B140160',
      description: '140x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 467.71,
      price: 935.43,
      sizeX: 150,
      sizeY: 160,
      weight: 7.398877957,
      code: 'B150160',
      description: '150x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 668.5,
      price: 1337.01,
      sizeX: 160,
      sizeY: 160,
      weight: 7.88162765,
      code: 'B160160',
      description: '160x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 747.8,
      price: 1495.61,
      sizeX: 180,
      sizeY: 160,
      weight: 8.847127038,
      code: 'B180160',
      description: '180x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 708.15,
      price: 1416.31,
      sizeX: 170,
      sizeY: 160,
      weight: 8.364377344,
      code: 'B170160',
      description: '170x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 787.45,
      price: 1574.91,
      sizeX: 190,
      sizeY: 160,
      weight: 9.329876731,
      code: 'B190160',
      description: '190x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 866.76,
      price: 1733.51,
      sizeX: 210,
      sizeY: 160,
      weight: 10.29537612,
      code: 'B210160',
      description: '210x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 906.41,
      price: 1812.81,
      sizeX: 220,
      sizeY: 160,
      weight: 10.77812581,
      code: 'B220160',
      description: '220x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 946.06,
      price: 1892.11,
      sizeX: 230,
      sizeY: 160,
      weight: 11.26087551,
      code: 'B230160',
      description: '230x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 985.71,
      price: 1971.41,
      sizeX: 240,
      sizeY: 160,
      weight: 11.7436252,
      code: 'B240160',
      description: '240x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 765.09,
      price: 1530.18,
      sizeX: 250,
      sizeY: 160,
      weight: 12.22637489,
      code: 'B250160',
      description: '250x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1065.01,
      price: 2130.01,
      sizeX: 260,
      sizeY: 160,
      weight: 12.70912459,
      code: 'B260160',
      description: '260x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1104.66,
      price: 2209.32,
      sizeX: 270,
      sizeY: 160,
      weight: 13.19187428,
      code: 'B270160',
      description: '270x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1144.31,
      price: 2288.62,
      sizeX: 280,
      sizeY: 160,
      weight: 13.67462397,
      code: 'B280160',
      description: '280x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1183.96,
      price: 2367.92,
      sizeX: 290,
      sizeY: 160,
      weight: 14.15737367,
      code: 'B290160',
      description: '290x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 913.78,
      price: 1827.56,
      sizeX: 300,
      sizeY: 160,
      weight: 14.64012336,
      code: 'B300160',
      description: '300x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1263.26,
      price: 2526.52,
      sizeX: 310,
      sizeY: 160,
      weight: 15.12287305,
      code: 'B310160',
      description: '310x160',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 868.61,
      price: 1737.21,
      sizeX: 160,
      sizeY: 160,
      weight: 10.4037485,
      code: 'C160160',
      description: '160x160',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 921.42,
      price: 1842.83,
      sizeX: 170,
      sizeY: 160,
      weight: 11.04413075,
      code: 'C170160',
      description: '170x160',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 974.23,
      price: 1948.46,
      sizeX: 180,
      sizeY: 160,
      weight: 11.68451299,
      code: 'C180160',
      description: '180x160',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1027.04,
      price: 2054.08,
      sizeX: 190,
      sizeY: 160,
      weight: 12.32489524,
      code: 'C190160',
      description: '190x160',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 809.89,
      price: 1619.78,
      sizeX: 200,
      sizeY: 160,
      weight: 12.96527748,
      code: 'C200160',
      description: '200x160',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1132.66,
      price: 2265.32,
      sizeX: 210,
      sizeY: 160,
      weight: 13.60565973,
      code: 'C210160',
      description: '210x160',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1185.47,
      price: 2370.95,
      sizeX: 220,
      sizeY: 160,
      weight: 14.24604198,
      code: 'C220160',
      description: '220x160',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1238.28,
      price: 2476.57,
      sizeX: 230,
      sizeY: 160,
      weight: 14.88642422,
      code: 'C230160',
      description: '230x160',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1291.09,
      price: 2582.19,
      sizeX: 240,
      sizeY: 160,
      weight: 15.52680647,
      code: 'C240160',
      description: '240x160',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1007.93,
      price: 2015.86,
      sizeX: 250,
      sizeY: 160,
      weight: 16.16718872,
      code: 'C250160',
      description: '250x160',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1396.72,
      price: 2793.43,
      sizeX: 260,
      sizeY: 160,
      weight: 16.80757096,
      code: 'C260160',
      description: '260x160',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1449.53,
      price: 2899.06,
      sizeX: 270,
      sizeY: 160,
      weight: 17.44795321,
      code: 'C270160',
      description: '270x160',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1502.34,
      price: 3004.68,
      sizeX: 280,
      sizeY: 160,
      weight: 18.08833546,
      code: 'C280160',
      description: '280x160',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1555.15,
      price: 3110.3,
      sizeX: 290,
      sizeY: 160,
      weight: 18.7287177,
      code: 'C290160',
      description: '290x160',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1205.97,
      price: 2411.94,
      sizeX: 300,
      sizeY: 160,
      weight: 19.36909995,
      code: 'C300160',
      description: '300x160',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 119.58,
      price: 239.16,
      sizeX: 10,
      sizeY: 160,
      weight: 0.955647353,
      code: 'D010160',
      description: '10x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 184.84,
      price: 369.68,
      sizeX: 20,
      sizeY: 160,
      weight: 1.753662152,
      code: 'D020160',
      description: '20x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 250.1,
      price: 500.19,
      sizeX: 30,
      sizeY: 160,
      weight: 2.551676952,
      code: 'D030160',
      description: '30x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 315.35,
      price: 630.71,
      sizeX: 40,
      sizeY: 160,
      weight: 3.349691751,
      code: 'D040160',
      description: '40x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 277.73,
      price: 555.45,
      sizeX: 50,
      sizeY: 160,
      weight: 4.147706551,
      code: 'D050160',
      description: '50x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 445.87,
      price: 891.74,
      sizeX: 60,
      sizeY: 160,
      weight: 4.945721351,
      code: 'D060160',
      description: '60x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 511.13,
      price: 1022.26,
      sizeX: 70,
      sizeY: 160,
      weight: 5.74373615,
      code: 'D070160',
      description: '70x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 576.39,
      price: 1152.77,
      sizeX: 80,
      sizeY: 160,
      weight: 6.54175095,
      code: 'D080160',
      description: '80x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 641.64,
      price: 1283.29,
      sizeX: 90,
      sizeY: 160,
      weight: 7.339765749,
      code: 'D090160',
      description: '90x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 522.44,
      price: 1044.89,
      sizeX: 100,
      sizeY: 160,
      weight: 8.137780549,
      code: 'D100160',
      description: '100x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 772.16,
      price: 1544.32,
      sizeX: 110,
      sizeY: 160,
      weight: 8.935795349,
      code: 'D110160',
      description: '110x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 837.42,
      price: 1674.84,
      sizeX: 120,
      sizeY: 160,
      weight: 9.733810148,
      code: 'D120160',
      description: '120x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 902.68,
      price: 1805.35,
      sizeX: 130,
      sizeY: 160,
      weight: 10.53182495,
      code: 'D130160',
      description: '130x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 967.93,
      price: 1935.87,
      sizeX: 140,
      sizeY: 160,
      weight: 11.32983975,
      code: 'D140160',
      description: '140x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 767.16,
      price: 1534.32,
      sizeX: 150,
      sizeY: 160,
      weight: 12.12785455,
      code: 'D150160',
      description: '150x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1011.88,
      price: 2023.76,
      sizeX: 200,
      sizeY: 160,
      weight: 16.11792854,
      code: 'D200160',
      description: '200x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1098.45,
      price: 2196.9,
      sizeX: 160,
      sizeY: 160,
      weight: 12.92586935,
      code: 'D160160',
      description: '160x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1163.71,
      price: 2327.42,
      sizeX: 170,
      sizeY: 160,
      weight: 13.72388415,
      code: 'D170160',
      description: '170x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1228.97,
      price: 2457.93,
      sizeX: 180,
      sizeY: 160,
      weight: 14.52189895,
      code: 'D180160',
      description: '180x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1294.22,
      price: 2588.45,
      sizeX: 190,
      sizeY: 160,
      weight: 15.31991375,
      code: 'D190160',
      description: '190x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1424.74,
      price: 2849.48,
      sizeX: 210,
      sizeY: 160,
      weight: 16.91594334,
      code: 'D210160',
      description: '210x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1490,
      price: 2979.99,
      sizeX: 220,
      sizeY: 160,
      weight: 17.71395814,
      code: 'D220160',
      description: '220x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1555.26,
      price: 3110.51,
      sizeX: 230,
      sizeY: 160,
      weight: 18.51197294,
      code: 'D230160',
      description: '230x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1620.51,
      price: 3241.03,
      sizeX: 240,
      sizeY: 160,
      weight: 19.30998774,
      code: 'D240160',
      description: '240x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1256.6,
      price: 2513.19,
      sizeX: 250,
      sizeY: 160,
      weight: 20.10800254,
      code: 'D250160',
      description: '250x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1751.03,
      price: 3502.06,
      sizeX: 260,
      sizeY: 160,
      weight: 20.90601734,
      code: 'D260160',
      description: '260x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1816.29,
      price: 3632.57,
      sizeX: 270,
      sizeY: 160,
      weight: 21.70403214,
      code: 'D270160',
      description: '270x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1881.55,
      price: 3763.09,
      sizeX: 280,
      sizeY: 160,
      weight: 22.50204694,
      code: 'D280160',
      description: '280x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1946.8,
      price: 3893.61,
      sizeX: 290,
      sizeY: 160,
      weight: 23.30006174,
      code: 'D290160',
      description: '290x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1501.31,
      price: 3002.63,
      sizeX: 300,
      sizeY: 160,
      weight: 24.09807654,
      code: 'D300160',
      description: '300x160',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 511.25,
      price: 1022.5,
      sizeX: 170,
      sizeY: 170,
      weight: 6.029445153,
      code: 'd',
      description: '170x170',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 539.82,
      price: 1079.64,
      sizeX: 180,
      sizeY: 170,
      weight: 6.374266362,
      code: 'A180170',
      description: '180x170',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 568.39,
      price: 1136.78,
      sizeX: 190,
      sizeY: 170,
      weight: 6.719087572,
      code: 'A190170',
      description: '190x170',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 447.72,
      price: 895.44,
      sizeX: 200,
      sizeY: 170,
      weight: 7.063908782,
      code: 'A200170',
      description: '200x170',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 625.53,
      price: 1251.06,
      sizeX: 210,
      sizeY: 170,
      weight: 7.408729991,
      code: 'A210170',
      description: '210x170',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 654.1,
      price: 1308.21,
      sizeX: 220,
      sizeY: 170,
      weight: 7.753551201,
      code: 'A220170',
      description: '220x170',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 682.67,
      price: 1365.35,
      sizeX: 230,
      sizeY: 170,
      weight: 8.098372411,
      code: 'A230170',
      description: '230x170',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 711.24,
      price: 1422.49,
      sizeX: 240,
      sizeY: 170,
      weight: 8.44319362,
      code: 'A240170',
      description: '240x170',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 554.86,
      price: 1109.72,
      sizeX: 250,
      sizeY: 170,
      weight: 8.78801483,
      code: 'A250170',
      description: '250x170',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 768.39,
      price: 1536.77,
      sizeX: 260,
      sizeY: 170,
      weight: 9.13283604,
      code: 'A260170',
      description: '260x170',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 796.96,
      price: 1593.92,
      sizeX: 270,
      sizeY: 170,
      weight: 9.47765725,
      code: 'A270170',
      description: '270x170',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 825.53,
      price: 1651.06,
      sizeX: 280,
      sizeY: 170,
      weight: 9.822478459,
      code: 'A280170',
      description: '280x170',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 854.1,
      price: 1708.2,
      sizeX: 290,
      sizeY: 170,
      weight: 10.16729967,
      code: 'A290170',
      description: '290x170',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 662,
      price: 1324.01,
      sizeX: 300,
      sizeY: 170,
      weight: 10.51212088,
      code: 'A300170',
      description: '300x170',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 78.23,
      price: 156.46,
      sizeX: 10,
      sizeY: 170,
      weight: 0.679790385,
      code: 'B010170',
      description: '10x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 120.29,
      price: 240.57,
      sizeX: 20,
      sizeY: 170,
      weight: 1.192096182,
      code: 'B020170',
      description: '20x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 121.76,
      price: 243.52,
      sizeX: 30,
      sizeY: 170,
      weight: 1.704401979,
      code: 'B030170',
      description: '30x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 204.4,
      price: 408.81,
      sizeX: 40,
      sizeY: 170,
      weight: 2.216707777,
      code: 'B040170',
      description: '40x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 180.68,
      price: 361.37,
      sizeX: 50,
      sizeY: 170,
      weight: 2.729013574,
      code: 'B050170',
      description: '50x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 288.52,
      price: 577.04,
      sizeX: 60,
      sizeY: 170,
      weight: 3.241319371,
      code: 'B060170',
      description: '60x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 330.58,
      price: 661.16,
      sizeX: 70,
      sizeY: 170,
      weight: 3.753625168,
      code: 'B070170',
      description: '70x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 372.64,
      price: 745.28,
      sizeX: 80,
      sizeY: 170,
      weight: 4.265930966,
      code: 'B080170',
      description: '80x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 414.7,
      price: 829.39,
      sizeX: 90,
      sizeY: 170,
      weight: 4.778236763,
      code: 'B090170',
      description: '90x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 338.4,
      price: 676.8,
      sizeX: 100,
      sizeY: 170,
      weight: 5.29054256,
      code: 'B100170',
      description: '100x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 498.81,
      price: 997.63,
      sizeX: 110,
      sizeY: 170,
      weight: 5.802848358,
      code: 'B110170',
      description: '110x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 540.87,
      price: 1081.74,
      sizeX: 120,
      sizeY: 170,
      weight: 6.315154155,
      code: 'B120170',
      description: '120x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 582.93,
      price: 1165.86,
      sizeX: 130,
      sizeY: 170,
      weight: 6.827459952,
      code: 'B130170',
      description: '130x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 653.84,
      price: 1307.68,
      sizeX: 200,
      sizeY: 170,
      weight: 10.41360053,
      code: 'B200170',
      description: '200x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 624.99,
      price: 1249.98,
      sizeX: 140,
      sizeY: 170,
      weight: 7.339765749,
      code: 'B140170',
      description: '140x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 496.12,
      price: 992.24,
      sizeX: 150,
      sizeY: 170,
      weight: 7.852071547,
      code: 'B150170',
      description: '150x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 793.22,
      price: 1586.45,
      sizeX: 180,
      sizeY: 170,
      weight: 9.388988938,
      code: 'B180170',
      description: '180x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 709.11,
      price: 1418.21,
      sizeX: 160,
      sizeY: 170,
      weight: 8.364377344,
      code: 'B160170',
      description: '160x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 708.15,
      price: 1416.31,
      sizeX: 170,
      sizeY: 170,
      weight: 8.876683141,
      code: 'B170170',
      description: '170x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 835.28,
      price: 1670.56,
      sizeX: 190,
      sizeY: 170,
      weight: 9.901294736,
      code: 'B190170',
      description: '190x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 919.4,
      price: 1838.8,
      sizeX: 210,
      sizeY: 170,
      weight: 10.92590633,
      code: 'B210170',
      description: '210x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 961.46,
      price: 1922.91,
      sizeX: 220,
      sizeY: 170,
      weight: 11.43821213,
      code: 'B220170',
      description: '220x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1003.52,
      price: 2007.03,
      sizeX: 230,
      sizeY: 170,
      weight: 11.95051792,
      code: 'B230170',
      description: '230x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1045.57,
      price: 2091.15,
      sizeX: 240,
      sizeY: 170,
      weight: 12.46282372,
      code: 'B240170',
      description: '240x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 811.56,
      price: 1623.12,
      sizeX: 250,
      sizeY: 170,
      weight: 12.97512952,
      code: 'B250170',
      description: '250x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1129.69,
      price: 2259.38,
      sizeX: 260,
      sizeY: 170,
      weight: 13.48743532,
      code: 'B260170',
      description: '260x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1171.75,
      price: 2343.5,
      sizeX: 270,
      sizeY: 170,
      weight: 13.99974111,
      code: 'B270170',
      description: '270x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1213.81,
      price: 2427.62,
      sizeX: 280,
      sizeY: 170,
      weight: 14.51204691,
      code: 'B280170',
      description: '280x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1255.87,
      price: 2511.73,
      sizeX: 290,
      sizeY: 170,
      weight: 15.02435271,
      code: 'B290170',
      description: '290x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 969.28,
      price: 1938.56,
      sizeX: 300,
      sizeY: 170,
      weight: 15.53665851,
      code: 'B300170',
      description: '300x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1339.98,
      price: 2679.97,
      sizeX: 310,
      sizeY: 170,
      weight: 16.0489643,
      code: 'B310170',
      description: '310x170',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 977.44,
      price: 1954.88,
      sizeX: 170,
      sizeY: 170,
      weight: 11.72392113,
      code: 'C170170',
      description: '170x170',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1033.46,
      price: 2066.92,
      sizeX: 180,
      sizeY: 170,
      weight: 12.40371151,
      code: 'C180170',
      description: '180x170',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1089.48,
      price: 2178.97,
      sizeX: 190,
      sizeY: 170,
      weight: 13.0835019,
      code: 'C190170',
      description: '190x170',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 859.13,
      price: 1718.26,
      sizeX: 200,
      sizeY: 170,
      weight: 13.76329228,
      code: 'C200170',
      description: '200x170',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1201.53,
      price: 2403.05,
      sizeX: 210,
      sizeY: 170,
      weight: 14.44308267,
      code: 'C210170',
      description: '210x170',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1257.55,
      price: 2515.1,
      sizeX: 220,
      sizeY: 170,
      weight: 15.12287305,
      code: 'C220170',
      description: '220x170',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1313.57,
      price: 2627.14,
      sizeX: 230,
      sizeY: 170,
      weight: 15.80266344,
      code: 'C230170',
      description: '230x170',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1369.59,
      price: 2739.19,
      sizeX: 240,
      sizeY: 170,
      weight: 16.48245382,
      code: 'C240170',
      description: '240x170',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1069.21,
      price: 2138.42,
      sizeX: 250,
      sizeY: 170,
      weight: 17.16224421,
      code: 'C250170',
      description: '250x170',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1481.64,
      price: 2963.27,
      sizeX: 260,
      sizeY: 170,
      weight: 17.84203459,
      code: 'C260170',
      description: '260x170',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1537.66,
      price: 3075.32,
      sizeX: 270,
      sizeY: 170,
      weight: 18.52182498,
      code: 'C270170',
      description: '270x170',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1593.68,
      price: 3187.36,
      sizeX: 280,
      sizeY: 170,
      weight: 19.20161536,
      code: 'C280170',
      description: '280x170',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1649.7,
      price: 3299.4,
      sizeX: 290,
      sizeY: 170,
      weight: 19.88140575,
      code: 'C290170',
      description: '290x170',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1279.29,
      price: 2558.59,
      sizeX: 300,
      sizeY: 170,
      weight: 20.56119613,
      code: 'C300170',
      description: '300x170',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 126.93,
      price: 253.87,
      sizeX: 10,
      sizeY: 170,
      weight: 1.01475956,
      code: 'D010170',
      description: '10x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 196.21,
      price: 392.41,
      sizeX: 20,
      sizeY: 170,
      weight: 1.862034532,
      code: 'D020170',
      description: '20x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 265.48,
      price: 530.95,
      sizeX: 30,
      sizeY: 170,
      weight: 2.709309505,
      code: 'D030170',
      description: '30x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 334.75,
      price: 669.5,
      sizeX: 40,
      sizeY: 170,
      weight: 3.556584477,
      code: 'D040170',
      description: '40x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 294.81,
      price: 589.61,
      sizeX: 50,
      sizeY: 170,
      weight: 4.40385945,
      code: 'D050170',
      description: '50x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 473.29,
      price: 946.58,
      sizeX: 60,
      sizeY: 170,
      weight: 5.251134422,
      code: 'D060170',
      description: '60x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 542.56,
      price: 1085.13,
      sizeX: 70,
      sizeY: 170,
      weight: 6.098409394,
      code: 'D070170',
      description: '70x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 611.84,
      price: 1223.67,
      sizeX: 80,
      sizeY: 170,
      weight: 6.945684367,
      code: 'D080170',
      description: '80x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 681.11,
      price: 1362.21,
      sizeX: 90,
      sizeY: 170,
      weight: 7.792959339,
      code: 'D090170',
      description: '90x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 554.58,
      price: 1109.15,
      sizeX: 100,
      sizeY: 170,
      weight: 8.640234312,
      code: 'D100170',
      description: '100x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 819.65,
      price: 1639.3,
      sizeX: 110,
      sizeY: 170,
      weight: 9.487509284,
      code: 'D110170',
      description: '110x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 888.92,
      price: 1777.84,
      sizeX: 120,
      sizeY: 170,
      weight: 10.33478426,
      code: 'D120170',
      description: '120x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 958.19,
      price: 1916.39,
      sizeX: 130,
      sizeY: 170,
      weight: 11.18205923,
      code: 'D130170',
      description: '130x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1027.46,
      price: 2054.93,
      sizeX: 140,
      sizeY: 170,
      weight: 12.0293342,
      code: 'D140170',
      description: '140x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 814.34,
      price: 1628.69,
      sizeX: 150,
      sizeY: 170,
      weight: 12.87660917,
      code: 'D150170',
      description: '150x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1074.11,
      price: 2148.22,
      sizeX: 200,
      sizeY: 170,
      weight: 17.11298404,
      code: 'D200170',
      description: '200x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1166.01,
      price: 2332.02,
      sizeX: 160,
      sizeY: 170,
      weight: 13.72388415,
      code: 'D160170',
      description: '160x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1163.71,
      price: 2327.42,
      sizeX: 170,
      sizeY: 170,
      weight: 14.57115912,
      code: 'D170170',
      description: '170x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1304.55,
      price: 2609.1,
      sizeX: 180,
      sizeY: 170,
      weight: 15.41843409,
      code: 'D180170',
      description: '180x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1373.82,
      price: 2747.64,
      sizeX: 190,
      sizeY: 170,
      weight: 16.26570906,
      code: 'D190170',
      description: '190x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1512.37,
      price: 3024.73,
      sizeX: 210,
      sizeY: 170,
      weight: 17.96025901,
      code: 'D210170',
      description: '210x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1581.64,
      price: 3163.27,
      sizeX: 220,
      sizeY: 170,
      weight: 18.80753398,
      code: 'D220170',
      description: '220x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1650.91,
      price: 3301.82,
      sizeX: 230,
      sizeY: 170,
      weight: 19.65480895,
      code: 'D230170',
      description: '230x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1720.18,
      price: 3440.36,
      sizeX: 240,
      sizeY: 170,
      weight: 20.50208393,
      code: 'D240170',
      description: '240x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1333.88,
      price: 2667.76,
      sizeX: 250,
      sizeY: 170,
      weight: 21.3493589,
      code: 'D250170',
      description: '250x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1858.72,
      price: 3717.45,
      sizeX: 260,
      sizeY: 170,
      weight: 22.19663387,
      code: 'D260170',
      description: '260x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1927.99,
      price: 3855.99,
      sizeX: 270,
      sizeY: 170,
      weight: 23.04390884,
      code: 'D270170',
      description: '270x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1997.27,
      price: 3994.53,
      sizeX: 280,
      sizeY: 170,
      weight: 23.89118382,
      code: 'D280170',
      description: '280x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2066.54,
      price: 4133.08,
      sizeX: 290,
      sizeY: 170,
      weight: 24.73845879,
      code: 'D290170',
      description: '290x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1593.65,
      price: 3187.3,
      sizeX: 300,
      sizeY: 170,
      weight: 25.58573376,
      code: 'D300170',
      description: '300x170',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 570.15,
      price: 1140.31,
      sizeX: 180,
      sizeY: 180,
      weight: 6.738791641,
      code: 'A180180',
      description: '180x180',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 600.33,
      price: 1200.66,
      sizeX: 190,
      sizeY: 180,
      weight: 7.10331692,
      code: 'A190180',
      description: '190x180',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 472.88,
      price: 945.76,
      sizeX: 200,
      sizeY: 180,
      weight: 7.467842199,
      code: 'A200180',
      description: '200x180',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 495.51,
      price: 991.03,
      sizeX: 210,
      sizeY: 180,
      weight: 7.832367478,
      code: 'A210180',
      description: '210x180',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 690.86,
      price: 1381.72,
      sizeX: 220,
      sizeY: 180,
      weight: 8.196892756,
      code: 'A220180',
      description: '220x180',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 721.04,
      price: 1442.07,
      sizeX: 230,
      sizeY: 180,
      weight: 8.561418035,
      code: 'A230180',
      description: '230x180',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 751.21,
      price: 1502.43,
      sizeX: 240,
      sizeY: 180,
      weight: 8.925943314,
      code: 'A240180',
      description: '240x180',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 586.04,
      price: 1172.08,
      sizeX: 250,
      sizeY: 180,
      weight: 9.290468593,
      code: 'A250180',
      description: '250x180',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 811.57,
      price: 1623.13,
      sizeX: 260,
      sizeY: 180,
      weight: 9.654993872,
      code: 'A260180',
      description: '260x180',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 841.74,
      price: 1683.48,
      sizeX: 270,
      sizeY: 180,
      weight: 10.01951915,
      code: 'A270180',
      description: '270x180',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 871.92,
      price: 1743.84,
      sizeX: 280,
      sizeY: 180,
      weight: 10.38404443,
      code: 'A280180',
      description: '280x180',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 902.09,
      price: 1804.19,
      sizeX: 290,
      sizeY: 180,
      weight: 10.74856971,
      code: 'A290180',
      description: '290x180',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 932.27,
      price: 1864.54,
      sizeX: 300,
      sizeY: 180,
      weight: 11.11309499,
      code: 'A300180',
      description: '300x180',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 82.71,
      price: 165.42,
      sizeX: 10,
      sizeY: 180,
      weight: 0.719198523,
      code: 'B010180',
      description: '10x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 127.17,
      price: 254.35,
      sizeX: 20,
      sizeY: 180,
      weight: 1.261060424,
      code: 'B020180',
      description: '20x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 124.33,
      price: 248.66,
      sizeX: 30,
      sizeY: 180,
      weight: 1.802922325,
      code: 'B030180',
      description: '30x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 216.11,
      price: 432.22,
      sizeX: 40,
      sizeY: 180,
      weight: 2.344784226,
      code: 'B040180',
      description: '40x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 191.03,
      price: 382.06,
      sizeX: 50,
      sizeY: 180,
      weight: 2.886646127,
      code: 'B050180',
      description: '50x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 305.04,
      price: 610.08,
      sizeX: 60,
      sizeY: 180,
      weight: 3.428508028,
      code: 'B060180',
      description: '60x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 349.51,
      price: 699.02,
      sizeX: 70,
      sizeY: 180,
      weight: 3.970369929,
      code: 'B070180',
      description: '70x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 393.97,
      price: 787.95,
      sizeX: 80,
      sizeY: 180,
      weight: 4.51223183,
      code: 'B080180',
      description: '80x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 438.44,
      price: 876.88,
      sizeX: 90,
      sizeY: 180,
      weight: 5.054093731,
      code: 'B090180',
      description: '90x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 357.78,
      price: 715.56,
      sizeX: 100,
      sizeY: 180,
      weight: 5.595955632,
      code: 'B100180',
      description: '100x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 527.37,
      price: 1054.75,
      sizeX: 110,
      sizeY: 180,
      weight: 6.137817533,
      code: 'B110180',
      description: '110x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 571.84,
      price: 1143.68,
      sizeX: 120,
      sizeY: 180,
      weight: 6.679679434,
      code: 'B120180',
      description: '120x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 616.31,
      price: 1232.62,
      sizeX: 130,
      sizeY: 180,
      weight: 7.221541335,
      code: 'B130180',
      description: '130x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 660.77,
      price: 1321.55,
      sizeX: 140,
      sizeY: 180,
      weight: 7.763403236,
      code: 'B140180',
      description: '140x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 524.53,
      price: 1049.06,
      sizeX: 150,
      sizeY: 180,
      weight: 8.305265137,
      code: 'B150180',
      description: '150x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 691.28,
      price: 1382.56,
      sizeX: 200,
      sizeY: 180,
      weight: 11.01457464,
      code: 'B200180',
      description: '200x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 749.71,
      price: 1499.42,
      sizeX: 160,
      sizeY: 180,
      weight: 8.847127038,
      code: 'B160180',
      description: '160x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 794.17,
      price: 1588.35,
      sizeX: 170,
      sizeY: 180,
      weight: 9.388988938,
      code: 'B170180',
      description: '170x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 838.64,
      price: 1677.28,
      sizeX: 180,
      sizeY: 180,
      weight: 9.930850839,
      code: 'B180180',
      description: '180x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 883.11,
      price: 1766.22,
      sizeX: 190,
      sizeY: 180,
      weight: 10.47271274,
      code: 'B190180',
      description: '190x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 972.04,
      price: 1944.08,
      sizeX: 210,
      sizeY: 180,
      weight: 11.55643654,
      code: 'B210180',
      description: '210x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1016.51,
      price: 2033.02,
      sizeX: 220,
      sizeY: 180,
      weight: 12.09829844,
      code: 'B220180',
      description: '220x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1060.97,
      price: 2121.95,
      sizeX: 230,
      sizeY: 180,
      weight: 12.64016034,
      code: 'B230180',
      description: '230x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1105.44,
      price: 2210.88,
      sizeX: 240,
      sizeY: 180,
      weight: 13.18202225,
      code: 'B240180',
      description: '240x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 858.03,
      price: 1716.06,
      sizeX: 250,
      sizeY: 180,
      weight: 13.72388415,
      code: 'B250180',
      description: '250x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1194.37,
      price: 2388.75,
      sizeX: 260,
      sizeY: 180,
      weight: 14.26574605,
      code: 'B260180',
      description: '260x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1238.84,
      price: 2477.68,
      sizeX: 270,
      sizeY: 180,
      weight: 14.80760795,
      code: 'B270180',
      description: '270x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1283.31,
      price: 2566.62,
      sizeX: 280,
      sizeY: 180,
      weight: 15.34946985,
      code: 'B280180',
      description: '280x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1327.77,
      price: 2655.55,
      sizeX: 290,
      sizeY: 180,
      weight: 15.89133175,
      code: 'B290180',
      description: '290x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1024.78,
      price: 2049.56,
      sizeX: 300,
      sizeY: 180,
      weight: 16.43319365,
      code: 'B300180',
      description: '300x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1416.71,
      price: 2833.42,
      sizeX: 310,
      sizeY: 180,
      weight: 16.97505555,
      code: 'B310180',
      description: '310x180',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1092.69,
      price: 2185.39,
      sizeX: 180,
      sizeY: 180,
      weight: 13.12291004,
      code: 'C180180',
      description: '180x180',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1151.93,
      price: 2303.85,
      sizeX: 190,
      sizeY: 180,
      weight: 13.84210856,
      code: 'C190180',
      description: '190x180',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 908.37,
      price: 1816.74,
      sizeX: 200,
      sizeY: 180,
      weight: 14.56130708,
      code: 'C200180',
      description: '200x180',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1270.39,
      price: 2540.78,
      sizeX: 210,
      sizeY: 180,
      weight: 15.28050561,
      code: 'C210180',
      description: '210x180',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1329.63,
      price: 2659.25,
      sizeX: 220,
      sizeY: 180,
      weight: 15.99970413,
      code: 'C220180',
      description: '220x180',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1388.86,
      price: 2777.72,
      sizeX: 230,
      sizeY: 180,
      weight: 16.71890265,
      code: 'C230180',
      description: '230x180',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1448.09,
      price: 2896.18,
      sizeX: 240,
      sizeY: 180,
      weight: 17.43810118,
      code: 'C240180',
      description: '240x180',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1130.49,
      price: 2260.99,
      sizeX: 250,
      sizeY: 180,
      weight: 18.1572997,
      code: 'C250180',
      description: '250x180',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1566.56,
      price: 3133.11,
      sizeX: 260,
      sizeY: 180,
      weight: 18.87649822,
      code: 'C260180',
      description: '260x180',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1625.79,
      price: 3251.58,
      sizeX: 270,
      sizeY: 180,
      weight: 19.59569675,
      code: 'C270180',
      description: '270x180',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1685.02,
      price: 3370.04,
      sizeX: 280,
      sizeY: 180,
      weight: 20.31489527,
      code: 'C280180',
      description: '280x180',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1744.25,
      price: 3488.51,
      sizeX: 290,
      sizeY: 180,
      weight: 21.03409379,
      code: 'C290180',
      description: '290x180',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1352.62,
      price: 2705.23,
      sizeX: 300,
      sizeY: 180,
      weight: 21.75329231,
      code: 'C300180',
      description: '300x180',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 134.29,
      price: 268.58,
      sizeX: 10,
      sizeY: 180,
      weight: 1.073871767,
      code: 'D010180',
      description: '10x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 207.57,
      price: 415.15,
      sizeX: 20,
      sizeY: 180,
      weight: 1.970406913,
      code: 'D020180',
      description: '20x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 280.86,
      price: 561.72,
      sizeX: 30,
      sizeY: 180,
      weight: 2.866942058,
      code: 'D030180',
      description: '30x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 354.14,
      price: 708.29,
      sizeX: 40,
      sizeY: 180,
      weight: 3.763477203,
      code: 'D040180',
      description: '40x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 311.89,
      price: 623.77,
      sizeX: 50,
      sizeY: 180,
      weight: 4.660012348,
      code: 'D050180',
      description: '50x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 500.71,
      price: 1001.43,
      sizeX: 60,
      sizeY: 180,
      weight: 5.556547494,
      code: 'D060180',
      description: '60x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 574,
      price: 1148,
      sizeX: 70,
      sizeY: 180,
      weight: 6.453082639,
      code: 'D070180',
      description: '70x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 647.28,
      price: 1294.57,
      sizeX: 80,
      sizeY: 180,
      weight: 7.349617784,
      code: 'D080180',
      description: '80x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 720.57,
      price: 1441.14,
      sizeX: 90,
      sizeY: 180,
      weight: 8.246152929,
      code: 'D090180',
      description: '90x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 586.71,
      price: 1173.41,
      sizeX: 100,
      sizeY: 180,
      weight: 9.142688074,
      code: 'D100180',
      description: '100x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 867.14,
      price: 1734.28,
      sizeX: 110,
      sizeY: 180,
      weight: 10.03922322,
      code: 'D110180',
      description: '110x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 940.42,
      price: 1880.85,
      sizeX: 120,
      sizeY: 180,
      weight: 10.93575836,
      code: 'D120180',
      description: '120x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1013.71,
      price: 2027.42,
      sizeX: 130,
      sizeY: 180,
      weight: 11.83229351,
      code: 'D130180',
      description: '130x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1087,
      price: 2173.99,
      sizeX: 140,
      sizeY: 180,
      weight: 12.72882866,
      code: 'D140180',
      description: '140x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 861.53,
      price: 1723.05,
      sizeX: 150,
      sizeY: 180,
      weight: 13.6253638,
      code: 'D150180',
      description: '150x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1233.57,
      price: 2467.13,
      sizeX: 160,
      sizeY: 180,
      weight: 14.52189895,
      code: 'D160180',
      description: '160x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1306.85,
      price: 2613.7,
      sizeX: 170,
      sizeY: 180,
      weight: 15.41843409,
      code: 'D170180',
      description: '170x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1136.35,
      price: 2272.69,
      sizeX: 200,
      sizeY: 180,
      weight: 18.10803953,
      code: 'D200180',
      description: '200x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1228.97,
      price: 2457.93,
      sizeX: 180,
      sizeY: 180,
      weight: 16.31496924,
      code: 'D180180',
      description: '180x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1453.42,
      price: 2906.84,
      sizeX: 190,
      sizeY: 180,
      weight: 17.21150438,
      code: 'D190180',
      description: '190x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1599.99,
      price: 3199.98,
      sizeX: 210,
      sizeY: 180,
      weight: 19.00457467,
      code: 'D210180',
      description: '210x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1673.28,
      price: 3346.55,
      sizeX: 220,
      sizeY: 180,
      weight: 19.90110982,
      code: 'D220180',
      description: '220x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1746.56,
      price: 3493.12,
      sizeX: 230,
      sizeY: 180,
      weight: 20.79764496,
      code: 'D230180',
      description: '230x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1819.85,
      price: 3639.69,
      sizeX: 240,
      sizeY: 180,
      weight: 21.69418011,
      code: 'D240180',
      description: '240x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1411.16,
      price: 2822.33,
      sizeX: 250,
      sizeY: 180,
      weight: 22.59071525,
      code: 'D250180',
      description: '250x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1966.42,
      price: 3932.83,
      sizeX: 260,
      sizeY: 180,
      weight: 23.4872504,
      code: 'D260180',
      description: '260x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2039.7,
      price: 4079.4,
      sizeX: 270,
      sizeY: 180,
      weight: 24.38378554,
      code: 'D270180',
      description: '270x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2112.99,
      price: 4225.97,
      sizeX: 280,
      sizeY: 180,
      weight: 25.28032069,
      code: 'D280180',
      description: '280x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2186.27,
      price: 4372.54,
      sizeX: 290,
      sizeY: 180,
      weight: 26.17685583,
      code: 'D290180',
      description: '290x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1685.98,
      price: 3371.97,
      sizeX: 300,
      sizeY: 180,
      weight: 27.07339098,
      code: 'D300180',
      description: '300x180',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 861.35,
      price: 1722.7,
      sizeX: 180,
      sizeY: 185,
      weight: 10.20178179,
      code: 'B180185',
      description: '180x185',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 632.27,
      price: 1264.54,
      sizeX: 190,
      sizeY: 190,
      weight: 7.487546268,
      code: 'A190190',
      description: '190x190',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 498.04,
      price: 996.08,
      sizeX: 200,
      sizeY: 190,
      weight: 7.871775616,
      code: 'A200190',
      description: '200x190',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 695.83,
      price: 1391.67,
      sizeX: 210,
      sizeY: 190,
      weight: 8.256004964,
      code: 'A210190',
      description: '210x190',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 727.62,
      price: 1455.23,
      sizeX: 220,
      sizeY: 190,
      weight: 8.640234312,
      code: 'A220190',
      description: '220x190',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 759.4,
      price: 1518.8,
      sizeX: 230,
      sizeY: 190,
      weight: 9.02446366,
      code: 'A230190',
      description: '230x190',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 791.18,
      price: 1582.36,
      sizeX: 240,
      sizeY: 190,
      weight: 9.408693008,
      code: 'A240190',
      description: '240x190',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 617.22,
      price: 1234.44,
      sizeX: 250,
      sizeY: 190,
      weight: 9.792922356,
      code: 'A250190',
      description: '250x190',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 854.74,
      price: 1709.49,
      sizeX: 260,
      sizeY: 190,
      weight: 10.1771517,
      code: 'A260190',
      description: '260x190',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 886.53,
      price: 1773.05,
      sizeX: 270,
      sizeY: 190,
      weight: 10.56138105,
      code: 'A270190',
      description: '270x190',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 918.31,
      price: 1836.61,
      sizeX: 280,
      sizeY: 190,
      weight: 10.9456104,
      code: 'A280190',
      description: '280x190',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 950.09,
      price: 1900.18,
      sizeX: 290,
      sizeY: 190,
      weight: 11.32983975,
      code: 'A290190',
      description: '290x190',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 736.4,
      price: 1472.81,
      sizeX: 300,
      sizeY: 190,
      weight: 11.7140691,
      code: 'A300190',
      description: '300x190',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 87.19,
      price: 174.38,
      sizeX: 10,
      sizeY: 190,
      weight: 0.758606661,
      code: 'B010190',
      description: '10x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 134.06,
      price: 268.13,
      sizeX: 20,
      sizeY: 190,
      weight: 1.330024666,
      code: 'B020190',
      description: '20x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 131.06,
      price: 262.13,
      sizeX: 30,
      sizeY: 190,
      weight: 1.901442671,
      code: 'B030190',
      description: '30x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 227.81,
      price: 455.62,
      sizeX: 40,
      sizeY: 190,
      weight: 2.472860675,
      code: 'B040190',
      description: '40x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 201.38,
      price: 402.75,
      sizeX: 50,
      sizeY: 190,
      weight: 3.04427868,
      code: 'B050190',
      description: '50x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 321.56,
      price: 643.12,
      sizeX: 60,
      sizeY: 190,
      weight: 3.615696685,
      code: 'B060190',
      description: '60x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 368.44,
      price: 736.87,
      sizeX: 70,
      sizeY: 190,
      weight: 4.187114689,
      code: 'B070190',
      description: '70x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 415.31,
      price: 830.62,
      sizeX: 80,
      sizeY: 190,
      weight: 4.758532694,
      code: 'B080190',
      description: '80x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 462.19,
      price: 924.37,
      sizeX: 90,
      sizeY: 190,
      weight: 5.329950699,
      code: 'B090190',
      description: '90x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 377.16,
      price: 754.31,
      sizeX: 100,
      sizeY: 190,
      weight: 5.901368703,
      code: 'B100190',
      description: '100x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 555.94,
      price: 1111.87,
      sizeX: 110,
      sizeY: 190,
      weight: 6.472786708,
      code: 'B110190',
      description: '110x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 602.81,
      price: 1205.62,
      sizeX: 120,
      sizeY: 190,
      weight: 7.044204713,
      code: 'B120190',
      description: '120x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 649.69,
      price: 1299.37,
      sizeX: 130,
      sizeY: 190,
      weight: 7.615622717,
      code: 'B130190',
      description: '130x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 696.56,
      price: 1393.12,
      sizeX: 140,
      sizeY: 190,
      weight: 8.187040722,
      code: 'B140190',
      description: '140x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 552.94,
      price: 1105.87,
      sizeX: 150,
      sizeY: 190,
      weight: 8.758458726,
      code: 'B150190',
      description: '150x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 728.72,
      price: 1457.43,
      sizeX: 200,
      sizeY: 190,
      weight: 11.61554875,
      code: 'B200190',
      description: '200x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 790.31,
      price: 1580.62,
      sizeX: 160,
      sizeY: 190,
      weight: 9.329876731,
      code: 'B160190',
      description: '160x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 837.18,
      price: 1674.37,
      sizeX: 170,
      sizeY: 190,
      weight: 9.901294736,
      code: 'B170190',
      description: '170x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 884.06,
      price: 1768.12,
      sizeX: 180,
      sizeY: 190,
      weight: 10.47271274,
      code: 'B180190',
      description: '180x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 930.93,
      price: 1861.87,
      sizeX: 190,
      sizeY: 190,
      weight: 11.04413075,
      code: 'B190190',
      description: '190x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1024.68,
      price: 2049.37,
      sizeX: 210,
      sizeY: 190,
      weight: 12.18696675,
      code: 'B210190',
      description: '210x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1071.56,
      price: 2143.12,
      sizeX: 220,
      sizeY: 190,
      weight: 12.75838476,
      code: 'B220190',
      description: '220x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1118.43,
      price: 2236.87,
      sizeX: 230,
      sizeY: 190,
      weight: 13.32980276,
      code: 'B230190',
      description: '230x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1165.31,
      price: 2330.62,
      sizeX: 240,
      sizeY: 190,
      weight: 13.90122077,
      code: 'B240190',
      description: '240x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 904.5,
      price: 1809,
      sizeX: 250,
      sizeY: 190,
      weight: 14.47263877,
      code: 'B250190',
      description: '250x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1259.06,
      price: 2518.12,
      sizeX: 260,
      sizeY: 190,
      weight: 15.04405678,
      code: 'B260190',
      description: '260x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1305.93,
      price: 2611.87,
      sizeX: 270,
      sizeY: 190,
      weight: 15.61547478,
      code: 'B270190',
      description: '270x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1352.81,
      price: 2705.62,
      sizeX: 280,
      sizeY: 190,
      weight: 16.18689279,
      code: 'B280190',
      description: '280x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1399.68,
      price: 2799.36,
      sizeX: 290,
      sizeY: 190,
      weight: 16.75831079,
      code: 'B290190',
      description: '290x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1080.28,
      price: 2160.56,
      sizeX: 300,
      sizeY: 190,
      weight: 17.3297288,
      code: 'B300190',
      description: '300x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1493.43,
      price: 2986.86,
      sizeX: 310,
      sizeY: 190,
      weight: 17.9011468,
      code: 'B310190',
      description: '310x190',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1214.37,
      price: 2428.74,
      sizeX: 190,
      sizeY: 190,
      weight: 14.60071522,
      code: 'C190190',
      description: '190x190',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 957.61,
      price: 1915.22,
      sizeX: 200,
      sizeY: 190,
      weight: 15.35932188,
      code: 'C200190',
      description: '200x190',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1339.26,
      price: 2678.52,
      sizeX: 210,
      sizeY: 190,
      weight: 16.11792854,
      code: 'C210190',
      description: '210x190',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1401.7,
      price: 2803.4,
      sizeX: 220,
      sizeY: 190,
      weight: 16.87653521,
      code: 'C220190',
      description: '220x190',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1464.15,
      price: 2928.29,
      sizeX: 230,
      sizeY: 190,
      weight: 17.63514187,
      code: 'C230190',
      description: '230x190',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1526.59,
      price: 3053.18,
      sizeX: 240,
      sizeY: 190,
      weight: 18.39374853,
      code: 'C240190',
      description: '240x190',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1191.77,
      price: 2383.55,
      sizeX: 250,
      sizeY: 190,
      weight: 19.15235519,
      code: 'C250190',
      description: '250x190',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1651.48,
      price: 3302.95,
      sizeX: 260,
      sizeY: 190,
      weight: 19.91096185,
      code: 'C260190',
      description: '260x190',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1713.92,
      price: 3427.84,
      sizeX: 270,
      sizeY: 190,
      weight: 20.66956851,
      code: 'C270190',
      description: '270x190',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1776.36,
      price: 3552.73,
      sizeX: 280,
      sizeY: 190,
      weight: 21.42817517,
      code: 'C280190',
      description: '280x190',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1838.81,
      price: 3677.61,
      sizeX: 290,
      sizeY: 190,
      weight: 22.18678184,
      code: 'C290190',
      description: '290x190',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1425.94,
      price: 2851.88,
      sizeX: 300,
      sizeY: 190,
      weight: 22.9453885,
      code: 'C300190',
      description: '300x190',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 141.64,
      price: 283.29,
      sizeX: 10,
      sizeY: 190,
      weight: 1.132983975,
      code: 'D010190',
      description: '10x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 218.94,
      price: 437.88,
      sizeX: 20,
      sizeY: 190,
      weight: 2.078779293,
      code: 'D020190',
      description: '20x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 296.24,
      price: 592.48,
      sizeX: 30,
      sizeY: 190,
      weight: 3.024574611,
      code: 'D030190',
      description: '30x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 373.54,
      price: 747.08,
      sizeX: 40,
      sizeY: 190,
      weight: 3.970369929,
      code: 'D040190',
      description: '40x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 328.97,
      price: 657.94,
      sizeX: 50,
      sizeY: 190,
      weight: 4.916165247,
      code: 'D050190',
      description: '50x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 528.14,
      price: 1056.27,
      sizeX: 60,
      sizeY: 190,
      weight: 5.861960565,
      code: 'D060190',
      description: '60x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 605.43,
      price: 1210.87,
      sizeX: 70,
      sizeY: 190,
      weight: 6.807755883,
      code: 'D070190',
      description: '70x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 682.73,
      price: 1365.47,
      sizeX: 80,
      sizeY: 190,
      weight: 7.753551201,
      code: 'D080190',
      description: '80x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 760.03,
      price: 1520.06,
      sizeX: 90,
      sizeY: 190,
      weight: 8.699346519,
      code: 'D090190',
      description: '90x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 618.84,
      price: 1237.68,
      sizeX: 100,
      sizeY: 190,
      weight: 9.645141837,
      code: 'D100190',
      description: '100x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 914.63,
      price: 1829.26,
      sizeX: 110,
      sizeY: 190,
      weight: 10.59093716,
      code: 'D110190',
      description: '110x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 991.93,
      price: 1983.86,
      sizeX: 120,
      sizeY: 190,
      weight: 11.53673247,
      code: 'D120190',
      description: '120x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1069.23,
      price: 2138.45,
      sizeX: 130,
      sizeY: 190,
      weight: 12.48252779,
      code: 'D130190',
      description: '130x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1146.53,
      price: 2293.05,
      sizeX: 140,
      sizeY: 190,
      weight: 13.42832311,
      code: 'D140190',
      description: '140x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 908.71,
      price: 1817.42,
      sizeX: 150,
      sizeY: 190,
      weight: 14.37411843,
      code: 'D150190',
      description: '150x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1301.12,
      price: 2602.25,
      sizeX: 160,
      sizeY: 190,
      weight: 15.31991375,
      code: 'D160190',
      description: '160x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1378.42,
      price: 2756.84,
      sizeX: 170,
      sizeY: 190,
      weight: 16.26570906,
      code: 'D170190',
      description: '170x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1455.72,
      price: 2911.44,
      sizeX: 180,
      sizeY: 190,
      weight: 17.21150438,
      code: 'D180190',
      description: '180x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1198.58,
      price: 2397.16,
      sizeX: 200,
      sizeY: 190,
      weight: 19.10309502,
      code: 'D200190',
      description: '200x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1294.22,
      price: 2588.45,
      sizeX: 190,
      sizeY: 190,
      weight: 18.1572997,
      code: 'D190190',
      description: '190x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1687.62,
      price: 3375.23,
      sizeX: 210,
      sizeY: 190,
      weight: 20.04889034,
      code: 'D210190',
      description: '210x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1764.92,
      price: 3529.83,
      sizeX: 220,
      sizeY: 190,
      weight: 20.99468565,
      code: 'D220190',
      description: '220x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1830,
      price: 3660,
      sizeX: 230,
      sizeY: 190,
      weight: 21.94048097,
      code: 'D230190',
      description: '230x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1919.51,
      price: 3839.03,
      sizeX: 240,
      sizeY: 190,
      weight: 22.88627629,
      code: 'D240190',
      description: '240x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1488.45,
      price: 2976.9,
      sizeX: 250,
      sizeY: 190,
      weight: 23.83207161,
      code: 'D250190',
      description: '250x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2074.11,
      price: 4148.22,
      sizeX: 260,
      sizeY: 190,
      weight: 24.77786693,
      code: 'D260190',
      description: '260x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2151.41,
      price: 4302.82,
      sizeX: 270,
      sizeY: 190,
      weight: 25.72366224,
      code: 'D270190',
      description: '270x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2228.71,
      price: 4457.42,
      sizeX: 280,
      sizeY: 190,
      weight: 26.66945756,
      code: 'D280190',
      description: '280x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2306.01,
      price: 4612.01,
      sizeX: 290,
      sizeY: 190,
      weight: 27.61525288,
      code: 'D290190',
      description: '290x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1778.32,
      price: 3556.64,
      sizeX: 300,
      sizeY: 190,
      weight: 28.5610482,
      code: 'D300190',
      description: '300x190',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 523.2,
      price: 1046.4,
      sizeX: 200,
      sizeY: 200,
      weight: 8.275709033,
      code: 'A200200',
      description: '200x200',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 730.99,
      price: 1461.97,
      sizeX: 210,
      sizeY: 200,
      weight: 8.67964245,
      code: 'A210200',
      description: '210x200',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 764.37,
      price: 1528.75,
      sizeX: 220,
      sizeY: 200,
      weight: 9.083575867,
      code: 'A220200',
      description: '220x200',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 797.76,
      price: 1595.52,
      sizeX: 230,
      sizeY: 200,
      weight: 9.487509284,
      code: 'A230200',
      description: '230x200',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 831.15,
      price: 1662.3,
      sizeX: 240,
      sizeY: 200,
      weight: 9.891442701,
      code: 'A240200',
      description: '240x200',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 561.95,
      price: 1123.9,
      sizeX: 250,
      sizeY: 200,
      weight: 10.29537612,
      code: 'A250200',
      description: '250x200',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 897.92,
      price: 1795.84,
      sizeX: 260,
      sizeY: 200,
      weight: 10.69930954,
      code: 'A260200',
      description: '260x200',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 927.98,
      price: 1855.96,
      sizeX: 270,
      sizeY: 200,
      weight: 11.10324295,
      code: 'A270200',
      description: '270x200',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 961.37,
      price: 1922.73,
      sizeX: 280,
      sizeY: 200,
      weight: 11.50717637,
      code: 'A280200',
      description: '280x200',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 994.75,
      price: 1989.51,
      sizeX: 290,
      sizeY: 200,
      weight: 11.91110979,
      code: 'A290200',
      description: '290x200',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 666.13,
      price: 1332.25,
      sizeX: 300,
      sizeY: 200,
      weight: 12.3150432,
      code: 'A300200',
      description: '300x200',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 545.79,
      price: 1091.58,
      sizeX: 320,
      sizeY: 200,
      weight: 13.12291004,
      code: 'A320200',
      description: '320x200',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 63.87,
      price: 127.75,
      sizeX: 10,
      sizeY: 200,
      weight: 0.7980148,
      code: 'B010200',
      description: '10x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 100.84,
      price: 201.67,
      sizeX: 20,
      sizeY: 200,
      weight: 1.398988908,
      code: 'B020200',
      description: '20x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 119.42,
      price: 238.85,
      sizeX: 30,
      sizeY: 200,
      weight: 1.999963016,
      code: 'B030200',
      description: '30x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 174.76,
      price: 349.52,
      sizeX: 40,
      sizeY: 200,
      weight: 2.600937125,
      code: 'B040200',
      description: '40x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 183.49,
      price: 366.99,
      sizeX: 50,
      sizeY: 200,
      weight: 3.201911233,
      code: 'B050200',
      description: '50x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 248.68,
      price: 497.37,
      sizeX: 60,
      sizeY: 200,
      weight: 3.802885341,
      code: 'B060200',
      description: '60x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 285.65,
      price: 571.29,
      sizeX: 70,
      sizeY: 200,
      weight: 4.40385945,
      code: 'B070200',
      description: '70x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 322.61,
      price: 645.22,
      sizeX: 80,
      sizeY: 200,
      weight: 5.004833558,
      code: 'B080200',
      description: '80x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 364.45,
      price: 728.9,
      sizeX: 90,
      sizeY: 200,
      weight: 5.605807666,
      code: 'B090200',
      description: '90x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 343.66,
      price: 687.32,
      sizeX: 100,
      sizeY: 200,
      weight: 6.206781775,
      code: 'B100200',
      description: '100x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 433.5,
      price: 866.99,
      sizeX: 110,
      sizeY: 200,
      weight: 6.807755883,
      code: 'B110200',
      description: '110x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 470.46,
      price: 940.92,
      sizeX: 120,
      sizeY: 200,
      weight: 7.408729991,
      code: 'B120200',
      description: '120x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 507.42,
      price: 1014.84,
      sizeX: 130,
      sizeY: 200,
      weight: 8.0097041,
      code: 'B130200',
      description: '130x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 544.38,
      price: 1088.76,
      sizeX: 140,
      sizeY: 200,
      weight: 8.610678208,
      code: 'B140200',
      description: '140x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 503.83,
      price: 1007.66,
      sizeX: 150,
      sizeY: 200,
      weight: 9.211652316,
      code: 'B150200',
      description: '150x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 618.31,
      price: 1236.61,
      sizeX: 160,
      sizeY: 200,
      weight: 9.812626425,
      code: 'B160200',
      description: '160x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 655.27,
      price: 1310.54,
      sizeX: 170,
      sizeY: 200,
      weight: 10.41360053,
      code: 'B170200',
      description: '170x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 692.23,
      price: 1384.46,
      sizeX: 180,
      sizeY: 200,
      weight: 11.01457464,
      code: 'B180200',
      description: '180x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 729.19,
      price: 1458.39,
      sizeX: 190,
      sizeY: 200,
      weight: 11.61554875,
      code: 'B190200',
      description: '190x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 664,
      price: 1328,
      sizeX: 200,
      sizeY: 200,
      weight: 12.21652286,
      code: 'B200200',
      description: '200x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 803.12,
      price: 1606.24,
      sizeX: 210,
      sizeY: 200,
      weight: 12.81749697,
      code: 'B210200',
      description: '210x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 840.08,
      price: 1680.16,
      sizeX: 220,
      sizeY: 200,
      weight: 13.41847107,
      code: 'B220200',
      description: '220x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 877.04,
      price: 1754.08,
      sizeX: 230,
      sizeY: 200,
      weight: 14.01944518,
      code: 'B230200',
      description: '230x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 914,
      price: 1828.01,
      sizeX: 240,
      sizeY: 200,
      weight: 14.62041929,
      code: 'B240200',
      description: '240x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 824.17,
      price: 1648.34,
      sizeX: 250,
      sizeY: 200,
      weight: 15.2213934,
      code: 'B250200',
      description: '250x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 987.93,
      price: 1975.86,
      sizeX: 260,
      sizeY: 200,
      weight: 15.82236751,
      code: 'B260200',
      description: '260x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1024.89,
      price: 2049.78,
      sizeX: 270,
      sizeY: 200,
      weight: 16.42334162,
      code: 'B270200',
      description: '270x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1061.85,
      price: 2123.71,
      sizeX: 280,
      sizeY: 200,
      weight: 17.02431572,
      code: 'B280200',
      description: '280x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1098.82,
      price: 2197.63,
      sizeX: 290,
      sizeY: 200,
      weight: 17.62528983,
      code: 'B290200',
      description: '290x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 984.34,
      price: 1968.68,
      sizeX: 300,
      sizeY: 200,
      weight: 18.22626394,
      code: 'B300200',
      description: '300x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1016.37,
      price: 2032.75,
      sizeX: 310,
      sizeY: 200,
      weight: 18.82723805,
      code: 'B310200',
      description: '310x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 806.47,
      price: 1612.94,
      sizeX: 320,
      sizeY: 200,
      weight: 19.42821216,
      code: 'B320200',
      description: '320x200',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 872.6,
      price: 1745.21,
      sizeX: 200,
      sizeY: 200,
      weight: 16.15733668,
      code: 'C200200',
      description: '200x200',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1056.09,
      price: 2112.18,
      sizeX: 210,
      sizeY: 200,
      weight: 16.95535148,
      code: 'C210200',
      description: '210x200',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1105.33,
      price: 2210.67,
      sizeX: 220,
      sizeY: 200,
      weight: 17.75336628,
      code: 'C220200',
      description: '220x200',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1154.57,
      price: 2309.15,
      sizeX: 230,
      sizeY: 200,
      weight: 18.55138108,
      code: 'C230200',
      description: '230x200',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1203.81,
      price: 2407.63,
      sizeX: 240,
      sizeY: 200,
      weight: 19.34939588,
      code: 'C240200',
      description: '240x200',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1085.98,
      price: 2171.96,
      sizeX: 250,
      sizeY: 200,
      weight: 20.14741068,
      code: 'C250200',
      description: '250x200',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1302.3,
      price: 2604.59,
      sizeX: 260,
      sizeY: 200,
      weight: 20.94542548,
      code: 'C260200',
      description: '260x200',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1351.54,
      price: 2703.08,
      sizeX: 270,
      sizeY: 200,
      weight: 21.74344028,
      code: 'C270200',
      description: '270x200',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1400.78,
      price: 2801.56,
      sizeX: 280,
      sizeY: 200,
      weight: 22.54145508,
      code: 'C280200',
      description: '280x200',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1450.02,
      price: 2900.04,
      sizeX: 290,
      sizeY: 200,
      weight: 23.33946988,
      code: 'C290200',
      description: '290x200',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1299.36,
      price: 2598.72,
      sizeX: 300,
      sizeY: 200,
      weight: 24.13748468,
      code: 'C300200',
      description: '300x200',
      eye: '5x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 102.11,
      price: 204.22,
      sizeX: 10,
      sizeY: 200,
      weight: 1.192096182,
      code: 'D010200',
      description: '10x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 163.1,
      price: 326.19,
      sizeX: 20,
      sizeY: 200,
      weight: 2.187151673,
      code: 'D020200',
      description: '20x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 194.2,
      price: 388.41,
      sizeX: 30,
      sizeY: 200,
      weight: 3.182207164,
      code: 'D030200',
      description: '30x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 285.06,
      price: 570.13,
      sizeX: 40,
      sizeY: 200,
      weight: 4.177262655,
      code: 'D040200',
      description: '40x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 300.22,
      price: 600.44,
      sizeX: 50,
      sizeY: 200,
      weight: 5.172318146,
      code: 'D050200',
      description: '50x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 407.03,
      price: 814.07,
      sizeX: 60,
      sizeY: 200,
      weight: 6.167373636,
      code: 'D060200',
      description: '60x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 468.02,
      price: 936.03,
      sizeX: 70,
      sizeY: 200,
      weight: 7.162429127,
      code: 'D070200',
      description: '70x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 529,
      price: 1058,
      sizeX: 80,
      sizeY: 200,
      weight: 8.157484618,
      code: 'D080200',
      description: '80x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 89.16,
      price: 178.33,
      sizeX: 90,
      sizeY: 200,
      weight: 9.152540109,
      code: 'D090200',
      description: '90x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 564.74,
      price: 1129.48,
      sizeX: 100,
      sizeY: 200,
      weight: 10.1475956,
      code: 'D100200',
      description: '100x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 711.95,
      price: 1423.91,
      sizeX: 110,
      sizeY: 200,
      weight: 11.14265109,
      code: 'D110200',
      description: '110x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 772.94,
      price: 1545.88,
      sizeX: 120,
      sizeY: 200,
      weight: 12.13770658,
      code: 'D120200',
      description: '120x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 833.92,
      price: 1667.84,
      sizeX: 130,
      sizeY: 200,
      weight: 13.13276207,
      code: 'D130200',
      description: '130x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 894.91,
      price: 1789.81,
      sizeX: 140,
      sizeY: 200,
      weight: 14.12781756,
      code: 'D140200',
      description: '140x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 829.26,
      price: 1658.53,
      sizeX: 150,
      sizeY: 200,
      weight: 15.12287305,
      code: 'D150200',
      description: '150x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1016.87,
      price: 2033.75,
      sizeX: 160,
      sizeY: 200,
      weight: 16.11792854,
      code: 'D160200',
      description: '160x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1077.86,
      price: 2155.72,
      sizeX: 170,
      sizeY: 200,
      weight: 17.11298404,
      code: 'D170200',
      description: '170x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1138.84,
      price: 2277.69,
      sizeX: 180,
      sizeY: 200,
      weight: 18.10803953,
      code: 'D180200',
      description: '180x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1011.88,
      price: 2023.76,
      sizeX: 200,
      sizeY: 200,
      weight: 20.09815051,
      code: 'D200200',
      description: '200x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1199.83,
      price: 2399.66,
      sizeX: 190,
      sizeY: 200,
      weight: 19.10309502,
      code: 'D190200',
      description: '190x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1321.8,
      price: 2643.59,
      sizeX: 210,
      sizeY: 200,
      weight: 21.093206,
      code: 'D210200',
      description: '210x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1382.78,
      price: 2765.56,
      sizeX: 220,
      sizeY: 200,
      weight: 22.08826149,
      code: 'D220200',
      description: '220x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1443.76,
      price: 2887.53,
      sizeX: 230,
      sizeY: 200,
      weight: 23.08331698,
      code: 'D230200',
      description: '230x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1504.75,
      price: 3009.5,
      sizeX: 240,
      sizeY: 200,
      weight: 24.07837247,
      code: 'D240200',
      description: '240x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1358.31,
      price: 2716.62,
      sizeX: 250,
      sizeY: 200,
      weight: 25.07342796,
      code: 'D250200',
      description: '250x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1626.72,
      price: 3253.43,
      sizeX: 260,
      sizeY: 200,
      weight: 26.06848345,
      code: 'D260200',
      description: '260x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1687.7,
      price: 3375.4,
      sizeX: 270,
      sizeY: 200,
      weight: 27.06353894,
      code: 'D270200',
      description: '270x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1748.69,
      price: 3497.37,
      sizeX: 280,
      sizeY: 200,
      weight: 28.05859444,
      code: 'D280200',
      description: '280x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1809.67,
      price: 3619.34,
      sizeX: 290,
      sizeY: 200,
      weight: 29.05364993,
      code: 'D290200',
      description: '290x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1622.83,
      price: 3245.66,
      sizeX: 300,
      sizeY: 200,
      weight: 30.04870542,
      code: 'D300200',
      description: '300x200',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 571.99,
      price: 1143.97,
      sizeX: 210,
      sizeY: 210,
      weight: 9.103279936,
      code: 'A210210',
      description: '210x210',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 794.15,
      price: 1588.3,
      sizeX: 220,
      sizeY: 210,
      weight: 9.526917422,
      code: 'A220210',
      description: '220x210',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 829.14,
      price: 1658.29,
      sizeX: 230,
      sizeY: 210,
      weight: 9.950554909,
      code: 'A230210',
      description: '230x210',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 864.14,
      price: 1728.27,
      sizeX: 240,
      sizeY: 210,
      weight: 10.37419239,
      code: 'A240210',
      description: '240x210',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 674.35,
      price: 1348.69,
      sizeX: 250,
      sizeY: 210,
      weight: 10.79782988,
      code: 'A250210',
      description: '250x210',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 934.12,
      price: 1868.24,
      sizeX: 260,
      sizeY: 210,
      weight: 11.22146737,
      code: 'A260210',
      description: '260x210',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 969.11,
      price: 1938.23,
      sizeX: 270,
      sizeY: 210,
      weight: 11.64510485,
      code: 'A270210',
      description: '270x210',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1011.09,
      price: 2022.17,
      sizeX: 280,
      sizeY: 210,
      weight: 12.06874234,
      code: 'A280210',
      description: '280x210',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 96.15,
      price: 192.3,
      sizeX: 10,
      sizeY: 210,
      weight: 0.837422938,
      code: 'B010210',
      description: '10x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 147.84,
      price: 295.68,
      sizeX: 20,
      sizeY: 210,
      weight: 1.46795315,
      code: 'B020210',
      description: '20x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 149.65,
      price: 299.29,
      sizeX: 30,
      sizeY: 210,
      weight: 2.098483362,
      code: 'B030210',
      description: '30x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 251.22,
      price: 502.44,
      sizeX: 40,
      sizeY: 210,
      weight: 2.729013574,
      code: 'B040210',
      description: '40x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 222.07,
      price: 444.14,
      sizeX: 50,
      sizeY: 210,
      weight: 3.359543786,
      code: 'B050210',
      description: '50x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 354.6,
      price: 709.21,
      sizeX: 60,
      sizeY: 210,
      weight: 3.990073998,
      code: 'B060210',
      description: '60x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 406.29,
      price: 812.59,
      sizeX: 70,
      sizeY: 210,
      weight: 4.62060421,
      code: 'B070210',
      description: '70x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 457.99,
      price: 915.97,
      sizeX: 80,
      sizeY: 210,
      weight: 5.251134422,
      code: 'B080210',
      description: '80x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 509.68,
      price: 1019.35,
      sizeX: 90,
      sizeY: 210,
      weight: 5.881664634,
      code: 'B090210',
      description: '90x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 415.91,
      price: 831.82,
      sizeX: 100,
      sizeY: 210,
      weight: 6.512194846,
      code: 'B100210',
      description: '100x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 613.06,
      price: 1226.12,
      sizeX: 110,
      sizeY: 210,
      weight: 7.142725058,
      code: 'B110210',
      description: '110x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 664.75,
      price: 1329.5,
      sizeX: 120,
      sizeY: 210,
      weight: 7.77325527,
      code: 'B120210',
      description: '120x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 716.44,
      price: 1432.88,
      sizeX: 130,
      sizeY: 210,
      weight: 8.403785482,
      code: 'B130210',
      description: '130x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 768.13,
      price: 1536.26,
      sizeX: 140,
      sizeY: 210,
      weight: 9.034315694,
      code: 'B140210',
      description: '140x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 609.75,
      price: 1219.5,
      sizeX: 150,
      sizeY: 210,
      weight: 9.664845906,
      code: 'B150210',
      description: '150x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 871.51,
      price: 1743.03,
      sizeX: 160,
      sizeY: 210,
      weight: 10.29537612,
      code: 'B160210',
      description: '160x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 923.21,
      price: 1846.41,
      sizeX: 170,
      sizeY: 210,
      weight: 10.92590633,
      code: 'B170210',
      description: '170x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 974.9,
      price: 1949.79,
      sizeX: 180,
      sizeY: 210,
      weight: 11.55643654,
      code: 'B180210',
      description: '180x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1026.59,
      price: 2053.17,
      sizeX: 190,
      sizeY: 210,
      weight: 12.18696675,
      code: 'B190210',
      description: '190x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 803.59,
      price: 1607.19,
      sizeX: 200,
      sizeY: 210,
      weight: 12.81749697,
      code: 'B200210',
      description: '200x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1129.97,
      price: 2259.94,
      sizeX: 210,
      sizeY: 210,
      weight: 13.44802718,
      code: 'B210210',
      description: '210x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1181.66,
      price: 2363.32,
      sizeX: 220,
      sizeY: 210,
      weight: 14.07855739,
      code: 'B220210',
      description: '220x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1233.35,
      price: 2466.7,
      sizeX: 230,
      sizeY: 210,
      weight: 14.7090876,
      code: 'B230210',
      description: '230x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1285.04,
      price: 2570.09,
      sizeX: 240,
      sizeY: 210,
      weight: 15.33961781,
      code: 'B240210',
      description: '240x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 997.44,
      price: 1994.87,
      sizeX: 250,
      sizeY: 210,
      weight: 15.97014803,
      code: 'B250210',
      description: '250x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1388.43,
      price: 2776.85,
      sizeX: 260,
      sizeY: 210,
      weight: 16.60067824,
      code: 'B260210',
      description: '260x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1440.12,
      price: 2880.23,
      sizeX: 270,
      sizeY: 210,
      weight: 17.23120845,
      code: 'B270210',
      description: '270x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1491.81,
      price: 2983.61,
      sizeX: 280,
      sizeY: 210,
      weight: 17.86173866,
      code: 'B280210',
      description: '280x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1543.5,
      price: 3087,
      sizeX: 290,
      sizeY: 210,
      weight: 18.49226887,
      code: 'B290210',
      description: '290x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1191.28,
      price: 2382.55,
      sizeX: 300,
      sizeY: 210,
      weight: 19.12279909,
      code: 'B300210',
      description: '300x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1235.16,
      price: 2470.32,
      sizeX: 310,
      sizeY: 210,
      weight: 19.7533293,
      code: 'B310210',
      description: '310x210',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 156.35,
      price: 312.7,
      sizeX: 10,
      sizeY: 210,
      weight: 1.251208389,
      code: 'D010210',
      description: '10x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 241.68,
      price: 483.35,
      sizeX: 20,
      sizeY: 210,
      weight: 2.295524053,
      code: 'D020210',
      description: '20x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 327,
      price: 654.01,
      sizeX: 30,
      sizeY: 210,
      weight: 3.339839717,
      code: 'D030210',
      description: '30x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 412.33,
      price: 824.66,
      sizeX: 40,
      sizeY: 210,
      weight: 4.384155381,
      code: 'D040210',
      description: '40x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 363.13,
      price: 726.26,
      sizeX: 50,
      sizeY: 210,
      weight: 5.428471044,
      code: 'D050210',
      description: '50x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 582.98,
      price: 1165.96,
      sizeX: 60,
      sizeY: 210,
      weight: 6.472786708,
      code: 'D060210',
      description: '60x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 668.31,
      price: 1336.61,
      sizeX: 70,
      sizeY: 210,
      weight: 7.517102372,
      code: 'D070210',
      description: '70x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 753.63,
      price: 1507.26,
      sizeX: 80,
      sizeY: 210,
      weight: 8.561418035,
      code: 'D080210',
      description: '80x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 683.1,
      price: 1366.2,
      sizeX: 100,
      sizeY: 210,
      weight: 10.65004936,
      code: 'D100210',
      description: '100x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1009.61,
      price: 2019.22,
      sizeX: 110,
      sizeY: 210,
      weight: 11.69436503,
      code: 'D110210',
      description: '110x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1094.94,
      price: 2189.87,
      sizeX: 120,
      sizeY: 210,
      weight: 12.73868069,
      code: 'D120210',
      description: '120x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1180.26,
      price: 2360.52,
      sizeX: 130,
      sizeY: 210,
      weight: 13.78299635,
      code: 'D130210',
      description: '130x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1265.59,
      price: 2531.17,
      sizeX: 140,
      sizeY: 210,
      weight: 14.82731202,
      code: 'D140210',
      description: '140x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 838.96,
      price: 1677.92,
      sizeX: 90,
      sizeY: 210,
      weight: 9.605733699,
      code: 'D090210',
      description: '90x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1003.07,
      price: 2006.15,
      sizeX: 150,
      sizeY: 210,
      weight: 15.87162768,
      code: 'D150210',
      description: '150x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1436.24,
      price: 2872.48,
      sizeX: 160,
      sizeY: 210,
      weight: 16.91594334,
      code: 'D160210',
      description: '160x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1521.57,
      price: 3043.13,
      sizeX: 170,
      sizeY: 210,
      weight: 17.96025901,
      code: 'D170210',
      description: '170x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1323.05,
      price: 2646.09,
      sizeX: 200,
      sizeY: 210,
      weight: 21.093206,
      code: 'D200210',
      description: '200x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1606.89,
      price: 3213.78,
      sizeX: 180,
      sizeY: 210,
      weight: 19.00457467,
      code: 'D180210',
      description: '180x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1692.22,
      price: 3384.43,
      sizeX: 190,
      sizeY: 210,
      weight: 20.04889034,
      code: 'D190210',
      description: '190x210',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 837.89,
      price: 1675.77,
      sizeX: 220,
      sizeY: 220,
      weight: 9.970258978,
      code: 'A220220',
      description: '220x220',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 874.48,
      price: 1748.97,
      sizeX: 230,
      sizeY: 220,
      weight: 10.41360053,
      code: 'A230220',
      description: '230x220',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 911.08,
      price: 1822.17,
      sizeX: 240,
      sizeY: 220,
      weight: 10.85694209,
      code: 'A240220',
      description: '240x220',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 710.76,
      price: 1421.52,
      sizeX: 250,
      sizeY: 220,
      weight: 11.30028364,
      code: 'A250220',
      description: '250x220',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 100.63,
      price: 201.25,
      sizeX: 10,
      sizeY: 220,
      weight: 0.876831076,
      code: 'B010220',
      description: '10x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 154.73,
      price: 309.45,
      sizeX: 20,
      sizeY: 220,
      weight: 1.536917392,
      code: 'B020220',
      description: '20x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 156.62,
      price: 313.24,
      sizeX: 30,
      sizeY: 220,
      weight: 2.197003708,
      code: 'B030220',
      description: '30x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 984.28,
      price: 1968.56,
      sizeX: 260,
      sizeY: 220,
      weight: 11.7436252,
      code: 'A260220',
      description: '260x220',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 262.93,
      price: 525.85,
      sizeX: 40,
      sizeY: 220,
      weight: 2.857090023,
      code: 'B040220',
      description: '40x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1020.88,
      price: 2041.75,
      sizeX: 270,
      sizeY: 220,
      weight: 12.18696675,
      code: 'A270220',
      description: '270x220',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1057.48,
      price: 2114.95,
      sizeX: 280,
      sizeY: 220,
      weight: 12.63030831,
      code: 'A280220',
      description: '280x220',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 232.41,
      price: 464.83,
      sizeX: 50,
      sizeY: 220,
      weight: 3.517176339,
      code: 'B050220',
      description: '50x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 371.12,
      price: 742.25,
      sizeX: 60,
      sizeY: 220,
      weight: 4.177262655,
      code: 'B060220',
      description: '60x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 425.22,
      price: 850.45,
      sizeX: 70,
      sizeY: 220,
      weight: 4.83734897,
      code: 'B070220',
      description: '70x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 479.32,
      price: 958.64,
      sizeX: 80,
      sizeY: 220,
      weight: 5.497435286,
      code: 'B080220',
      description: '80x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 533.42,
      price: 1066.84,
      sizeX: 90,
      sizeY: 220,
      weight: 6.157521602,
      code: 'B090220',
      description: '90x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 435.29,
      price: 870.57,
      sizeX: 100,
      sizeY: 220,
      weight: 6.817607918,
      code: 'B100220',
      description: '100x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 641.62,
      price: 1283.24,
      sizeX: 110,
      sizeY: 220,
      weight: 7.477694233,
      code: 'B110220',
      description: '110x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 695.72,
      price: 1391.44,
      sizeX: 120,
      sizeY: 220,
      weight: 8.137780549,
      code: 'B120220',
      description: '120x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 749.82,
      price: 1499.64,
      sizeX: 130,
      sizeY: 220,
      weight: 8.797866865,
      code: 'B130220',
      description: '130x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 803.92,
      price: 1607.84,
      sizeX: 140,
      sizeY: 220,
      weight: 9.45795318,
      code: 'B140220',
      description: '140x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 638.16,
      price: 1276.32,
      sizeX: 150,
      sizeY: 220,
      weight: 10.1180395,
      code: 'B150220',
      description: '150x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 912.12,
      price: 1824.23,
      sizeX: 160,
      sizeY: 220,
      weight: 10.77812581,
      code: 'B160220',
      description: '160x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 966.22,
      price: 1932.43,
      sizeX: 170,
      sizeY: 220,
      weight: 11.43821213,
      code: 'B170220',
      description: '170x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1020.31,
      price: 2040.63,
      sizeX: 180,
      sizeY: 220,
      weight: 12.09829844,
      code: 'B180220',
      description: '180x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1074.41,
      price: 2148.83,
      sizeX: 190,
      sizeY: 220,
      weight: 12.75838476,
      code: 'B190220',
      description: '190x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 841.03,
      price: 1682.06,
      sizeX: 200,
      sizeY: 220,
      weight: 13.41847107,
      code: 'B200220',
      description: '200x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1182.61,
      price: 2365.22,
      sizeX: 210,
      sizeY: 220,
      weight: 14.07855739,
      code: 'B210220',
      description: '210x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1236.71,
      price: 2473.42,
      sizeX: 220,
      sizeY: 220,
      weight: 14.73864371,
      code: 'B220220',
      description: '220x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1290.81,
      price: 2581.62,
      sizeX: 230,
      sizeY: 220,
      weight: 15.39873002,
      code: 'B230220',
      description: '230x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1344.91,
      price: 2689.82,
      sizeX: 240,
      sizeY: 220,
      weight: 16.05881634,
      code: 'B240220',
      description: '240x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1043.9,
      price: 2087.81,
      sizeX: 250,
      sizeY: 220,
      weight: 16.71890265,
      code: 'B250220',
      description: '250x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1453.11,
      price: 2906.22,
      sizeX: 260,
      sizeY: 220,
      weight: 17.37898897,
      code: 'B260220',
      description: '260x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1507.21,
      price: 3014.42,
      sizeX: 270,
      sizeY: 220,
      weight: 18.03907528,
      code: 'B270220',
      description: '270x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1561.31,
      price: 3122.61,
      sizeX: 280,
      sizeY: 220,
      weight: 18.6991616,
      code: 'B280220',
      description: '280x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1615.41,
      price: 3230.81,
      sizeX: 290,
      sizeY: 220,
      weight: 19.35924792,
      code: 'B290220',
      description: '290x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1246.78,
      price: 2493.55,
      sizeX: 300,
      sizeY: 220,
      weight: 20.01933423,
      code: 'B300220',
      description: '300x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1723.6,
      price: 3447.21,
      sizeX: 310,
      sizeY: 220,
      weight: 20.67942055,
      code: 'B310220',
      description: '310x220',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 163.71,
      price: 327.41,
      sizeX: 10,
      sizeY: 220,
      weight: 1.310320597,
      code: 'D010220',
      description: '10x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 253.04,
      price: 506.09,
      sizeX: 20,
      sizeY: 220,
      weight: 2.403896433,
      code: 'D020220',
      description: '20x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 342.38,
      price: 684.77,
      sizeX: 30,
      sizeY: 220,
      weight: 3.49747227,
      code: 'D030220',
      description: '30x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 431.72,
      price: 863.45,
      sizeX: 40,
      sizeY: 220,
      weight: 4.591048106,
      code: 'D040220',
      description: '40x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 390.8,
      price: 781.59,
      sizeX: 50,
      sizeY: 220,
      weight: 5.684623943,
      code: 'D050220',
      description: '50x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 610.4,
      price: 1220.8,
      sizeX: 60,
      sizeY: 220,
      weight: 6.778199779,
      code: 'D060220',
      description: '60x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 699.74,
      price: 1399.48,
      sizeX: 70,
      sizeY: 220,
      weight: 7.871775616,
      code: 'D070220',
      description: '70x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 789.08,
      price: 1578.16,
      sizeX: 80,
      sizeY: 220,
      weight: 8.965351452,
      code: 'D080220',
      description: '80x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 715.23,
      price: 1430.47,
      sizeX: 100,
      sizeY: 220,
      weight: 11.15250313,
      code: 'D100220',
      description: '100x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1057.1,
      price: 2114.2,
      sizeX: 110,
      sizeY: 220,
      weight: 12.24607896,
      code: 'D110220',
      description: '110x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1146.44,
      price: 2292.88,
      sizeX: 120,
      sizeY: 220,
      weight: 13.3396548,
      code: 'D120220',
      description: '120x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1235.78,
      price: 2471.56,
      sizeX: 130,
      sizeY: 220,
      weight: 14.43323063,
      code: 'D130220',
      description: '130x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1325.12,
      price: 2650.24,
      sizeX: 140,
      sizeY: 220,
      weight: 15.52680647,
      code: 'D140220',
      description: '140x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 878.42,
      price: 1756.84,
      sizeX: 90,
      sizeY: 220,
      weight: 10.05892729,
      code: 'D090220',
      description: '90x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1050.26,
      price: 2100.51,
      sizeX: 150,
      sizeY: 220,
      weight: 16.62038231,
      code: 'D150220',
      description: '150x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1503.8,
      price: 3007.59,
      sizeX: 160,
      sizeY: 220,
      weight: 17.71395814,
      code: 'D160220',
      description: '160x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1593.14,
      price: 3186.27,
      sizeX: 170,
      sizeY: 220,
      weight: 18.80753398,
      code: 'D170220',
      description: '170x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1385.28,
      price: 2770.56,
      sizeX: 200,
      sizeY: 220,
      weight: 22.08826149,
      code: 'D200220',
      description: '200x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1682.48,
      price: 3364.95,
      sizeX: 180,
      sizeY: 220,
      weight: 19.90110982,
      code: 'D180220',
      description: '180x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1771.82,
      price: 3543.63,
      sizeX: 190,
      sizeY: 220,
      weight: 20.99468565,
      code: 'D190220',
      description: '190x220',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 105.11,
      price: 210.21,
      sizeX: 10,
      sizeY: 230,
      weight: 0.916239214,
      code: 'B010230',
      description: '10x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 161.61,
      price: 323.23,
      sizeX: 20,
      sizeY: 230,
      weight: 1.605881634,
      code: 'B020230',
      description: '20x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 158,
      price: 316,
      sizeX: 30,
      sizeY: 230,
      weight: 2.295524053,
      code: 'B030230',
      description: '30x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 274.63,
      price: 549.26,
      sizeX: 40,
      sizeY: 230,
      weight: 2.985166473,
      code: 'B040230',
      description: '40x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 912.85,
      price: 1825.69,
      sizeX: 230,
      sizeY: 230,
      weight: 10.87664616,
      code: 'A230230',
      description: '230x230',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 951.05,
      price: 1902.1,
      sizeX: 240,
      sizeY: 230,
      weight: 11.33969178,
      code: 'A240230',
      description: '240x230',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 741.94,
      price: 1483.88,
      sizeX: 250,
      sizeY: 230,
      weight: 11.80273741,
      code: 'A250230',
      description: '250x230',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1027.46,
      price: 2054.92,
      sizeX: 260,
      sizeY: 230,
      weight: 12.26578303,
      code: 'A260230',
      description: '260x230',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 242.76,
      price: 485.52,
      sizeX: 50,
      sizeY: 230,
      weight: 3.674808892,
      code: 'B050230',
      description: '50x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 387.64,
      price: 775.29,
      sizeX: 60,
      sizeY: 230,
      weight: 4.364451311,
      code: 'B060230',
      description: '60x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 444.15,
      price: 888.3,
      sizeX: 70,
      sizeY: 230,
      weight: 5.054093731,
      code: 'B070230',
      description: '70x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 500.66,
      price: 1001.32,
      sizeX: 80,
      sizeY: 230,
      weight: 5.74373615,
      code: 'B080230',
      description: '80x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 557.17,
      price: 1114.33,
      sizeX: 90,
      sizeY: 230,
      weight: 6.43337857,
      code: 'B090230',
      description: '90x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1065.66,
      price: 2131.32,
      sizeX: 270,
      sizeY: 230,
      weight: 12.72882866,
      code: 'A270230',
      description: '270x230',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1103.86,
      price: 2207.73,
      sizeX: 280,
      sizeY: 230,
      weight: 13.19187428,
      code: 'A280230',
      description: '280x230',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 454.66,
      price: 909.33,
      sizeX: 100,
      sizeY: 230,
      weight: 7.123020989,
      code: 'B100230',
      description: '100x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 670.18,
      price: 1340.36,
      sizeX: 110,
      sizeY: 230,
      weight: 7.812663408,
      code: 'B110230',
      description: '110x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 726.69,
      price: 1453.38,
      sizeX: 120,
      sizeY: 230,
      weight: 8.502305828,
      code: 'B120230',
      description: '120x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 783.2,
      price: 1566.39,
      sizeX: 130,
      sizeY: 230,
      weight: 9.191948247,
      code: 'B130230',
      description: '130x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 839.7,
      price: 1679.41,
      sizeX: 140,
      sizeY: 230,
      weight: 9.881590667,
      code: 'B140230',
      description: '140x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 666.57,
      price: 1333.13,
      sizeX: 150,
      sizeY: 230,
      weight: 10.57123309,
      code: 'B150230',
      description: '150x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 952.72,
      price: 1905.44,
      sizeX: 160,
      sizeY: 230,
      weight: 11.26087551,
      code: 'B160230',
      description: '160x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1009.23,
      price: 2018.45,
      sizeX: 170,
      sizeY: 230,
      weight: 11.95051792,
      code: 'B170230',
      description: '170x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1065.73,
      price: 2131.47,
      sizeX: 180,
      sizeY: 230,
      weight: 12.64016034,
      code: 'B180230',
      description: '180x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1122.24,
      price: 2244.48,
      sizeX: 190,
      sizeY: 230,
      weight: 13.32980276,
      code: 'B190230',
      description: '190x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 878.47,
      price: 1756.94,
      sizeX: 200,
      sizeY: 230,
      weight: 14.01944518,
      code: 'B200230',
      description: '200x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1235.26,
      price: 2470.51,
      sizeX: 210,
      sizeY: 230,
      weight: 14.7090876,
      code: 'B210230',
      description: '210x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1291.76,
      price: 2583.53,
      sizeX: 220,
      sizeY: 230,
      weight: 15.39873002,
      code: 'B220230',
      description: '220x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1348.27,
      price: 2696.54,
      sizeX: 230,
      sizeY: 230,
      weight: 16.08837244,
      code: 'B230230',
      description: '230x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1404.78,
      price: 2809.55,
      sizeX: 240,
      sizeY: 230,
      weight: 16.77801486,
      code: 'B240230',
      description: '240x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1090.37,
      price: 2180.74,
      sizeX: 250,
      sizeY: 230,
      weight: 17.46765728,
      code: 'B250230',
      description: '250x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1517.79,
      price: 3035.58,
      sizeX: 260,
      sizeY: 230,
      weight: 18.1572997,
      code: 'B260230',
      description: '260x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1574.3,
      price: 3148.6,
      sizeX: 270,
      sizeY: 230,
      weight: 18.84694212,
      code: 'B270230',
      description: '270x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1630.81,
      price: 3261.61,
      sizeX: 280,
      sizeY: 230,
      weight: 19.53658454,
      code: 'B280230',
      description: '280x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1687.31,
      price: 3374.63,
      sizeX: 290,
      sizeY: 230,
      weight: 20.22622696,
      code: 'B290230',
      description: '290x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1302.28,
      price: 2604.55,
      sizeX: 300,
      sizeY: 230,
      weight: 20.91586938,
      code: 'B300230',
      description: '300x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1800.33,
      price: 3600.66,
      sizeX: 310,
      sizeY: 230,
      weight: 21.6055118,
      code: 'B310230',
      description: '310x230',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 171.06,
      price: 342.12,
      sizeX: 10,
      sizeY: 230,
      weight: 1.369432804,
      code: 'D010230',
      description: '10x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 264.41,
      price: 528.82,
      sizeX: 20,
      sizeY: 230,
      weight: 2.512268814,
      code: 'D020230',
      description: '20x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 357.77,
      price: 715.53,
      sizeX: 30,
      sizeY: 230,
      weight: 3.655104823,
      code: 'D030230',
      description: '30x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 451.12,
      price: 902.24,
      sizeX: 40,
      sizeY: 230,
      weight: 4.797940832,
      code: 'D040230',
      description: '40x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 397.29,
      price: 794.58,
      sizeX: 50,
      sizeY: 230,
      weight: 5.940776841,
      code: 'D050230',
      description: '50x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 637.82,
      price: 1275.65,
      sizeX: 60,
      sizeY: 230,
      weight: 7.083612851,
      code: 'D060230',
      description: '60x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 731.18,
      price: 1462.36,
      sizeX: 70,
      sizeY: 230,
      weight: 8.22644886,
      code: 'D070230',
      description: '70x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 824.53,
      price: 1649.06,
      sizeX: 80,
      sizeY: 230,
      weight: 9.369284869,
      code: 'D080230',
      description: '80x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 747.36,
      price: 1494.73,
      sizeX: 100,
      sizeY: 230,
      weight: 11.65495689,
      code: 'D100230',
      description: '100x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1104.59,
      price: 2209.18,
      sizeX: 110,
      sizeY: 230,
      weight: 12.7977929,
      code: 'D110230',
      description: '110x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1197.94,
      price: 2395.89,
      sizeX: 120,
      sizeY: 230,
      weight: 13.94062891,
      code: 'D120230',
      description: '120x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1291.3,
      price: 2582.59,
      sizeX: 130,
      sizeY: 230,
      weight: 15.08346492,
      code: 'D130230',
      description: '130x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1384.65,
      price: 2769.3,
      sizeX: 140,
      sizeY: 230,
      weight: 16.22630093,
      code: 'D140230',
      description: '140x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 917.88,
      price: 1835.77,
      sizeX: 90,
      sizeY: 230,
      weight: 10.51212088,
      code: 'D090230',
      description: '90x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1097.44,
      price: 2194.88,
      sizeX: 150,
      sizeY: 230,
      weight: 17.36913693,
      code: 'D150230',
      description: '150x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1571.36,
      price: 3142.71,
      sizeX: 160,
      sizeY: 230,
      weight: 18.51197294,
      code: 'D160230',
      description: '160x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1664.71,
      price: 3329.42,
      sizeX: 170,
      sizeY: 230,
      weight: 19.65480895,
      code: 'D170230',
      description: '170x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1447.51,
      price: 2895.02,
      sizeX: 200,
      sizeY: 230,
      weight: 23.08331698,
      code: 'D200230',
      description: '200x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1758.06,
      price: 3516.12,
      sizeX: 180,
      sizeY: 230,
      weight: 20.79764496,
      code: 'D180230',
      description: '180x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1851.41,
      price: 3702.83,
      sizeX: 190,
      sizeY: 230,
      weight: 21.94048097,
      code: 'D190230',
      description: '190x230',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 109.59,
      price: 219.17,
      sizeX: 10,
      sizeY: 240,
      weight: 0.955647353,
      code: 'B010240',
      description: '10x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 168.5,
      price: 337.01,
      sizeX: 20,
      sizeY: 240,
      weight: 1.674845876,
      code: 'B020240',
      description: '20x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 170.56,
      price: 341.13,
      sizeX: 30,
      sizeY: 240,
      weight: 2.394044399,
      code: 'B030240',
      description: '30x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 286.33,
      price: 572.67,
      sizeX: 40,
      sizeY: 240,
      weight: 3.113242922,
      code: 'B040240',
      description: '40x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 253.11,
      price: 506.22,
      sizeX: 50,
      sizeY: 240,
      weight: 3.832441445,
      code: 'B050240',
      description: '50x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 404.16,
      price: 808.33,
      sizeX: 60,
      sizeY: 240,
      weight: 4.551639968,
      code: 'B060240',
      description: '60x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 463.08,
      price: 926.16,
      sizeX: 70,
      sizeY: 240,
      weight: 5.270838491,
      code: 'B070240',
      description: '70x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 522,
      price: 1043.99,
      sizeX: 80,
      sizeY: 240,
      weight: 5.990037014,
      code: 'B080240',
      description: '80x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 580.91,
      price: 1161.82,
      sizeX: 90,
      sizeY: 240,
      weight: 6.709235537,
      code: 'B090240',
      description: '90x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 991.02,
      price: 1982.04,
      sizeX: 240,
      sizeY: 240,
      weight: 11.82244148,
      code: 'A240240',
      description: '240x240',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1030.83,
      price: 2061.65,
      sizeX: 250,
      sizeY: 240,
      weight: 12.30519117,
      code: 'A250240',
      description: '250x240',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1070.64,
      price: 2141.27,
      sizeX: 260,
      sizeY: 240,
      weight: 12.78794086,
      code: 'A260240',
      description: '260x240',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1110.44,
      price: 2220.89,
      sizeX: 270,
      sizeY: 240,
      weight: 13.27069056,
      code: 'A270240',
      description: '270x240',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1150.25,
      price: 2300.51,
      sizeX: 280,
      sizeY: 240,
      weight: 13.75344025,
      code: 'A280240',
      description: '280x240',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 474.04,
      price: 948.08,
      sizeX: 100,
      sizeY: 240,
      weight: 7.42843406,
      code: 'B100240',
      description: '100x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 698.74,
      price: 1397.49,
      sizeX: 110,
      sizeY: 240,
      weight: 8.147632584,
      code: 'B110240',
      description: '110x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 757.66,
      price: 1515.32,
      sizeX: 120,
      sizeY: 240,
      weight: 8.866831107,
      code: 'B120240',
      description: '120x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 816.57,
      price: 1633.15,
      sizeX: 130,
      sizeY: 240,
      weight: 9.58602963,
      code: 'B130240',
      description: '130x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 875.49,
      price: 1750.98,
      sizeX: 140,
      sizeY: 240,
      weight: 10.30522815,
      code: 'B140240',
      description: '140x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 694.97,
      price: 1389.95,
      sizeX: 150,
      sizeY: 240,
      weight: 11.02442668,
      code: 'B150240',
      description: '150x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 993.32,
      price: 1986.64,
      sizeX: 160,
      sizeY: 240,
      weight: 11.7436252,
      code: 'B160240',
      description: '160x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1052.24,
      price: 2104.47,
      sizeX: 170,
      sizeY: 240,
      weight: 12.46282372,
      code: 'B170240',
      description: '170x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1111.15,
      price: 2222.3,
      sizeX: 180,
      sizeY: 240,
      weight: 13.18202225,
      code: 'B180240',
      description: '180x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1170.07,
      price: 2340.13,
      sizeX: 190,
      sizeY: 240,
      weight: 13.90122077,
      code: 'B190240',
      description: '190x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 915.91,
      price: 1831.82,
      sizeX: 200,
      sizeY: 240,
      weight: 14.62041929,
      code: 'B200240',
      description: '200x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1287.9,
      price: 2575.8,
      sizeX: 210,
      sizeY: 240,
      weight: 15.33961781,
      code: 'B210240',
      description: '210x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1346.81,
      price: 2693.63,
      sizeX: 220,
      sizeY: 240,
      weight: 16.05881634,
      code: 'B220240',
      description: '220x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1405.73,
      price: 2811.46,
      sizeX: 230,
      sizeY: 240,
      weight: 16.77801486,
      code: 'B230240',
      description: '230x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1464.64,
      price: 2929.29,
      sizeX: 240,
      sizeY: 240,
      weight: 17.49721338,
      code: 'B240240',
      description: '240x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1136.84,
      price: 2273.68,
      sizeX: 250,
      sizeY: 240,
      weight: 18.21641191,
      code: 'B250240',
      description: '250x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1582.48,
      price: 3164.95,
      sizeX: 260,
      sizeY: 240,
      weight: 18.93561043,
      code: 'B260240',
      description: '260x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1641.39,
      price: 3282.78,
      sizeX: 270,
      sizeY: 240,
      weight: 19.65480895,
      code: 'B270240',
      description: '270x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1700.31,
      price: 3400.61,
      sizeX: 280,
      sizeY: 240,
      weight: 20.37400748,
      code: 'B280240',
      description: '280x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1759.22,
      price: 3518.44,
      sizeX: 290,
      sizeY: 240,
      weight: 21.093206,
      code: 'B290240',
      description: '290x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1357.77,
      price: 2715.55,
      sizeX: 300,
      sizeY: 240,
      weight: 21.81240452,
      code: 'B300240',
      description: '300x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1877.05,
      price: 3754.11,
      sizeX: 310,
      sizeY: 240,
      weight: 22.53160305,
      code: 'B310240',
      description: '310x240',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 178.41,
      price: 356.83,
      sizeX: 10,
      sizeY: 240,
      weight: 1.428545012,
      code: 'D010240',
      description: '10x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 275.78,
      price: 551.56,
      sizeX: 20,
      sizeY: 240,
      weight: 2.620641194,
      code: 'D020240',
      description: '20x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 279.86,
      price: 559.72,
      sizeX: 30,
      sizeY: 240,
      weight: 3.812737376,
      code: 'D030240',
      description: '30x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 470.51,
      price: 941.03,
      sizeX: 40,
      sizeY: 240,
      weight: 5.004833558,
      code: 'D040240',
      description: '40x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 414.37,
      price: 828.74,
      sizeX: 50,
      sizeY: 240,
      weight: 6.19692974,
      code: 'D050240',
      description: '50x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 665.25,
      price: 1330.49,
      sizeX: 60,
      sizeY: 240,
      weight: 7.389025922,
      code: 'D060240',
      description: '60x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 762.61,
      price: 1525.23,
      sizeX: 70,
      sizeY: 240,
      weight: 8.581122104,
      code: 'D070240',
      description: '70x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 859.98,
      price: 1719.96,
      sizeX: 80,
      sizeY: 240,
      weight: 9.773218286,
      code: 'D080240',
      description: '80x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1039.33,
      price: 2078.65,
      sizeX: 100,
      sizeY: 240,
      weight: 12.15741065,
      code: 'D100240',
      description: '100x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1152.08,
      price: 2304.16,
      sizeX: 110,
      sizeY: 240,
      weight: 13.34950683,
      code: 'D110240',
      description: '110x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1249.45,
      price: 2498.89,
      sizeX: 120,
      sizeY: 240,
      weight: 14.54160301,
      code: 'D120240',
      description: '120x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1346.81,
      price: 2693.63,
      sizeX: 130,
      sizeY: 240,
      weight: 15.7336992,
      code: 'D130240',
      description: '130x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1444.18,
      price: 2888.36,
      sizeX: 140,
      sizeY: 240,
      weight: 16.92579538,
      code: 'D140240',
      description: '140x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 957.35,
      price: 1914.69,
      sizeX: 90,
      sizeY: 240,
      weight: 10.96531447,
      code: 'D090240',
      description: '90x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1144.62,
      price: 2289.24,
      sizeX: 150,
      sizeY: 240,
      weight: 18.11789156,
      code: 'D150240',
      description: '150x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1638.91,
      price: 3277.83,
      sizeX: 160,
      sizeY: 240,
      weight: 19.30998774,
      code: 'D160240',
      description: '160x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1736.28,
      price: 3472.56,
      sizeX: 170,
      sizeY: 240,
      weight: 20.50208393,
      code: 'D170240',
      description: '170x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1509.75,
      price: 3019.49,
      sizeX: 200,
      sizeY: 240,
      weight: 24.07837247,
      code: 'D200240',
      description: '200x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1833.65,
      price: 3667.29,
      sizeX: 180,
      sizeY: 240,
      weight: 21.69418011,
      code: 'D180240',
      description: '180x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1931.01,
      price: 3862.03,
      sizeX: 190,
      sizeY: 240,
      weight: 22.88627629,
      code: 'D190240',
      description: '190x240',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 79.48,
      price: 158.97,
      sizeX: 10,
      sizeY: 250,
      weight: 0.995055491,
      code: 'B010250',
      description: '10x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 125.48,
      price: 250.95,
      sizeX: 20,
      sizeY: 250,
      weight: 1.743810118,
      code: 'B020250',
      description: '20x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 148.61,
      price: 297.21,
      sizeX: 30,
      sizeY: 250,
      weight: 2.492564744,
      code: 'B030250',
      description: '30x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 217.46,
      price: 434.92,
      sizeX: 40,
      sizeY: 250,
      weight: 3.241319371,
      code: 'B040250',
      description: '40x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 228.33,
      price: 456.65,
      sizeX: 50,
      sizeY: 250,
      weight: 3.990073998,
      code: 'B050250',
      description: '50x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 309.45,
      price: 618.89,
      sizeX: 60,
      sizeY: 250,
      weight: 4.738828625,
      code: 'B060250',
      description: '60x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 355.44,
      price: 710.88,
      sizeX: 70,
      sizeY: 250,
      weight: 5.487583252,
      code: 'B070250',
      description: '70x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 401.43,
      price: 802.86,
      sizeX: 80,
      sizeY: 250,
      weight: 6.236337878,
      code: 'B080250',
      description: '80x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 804.3,
      price: 1608.6,
      sizeX: 250,
      sizeY: 250,
      weight: 12.80764493,
      code: 'A250250',
      description: '250x250',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1113.81,
      price: 2227.63,
      sizeX: 260,
      sizeY: 250,
      weight: 13.31009869,
      code: 'A260250',
      description: '260x250',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1155.23,
      price: 2310.46,
      sizeX: 270,
      sizeY: 250,
      weight: 13.81255246,
      code: 'A270250',
      description: '270x250',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1196.64,
      price: 2393.29,
      sizeX: 280,
      sizeY: 250,
      weight: 14.31500622,
      code: 'A280250',
      description: '280x250',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 447.43,
      price: 894.85,
      sizeX: 90,
      sizeY: 250,
      weight: 6.985092505,
      code: 'B090250',
      description: '90x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 427.63,
      price: 855.26,
      sizeX: 100,
      sizeY: 250,
      weight: 7.733847132,
      code: 'B100250',
      description: '100x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 539.41,
      price: 1078.82,
      sizeX: 110,
      sizeY: 250,
      weight: 8.482601759,
      code: 'B110250',
      description: '110x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 585.4,
      price: 1170.81,
      sizeX: 120,
      sizeY: 250,
      weight: 9.231356385,
      code: 'B120250',
      description: '120x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 631.4,
      price: 1262.79,
      sizeX: 130,
      sizeY: 250,
      weight: 9.980111012,
      code: 'B130250',
      description: '130x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 677.39,
      price: 1354.78,
      sizeX: 140,
      sizeY: 250,
      weight: 10.72886564,
      code: 'B140250',
      description: '140x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 626.93,
      price: 1253.86,
      sizeX: 150,
      sizeY: 250,
      weight: 11.47762027,
      code: 'B150250',
      description: '150x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 769.37,
      price: 1538.75,
      sizeX: 160,
      sizeY: 250,
      weight: 12.22637489,
      code: 'B160250',
      description: '160x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 815.37,
      price: 1630.73,
      sizeX: 170,
      sizeY: 250,
      weight: 12.97512952,
      code: 'B170250',
      description: '170x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 861.36,
      price: 1722.72,
      sizeX: 180,
      sizeY: 250,
      weight: 13.72388415,
      code: 'B180250',
      description: '180x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 907.35,
      price: 1814.71,
      sizeX: 190,
      sizeY: 250,
      weight: 14.47263877,
      code: 'B190250',
      description: '190x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 826.23,
      price: 1652.47,
      sizeX: 200,
      sizeY: 250,
      weight: 15.2213934,
      code: 'B200250',
      description: '200x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 999.34,
      price: 1998.68,
      sizeX: 210,
      sizeY: 250,
      weight: 15.97014803,
      code: 'B210250',
      description: '210x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1045.33,
      price: 2090.66,
      sizeX: 220,
      sizeY: 250,
      weight: 16.71890265,
      code: 'B220250',
      description: '220x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1091.32,
      price: 2182.65,
      sizeX: 230,
      sizeY: 250,
      weight: 17.46765728,
      code: 'B230250',
      description: '230x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1137.32,
      price: 2274.63,
      sizeX: 240,
      sizeY: 250,
      weight: 18.21641191,
      code: 'B240250',
      description: '240x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1025.53,
      price: 2051.07,
      sizeX: 250,
      sizeY: 250,
      weight: 18.96516653,
      code: 'B250250',
      description: '250x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1229.3,
      price: 2458.6,
      sizeX: 260,
      sizeY: 250,
      weight: 19.71392116,
      code: 'B260250',
      description: '260x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1275.3,
      price: 2550.59,
      sizeX: 270,
      sizeY: 250,
      weight: 20.46267579,
      code: 'B270250',
      description: '270x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1321.29,
      price: 2642.58,
      sizeX: 280,
      sizeY: 250,
      weight: 21.21143041,
      code: 'B280250',
      description: '280x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1367.28,
      price: 2734.56,
      sizeX: 290,
      sizeY: 250,
      weight: 21.96018504,
      code: 'B290250',
      description: '290x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1224.84,
      price: 2449.67,
      sizeX: 300,
      sizeY: 250,
      weight: 22.70893967,
      code: 'B300250',
      description: '300x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1459.27,
      price: 2918.53,
      sizeX: 310,
      sizeY: 250,
      weight: 23.45769429,
      code: 'B310250',
      description: '310x250',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 139.33,
      price: 278.65,
      sizeX: 10,
      sizeY: 250,
      weight: 1.487657219,
      code: 'D010250',
      description: '10x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 215.36,
      price: 430.72,
      sizeX: 20,
      sizeY: 250,
      weight: 2.729013574,
      code: 'D020250',
      description: '20x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 252.54,
      price: 505.09,
      sizeX: 30,
      sizeY: 250,
      weight: 3.970369929,
      code: 'D030250',
      description: '30x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 367.43,
      price: 734.86,
      sizeX: 40,
      sizeY: 250,
      weight: 5.211726284,
      code: 'D040250',
      description: '40x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 373.92,
      price: 747.85,
      sizeX: 50,
      sizeY: 250,
      weight: 6.453082639,
      code: 'D050250',
      description: '50x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 519.5,
      price: 1039,
      sizeX: 60,
      sizeY: 250,
      weight: 7.694438994,
      code: 'D060250',
      description: '60x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 595.54,
      price: 1191.07,
      sizeX: 70,
      sizeY: 250,
      weight: 8.935795349,
      code: 'D070250',
      description: '70x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 671.57,
      price: 1343.14,
      sizeX: 80,
      sizeY: 250,
      weight: 10.1771517,
      code: 'D080250',
      description: '80x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 703.41,
      price: 1406.82,
      sizeX: 100,
      sizeY: 250,
      weight: 12.65986441,
      code: 'D100250',
      description: '100x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 899.68,
      price: 1799.35,
      sizeX: 110,
      sizeY: 250,
      weight: 13.90122077,
      code: 'D110250',
      description: '110x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 975.71,
      price: 1951.43,
      sizeX: 120,
      sizeY: 250,
      weight: 15.14257712,
      code: 'D120250',
      description: '120x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1051.75,
      price: 2103.5,
      sizeX: 130,
      sizeY: 250,
      weight: 16.38393348,
      code: 'D130250',
      description: '130x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1127.78,
      price: 2255.57,
      sizeX: 140,
      sizeY: 250,
      weight: 17.62528983,
      code: 'D140250',
      description: '140x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 996.81,
      price: 1993.62,
      sizeX: 90,
      sizeY: 250,
      weight: 11.41850806,
      code: 'D090250',
      description: '90x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1032.9,
      price: 2065.79,
      sizeX: 150,
      sizeY: 250,
      weight: 18.86664619,
      code: 'D150250',
      description: '150x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1279.85,
      price: 2559.71,
      sizeX: 160,
      sizeY: 250,
      weight: 20.10800254,
      code: 'D160250',
      description: '160x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1355.89,
      price: 2711.78,
      sizeX: 170,
      sizeY: 250,
      weight: 21.3493589,
      code: 'D170250',
      description: '170x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1362.38,
      price: 2724.76,
      sizeX: 200,
      sizeY: 250,
      weight: 25.07342796,
      code: 'D200250',
      description: '200x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1431.92,
      price: 2863.85,
      sizeX: 180,
      sizeY: 250,
      weight: 22.59071525,
      code: 'D180250',
      description: '180x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2010.61,
      price: 4021.22,
      sizeX: 190,
      sizeY: 250,
      weight: 23.83207161,
      code: 'D190250',
      description: '190x250',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 118.55,
      price: 237.09,
      sizeX: 10,
      sizeY: 260,
      weight: 1.034463629,
      code: 'B010260',
      description: '10x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 182.28,
      price: 364.56,
      sizeX: 20,
      sizeY: 260,
      weight: 1.81277436,
      code: 'B020260',
      description: '20x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 246.01,
      price: 492.02,
      sizeX: 30,
      sizeY: 260,
      weight: 2.59108509,
      code: 'B030260',
      description: '30x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 309.74,
      price: 619.48,
      sizeX: 40,
      sizeY: 260,
      weight: 3.369395821,
      code: 'B040260',
      description: '40x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 273.8,
      price: 547.6,
      sizeX: 50,
      sizeY: 260,
      weight: 4.147706551,
      code: 'B050260',
      description: '50x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 437.21,
      price: 874.41,
      sizeX: 60,
      sizeY: 260,
      weight: 4.926017281,
      code: 'B060260',
      description: '60x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 500.94,
      price: 1001.88,
      sizeX: 70,
      sizeY: 260,
      weight: 5.704328012,
      code: 'B070260',
      description: '70x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 564.67,
      price: 1129.34,
      sizeX: 80,
      sizeY: 260,
      weight: 6.482638742,
      code: 'B808260',
      description: '80x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1156.99,
      price: 2313.99,
      sizeX: 260,
      sizeY: 260,
      weight: 13.83225653,
      code: 'A260260',
      description: '260x260',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1200.01,
      price: 2400.03,
      sizeX: 270,
      sizeY: 260,
      weight: 14.35441436,
      code: 'A270260',
      description: '270x260',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1243.03,
      price: 2486.07,
      sizeX: 280,
      sizeY: 260,
      weight: 14.87657219,
      code: 'A280260',
      description: '280x260',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 628.4,
      price: 1256.8,
      sizeX: 90,
      sizeY: 260,
      weight: 7.260949473,
      code: 'B090260',
      description: '90x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 512.79,
      price: 1025.59,
      sizeX: 100,
      sizeY: 260,
      weight: 8.039260203,
      code: 'B100260',
      description: '100x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 755.87,
      price: 1511.73,
      sizeX: 110,
      sizeY: 260,
      weight: 8.817570934,
      code: 'B110260',
      description: '110x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 819.6,
      price: 1639.19,
      sizeX: 120,
      sizeY: 260,
      weight: 9.595881664,
      code: 'B120260',
      description: '120x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 883.33,
      price: 1766.66,
      sizeX: 130,
      sizeY: 260,
      weight: 10.37419239,
      code: 'B130260',
      description: '130x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 947.06,
      price: 1894.12,
      sizeX: 140,
      sizeY: 260,
      weight: 11.15250313,
      code: 'B140260',
      description: '140x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 751.79,
      price: 1503.58,
      sizeX: 150,
      sizeY: 260,
      weight: 11.93081386,
      code: 'B150260',
      description: '150x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1074.52,
      price: 2149.05,
      sizeX: 160,
      sizeY: 260,
      weight: 12.70912459,
      code: 'B160260',
      description: '160x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1138.26,
      price: 2276.51,
      sizeX: 170,
      sizeY: 260,
      weight: 13.48743532,
      code: 'B170260',
      description: '170x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1201.99,
      price: 2403.98,
      sizeX: 180,
      sizeY: 260,
      weight: 14.26574605,
      code: 'B180260',
      description: '180x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1265.72,
      price: 2531.44,
      sizeX: 190,
      sizeY: 260,
      weight: 15.04405678,
      code: 'B190260',
      description: '190x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 990.78,
      price: 1981.57,
      sizeX: 200,
      sizeY: 260,
      weight: 15.82236751,
      code: 'B200260',
      description: '200x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1393.18,
      price: 2786.37,
      sizeX: 210,
      sizeY: 260,
      weight: 16.60067824,
      code: 'B210260',
      description: '210x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1456.92,
      price: 2913.83,
      sizeX: 220,
      sizeY: 260,
      weight: 17.37898897,
      code: 'B220260',
      description: '220x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1520.65,
      price: 3041.29,
      sizeX: 230,
      sizeY: 260,
      weight: 18.1572997,
      code: 'B230260',
      description: '230x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1584.38,
      price: 3168.76,
      sizeX: 240,
      sizeY: 260,
      weight: 18.93561043,
      code: 'B240260',
      description: '240x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1229.78,
      price: 2459.56,
      sizeX: 250,
      sizeY: 260,
      weight: 19.71392116,
      code: 'B250260',
      description: '250x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1711.84,
      price: 3423.69,
      sizeX: 260,
      sizeY: 260,
      weight: 20.49223189,
      code: 'B260260',
      description: '260x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1775.57,
      price: 3551.15,
      sizeX: 270,
      sizeY: 260,
      weight: 21.27054262,
      code: 'B270260',
      description: '270x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1839.31,
      price: 3678.61,
      sizeX: 280,
      sizeY: 260,
      weight: 22.04885335,
      code: 'B280260',
      description: '280x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1903.04,
      price: 3806.08,
      sizeX: 290,
      sizeY: 260,
      weight: 22.82716408,
      code: 'B290260',
      description: '290x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1468.77,
      price: 2937.55,
      sizeX: 300,
      sizeY: 260,
      weight: 23.60547481,
      code: 'B300260',
      description: '300x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2030.5,
      price: 4061,
      sizeX: 310,
      sizeY: 260,
      weight: 24.38378554,
      code: 'B310260',
      description: '310x260',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 193.12,
      price: 386.24,
      sizeX: 10,
      sizeY: 260,
      weight: 1.546769426,
      code: 'D010260',
      description: '10x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 298.52,
      price: 597.03,
      sizeX: 20,
      sizeY: 260,
      weight: 2.837385954,
      code: 'D020260',
      description: '20x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 403.91,
      price: 807.82,
      sizeX: 30,
      sizeY: 260,
      weight: 4.128002482,
      code: 'D030260',
      description: '30x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 509.3,
      price: 1018.61,
      sizeX: 40,
      sizeY: 260,
      weight: 5.41861901,
      code: 'D040260',
      description: '40x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 448.53,
      price: 897.06,
      sizeX: 50,
      sizeY: 260,
      weight: 6.709235537,
      code: 'D050260',
      description: '50x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 720.09,
      price: 1440.18,
      sizeX: 60,
      sizeY: 260,
      weight: 7.999852065,
      code: 'D060260',
      description: '60x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 825.48,
      price: 1650.97,
      sizeX: 70,
      sizeY: 260,
      weight: 9.290468593,
      code: 'D070260',
      description: '70x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 930.88,
      price: 1861.76,
      sizeX: 80,
      sizeY: 260,
      weight: 10.58108512,
      code: 'D080260',
      description: '80x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 843.76,
      price: 1687.52,
      sizeX: 100,
      sizeY: 260,
      weight: 13.16231818,
      code: 'D100260',
      description: '100x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1247.06,
      price: 2494.12,
      sizeX: 110,
      sizeY: 260,
      weight: 14.4529347,
      code: 'D110260',
      description: '110x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1352.45,
      price: 2704.91,
      sizeX: 120,
      sizeY: 260,
      weight: 15.74355123,
      code: 'D120260',
      description: '120x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1457.85,
      price: 2915.69,
      sizeX: 130,
      sizeY: 260,
      weight: 17.03416776,
      code: 'D130260',
      description: '130x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1563.24,
      price: 3126.48,
      sizeX: 140,
      sizeY: 260,
      weight: 18.32478429,
      code: 'D140260',
      description: '140x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1036.27,
      price: 2072.54,
      sizeX: 90,
      sizeY: 260,
      weight: 11.87170165,
      code: 'D090260',
      description: '90x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1238.99,
      price: 2477.97,
      sizeX: 150,
      sizeY: 260,
      weight: 19.61540081,
      code: 'D150260',
      description: '150x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1774.03,
      price: 3548.06,
      sizeX: 160,
      sizeY: 260,
      weight: 20.90601734,
      code: 'D160260',
      description: '160x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1879.42,
      price: 3758.85,
      sizeX: 170,
      sizeY: 260,
      weight: 22.19663387,
      code: 'D170260',
      description: '170x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1634.21,
      price: 3268.42,
      sizeX: 200,
      sizeY: 260,
      weight: 26.06848345,
      code: 'D200260',
      description: '200x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1984.82,
      price: 3969.63,
      sizeX: 180,
      sizeY: 260,
      weight: 23.4872504,
      code: 'D180260',
      description: '180x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2090.21,
      price: 4180.42,
      sizeX: 190,
      sizeY: 260,
      weight: 24.77786693,
      code: 'D190260',
      description: '190x260',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 123.03,
      price: 246.05,
      sizeX: 10,
      sizeY: 270,
      weight: 1.073871767,
      code: 'B010270',
      description: '10x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 189.17,
      price: 378.33,
      sizeX: 20,
      sizeY: 270,
      weight: 1.881738602,
      code: 'B020270',
      description: '20x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 255.31,
      price: 510.61,
      sizeX: 30,
      sizeY: 270,
      weight: 2.689605436,
      code: 'B030270',
      description: '30x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 321.45,
      price: 642.89,
      sizeX: 40,
      sizeY: 270,
      weight: 3.49747227,
      code: 'B040270',
      description: '40x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 284.15,
      price: 568.29,
      sizeX: 50,
      sizeY: 270,
      weight: 4.305339104,
      code: 'B050270',
      description: '50x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 453.73,
      price: 907.45,
      sizeX: 60,
      sizeY: 270,
      weight: 5.113205938,
      code: 'B060270',
      description: '60x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1244.8,
      price: 2489.59,
      sizeX: 270,
      sizeY: 270,
      weight: 14.89627626,
      code: 'A270270',
      description: '270x270',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1289.42,
      price: 2578.84,
      sizeX: 280,
      sizeY: 270,
      weight: 15.43813816,
      code: 'A280270',
      description: '280x270',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 519.87,
      price: 1039.73,
      sizeX: 70,
      sizeY: 270,
      weight: 5.921072772,
      code: 'B070270',
      description: '70x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 586.01,
      price: 1172.01,
      sizeX: 80,
      sizeY: 270,
      weight: 6.728939606,
      code: 'B080270',
      description: '80x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 652.15,
      price: 1304.29,
      sizeX: 90,
      sizeY: 270,
      weight: 7.536806441,
      code: 'B090270',
      description: '90x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 532.17,
      price: 1064.34,
      sizeX: 100,
      sizeY: 270,
      weight: 8.344673275,
      code: 'B100270',
      description: '100x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 784.43,
      price: 1568.85,
      sizeX: 110,
      sizeY: 270,
      weight: 9.152540109,
      code: 'B110270',
      description: '110x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 850.57,
      price: 1701.13,
      sizeX: 120,
      sizeY: 270,
      weight: 9.960406943,
      code: 'B120270',
      description: '120x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 916.71,
      price: 1833.41,
      sizeX: 130,
      sizeY: 270,
      weight: 10.76827378,
      code: 'B130270',
      description: '130x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 982.85,
      price: 1965.69,
      sizeX: 140,
      sizeY: 270,
      weight: 11.57614061,
      code: 'B140270',
      description: '140x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 780.2,
      price: 1560.39,
      sizeX: 150,
      sizeY: 270,
      weight: 12.38400745,
      code: 'B150270',
      description: '150x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1115.13,
      price: 2230.25,
      sizeX: 160,
      sizeY: 270,
      weight: 13.19187428,
      code: 'B160270',
      description: '160x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1181.27,
      price: 2362.53,
      sizeX: 170,
      sizeY: 270,
      weight: 13.99974111,
      code: 'B170270',
      description: '170x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1247.41,
      price: 2494.81,
      sizeX: 180,
      sizeY: 270,
      weight: 14.80760795,
      code: 'B180270',
      description: '180x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1313.55,
      price: 2627.09,
      sizeX: 190,
      sizeY: 270,
      weight: 15.61547478,
      code: 'B190270',
      description: '190x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1028.22,
      price: 2056.44,
      sizeX: 200,
      sizeY: 270,
      weight: 16.42334162,
      code: 'B200270',
      description: '200x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1445.83,
      price: 2891.65,
      sizeX: 210,
      sizeY: 270,
      weight: 17.23120845,
      code: 'B210270',
      description: '210x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1511.97,
      price: 3023.93,
      sizeX: 220,
      sizeY: 270,
      weight: 18.03907528,
      code: 'B220270',
      description: '220x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1578.11,
      price: 3156.21,
      sizeX: 230,
      sizeY: 270,
      weight: 18.84694212,
      code: 'B230270',
      description: '230x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1644.25,
      price: 3288.49,
      sizeX: 240,
      sizeY: 270,
      weight: 19.65480895,
      code: 'B240270',
      description: '240x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1276.25,
      price: 2552.49,
      sizeX: 250,
      sizeY: 270,
      weight: 20.46267579,
      code: 'B250270',
      description: '250x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1776.53,
      price: 3553.05,
      sizeX: 260,
      sizeY: 270,
      weight: 21.27054262,
      code: 'B260270',
      description: '260x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1842.67,
      price: 3685.33,
      sizeX: 270,
      sizeY: 270,
      weight: 22.07840946,
      code: 'B270270',
      description: '270x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1908.81,
      price: 3817.61,
      sizeX: 280,
      sizeY: 270,
      weight: 22.88627629,
      code: 'B280270',
      description: '280x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1974.95,
      price: 3949.89,
      sizeX: 290,
      sizeY: 270,
      weight: 23.69414312,
      code: 'B290270',
      description: '290x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1524.27,
      price: 3048.54,
      sizeX: 300,
      sizeY: 270,
      weight: 24.50200996,
      code: 'B300270',
      description: '300x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2107.23,
      price: 4214.45,
      sizeX: 310,
      sizeY: 270,
      weight: 25.30987679,
      code: 'B310270',
      description: '310x270',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 200.48,
      price: 400.95,
      sizeX: 10,
      sizeY: 270,
      weight: 1.605881634,
      code: 'D010270',
      description: '10x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 309.88,
      price: 619.77,
      sizeX: 20,
      sizeY: 270,
      weight: 2.945758334,
      code: 'D020270',
      description: '20x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 419.29,
      price: 838.58,
      sizeX: 30,
      sizeY: 270,
      weight: 4.285635035,
      code: 'D030270',
      description: '30x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 528.7,
      price: 1057.4,
      sizeX: 40,
      sizeY: 270,
      weight: 5.625511735,
      code: 'D040270',
      description: '40x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 465.61,
      price: 931.22,
      sizeX: 50,
      sizeY: 270,
      weight: 6.965388436,
      code: 'D050270',
      description: '50x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 747.51,
      price: 1495.03,
      sizeX: 60,
      sizeY: 270,
      weight: 8.305265137,
      code: 'D060270',
      description: '60x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 856.92,
      price: 1713.84,
      sizeX: 70,
      sizeY: 270,
      weight: 9.645141837,
      code: 'D070270',
      description: '70x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 966.33,
      price: 1932.66,
      sizeX: 80,
      sizeY: 270,
      weight: 10.98501854,
      code: 'D080270',
      description: '80x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 875.89,
      price: 1751.78,
      sizeX: 100,
      sizeY: 270,
      weight: 13.66477194,
      code: 'D100270',
      description: '100x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1294.55,
      price: 2589.1,
      sizeX: 110,
      sizeY: 270,
      weight: 15.00464864,
      code: 'D110270',
      description: '110x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1403.96,
      price: 2807.91,
      sizeX: 120,
      sizeY: 270,
      weight: 16.34452534,
      code: 'D120270',
      description: '120x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1513.36,
      price: 3026.73,
      sizeX: 130,
      sizeY: 270,
      weight: 17.68440204,
      code: 'D130270',
      description: '130x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1622.77,
      price: 3245.54,
      sizeX: 140,
      sizeY: 270,
      weight: 19.02427874,
      code: 'D140270',
      description: '140x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1075.74,
      price: 2151.47,
      sizeX: 90,
      sizeY: 270,
      weight: 12.32489524,
      code: 'D090270',
      description: '90x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1286.17,
      price: 2572.34,
      sizeX: 150,
      sizeY: 270,
      weight: 20.36415544,
      code: 'D150270',
      description: '150x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1841.59,
      price: 3683.17,
      sizeX: 160,
      sizeY: 270,
      weight: 21.70403214,
      code: 'D160270',
      description: '160x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1950.99,
      price: 3901.99,
      sizeX: 170,
      sizeY: 270,
      weight: 23.04390884,
      code: 'D170270',
      description: '170x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1696.45,
      price: 3392.89,
      sizeX: 200,
      sizeY: 270,
      weight: 27.06353894,
      code: 'D200270',
      description: '200x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2060.4,
      price: 4120.8,
      sizeX: 180,
      sizeY: 270,
      weight: 24.38378554,
      code: 'D180270',
      description: '180x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2169.81,
      price: 4339.62,
      sizeX: 190,
      sizeY: 270,
      weight: 25.72366224,
      code: 'D190270',
      description: '190x270',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 127.51,
      price: 255.01,
      sizeX: 10,
      sizeY: 280,
      weight: 1.113279906,
      code: 'B010280',
      description: '10x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 196.05,
      price: 392.11,
      sizeX: 20,
      sizeY: 280,
      weight: 1.950702843,
      code: 'B020280',
      description: '20x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 264.6,
      price: 529.21,
      sizeX: 30,
      sizeY: 280,
      weight: 2.788125781,
      code: 'B030280',
      description: '30x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 333.15,
      price: 666.3,
      sizeX: 40,
      sizeY: 280,
      weight: 3.625548719,
      code: 'B040280',
      description: '40x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 294.49,
      price: 588.99,
      sizeX: 50,
      sizeY: 280,
      weight: 4.462971657,
      code: 'B050280',
      description: '50x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 470.25,
      price: 940.49,
      sizeX: 60,
      sizeY: 280,
      weight: 5.300394595,
      code: 'B060280',
      description: '60x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1335.81,
      price: 2671.62,
      sizeX: 280,
      sizeY: 280,
      weight: 15.99970413,
      code: 'A280280',
      description: '280x280',
      eye: '10x10',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 538.8,
      price: 1077.59,
      sizeX: 70,
      sizeY: 280,
      weight: 6.137817533,
      code: 'B070280',
      description: '70x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 607.34,
      price: 1214.69,
      sizeX: 80,
      sizeY: 280,
      weight: 6.975240471,
      code: 'B080280',
      description: '80x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 675.89,
      price: 1351.78,
      sizeX: 90,
      sizeY: 280,
      weight: 7.812663408,
      code: 'B090280',
      description: '90x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 551.55,
      price: 1103.1,
      sizeX: 100,
      sizeY: 280,
      weight: 8.650086346,
      code: 'B100280',
      description: '100x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 812.99,
      price: 1625.98,
      sizeX: 110,
      sizeY: 280,
      weight: 9.487509284,
      code: 'B110280',
      description: '110x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 881.54,
      price: 1763.07,
      sizeX: 120,
      sizeY: 280,
      weight: 10.32493222,
      code: 'B120280',
      description: '120x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 950.08,
      price: 1900.17,
      sizeX: 130,
      sizeY: 280,
      weight: 11.16235516,
      code: 'B130280',
      description: '130x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1018.63,
      price: 2037.26,
      sizeX: 140,
      sizeY: 280,
      weight: 11.9997781,
      code: 'B140280',
      description: '140x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 808.6,
      price: 1617.21,
      sizeX: 150,
      sizeY: 280,
      weight: 12.83720104,
      code: 'B150280',
      description: '150x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1155.73,
      price: 2311.46,
      sizeX: 160,
      sizeY: 280,
      weight: 13.67462397,
      code: 'B160280',
      description: '160x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1224.28,
      price: 2448.55,
      sizeX: 170,
      sizeY: 280,
      weight: 14.51204691,
      code: 'B170280',
      description: '170x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1292.82,
      price: 2585.65,
      sizeX: 180,
      sizeY: 280,
      weight: 15.34946985,
      code: 'B180280',
      description: '180x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1361.37,
      price: 2722.75,
      sizeX: 190,
      sizeY: 280,
      weight: 16.18689279,
      code: 'B190280',
      description: '190x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1065.66,
      price: 2131.32,
      sizeX: 200,
      sizeY: 280,
      weight: 17.02431572,
      code: 'B200280',
      description: '200x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1498.47,
      price: 2996.94,
      sizeX: 210,
      sizeY: 280,
      weight: 17.86173866,
      code: 'B210280',
      description: '210x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1567.02,
      price: 3134.03,
      sizeX: 220,
      sizeY: 280,
      weight: 18.6991616,
      code: 'B220280',
      description: '220x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1635.57,
      price: 3271.13,
      sizeX: 230,
      sizeY: 280,
      weight: 19.53658454,
      code: 'B230280',
      description: '230x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1704.11,
      price: 3408.23,
      sizeX: 240,
      sizeY: 280,
      weight: 20.37400748,
      code: 'B240280',
      description: '240x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1322.72,
      price: 2645.43,
      sizeX: 250,
      sizeY: 280,
      weight: 21.21143041,
      code: 'B250280',
      description: '250x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1841.21,
      price: 3682.42,
      sizeX: 260,
      sizeY: 280,
      weight: 22.04885335,
      code: 'B260280',
      description: '260x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1909.76,
      price: 3819.52,
      sizeX: 270,
      sizeY: 280,
      weight: 22.88627629,
      code: 'B270280',
      description: '270x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1978.31,
      price: 3956.61,
      sizeX: 280,
      sizeY: 280,
      weight: 23.72369923,
      code: 'B280280',
      description: '280x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2046.85,
      price: 4093.71,
      sizeX: 290,
      sizeY: 280,
      weight: 24.56112217,
      code: 'B290280',
      description: '290x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1579.77,
      price: 3159.54,
      sizeX: 300,
      sizeY: 280,
      weight: 25.3985451,
      code: 'B300280',
      description: '300x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2183.95,
      price: 4367.9,
      sizeX: 310,
      sizeY: 280,
      weight: 26.23596804,
      code: 'B310280',
      description: '310x280',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 207.83,
      price: 415.66,
      sizeX: 10,
      sizeY: 280,
      weight: 1.664993841,
      code: 'D010280',
      description: '10x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 321.25,
      price: 642.5,
      sizeX: 20,
      sizeY: 280,
      weight: 3.054130715,
      code: 'D020280',
      description: '20x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 434.67,
      price: 869.34,
      sizeX: 30,
      sizeY: 280,
      weight: 4.443267588,
      code: 'D030280',
      description: '30x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 548.09,
      price: 1096.19,
      sizeX: 40,
      sizeY: 280,
      weight: 5.832404461,
      code: 'D040280',
      description: '40x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 482.69,
      price: 965.39,
      sizeX: 50,
      sizeY: 280,
      weight: 7.221541335,
      code: 'D050280',
      description: '50x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 774.94,
      price: 1549.87,
      sizeX: 60,
      sizeY: 280,
      weight: 8.610678208,
      code: 'D060280',
      description: '60x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 888.36,
      price: 1776.71,
      sizeX: 70,
      sizeY: 280,
      weight: 9.999815081,
      code: 'D070280',
      description: '70x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1001.78,
      price: 2003.55,
      sizeX: 80,
      sizeY: 280,
      weight: 11.38895195,
      code: 'D080280',
      description: '80x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 908.02,
      price: 1816.04,
      sizeX: 100,
      sizeY: 280,
      weight: 14.1672257,
      code: 'D100280',
      description: '100x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1342.04,
      price: 2684.08,
      sizeX: 110,
      sizeY: 280,
      weight: 15.55636257,
      code: 'D110280',
      description: '110x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1455.46,
      price: 2910.92,
      sizeX: 120,
      sizeY: 280,
      weight: 16.94549945,
      code: 'D120280',
      description: '120x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1568.88,
      price: 3137.76,
      sizeX: 130,
      sizeY: 280,
      weight: 18.33463632,
      code: 'D130280',
      description: '130x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1682.3,
      price: 3364.61,
      sizeX: 140,
      sizeY: 280,
      weight: 19.7237732,
      code: 'D140280',
      description: '140x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1115.2,
      price: 2230.4,
      sizeX: 90,
      sizeY: 280,
      weight: 12.77808883,
      code: 'D090280',
      description: '90x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1346.79,
      price: 2693.59,
      sizeX: 150,
      sizeY: 280,
      weight: 21.11291007,
      code: 'D150280',
      description: '150x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1909.14,
      price: 3818.29,
      sizeX: 160,
      sizeY: 280,
      weight: 22.50204694,
      code: 'D160280',
      description: '160x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2022.57,
      price: 4045.13,
      sizeX: 170,
      sizeY: 280,
      weight: 23.89118382,
      code: 'D170280',
      description: '170x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1758.68,
      price: 3517.36,
      sizeX: 200,
      sizeY: 280,
      weight: 28.05859444,
      code: 'D200280',
      description: '200x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2135.99,
      price: 4271.97,
      sizeX: 180,
      sizeY: 280,
      weight: 25.28032069,
      code: 'D180280',
      description: '180x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2249.41,
      price: 4498.82,
      sizeX: 190,
      sizeY: 280,
      weight: 26.66945756,
      code: 'D190280',
      description: '190x280',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 131.99,
      price: 263.97,
      sizeX: 10,
      sizeY: 290,
      weight: 1.152688044,
      code: 'B010290',
      description: '10x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 202.94,
      price: 405.88,
      sizeX: 20,
      sizeY: 290,
      weight: 2.019667085,
      code: 'B020290',
      description: '20x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 273.9,
      price: 547.8,
      sizeX: 30,
      sizeY: 290,
      weight: 2.886646127,
      code: 'B030290',
      description: '30x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 344.86,
      price: 689.71,
      sizeX: 40,
      sizeY: 290,
      weight: 3.753625168,
      code: 'B040290',
      description: '40x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 304.84,
      price: 609.68,
      sizeX: 50,
      sizeY: 290,
      weight: 4.62060421,
      code: 'B050290',
      description: '50x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 486.77,
      price: 973.54,
      sizeX: 60,
      sizeY: 290,
      weight: 5.487583252,
      code: 'B060290',
      description: '60x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 557.72,
      price: 1115.45,
      sizeX: 70,
      sizeY: 290,
      weight: 6.354562293,
      code: 'B070290',
      description: '70x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 628.68,
      price: 1257.36,
      sizeX: 80,
      sizeY: 290,
      weight: 7.221541335,
      code: 'B080290',
      description: '80x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 699.64,
      price: 1399.27,
      sizeX: 90,
      sizeY: 290,
      weight: 8.088520376,
      code: 'B090290',
      description: '90x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 570.93,
      price: 1141.85,
      sizeX: 100,
      sizeY: 290,
      weight: 8.955499418,
      code: 'B100290',
      description: '100x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 841.55,
      price: 1683.1,
      sizeX: 110,
      sizeY: 290,
      weight: 9.822478459,
      code: 'B110290',
      description: '110x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 912.51,
      price: 1825.01,
      sizeX: 120,
      sizeY: 290,
      weight: 10.6894575,
      code: 'B120290',
      description: '120x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 983.46,
      price: 1966.92,
      sizeX: 130,
      sizeY: 290,
      weight: 11.55643654,
      code: 'B130290',
      description: '130x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1054.42,
      price: 2108.84,
      sizeX: 140,
      sizeY: 290,
      weight: 12.42341558,
      code: 'B140290',
      description: '140x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 837.01,
      price: 1674.02,
      sizeX: 150,
      sizeY: 290,
      weight: 13.29039463,
      code: 'B150290',
      description: '150x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1196.33,
      price: 2392.66,
      sizeX: 160,
      sizeY: 290,
      weight: 14.15737367,
      code: 'B160290',
      description: '160x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1267.29,
      price: 2534.57,
      sizeX: 170,
      sizeY: 290,
      weight: 15.02435271,
      code: 'B170290',
      description: '170x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1338.24,
      price: 2676.49,
      sizeX: 180,
      sizeY: 290,
      weight: 15.89133175,
      code: 'B180290',
      description: '180x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1409.2,
      price: 2818.4,
      sizeX: 190,
      sizeY: 290,
      weight: 16.75831079,
      code: 'B190290',
      description: '190x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1103.1,
      price: 2206.2,
      sizeX: 200,
      sizeY: 290,
      weight: 17.62528983,
      code: 'B200290',
      description: '200x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1551.11,
      price: 3102.22,
      sizeX: 210,
      sizeY: 290,
      weight: 18.49226887,
      code: 'B210290',
      description: '210x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1622.07,
      price: 3244.14,
      sizeX: 220,
      sizeY: 290,
      weight: 19.35924792,
      code: 'B220290',
      description: '220x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1693.02,
      price: 3386.05,
      sizeX: 230,
      sizeY: 290,
      weight: 20.22622696,
      code: 'B230290',
      description: '230x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1763.98,
      price: 3527.96,
      sizeX: 240,
      sizeY: 290,
      weight: 21.093206,
      code: 'B240290',
      description: '240x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1369.18,
      price: 2738.37,
      sizeX: 250,
      sizeY: 290,
      weight: 21.96018504,
      code: 'B250290',
      description: '250x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1905.89,
      price: 3811.79,
      sizeX: 260,
      sizeY: 290,
      weight: 22.82716408,
      code: 'B260290',
      description: '260x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1976.85,
      price: 3953.7,
      sizeX: 270,
      sizeY: 290,
      weight: 23.69414312,
      code: 'B270290',
      description: '270x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2047.81,
      price: 4095.61,
      sizeX: 280,
      sizeY: 290,
      weight: 24.56112217,
      code: 'B280290',
      description: '280x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2118.76,
      price: 4237.52,
      sizeX: 290,
      sizeY: 290,
      weight: 25.42810121,
      code: 'B290290',
      description: '290x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1635.27,
      price: 3270.54,
      sizeX: 300,
      sizeY: 290,
      weight: 26.29508025,
      code: 'B300290',
      description: '300x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2260.68,
      price: 4521.35,
      sizeX: 310,
      sizeY: 290,
      weight: 27.16205929,
      code: 'B310290',
      description: '310x290',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 215.18,
      price: 430.37,
      sizeX: 10,
      sizeY: 290,
      weight: 1.724106049,
      code: 'D010290',
      description: '10x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 332.62,
      price: 665.24,
      sizeX: 20,
      sizeY: 290,
      weight: 3.162503095,
      code: 'D020290',
      description: '20x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 450.05,
      price: 900.11,
      sizeX: 30,
      sizeY: 290,
      weight: 4.600900141,
      code: 'D030290',
      description: '30x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 567.49,
      price: 1134.98,
      sizeX: 40,
      sizeY: 290,
      weight: 6.039297187,
      code: 'D040290',
      description: '40x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 499.77,
      price: 999.55,
      sizeX: 50,
      sizeY: 290,
      weight: 7.477694233,
      code: 'D050290',
      description: '50x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 802.36,
      price: 1604.71,
      sizeX: 60,
      sizeY: 290,
      weight: 8.916091279,
      code: 'D060290',
      description: '60x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 919.79,
      price: 1839.58,
      sizeX: 70,
      sizeY: 290,
      weight: 10.35448833,
      code: 'D070290',
      description: '70x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1037.23,
      price: 2074.45,
      sizeX: 80,
      sizeY: 290,
      weight: 11.79288537,
      code: 'D080290',
      description: '80x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 940.15,
      price: 1880.31,
      sizeX: 100,
      sizeY: 290,
      weight: 14.66967946,
      code: 'D100290',
      description: '100x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1389.53,
      price: 2779.06,
      sizeX: 110,
      sizeY: 290,
      weight: 16.10807651,
      code: 'D110290',
      description: '110x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1506.96,
      price: 3013.93,
      sizeX: 120,
      sizeY: 290,
      weight: 17.54647356,
      code: 'D120290',
      description: '120x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1624.4,
      price: 3248.8,
      sizeX: 130,
      sizeY: 290,
      weight: 18.9848706,
      code: 'D130290',
      description: '130x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1741.83,
      price: 3483.67,
      sizeX: 140,
      sizeY: 290,
      weight: 20.42326765,
      code: 'D140290',
      description: '140x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1154.66,
      price: 2309.32,
      sizeX: 90,
      sizeY: 290,
      weight: 13.23128242,
      code: 'D090290',
      description: '90x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1380.53,
      price: 2761.06,
      sizeX: 150,
      sizeY: 290,
      weight: 21.8616647,
      code: 'D150290',
      description: '150x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1976.7,
      price: 3953.41,
      sizeX: 160,
      sizeY: 290,
      weight: 23.30006174,
      code: 'D160290',
      description: '160x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2094.14,
      price: 4188.27,
      sizeX: 170,
      sizeY: 290,
      weight: 24.73845879,
      code: 'D170290',
      description: '170x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1820.91,
      price: 3641.82,
      sizeX: 200,
      sizeY: 290,
      weight: 29.05364993,
      code: 'D200290',
      description: '200x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2211.57,
      price: 4423.14,
      sizeX: 180,
      sizeY: 290,
      weight: 26.17685583,
      code: 'D180290',
      description: '180x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2329.01,
      price: 4658.01,
      sizeX: 190,
      sizeY: 290,
      weight: 27.61525288,
      code: 'D190290',
      description: '190x290',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 95.09,
      price: 190.19,
      sizeX: 10,
      sizeY: 300,
      weight: 1.192096182,
      code: 'B010300',
      description: '10x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 150.12,
      price: 300.23,
      sizeX: 20,
      sizeY: 300,
      weight: 2.088631327,
      code: 'B020300',
      description: '20x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 177.79,
      price: 355.57,
      sizeX: 30,
      sizeY: 300,
      weight: 2.985166473,
      code: 'B030300',
      description: '30x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 260.16,
      price: 520.33,
      sizeX: 40,
      sizeY: 300,
      weight: 3.881701618,
      code: 'B040300',
      description: '40x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 273.16,
      price: 546.32,
      sizeX: 50,
      sizeY: 300,
      weight: 4.778236763,
      code: 'B050300',
      description: '50x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 370.21,
      price: 740.42,
      sizeX: 60,
      sizeY: 300,
      weight: 5.674771908,
      code: 'B060300',
      description: '60x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 425.23,
      price: 850.47,
      sizeX: 70,
      sizeY: 300,
      weight: 6.571307053,
      code: 'B070300',
      description: '70x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 480.26,
      price: 960.51,
      sizeX: 80,
      sizeY: 300,
      weight: 7.467842199,
      code: 'B080300',
      description: '80x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 535.28,
      price: 1070.56,
      sizeX: 90,
      sizeY: 300,
      weight: 8.364377344,
      code: 'B090300',
      description: '90x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 511.6,
      price: 1023.19,
      sizeX: 100,
      sizeY: 300,
      weight: 9.260912489,
      code: 'B100300',
      description: '100x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 645.33,
      price: 1290.65,
      sizeX: 110,
      sizeY: 300,
      weight: 10.15744763,
      code: 'B110300',
      description: '110x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 700.35,
      price: 1400.7,
      sizeX: 120,
      sizeY: 300,
      weight: 11.05398278,
      code: 'B120300',
      description: '120x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 755.37,
      price: 1510.75,
      sizeX: 130,
      sizeY: 300,
      weight: 11.95051792,
      code: 'B130300',
      description: '130x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 810.4,
      price: 1620.79,
      sizeX: 140,
      sizeY: 300,
      weight: 12.84705307,
      code: 'B140300',
      description: '140x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 750.03,
      price: 1500.06,
      sizeX: 150,
      sizeY: 300,
      weight: 13.74358822,
      code: 'B150300',
      description: '150x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 920.44,
      price: 1840.89,
      sizeX: 160,
      sizeY: 300,
      weight: 14.64012336,
      code: 'B160300',
      description: '160x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 975.47,
      price: 1950.93,
      sizeX: 170,
      sizeY: 300,
      weight: 15.53665851,
      code: 'B170300',
      description: '170x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1030.49,
      price: 2060.98,
      sizeX: 180,
      sizeY: 300,
      weight: 16.43319365,
      code: 'B180300',
      description: '180x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1085.51,
      price: 2171.03,
      sizeX: 190,
      sizeY: 300,
      weight: 17.3297288,
      code: 'B190300',
      description: '190x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 988.46,
      price: 1976.93,
      sizeX: 200,
      sizeY: 300,
      weight: 18.22626394,
      code: 'B200300',
      description: '200x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1594.08,
      price: 3188.16,
      sizeX: 210,
      sizeY: 300,
      weight: 19.12279909,
      code: 'B210300',
      description: '210x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1667.44,
      price: 3334.89,
      sizeX: 220,
      sizeY: 300,
      weight: 20.01933423,
      code: 'B220300',
      description: '220x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1740.81,
      price: 3481.62,
      sizeX: 230,
      sizeY: 300,
      weight: 20.91586938,
      code: 'B230300',
      description: '230x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1814.17,
      price: 3628.35,
      sizeX: 240,
      sizeY: 300,
      weight: 21.81240452,
      code: 'B240300',
      description: '240x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1887.54,
      price: 3775.07,
      sizeX: 250,
      sizeY: 300,
      weight: 22.70893967,
      code: 'B250300',
      description: '250x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1960.9,
      price: 3921.8,
      sizeX: 260,
      sizeY: 300,
      weight: 23.60547481,
      code: 'B260300',
      description: '260x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2034.27,
      price: 4068.53,
      sizeX: 270,
      sizeY: 300,
      weight: 24.50200996,
      code: 'B270300',
      description: '270x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2107.63,
      price: 4215.26,
      sizeX: 280,
      sizeY: 300,
      weight: 25.3985451,
      code: 'B280300',
      description: '280x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2180.99,
      price: 4361.99,
      sizeX: 290,
      sizeY: 300,
      weight: 26.29508025,
      code: 'B290300',
      description: '290x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2254.36,
      price: 4508.72,
      sizeX: 300,
      sizeY: 300,
      weight: 27.19161539,
      code: 'B300300',
      description: '300x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2327.72,
      price: 4655.45,
      sizeX: 310,
      sizeY: 300,
      weight: 28.08815054,
      code: 'B310300',
      description: '310x300',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 203.35,
      price: 406.69,
      sizeX: 10,
      sizeY: 300,
      weight: 1.783218256,
      code: 'D010300',
      description: '10x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 324.79,
      price: 649.59,
      sizeX: 20,
      sizeY: 300,
      weight: 3.270875475,
      code: 'D020300',
      description: '20x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 334.92,
      price: 669.84,
      sizeX: 30,
      sizeY: 300,
      weight: 4.758532694,
      code: 'D030300',
      description: '30x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 567.69,
      price: 1135.38,
      sizeX: 40,
      sizeY: 300,
      weight: 6.246189913,
      code: 'D040300',
      description: '40x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 517.21,
      price: 1034.42,
      sizeX: 50,
      sizeY: 300,
      weight: 7.733847132,
      code: 'D050300',
      description: '50x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 810.59,
      price: 1621.17,
      sizeX: 60,
      sizeY: 300,
      weight: 9.221504351,
      code: 'D060300',
      description: '60x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 932.03,
      price: 1864.07,
      sizeX: 70,
      sizeY: 300,
      weight: 10.70916157,
      code: 'D070300',
      description: '70x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1053.48,
      price: 2106.97,
      sizeX: 80,
      sizeY: 300,
      weight: 12.19681879,
      code: 'D080300',
      description: '80x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 972.94,
      price: 1945.88,
      sizeX: 100,
      sizeY: 300,
      weight: 15.17213323,
      code: 'D100300',
      description: '100x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1417.83,
      price: 2835.65,
      sizeX: 110,
      sizeY: 300,
      weight: 16.65979045,
      code: 'D110300',
      description: '110x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1539.28,
      price: 3078.55,
      sizeX: 120,
      sizeY: 300,
      weight: 18.14744766,
      code: 'D120300',
      description: '120x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1660.72,
      price: 3321.45,
      sizeX: 130,
      sizeY: 300,
      weight: 19.63510488,
      code: 'D130300',
      description: '130x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1782.17,
      price: 3564.34,
      sizeX: 140,
      sizeY: 300,
      weight: 21.1227621,
      code: 'D140300',
      description: '140x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1174.93,
      price: 2349.86,
      sizeX: 90,
      sizeY: 300,
      weight: 13.23128242,
      code: 'D090300',
      description: '90x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1428.67,
      price: 2857.33,
      sizeX: 150,
      sizeY: 300,
      weight: 22.61041932,
      code: 'D150300',
      description: '150x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2025.07,
      price: 4050.14,
      sizeX: 160,
      sizeY: 300,
      weight: 24.09807654,
      code: 'D160300',
      description: '160x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2146.52,
      price: 4293.03,
      sizeX: 170,
      sizeY: 300,
      weight: 25.58573376,
      code: 'D170300',
      description: '170x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2267.96,
      price: 4535.93,
      sizeX: 180,
      sizeY: 300,
      weight: 27.07339098,
      code: 'D180300',
      description: '180x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1884.39,
      price: 3768.79,
      sizeX: 200,
      sizeY: 300,
      weight: 30.04870542,
      code: 'D200300',
      description: '200x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2389.41,
      price: 4778.82,
      sizeX: 190,
      sizeY: 300,
      weight: 28.5610482,
      code: 'D190300',
      description: '190x300',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 292.49,
      price: 584.98,
      sizeX: 30,
      sizeY: 310,
      weight: 3.083686818,
      code: 'B030310',
      description: '30x310',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1656.4,
      price: 3312.8,
      sizeX: 210,
      sizeY: 310,
      weight: 19.7533293,
      code: 'B210310',
      description: '210x310',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1732.17,
      price: 3464.34,
      sizeX: 220,
      sizeY: 310,
      weight: 20.67942055,
      code: 'B220310',
      description: '220x310',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1807.94,
      price: 3615.89,
      sizeX: 230,
      sizeY: 310,
      weight: 21.6055118,
      code: 'B230310',
      description: '230x310',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1883.72,
      price: 3767.43,
      sizeX: 240,
      sizeY: 310,
      weight: 22.53160305,
      code: 'B240310',
      description: '240x310',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2035.26,
      price: 4070.52,
      sizeX: 260,
      sizeY: 310,
      weight: 24.38378554,
      code: 'B260310',
      description: '260x310',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2111.03,
      price: 4222.07,
      sizeX: 270,
      sizeY: 310,
      weight: 25.30987679,
      code: 'B270310',
      description: '270x310',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2186.81,
      price: 4373.61,
      sizeX: 280,
      sizeY: 310,
      weight: 26.23596804,
      code: 'B280310',
      description: '280x310',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 2262.58,
      price: 4525.16,
      sizeX: 290,
      sizeY: 310,
      weight: 27.16205929,
      code: 'B290310',
      description: '290x310',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 1946.63,
      price: 3893.26,
      sizeX: 200,
      sizeY: 310,
      weight: 31.04376091,
      code: 'D200310',
      description: '200x310',
      eye: '10x2.5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 810.27,
      price: 1620.55,
      sizeX: 200,
      sizeY: 320,
      weight: 19.42821216,
      code: 'B200320',
      description: '200x320',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 19.99,
      price: 39.98,
      sizeX: 10,
      sizeY: 40,
      weight: 0.167484588,
      code: 'B010040',
      description: '10x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 28.95,
      price: 57.9,
      sizeX: 10,
      sizeY: 60,
      weight: 0.246300864,
      code: 'B010060',
      description: '10x60',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    },
    {
      isEnabled: true,
      cost: 138.27,
      price: 276.54,
      sizeX: 120,
      sizeY: 40,
      weight: 1.57632553,
      code: 'B120040',
      description: '120x40',
      eye: '10x5',
      name: 'gabionová síť',
      type: 'network',
      plating: 'Galfan',
      diameter: 4
    }
  ];

  dispatch(slice.actions.setProducts(data));
};

export default slice;
/*

import { mailApi } from '../__fakeApi__/mailApi';
import type { AppThunk } from '../store';
import type { Email, Label } from '../types/mail';
import objFromArray from '../utils/objFromArray';

interface MailState {
  emails: {
    byId: Record<string, Email>;
    allIds: string[];
  };
  labels: Label[];
  isSidebarOpen: boolean;
  isComposeOpen: boolean;
}

const initialState: MailState = {
  emails: {
    byId: {},
    allIds: []
  },
  labels: [],
  isSidebarOpen: false,
  isComposeOpen: false
};

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    getLabels(state: MailState, action: PayloadAction<Label[]>): void {
      state.labels = action.payload;
    },
    getEmails(state: MailState, action: PayloadAction<Email[]>): void {
      const emails = action.payload;

      state.emails.byId = objFromArray(emails);
      state.emails.allIds = Object.keys(state.emails.byId);
    },
    getEmail(state: MailState, action: PayloadAction<Email>): void {
      const email = action.payload;

      state.emails.byId[email.id] = email;

      if (!state.emails.allIds.includes(email.id)) {
        state.emails.allIds.push(email.id);
      }
    },
    openSidebar(state: MailState): void {
      state.isSidebarOpen = true;
    },
    closeSidebar(state: MailState): void {
      state.isSidebarOpen = false;
    },
    openCompose(state: MailState): void {
      state.isComposeOpen = true;
    },
    closeCompose(state: MailState): void {
      state.isComposeOpen = false;
    }
  }
});

export const { reducer } = slice;

export const getLabels = (): AppThunk => async (dispatch): Promise<void> => {
  const data = await mailApi.getLabels();

  dispatch(slice.actions.getLabels(data));
};

export const getEmails = ({
  customLabel,
  systemLabel
}: { customLabel: string, systemLabel: string }): AppThunk => async (dispatch): Promise<void> => {
  const data = await mailApi.getEmails({ customLabel, systemLabel });

  dispatch(slice.actions.getEmails(data));
};

export const getEmail = (emailId: string): AppThunk => async (dispatch): Promise<void> => {
  const data = await mailApi.getEmail(emailId);

  dispatch(slice.actions.getEmail(data));
};

export const openSidebar = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.openSidebar());
};

export const closeSidebar = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.closeSidebar());
};

export const openCompose = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.openCompose());
};

export const closeCompose = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.closeCompose());
};

export default slice;

*/
